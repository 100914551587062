export const LOGIN_API_REQUEST = 'LOGIN_API_REQUEST'
export const LOGIN_API_RESPONSE = 'LOGIN_API_RESPONSE'

export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST'
export const VERIFY_TOKEN_RESPONSE = 'VERIFY_TOKEN_RESPONSE'

export const CLEAR_VERIFY_TOKEN_RESPONSE = 'CLEAR_VERIFY_TOKEN_RESPONSE'
export const CLEAR_LOGOUT_RESPONSE = 'CLEAR_LOGOUT_RESPONSE'


export const LOGOUT_API_REQUEST = 'LOGOUT_API_REQUEST'
export const LOGOUT_API_RESPONSE = 'LOGOUT_API_RESPONSE'


export const ADMIN_OTP_VERIFICATION_REQUEST = 'ADMIN_OTP_VERIFICATION_REQUEST'
export const ADMIN_OTP_VERIFICATION_RESPONSE = 'ADMIN_OTP_VERIFICATION_RESPONSE'
export const CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE = 'CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE'
