import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';

const HighlightedText = ({ texts, highlight, isTag }) => {
  const firstHighlightRef = useRef(null);
  return (
    <div className="JoditEditor-border">
      {texts?.map((text, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ))}
      </div>
  
  );
 
 
};

export default HighlightedText;
