require('yup-phone')
import React, { useRef, useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  CardHeader,
} from 'reactstrap'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import { isValidNumberField } from 'helpers/SimpleReactValidators'
import { config } from './CommonFunction'
import {
  updateEmailTemplateRequest,
  getCustomEmailFieldListRequest,
  getTemplateDetailsListByIdRequest,
  getTemplateListRequest,
  updateEmailTemplateStatusRequest,
} from 'store/master/emailTemplate/action'
import JoditEditor from 'jodit-react'
import { getRoleListRequest } from 'store/master/role/action'
import { __t } from 'i18n/translator'
import ReactSwitch from './ReactSwitch'
import { removeTags } from 'helpers/generalUtils'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { SUCCESS } from 'constant/commonConstants'
import Logo from '../../../images/logo.webp'

const initialTemplateState = (values) => {
  return {
    isActive: '',
    subject: '',
    templateDescription: '',
  }
}

const CreateTemplate = (props) => {
  const [roleId, setRoleId] = useState(0)
  const [templateId, setTemplateId] = useState(1)
  const [activeTab, setActiveTab] = useState('toUserTab')
  const [status, setStatus] = useState('')

  const toggle = (tab) => {
    //if (activeTab !== tab)
    setActiveTab(tab)
  }
  const [sideBarPanelTab, setsideBarPanelTab] = useState(0)

  const sideBarToggle = (tab) => {
    //if (activeTab !== tab)
    setsideBarPanelTab(tab)
  }

  const [initialTemplateValues, setInitialTemplateValues] = useState(
    initialTemplateState(),
  )
  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidNumberField()),
    }),
  )
  const editor = useRef(null)

  useEffect(() => {
    props.getRoleList()
    props.templateListRequest()
    props.customEmailFieldRequest()
  }, [])

  useEffect(() => {
    if (props.TemplateList?.length > 0) {
      if (props.TemplateList[0].id) {
        setTemplateId(props.TemplateList[0].id)
        setRoleId(0)
      }
    }
  }, [props.TemplateList])

  useEffect(() => {
    setStatus(props.emailDetailsList?.isActive)
    const emailBody = props.emailDetailsList?.emailBody;
    let modifiedEmailBody;
    if (emailBody) {
      modifiedEmailBody = emailBody.replace(/cid:logo/g, Logo);

    }

    // Replace "cid:logo" with "../../../images/logo.webp"
    const data = {
      isActive: props.emailDetailsList?.isActive,
      subject: props.emailDetailsList?.subject,
      templateDescription: modifiedEmailBody,
    }
    setInitialTemplateValues(data)
  }, [props.emailDetailsList])

  const getTemplateDetailsList = () => {
    let formatValue = {
      templateId: templateId,
      roleId: roleId,
    }
    props.templateDetailsListById(formatValue)
  }

  useEffect(() => {
    getTemplateDetailsList()
  }, [templateId, roleId])

  const handleCopyClick = async (values) => {
    try {
      await navigator.clipboard.writeText(values)
    } catch (error) {
      console.log('Error copying text:', error)
    }
  }

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let modifiedEmailBody = values.templateDescription.replace(Logo, "cid:logo");
      let Data = {
        subject: values.subject,
        emailBody: modifiedEmailBody,
        isActive: status,
        roleId: roleId,
        templateId: templateId,
      }
      props.updateEmailTemplate(Data)
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <>
      <div className="container">
        <Row className="m-0 mt-4 template-box">
          <Col md="3" className="Default_notifications">
            <Card>
              <CardHeader>
                <h4 className="card-title">Default Notifications</h4>
              </CardHeader>
              <CardBody>
                <ul>
                  {props.TemplateList?.map((item, index) => (
                    <li className="" key={index}>
                      <a
                        //href="#"
                        className={`btn ${sideBarPanelTab === index ? 'active' : ''
                          }`}
                        onClick={() => {
                          sideBarToggle(index)
                          setTemplateId(item.id)
                        }}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>

          <Formik
            enableReinitialize={true}
            initialValues={initialTemplateValues}
            onSubmit={formSubmit}
          >
            {({ values, errors, touched, setFieldValue, handleSubmit }) => (
              <>
                <Col md="6" className="edit_template">
                  <Form>
                    <Nav tabs>
                      <NavItem>
                        <Button
                          className={activeTab == 'toUserTab' && 'show'}
                          color="link"
                          onClick={() => {
                            setRoleId(0)
                            toggle('toUserTab')
                            // toggle(0)
                          }}
                        >
                          {'To User'}
                        </Button>
                      </NavItem>
                      {props.RoleList?.map((x, index) => (
                        <NavItem key={index}>
                          <Button
                            className={activeTab === index ? 'show' : ''}
                            color="link"
                            onClick={() => {
                              setRoleId(x.value)
                              toggle(index)
                            }}
                          >
                            {'To ' + x.label}
                          </Button>
                        </NavItem>
                      ))}
                    </Nav>
                    <Card>
                      <CardBody className="pt-0">
                        <FormGroup className="col-12 mt-3 mb-3">
                          <ReactSwitch
                            status={status}
                            checkSwitch={(e) => {
                              setStatus(e)
                              props.templateStatus({
                                isActive: e,
                                roleId: roleId,
                                templateId: templateId,
                              })
                            }}
                          />
                        </FormGroup>
                        <div className="no-floating-label">
                          <FormGroup className="col-12 mt-2">
                            <Label
                              className="form-label"
                              for="exampleEmailSubject"
                            >
                              Email Subject
                              <span className="text-danger required">*</span>
                            </Label>
                            <Field
                              className="form-control"
                              type="text"
                              name="subject"
                              id="exampleEmailSubject"
                              placeholder="Email Subject"
                            />

                            {validator.current.message(
                              __t(
                                'validations.template.template_email_subject_required',
                              ),
                              values.subject,
                              'required',
                              {
                                messages: {
                                  required: __t(
                                    'validations.template.template_email_subject_required',
                                  ),
                                },
                              },
                            )}
                          </FormGroup>
                        </div>

                        <FormGroup className="mb-1">
                          <JoditEditor
                            ref={editor}
                            config={config}
                            rows="2"
                            name="templateDescription "
                            value={values.templateDescription}
                            onBlur={(newContent) => {
                              setFieldValue('templateDescription', newContent)
                            }}
                          />

                          {validator.current.message(
                            __t(
                              'validations.template.template_content_required',
                            ),
                            removeTags(values.templateDescription),
                            'required',
                            {
                              messages: {
                                required: __t(
                                  'validations.template.template_content_required',
                                ),
                              },
                            },
                          )}
                        </FormGroup>
                        <span className="d-block">
                          Allowed HTML tags &lt;div&gt;, &lt;label&gt;,
                          &lt;button&gt;, &lt;span&gt;, &lt;p&gt;, &lt;ul&gt;,
                          &lt;li&gt;, &lt;tr&gt;, &lt;td&gt;, &lt;a&gt;,
                          &lt;br&gt;, &lt;b&gt;, &lt;h1&gt;, &lt;h2&gt;,
                          &lt;hr&gt;
                        </span>
                        <Button
                          type="submit"
                          color="primary"
                          block
                          className="w-auto waves-effect waves-light float-end"
                          onClick={handleSubmit}
                        >
                          {'Submit'}
                        </Button>
                      </CardBody>
                    </Card>
                  </Form>
                </Col>
              </>
            )}
          </Formik>

          <Col md="3" className="Default_notifications">
            <Card>
              <div className="card-header">
                <h4 className="card-title">Insert email placeholders</h4>
              </div>

              <CardBody className="insert_placeholder">
                <ul>
                  {props.CustomEmailFieldList?.map((item, index) => (
                    <li key={index}>
                      <span>{item.customName}</span>
                      <Button
                        className="my-tooltip"
                        data-tooltip-content={'Copy'}
                        color="primary"
                        onClick={() => {
                          ApiResponseMessage(`Copied successfully`, SUCCESS)
                          handleCopyClick(item.customName)
                        }}
                      >
                        <svg
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.009 4H4ZM2.002 6L2 18H12V6H2.002ZM6 4H14V14H16V2H6V4Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Button>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = ({
  templateReducer,
  createUserReducer,
  roleReducer,
}) => {
  let RoleList
  let TempList
  let CustomEmailFieldList
  let emailDetailsList
  let updateTemplateResponse
  if (
    templateReducer.emailDetailsList &&
    templateReducer.emailDetailsList.emailTemplateDetails
  ) {
    emailDetailsList = templateReducer.emailDetailsList.emailTemplateDetails
  }

  if (roleReducer.getRoleList && roleReducer.getRoleList?.length > 0) {
    RoleList = roleReducer.getRoleList?.map((opt) => ({
      value: opt.id,
      label: opt.roleName,
    }))
  }

  if (
    templateReducer.getTemplateList &&
    templateReducer.getTemplateList?.length > 0
  ) {
    TempList = templateReducer.getTemplateList
  }

  if (
    templateReducer.customEmailFieldList &&
    templateReducer.customEmailFieldList?.length > 0
  ) {
    CustomEmailFieldList = templateReducer.customEmailFieldList
  }

  if (templateReducer.editEmailTemaplteList) {
    updateTemplateResponse = templateReducer.editEmailTemaplteList
  }

  return {
    TemplateList: TempList,
    RoleList: RoleList,
    userCreate: createUserReducer.createUserApiResponse,
    CustomEmailFieldList: CustomEmailFieldList,
    emailDetailsList: emailDetailsList,
    updateTemplateResponse: updateTemplateResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleList: (creds) => {
      dispatch(getRoleListRequest(creds))
    },

    updateEmailTemplate: (creds) => {
      dispatch(updateEmailTemplateRequest(creds))
    },

    templateListRequest: (creds) => {
      dispatch(getTemplateListRequest(creds))
    },

    customEmailFieldRequest: (creds) => {
      dispatch(getCustomEmailFieldListRequest(creds))
    },

    templateDetailsListById: (creds) => {
      dispatch(getTemplateDetailsListByIdRequest(creds))
    },

    templateStatus: (creds) => {
      dispatch(updateEmailTemplateStatusRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate)
