import { combineReducers } from 'redux'
import { loginReducer } from './auth/login/reducer'
import { createUserReducer } from './master/user/reducer'
import { lawReducer } from './master/law/reducer'
import { ForgetPasswordReducer } from './auth/passwordforget/reducer'
import { countryReducer } from './master/country/reducer'
import { roleReducer } from './master/role/reducer'
import { categoryReducer } from './master/category/reducer'
import { moduleReducer } from './master/module/reducer'
import sectionReducer from './master/section/reducer'
import { faqReducer } from './faq/reducer'
import sharedReducer from './shared/reducer'
import { reportReducer } from './report/reducer'
//---------------------------------------------------------------
import { UserHomeReducer } from './frontEnd/home/reducer'
import { UserAboutReducer } from './frontEnd/about/reducer'
import { UserFaqReducer } from './frontEnd/faq/reducer'
import { UserCommonReducer } from './frontEnd/common/reducer'
import { userReportReducer } from './frontEnd/report/reducer'
import { UserContactUsReducer } from './frontEnd/contactus/reducer'
import { articleReducer } from './article/reducer'
import { UserTicketReducer } from './frontEnd/tickets/reducer'
import { TicketReducer } from './tickets/reducer'
import { UserArticleReducer } from './frontEnd/article/reducer'
import { AuditLogReducer } from './auditLog/reducer'
import { SubsciberReducer } from './subscriber/reducer'
import { DashboardReducer } from './dashboard/reducer'
import { TemplateReducer } from './master/emailTemplate/reducer'
import { CLEAR_STORE } from './clearstore/actionTypes'
import { serviceLineReducer } from './master/serviceline/reducer'
import { consultationReducer } from './consultation/reducer'
import { teamCreationReducer } from './teamCreation/reducer'

/**
 * Here we add all reducers if we not add then that reducers not work
 * add one reducers only one time do not add reducers multiple times
 * root reducers(combineReducers) we add in store file store.js
 */
export const appReducer = combineReducers({
  categoryReducer: categoryReducer,
  createUserReducer: createUserReducer,
  loginReducer: loginReducer,
  lawReducer: lawReducer,
  forgetPasswordReducer: ForgetPasswordReducer,
  countryReducer: countryReducer,
  roleReducer: roleReducer,
  moduleReducer: moduleReducer,
  sectionReducer: sectionReducer,
  faqReducer: faqReducer,
  articleReducer: articleReducer,
  sharedReducer: sharedReducer,
  reportReducer: reportReducer,
  userHomeReducer: UserHomeReducer,
  userAboutReducer: UserAboutReducer,
  userFaqReducer: UserFaqReducer,
  userCommonReducer: UserCommonReducer,
  userReportReducer: userReportReducer,
  userContactUsReducer: UserContactUsReducer,
  userTicketReducer: UserTicketReducer,
  ticketReducer: TicketReducer,
  userArticleReducer: UserArticleReducer,
  auditLogReducer: AuditLogReducer,
  subsciberReducer: SubsciberReducer,
  dashboardReducer: DashboardReducer,
  templateReducer: TemplateReducer,
  serviceLineReducer: serviceLineReducer,
  consultationReducer: consultationReducer,
  teamCreationReducer: teamCreationReducer,
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
