import { useEffect, useRef, useState, React, useCallback } from 'react'
import { Formik, Field, FieldArray } from 'formik'
import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Card,
  CardBody,
  UncontrolledCollapse,
  Collapse,
  Input
} from 'reactstrap'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { WithContext as ReactTags } from 'react-tag-input'
import SimpleReactValidator from 'simple-react-validator'
import {
  clearReportFaqListResponse,
  createReportRequest,
  deleteFaqSectionRequest,
  deleteReportFaqRequest,
  getCheckReportTitleRequest,
  getReportDetailsRequest,
  getReportFaqListRequest,
  getReportsKeywordsRequest,
} from 'store/report/action'
import { getUserListRequest } from 'store/master/user/action'
import Select from 'react-select'
import JoditEditor from 'jodit-react'

import {
  getCategoryListRequest,
  getSubCatListByCatIdRequest,
} from 'store/master/category/action'
import Dropdown from 'react-bootstrap/Dropdown'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { statusBox } from 'pages/Faq/CommonFunction'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import {
  DANGER,
  ERROR,
  INTERNAL,
  PERSONAL,
  SUCCESS,
} from 'constant/commonConstants'
import { onlyAlpha } from 'helpers/validators'
import 'jodit/build/jodit.min.css'
import {
  APPROVED,
  DRAFTED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
  PUBLISHED
} from 'constant/databaseConstants'
import FaqFilterSelectorFormik from 'components/Selector/FaqFilterSelectorFormik'

import { __t } from 'i18n/translator'

import { formatDateForApi, getDateTimesSlach } from 'helpers/DataTimeHelpers'

import { getPublisherListRequest } from 'store/frontEnd/common/action'

import { clearStore } from 'store/clearstore/actions'
import { store } from 'store/store'
import { reportType } from 'constant/dropdown'
import { getServiceLineListRequest } from 'store/master/serviceline/action'
import { isValidCategoryaField } from 'helpers/SimpleReactValidators'
import EditFaqForm from './EditFaqForm'
import { commonAleartBox, commonConfirmBox } from 'helpers/messagehelpers'
import { statusLableChanges } from 'helpers/generalUtils'

import GroupFaqModal from './GroupFaqModal'
const initialReportState = (values) => {
  return {
    // Report Filter JSON
    isRefresh: false,
    SubCategoryId: '',
    CategoryId: '',
    laws: '',
    sections: '',
    filterStatus: '',
    createdDate: '',

    reportTitle: '',
    reportDescription: '',
    firstApprovalId: '',
    secondApprovalId: '',
    keywords: [],
    videoUrl: '',
    videoTitle: '',
    isDraft: true,
    countryId: '',
    subCategoryId: '',
    categoryId: '',
    version: '',
    reportSection: [
      {
        sectionTitle: '',
        sectionData: {
          description: '',
          orderNo: 0,
          faqs: [],
        },
      },
    ],
    otherCategory: [
      {
        otherCategory: '',
      },
    ],
  }
}
const initialFaqState = (values) => {
  return {
    parentIndex: null,
    subIndex: null,
    values: null,
  }
}

const initialFaqFilter = {
  reportId: '',
  searchText: '',
  filter: {
    categoryId: '',
    subCategoryId: '',
  },
}

const CreateReport = (props) => {
  const navigate = useNavigate()
  const [initialReportValues, setInitialReportValues] = useState(
    initialReportState(),
  )
  const [faqIndex, setAddFaq] = useState(initialFaqState)
  const [faqList, setfaqList] = useState([])
  const [isFaqFilter, setFaqFilter] = useState(false)
  const [isGeneralInfo, setisGeneralInfo] = useState(false)
  const [toggleId, setToggleId] = useState('')
  const [selectedFaqIds, setSelectedFaqIds] = useState([])
  const [isDraFSave, setisDraFSave] = useState(true)
  const [isRedirect, setIsRedirect] = useState(false)
  const [tags, setTags] = useState([])
  const countryData = JSON.parse(localStorage.getItem('countryData'))
  const [serchedValues, setSerchedValues] = useState('')

  const [toggleDescriptionId, setToggleDescriptionId] = useState('')
  const [selectedSection, setSelectedSection] = useState()
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [isActiveClass, setIsActiveClass] = useState(false)

  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false)
  const [isFaqGroupModalOpen, setIsFaqGroupModalOpen] = useState(false)
  const [groupByFaqList, setGroupByFaqList] = useState([])


  const [faqModalData, setFaqModalData] = useState([])
  const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])
  const [page, setPage] = useState(0)
  const [faqFilterData, setFaqFilterData] = useState([])
  const [serviceId, setServiceId] = useState('0')

  const [isCategory, setIsCategory] = useState(true)
  const [scrollToSectionId, setScrollToSectionId] = useState('')
  const [storeFaq, setStoreFaq] = useState([])
  const [faqTotalCount, setFaqTotalCount] = useState(0)

  const loader = useRef(null)

  useEffect(() => {
    if (props.reportFaqList.length > 0) {
      let array = storeFaq
      for (let i = 0; i < props.reportFaqList.length; i++) {
        const index = storeFaq.findIndex(
          (object) => object.id === props.reportFaqList[i]?.id,
        )

        if (index == -1) {
          array.push(props.reportFaqList[i])
        }
      }
      if (array.length > 0) {
        array = array.map((x) => {
          return {
            ...x,
            isParent: false,
            isGroup: false,
          }
        })
        setStoreFaq(array)
      }
    }

    const key = 'id'
    // Create a copy of the reportDownloadList using slice
    const arrayCopy = props.reportFaqList?.slice()
    const arrayUniqueByKey = [
      ...new Map(arrayCopy?.map((item) => [item[key], item])).values(),
    ]
    setfaqList(arrayUniqueByKey?.filter((x) => !selectedFaqIds.includes(x.id)))
  }, [props.reportFaqList])

  useEffect(() => {
    return () => {
      props.clearReportFaqList()
    }
  }, [])

  useEffect(() => {
    let totalCount = 0
    if (
      serchedValues ||
      faqFilterData.categoryId ||
      faqFilterData.date ||
      faqFilterData.law ||
      faqFilterData.section ||
      faqFilterData.subCategoryId
    ) {
      totalCount = props.reportFaqList?.filter((x) =>
        selectedFaqIds.includes(x.id),
      ).length

      totalCount = props.faqTotalCount - totalCount
    } else {
      totalCount = props.faqTotalCount - selectedFaqIds.length
    }


    setFaqTotalCount(totalCount)
  }, [faqList, props.faqTotalCount])

  // scroll pagination start
  const handleObserver = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting) {
      setPage((prev) => prev + 1)
    }
  }, [])
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '30px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])

  const reportFaqFilter = (newSearch = false) => {
    let data = initialFaqFilter
    let faqFilterUpdateValue = faqFilterData

    if (serviceId != '' && serviceId != '0') {
      faqFilterUpdateValue = {
        ...faqFilterUpdateValue,
        categoryId: serviceId,
      }
    }
    let SearchRequest = {
      ...data,
      filter: faqFilterUpdateValue,
      reportId: props.id ? props.id : '',
      offset: newSearch ? 0 : props.reportFaqList.length,
    }

    if (serchedValues && serchedValues.trim().length > 0) {
      SearchRequest = { ...SearchRequest, searchText: serchedValues }
    }
    props.getReportFaqList(SearchRequest)
    // return SearchRequest
  }
  useEffect(() => {
    if (page > 1) {
      reportFaqFilter(false)
    }
  }, [page])
  useEffect(() => {
    if (serchedValues) {
      setPage(0)
      reportFaqFilter(true)
      props.clearReportFaqList()
    } else {
      reportFaqFilter(false)
      setfaqList(storeFaq?.filter((x) => !selectedFaqIds.includes(x.id)))
    }
  }, [serchedValues])

  useEffect(() => {
    setPage(0)
    reportFaqFilter(true)

    if (
      faqFilterData.categoryId ||
      faqFilterData.date ||
      faqFilterData.law ||
      faqFilterData.section ||
      faqFilterData.subCategoryId
    ) {
      props.clearReportFaqList()
    } else {
      setfaqList(storeFaq?.filter((x) => !selectedFaqIds.includes(x.id)))
    }
  }, [faqFilterData])

  // scroll pagination End

  const toggle = (id) => {
    setToggleId(toggleId === id ? '' : id)
  }
  const descriptionToggle = (id) => {
    setToggleDescriptionId(toggleDescriptionId === id ? '' : id)
  }
  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidCategoryaField()),
    }),
  )
  let dispatch = useDispatch()
  const editor = useRef(null)
  const config = {
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'ul',
      'ol',
      '|',
      'center',
      'left',
      'right',
      'justify',
      '|',
      'link',
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ['brush', 'file', 'font'],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  }

  const selectorFilter = {
    searchText: '',
    type: 'REPORT',
    pagination: {
      limit: 0,
      page: 0,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  }

  useEffect(() => {
    store.dispatch(clearStore())
    // props.getServiceLineListRequest({
    //   // searchText: '',
    //   pagination: {
    //     limit: 0,
    //     page: 0,
    //     orderKey: 'createdDate',
    //     orderBy: 'ASC',
    //   },
    // })
  }, [])

  useEffect(() => {
    if (props.categoryList?.length == 1 && !props.id && !isCategory) {
      let categoryId = props.categoryList[0]?.value
        ? props.categoryList[0]?.value
        : ''
      if (categoryId != '') {
        setIsCategory(false)
        props.getUserListRequest({
          ...selectorFilter,
          countryId: countryData != undefined && countryData.id,
          subCatId: '',
          catId: categoryId,
        })

        setIsActiveFilter(true)
        filterSubCategory(categoryId)
        setServiceId(categoryId)
        setInitialReportValues({
          ...initialReportState(),
          categoryId: categoryId,
        })
      }
    }
  }, [props.categoryList])

  useEffect(() => {
    //dispatch(getUserListRequest(selectorFilter))
    dispatch(getCategoryListRequest(selectorFilter))
    dispatch(getReportsKeywordsRequest())
    if (props.id) {
      dispatch(getReportDetailsRequest(props.id))
    }
  }, [])
  useEffect(() => {
    if (props.reportDetails && props.reportDetails?.sectionData?.reportTitle) {
      if (props?.reportDetails?.sectionData?.categoryId) {
        filterSubCategory(
          props.reportDetails?.sectionData?.categoryId,
          props.reportDetails?.sectionData?.reportType,
        )
        props.getUserListRequest({
          ...selectorFilter,
          countryId: props.reportDetails?.sectionData?.countryId,
          catId: props?.reportDetails?.sectionData?.categoryId,
          subCatId: props.reportDetails?.sectionData?.subCategoryId?.split(','),
        })
        props.getPublisherListRequest({
          ...selectorFilter,
          countryId: props.reportDetails?.sectionData?.countryId
            ? props.reportDetails?.sectionData?.countryId
            : '',
          catId: props?.reportDetails?.sectionData?.categoryId,
          subCatId: props.reportDetails?.sectionData?.subCategoryId?.split(','),
        })
      }

      if (
        (props.reportDetails?.sectionData.status === APPROVED ||
          props.reportDetails?.sectionData.status === REJECTED_BY_APPROVER ||
          props.reportDetails?.sectionData.status === REJECTED_BY_PUBLISHER ||
          props.reportDetails?.sectionData.status === PUBLISHED) &&
        props.reportDetails.sectionData.approvalDetails.length > 0
      ) {
        const approvalUsers =
          props.reportDetails.sectionData.approvalDetails?.filter(
            (x) => x.workFlowId == props.reportDetails.sectionData.workFlowId,
          )

        const reportDetails = props.reportDetails.sectionData
        const updatedReportDetails = Object.assign({}, reportDetails, {
          firstApprovalId: approvalUsers && approvalUsers[0]?.approverId,
          secondApprovalId: approvalUsers && approvalUsers[1]?.approverId,
          otherCategory: props.reportDetails?.sectionData?.otherCategory
            ? props.reportDetails?.sectionData?.otherCategory
              ?.split(',')
              ?.map((x) => {
                return { otherCategory: x }
              })
            : [],
          subCategoryId:
            props.reportDetails?.sectionData?.subCategoryId?.split(','),
          // version: props?.reportDetails?.sectionData?.currentVersion
          //   ? props?.reportDetails?.sectionData?.currentVersion.replace(
          //       /\./g,
          //       '',
          //     )
          //   : '',
        })

        setInitialReportValues(updatedReportDetails)
      } else {
        let updateReportDetails = {
          ...props.reportDetails.sectionData,
          otherCategory: props.reportDetails?.sectionData?.otherCategory
            ? props.reportDetails?.sectionData?.otherCategory
              ?.split(',')
              ?.map((x) => {
                return { otherCategory: x }
              })
            : [],
          subCategoryId:
            props.reportDetails?.sectionData?.subCategoryId?.split(','),

          // version: props?.reportDetails?.sectionData?.currentVersion
          //   ? props?.reportDetails?.sectionData?.currentVersion.replace(
          //       /\./g,
          //       '',
          //     )
          //   : '',
        }

        setInitialReportValues(updateReportDetails)
      }

      setTags(
        props.reportDetails?.sectionData?.keywords?.map((x) => ({
          id: x,
          text: x,
        })),
      )
    }

    if (
      props.reportDetails?.sectionData?.status ===
      REJECTED_BY_APPROVER ||
      props.reportDetails?.sectionData?.status ===
      REJECTED_BY_PUBLISHER ||
      props.reportDetails?.sectionData?.status === PUBLISHED ||
      !props.id) {
      setisDraFSave(false)
    }

    if (isRedirect === true && props.status == SUCCESS) {
      resetData()
    }
  }, [props.reportDetails])
  useEffect(() => {
    if (initialReportValues?.firstApprovalId) {
      if (props.userDropdownList?.length > 0) {
        let checkApprovalId = props.userDropdownList?.filter(
          (x) => x.value == initialReportValues.firstApprovalId,
        )
        if (checkApprovalId.length == 0) {
          setInitialReportValues({ ...initialReportValues, firstApprovalId: "" })
        }
      }
    }

  }, [props.userDropdownList])

  useEffect(() => {
    if (initialReportValues?.secondApprovalId) {
      if (props.publisherList?.length > 0) {
        let checkSecondApproval = props.publisherList?.filter(
          (x) => x.value == initialReportValues.secondApprovalId,
        )
        if (checkSecondApproval.length == 0) {
          setInitialReportValues({ ...initialReportValues, secondApprovalId: "" })
        }
      }
    }
  }, [props.publisherList])



  const closeFaqModal = () => {
    setIsFaqModalOpen(false)
    setFaqModalData([])
  }

  const openFaqModal = (index, values) => {
    if (values.newQuestion) {
      values = {
        ...values,
        question: values.newQuestion,
        answer: values.newAnswer,
      }
    }

    setFaqModalData({ updateIndex: index, values })
    setIsFaqModalOpen(true)
  }

  const addSelectedSectionClass = (id, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    setSelectedSection(id)
  }

  const resetData = () => {
    setInitialReportValues(setInitialReportValues())
    setisDraFSave(true)
    navigate('/console/report', {})
    //props.resetAction()
  }

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formattedValues = {
        ...values,
        // countryId: values.countryId,
        countryId: countryData != undefined && countryData.id,

        isDraft: isDraFSave,
        // version: version,
        keywords: tags.map((opt) => opt.id),
        otherCategory: values?.otherCategory?.map((x) => x.otherCategory),
      }

      let isCheckFaq = false
      for (let index = 0; index < values.reportSection.length; index++) {
        isCheckFaq = values.reportSection[index].sectionData.faqs.length > 0
        if (isCheckFaq) {
          break
        }
        if (values.reportSection[index].subSections) {
          for (
            let subIndex = 0;
            subIndex < values.reportSection[index]?.subSections.length;
            subIndex++
          ) {
            isCheckFaq =
              values.reportSection[index].subSections[subIndex].sectionData.faqs
                .length > 0
            if (isCheckFaq) {
              break
            }
          }
        }
      }

      if (isCheckFaq) {
        dispatch(createReportRequest(formattedValues))
      } else {
        ApiResponseMessage('At least one FAQ should be added', DANGER)
      }
    } else {
      validator.current.showMessages()
    }
  }
  const addFaqFilter = (parentIndex, subIndex, values, isOpen) => {
    reportFaqFilter(false)
    setSerchedValues('')
    setAddFaq({
      ...faqIndex,
      parentIndex: parentIndex,
      subIndex: subIndex,
      values: values,
    })
    setfaqList(
      props.reportFaqList?.filter((x) => !selectedFaqIds.includes(x.id)),
    )
    setFaqFilter(true)
  }

  const setFaqValues = (id) => {
    let faqIds = selectedFaqIds
    faqIds.push(id)
    setSelectedFaqIds(faqIds)

    let values = faqIndex.values
    let updatedReportSection = []
    if (faqIndex.subIndex != null) {
      updatedReportSection = {
        ...values,
        reportSection: values.reportSection.map((section, parentIndex) => {
          const updatedSubSections = section.subSections?.map(
            (subSection, subIndex) => {
              let updateFaq = []
              if (
                parentIndex == faqIndex.parentIndex &&
                subIndex == faqIndex.subIndex
              ) {
                const faqs = props.reportFaqList.filter((x) => x.id === id)
                updateFaq = [...subSection.sectionData.faqs, ...faqs]
              } else {
                updateFaq = subSection.sectionData.faqs
              }
              return {
                ...subSection,
                sectionData: {
                  ...subSection.sectionData,
                  faqs: updateFaq,
                },
              }
            },
          )
          return {
            ...section,
            subSections: updatedSubSections,
          }
        }),
      }
    } else {
      updatedReportSection = {
        ...values,
        reportSection: values.reportSection.map((section, parentIndex) => {
          let updateFaq = []
          if (parentIndex == faqIndex.parentIndex) {
            const faqs = storeFaq.filter((x) => x.id === id)
            updateFaq = [...section.sectionData.faqs, ...faqs]
          } else {
            updateFaq = section.sectionData.faqs
          }
          return {
            ...section,
            sectionData: {
              ...section.sectionData,
              faqs: updateFaq,
            },
          }
        }),
      }
    }
    setAddFaq({
      ...faqIndex,
      values: updatedReportSection,
    })

    if (
      serchedValues ||
      faqFilterData.categoryId ||
      faqFilterData.date ||
      faqFilterData.law ||
      faqFilterData.section ||
      faqFilterData.subCategoryId
    ) {
      setfaqList(
        props.reportFaqList?.filter((x) => !selectedFaqIds.includes(x.id)),
      )
    } else {
      setfaqList(storeFaq?.filter((x) => !selectedFaqIds.includes(x.id)))
    }

    setInitialReportValues({
      ...updatedReportSection,
      isRefresh: updatedReportSection.isRefresh ? false : true,
    })
  }

  const deleteGroupFaq = (faqList, faqId) => {
    let groupFaq = [];
    let isParent = false;

    groupFaq = faqList.map((faq) => {
      if (faq.id === faqId) {
        isParent = true;
      }

      if (isParent && faq.isGroup && faq.isParent && faq.id !== faqId) {
        isParent = false;
      }

      if (isParent && !faq.isGroup) {
        isParent = false;
      }

      if (isParent || !faq.isGroup) {
        return faq;
      }
    }).filter(Boolean);

    let newFaqList = groupFaq.map((faq) => {
      return {
        ...faq,
        isGroup: false,
        isParent: false,
      };
    })?.filter(x => x.id != faqId)
    return newFaqList
  }
  const unGroupFaq = (faqList, faqId, index, setFieldValue) => {

    let groupFaq = [];
    let isParent = false;

    groupFaq = faqList.map((faq) => {
      if (faq.id === faqId) {
        isParent = true;
      }

      if (isParent && faq.isGroup && faq.isParent && faq.id !== faqId) {
        isParent = false;
      }

      if (isParent && !faq.isGroup) {
        isParent = false;
      }
      if (isParent) {
        return faq;
      }
    }).filter(Boolean);

    let newFaqList = faqList.map((faq) => {
      const updateFaq = groupFaq.find((x) => x.id === faq.id);
      const data = updateFaq ? {
        isGroup: false,
        isParent: false
      } : {}
      return {
        ...faq,
        ...data
      };
    });

    if (index) {
      setFieldValue(index, newFaqList)
    }
    return newFaqList
  }




  const deleteFaqValues = (parentIndex, subIndex, values, id, sectionFaqId) => {
    if (sectionFaqId && id && values?.reportId) {
      dispatch(
        deleteReportFaqRequest(
          { faqId: sectionFaqId, reportId: props.id, mainFaqId: id },
          values.reportId,
        ),
      )
    }
    let updatedReportSection = []
    if (subIndex != null) {
      updatedReportSection = {
        ...values,
        reportSection: values.reportSection.map((section) => {
          const updatedSubSections = section.subSections?.map((subSection) => {
            let updatedFaqs = subSection.sectionData.faqs?.filter(
              (faq) => faq.id !== id,
            )
            const deleteFaq = subSection.sectionData.faqs?.filter(
              (faq) => faq.id == id,
            )[0]

            if (deleteFaq && deleteFaq?.isGroup && deleteFaq?.isParent) {

              let groupFaqs = deleteGroupFaq(subSection.sectionData.faqs, id)

              updatedFaqs = updatedFaqs?.map((faq) => {
                const updateFaq = groupFaqs.find((x) => x.id === faq.id);
                return {
                  ...faq,
                  ...updateFaq
                };
              });
              //let groupIds = groupFaqs?.map(x => x.id)
              //updatedFaqs = updatedFaqs?.filter(x => !groupIds?.includes(x.id))
              //updatedFaqs = [...updatedFaqs, ...groupFaqs]
            }
            return {
              ...subSection,
              sectionData: {
                ...subSection.sectionData,
                faqs: updatedFaqs,
              },
            }
          })
          return {
            ...section,
            subSections: updatedSubSections,
          }
        }),
      }
    } else {
      updatedReportSection = {
        ...values,
        reportSection: values.reportSection.map((section) => {
          let updatedFaqs = section.sectionData.faqs.filter(
            (faq) => faq.id !== id,
          )
          const deleteFaq = section.sectionData.faqs.filter(
            (faq) => faq.id == id,
          )[0]

          if (deleteFaq && deleteFaq.isGroup && deleteFaq.isParent) {
            let groupFaqs = deleteGroupFaq(section.sectionData.faqs, id)
            //let groupIds = groupFaqs?.map(x => x.id)
            //updatedFaqs = updatedFaqs?.filter(x => !groupIds.includes(x.id))
            updatedFaqs = updatedFaqs?.map((faq) => {
              const updateFaq = groupFaqs.find((x) => x.id === faq.id);
              return {
                ...faq,
                ...updateFaq
              };
            });
            //updatedFaqs = [...updatedFaqs, ...groupFaqs]
          }
          return {
            ...section,
            sectionData: {
              ...section.sectionData,
              faqs: updatedFaqs,
            },
          }
        }),
      }
    }

    values = updatedReportSection
    let faqIds = selectedFaqIds?.filter((x) => x != id)
    setSelectedFaqIds(faqIds)
    setfaqList(props.reportFaqList?.filter((x) => !faqIds.includes(x.id)))
    setInitialReportValues(values)
    closeFaqFilter()
  }
  const deleteAllfaqInOneSection = (values, sectionId) => {
    if (sectionId) {
      dispatch(
        deleteFaqSectionRequest(
          { sectionId: sectionId, reportId: props.id },
          props.id,
        ),
      )
    }
    let parentIds = values?.sectionData?.faqs?.map((x) => x.id)
    let subIds = values?.subSections
      ?.map((parent) => {
        const children = parent.sectionData?.faqs?.map((child) => {
          return child.id
        })
        return children
      })

      .flat()
    let deleteIds = []
    if (subIds && subIds?.length > 0) {
      deleteIds = subIds
    }
    if (parentIds && parentIds?.length > 0) {
      deleteIds = parentIds
    }
    if (parentIds && parentIds?.length > 0 && subIds && subIds?.length > 0) {
      deleteIds = [...parentIds, ...subIds]
    }

    let faqIds = selectedFaqIds?.filter((x) => !deleteIds.includes(x))
    if (faqIds) {
      setSelectedFaqIds(faqIds)

      setfaqList(props.reportFaqList?.filter((x) => !faqIds.includes(x.id)))
    }
    closeFaqFilter()
  }

  const closeFaqFilter = () => {
    setFaqFilter(false)
    setfaqList([])
    setAddFaq({
      ...faqIndex,
      parentIndex: null,
      subIndex: null,
      values: null,
    })
    setSerchedValues('')
    props.clearReportFaqList()
    setPage(0)
  }

  // add api data for suggestion of keywords

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  const handleAddition = (tag) => {
    const matches = onlyAlpha.exec(tag.text)
    if (matches) {
      setTags([...tags, tag])
    } else {
      ApiResponseMessage('Only alphabets are allowed for this field', DANGER)
    }
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
  }
  const groupFaqModal = (index, faqList, faqId) => {
    closeFaqFilter()
    let faqList1 = faqList

    if (faqId) {
      let groupFaq = [];
      let isParent = false;


      groupFaq = faqList1.map((faq, index) => {

        faq = {
          ...faq,
          oldIndex: index

        }
        if (faq.id === faqId) {
          isParent = true;
        }

        if (isParent && faq.isGroup && faq.isParent && faq.id !== faqId) {
          isParent = false;
        }

        if (isParent && !faq.isGroup) {
          isParent = false;
        }

        if (isParent || !faq.isGroup) {
          return faq;
        }
      }).filter(Boolean);


      console.log(groupFaq)

      setGroupByFaqList({
        index: index,
        faqList: groupFaq,
        oldFaq: faqList,
        isEdit: true
      })
      setIsFaqGroupModalOpen(true)
    } else {
      let updateFaq = faqList?.map((x, index) => ({ ...x, oldIndex: index }));
      let checkFaq = updateFaq.filter(x => !x.isGroup)
      if (checkFaq?.length > 1) {
        setGroupByFaqList({
          index: index,
          faqList: checkFaq,
          oldFaq: faqList
        })
        setIsFaqGroupModalOpen(true)
      } else {
        ApiResponseMessage("At least 2 FAQs must be there.", DANGER)
      }

    }


  }

  const onDragEnd = (result, values, parentIndex, subIndex) => {
    if (result?.destination?.index == null) {
      return false
    }
    let updatedReportSection = []
    if (subIndex == null) {

      let checkData = values.reportSection[parentIndex]?.sectionData?.faqs[result?.destination?.index]
      if (checkData?.isGroup || checkData?.isGroup) {
        return false
      }

      const faqs = [...values.reportSection[parentIndex].sectionData.faqs]
      const [reorderedItem] = faqs.splice(result.source?.index, 1)
      faqs.splice(result.destination.index, 0, reorderedItem)
      updatedReportSection = {
        ...values,
        reportSection: values.reportSection?.map((section, x) => {
          let updateFaq = []
          if (x == parentIndex) {
            updateFaq = faqs
          } else {
            updateFaq = section.sectionData.faqs
          }
          return {
            ...section,
            sectionData: {
              ...section.sectionData,
              faqs: updateFaq,
            },
          }
        }),
      }
      setInitialReportValues(updatedReportSection)
    } else {

      let checkData = values.reportSection[parentIndex].subSections[subIndex].sectionData
        .faqs[result?.destination?.index]
      if (checkData?.isParent || checkData?.isGroup) {
        return false
      }

      const faqs = [
        ...values.reportSection[parentIndex].subSections[subIndex].sectionData
          .faqs,
      ]
      const [reorderedItem] = faqs.splice(result.source?.index, 1)
      faqs.splice(result.destination.index, 0, reorderedItem)

      updatedReportSection = {
        ...values,
        reportSection: values.reportSection.map((section, parentIdx) => {
          const updatedSubSections = section.subSections?.map(
            (subSection, subIdx) => {
              let updateFaq = []
              if (parentIdx == parentIndex && subIdx == subIndex) {
                updateFaq = faqs
              } else {
                updateFaq = subSection.sectionData.faqs
              }
              return {
                ...subSection,
                sectionData: {
                  ...subSection.sectionData,
                  faqs: updateFaq,
                },
              }
            },
          )
          return {
            ...section,
            subSections: updatedSubSections,
          }
        }),
      }
      setInitialReportValues(updatedReportSection)
    }
  }

  const filterSubCategory = (id, type) => {
    if (id) {
      let subCategorys = props.categoryAndSubList
        ?.filter((item) => item.id == id)[0]
        ?.subCat.map((opt) => ({
          value: opt.id,
          label: opt.catName,
        }))
      if (type == 'Personal') {
        if (subCategorys) {
          setSubCategoryDropdownList([
            ...subCategorys,
            {
              value: '0',
              label: 'Other',
            },
          ])
        }
      } else {
        setSubCategoryDropdownList(subCategorys)
      }
    } else {
      setSubCategoryDropdownList([])
    }
  }

  useEffect(() => {
    if (scrollToSectionId != '') {
      scrollToSection(scrollToSectionId)
      setScrollToSectionId('')
    }
  }, [scrollToSectionId])

  const scrollToSection = (className) => {
    const section = document.querySelector('#' + className)
    if (section) {
      let position = section.getBoundingClientRect()
      window.scrollTo(position.left, position.top + window.scrollY - 400)

      document.getElementById(className).classList.add('animate__bounceIn')
      setTimeout(function () {
        document.getElementById(className).classList.remove('animate__bounceIn')
      }, 850)
    }
  }




  const backgroundColorChange = (value, nextIndex) => {
    let colorClass = "";

    if (value.isParent && value.isGroup) {
      colorClass = "group-parent"
    } else if (value.isParent == false && value.isGroup == true) {
      colorClass = "group-child"
    }
    if (nextIndex?.isParent == true && nextIndex?.isGroup == true && value.isParent == false && value.isGroup == true) {
      colorClass = "group-child group-last A0"
    }
    else if (!nextIndex && value.isParent == false && value.isGroup == true) {
      colorClass = "group-child group-last A1"
    }
    else if (nextIndex && nextIndex?.isParent == false && nextIndex?.isGroup == false && value.isParent == false && value.isGroup == true) {
      colorClass = "group-child group-last B1"
    }
    else if (!nextIndex?.isParent && !nextIndex?.isGroup && value.isParent == false && value.isGroup == true) {
      colorClass = "group-child group-last c1"

    }
    return colorClass
  };

  const isDeleteGroupButton = (value, nextIndex, previousIndex) => {
    let status = true;
    if (value?.isGroup && !value?.isParent && previousIndex?.isGroup && previousIndex?.isParent && !nextIndex?.isGroup && !nextIndex?.isParent) {
      status = false
    }
    return status
  }



  return (
    <Formik
      initialValues={initialReportValues}
      onSubmit={formSubmit}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <div className="create-report">
          {/* <div className="row">
            <div className="col-md-12">{JSON.stringify(values)}</div>
          </div> */}
          <div className="container">
            <div className="section-head mt-3">
              <div className="flex-wrap">
                <Button className="back_btn" onClick={() => resetData()}>
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                {/* <h3> {!props.id ? 'Add report' : ' Update report'} </h3> */}
                <Field
                  placeholder="Enter your report title here..."
                  className="form-control"
                  // className={props.id ? 'form-control disable' : 'form-control'}
                  name="reportTitle"
                  value={values.reportTitle} // Use "value" instead of "values"
                  // onBlur={(event) => {
                  //   closeFaqFilter()
                  //   const { value } = event.target
                  //   setFieldValue('reportTitle', value)
                  //   if (value.length > 3) {
                  //     props.getCheckReportTitleRequest({
                  //       reportId: props.id ? props.id : '',
                  //       reportTitle: value,
                  //     })
                  //   }
                  // }}
                  onChange={(e) => {
                    closeFaqFilter()
                    const { value } = event.target
                    setFieldValue('reportTitle', value)
                    if (value.length > 3) {
                      props.getCheckReportTitleRequest({
                        reportId: props.id ? props.id : '',
                        reportTitle: value,
                      })
                    }
                  }}
                />

                {validator.current.message(
                  __t('validations.report.report_title'),
                  values.reportTitle,
                  'required',
                  {
                    messages: {
                      required: __t('validations.report.report_title'),
                    },
                  },
                )}

                <div className="report_description">
                  <Button
                    color="link"
                    className={
                      toggleId === 'description'
                        ? 'add-description'
                        : 'close-description'
                    }
                    onClick={() => toggle('description')}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {' '}
                      <path
                        d="M7 1L7 13"
                        stroke="CurrentColor"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />{' '}
                      <path
                        d="M13 7L1 7"
                        stroke="CurrentColor"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />{' '}
                    </svg>{' '}
                    {toggleId === 'description'
                      ? 'Close description'
                      : 'Add description'}
                  </Button>
                  <Collapse isOpen={toggleId === 'description' ? true : false}>
                    <FormGroup className="mb-0">
                      <JoditEditor
                        ref={editor}
                        config={config}
                        rows="2"
                        name="reportDescription"
                        value={values.reportDescription}
                        onBlur={(newContent) => {
                          setFieldValue('reportDescription', newContent)
                        }}
                      />
                    </FormGroup>
                  </Collapse>
                </div>
              </div>
              <FormGroup className="header_select d-block">
                <Label className="form-label">
                  Report Type
                  <span className="text-danger required">*</span>
                </Label>
                <Field
                  component={Select}
                  menuPlacement="auto"
                  name="reportType"
                  className="form-control selectbox"
                  options={reportType}
                  onChange={(opt) => {
                    filterSubCategory(values.categoryId, opt?.value)
                    setFieldValue('reportType', opt?.value ? opt?.value : '')
                    if (opt?.value == 'Personal') {
                      setFieldValue('otherCategory', [
                        {
                          otherCategory: '',
                        },
                      ])
                    } else {
                      setFieldValue(
                        'subCategoryId',
                        values?.subCategoryId
                          ? values?.subCategoryId?.filter((x) => x != '0')
                          : [],
                      )
                    }
                    closeFaqFilter()
                    validator.current.purgeFields()
                  }}
                  value={reportType.filter(
                    (option) => option.value === values.reportType,
                  )}
                  isClearable={true}
                  isDisabled={!props.id ? false : true}
                />
                {validator.current.message(
                  __t('validations.report.report_type'),
                  values.reportType,
                  'required',
                  {
                    messages: {
                      required: __t('validations.report.report_type'),
                    },
                  },
                )}
              </FormGroup>
              <div className="status-drop-box header-action-item">
                {values?.status && (
                  <div className="notification-list">
                    <span
                      className={
                        'faq-list-status-box ' + statusBox(values?.status)
                      }
                    ></span>
                    {values?.status == 'Drafted' ? 'Saved' : statusLableChanges(values?.status)}
                  </div>
                )}

                <Button
                  className="creat_btn"
                  onClick={() => setisGeneralInfo(true)}
                >
                  <svg
                    width="27"
                    height="24"
                    viewBox="0 0 27 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.3125 12L4.3125 1.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M22.6875 22.5L22.6875 19.875"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M4.3125 22.5L4.3125 17.25"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M22.6875 12L22.6875 1.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M13.5 5.4375L13.5 1.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M13.5 22.5L13.5 12"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle
                      cx="4.3125"
                      cy="14.625"
                      r="2.625"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle
                      cx="13.5"
                      cy="8.0625"
                      r="2.625"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <circle
                      cx="22.6875"
                      cy="15.9375"
                      r="2.625"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  General info
                </Button>
              </div>
            </div>
          </div>

          <div className="report-content">
            <div className="report-tabs">
              <div
                className={
                  isFaqFilter ? 'report-tab-list' : 'report-tab-list d-none'
                }
              >
                <div className="faq-sidebar-list">
                  <div className="faq-list">
                    <div className="w-100">
                      <Button
                        className="back-btn"
                        onClick={() => closeFaqFilter()}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.8667 9.4L11.2667 15C13.4536 17.1869 14.6797 18.4131 16.8667 20.6M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                      <h6>
                        FAQ Listing <small>Total Count: {faqTotalCount} </small>
                      </h6>
                    </div>

                    <Dropdown className="filter_btn repot_filter_btn">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className={isActiveClass ? 'filter_icon' : 'active'}
                        onClick={() =>
                          setIsActiveFilter(isActiveFilter ? false : true)
                        }
                      >
                        <svg
                          className="filter_svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M23.0626 2C23.0626 1.44772 22.6149 1 22.0626 1H2C1.44772 1 1 1.44772 1 2V4.72252C1 4.98774 1.10536 5.24209 1.29289 5.42963L7.60165 11.7384C7.78918 11.9259 7.89454 12.1803 7.89454 12.4455V22.369C7.89454 22.7102 8.22889 22.9512 8.55265 22.8433L15.8261 20.4188C16.0303 20.3508 16.168 20.1597 16.168 19.9445V12.4455C16.168 12.1803 16.2734 11.926 16.4609 11.7384L22.7697 5.42963C22.9572 5.24209 23.0626 4.98774 23.0626 4.72252V2Z"
                            fill="currentColor"
                            fillOpacity="0.15"
                          />
                          <path
                            d="M8.55265 22.8433L8.78982 23.5548V23.5548L8.55265 22.8433ZM15.8261 20.4188L15.589 19.7073L15.589 19.7073L15.8261 20.4188ZM7.60165 11.7384L7.07132 12.2687L7.60165 11.7384ZM1.29289 5.42963L0.762562 5.95996L1.29289 5.42963ZM2 1.75H22.0626V0.25H2V1.75ZM1.75 4.72252V2H0.25V4.72252H1.75ZM8.13198 11.2081L1.82322 4.8993L0.762562 5.95996L7.07132 12.2687L8.13198 11.2081ZM7.14454 12.4455V22.369H8.64454V12.4455H7.14454ZM7.14454 22.369C7.14454 23.2222 7.98042 23.8246 8.78982 23.5548L8.31548 22.1318C8.47736 22.0778 8.64454 22.1983 8.64454 22.369H7.14454ZM8.78982 23.5548L16.0633 21.1303L15.589 19.7073L8.31548 22.1318L8.78982 23.5548ZM16.0633 21.1303C16.5737 20.9602 16.918 20.4825 16.918 19.9445H15.418C15.418 19.8369 15.4869 19.7413 15.589 19.7073L16.0633 21.1303ZM16.918 19.9445V12.4455H15.418V19.9445H16.918ZM22.2394 4.8993L15.9306 11.2081L16.9912 12.2688L23.3 5.95996L22.2394 4.8993ZM22.3126 2V4.72252H23.8126V2H22.3126ZM23.3 5.95996C23.6282 5.63177 23.8126 5.18665 23.8126 4.72252H22.3126C22.3126 4.78883 22.2863 4.85242 22.2394 4.8993L23.3 5.95996ZM16.918 12.4455C16.918 12.3792 16.9444 12.3156 16.9912 12.2688L15.9306 11.2081C15.6024 11.5363 15.418 11.9814 15.418 12.4455H16.918ZM7.07132 12.2687C7.1182 12.3156 7.14454 12.3792 7.14454 12.4455H8.64454C8.64454 11.9814 8.46017 11.5362 8.13198 11.2081L7.07132 12.2687ZM0.25 4.72252C0.25 5.18665 0.434373 5.63177 0.762562 5.95996L1.82322 4.8993C1.77634 4.85242 1.75 4.78883 1.75 4.72252H0.25ZM22.0626 1.75C22.2007 1.75 22.3126 1.86193 22.3126 2H23.8126C23.8126 1.0335 23.0291 0.25 22.0626 0.25V1.75ZM2 0.25C1.0335 0.25 0.25 1.0335 0.25 2H1.75C1.75 1.86193 1.86193 1.75 2 1.75V0.25Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          className="filter_clear_svg"
                          width="23"
                          height="25"
                          viewBox="0 0 23 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.5 8.33333C10.7418 8.33333 10.0007 8.57771 9.37031 9.03554C8.73993 9.49338 8.2486 10.1441 7.95846 10.9055C7.66833 11.6668 7.59241 12.5046 7.74032 13.3129C7.88823 14.1211 8.25332 14.8636 8.78943 15.4463C9.32553 16.029 10.0086 16.4258 10.7522 16.5866C11.4957 16.7474 12.2665 16.6649 12.967 16.3495C13.6674 16.0341 14.2661 15.5001 14.6873 14.8149C15.1085 14.1297 15.3333 13.3241 15.3333 12.5C15.3333 11.3949 14.9295 10.3351 14.2106 9.55372C13.4917 8.77232 12.5167 8.33333 11.5 8.33333ZM11.5 14.5833C11.1209 14.5833 10.7504 14.4612 10.4352 14.2322C10.12 14.0033 9.8743 13.6779 9.72923 13.2973C9.58416 12.9166 9.54621 12.4977 9.62016 12.0936C9.69412 11.6894 9.87666 11.3182 10.1447 11.0269C10.4128 10.7355 10.7543 10.5371 11.1261 10.4567C11.4979 10.3763 11.8833 10.4176 12.2335 10.5753C12.5837 10.7329 12.883 11 13.0937 11.3426C13.3043 11.6852 13.4167 12.088 13.4167 12.5C13.4167 13.0525 13.2147 13.5824 12.8553 13.9731C12.4958 14.3638 12.0083 14.5833 11.5 14.5833ZM19.3679 8.04792L20.7192 4.84375L18.4843 2.4125L15.3717 3.92083L14.168 3.30417L13.0372 0H9.87467L8.65567 3.45833L7.40408 3.94792L4.44667 2.46875L2.21184 4.90208L3.60525 8.29167L3.03984 9.6L0 10.8292V14.2667L3.18167 15.5917L3.63209 16.9521L2.27125 20.1667L4.50992 22.5958L7.62833 21.0812L8.832 21.6958L9.96283 25H13.1253L14.3443 21.5417L15.5959 21.0521L18.5533 22.5313L20.7805 20.0917L19.3928 16.7083L19.9602 15.4L23 14.1708V10.7333L19.8183 9.40834L19.3679 8.04792ZM21.0833 13.0542L18.5533 14.075L17.5126 16.4917L18.7067 19.4042L17.9208 20.2646L15.4675 19.0396L13.1541 19.9438L12.1038 22.9167H10.9902L10.0491 20.1667L7.82575 19.0333L5.14242 20.3396L4.35275 19.4833L5.48358 16.8104L4.65175 14.2979L1.91667 13.1563V11.9458L4.44667 10.9229L5.48933 8.50625L4.28758 5.58958L5.07533 4.73125L7.53442 5.96042L9.84592 5.05625L10.8962 2.08333H12.0098L12.9509 4.83333L15.1704 5.96458L17.848 4.66875L18.6377 5.52708L17.5164 8.19375L18.3483 10.7021L21.0833 11.8437V13.0542Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                  <div
                    className={
                      isActiveFilter ? 'filter_faq show' : 'filter_faq'
                    }
                  >
                    <FaqFilterSelectorFormik
                      // Type={'CONTENT'}
                      isReportModule={true}
                      setIsActiveClass={setIsActiveClass}
                      getFaqListFilter={(
                        categoryId,
                        subCategoryId,
                        law,
                        section,
                        // status,
                        date,
                      ) => {
                        setServiceId('')
                        if (
                          categoryId ||
                          subCategoryId ||
                          law ||
                          section ||
                          // status ||
                          date
                        ) {
                          setIsActiveFilter(true)
                        }

                        setFaqFilterData({
                          categoryId: categoryId ? categoryId : '',
                          subCategoryId: subCategoryId ? subCategoryId : '',
                          law: law ? law : '',
                          section: section ? section : '',
                          date: date ? formatDateForApi(date) : '',
                        })
                      }}
                    ></FaqFilterSelectorFormik>
                  </div>
                  <div className="search-box">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
                    </svg>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        setSerchedValues(e.target?.value)
                      }}
                      value={serchedValues}
                    ></input>
                    {serchedValues.length > 0 && (
                      <span
                        className="clear_serach"
                        title="Clear search"
                        onClick={() => {
                          setSerchedValues('')
                          props.clearReportFaqList()
                          setPage(page + 1)
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3 0.70998C13.2075 0.617276 13.0976 0.543728 12.9766 0.493547C12.8556 0.443366 12.726 0.417535 12.595 0.417535C12.464 0.417535 12.3343 0.443366 12.2134 0.493547C12.0924 0.543728 11.9825 0.617276 11.89 0.70998L6.99999 5.58998L2.10999 0.699979C2.01741 0.607397 1.9075 0.533957 1.78654 0.483852C1.66557 0.433747 1.53593 0.407959 1.40499 0.407959C1.27406 0.407959 1.14442 0.433747 1.02345 0.483852C0.902487 0.533957 0.792576 0.607397 0.699995 0.699979C0.607413 0.792561 0.533973 0.902472 0.483868 1.02344C0.433763 1.1444 0.407974 1.27405 0.407974 1.40498C0.407974 1.53591 0.433763 1.66556 0.483868 1.78652C0.533973 1.90749 0.607413 2.0174 0.699995 2.10998L5.58999 6.99998L0.699995 11.89C0.607413 11.9826 0.533973 12.0925 0.483868 12.2134C0.433763 12.3344 0.407974 12.464 0.407974 12.595C0.407974 12.7259 0.433763 12.8556 0.483868 12.9765C0.533973 13.0975 0.607413 13.2074 0.699995 13.3C0.792576 13.3926 0.902487 13.466 1.02345 13.5161C1.14442 13.5662 1.27406 13.592 1.40499 13.592C1.53593 13.592 1.66557 13.5662 1.78654 13.5161C1.9075 13.466 2.01741 13.3926 2.10999 13.3L6.99999 8.40998L11.89 13.3C11.9826 13.3926 12.0925 13.466 12.2135 13.5161C12.3344 13.5662 12.4641 13.592 12.595 13.592C12.7259 13.592 12.8556 13.5662 12.9765 13.5161C13.0975 13.466 13.2074 13.3926 13.3 13.3C13.3926 13.2074 13.466 13.0975 13.5161 12.9765C13.5662 12.8556 13.592 12.7259 13.592 12.595C13.592 12.464 13.5662 12.3344 13.5161 12.2134C13.466 12.0925 13.3926 11.9826 13.3 11.89L8.40999 6.99998L13.3 2.10998C13.68 1.72998 13.68 1.08998 13.3 0.70998Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  <ul>
                    {faqList?.map((x, key) => {
                      return (
                        <>
                          <li key={key}>
                            <p>
                              <small
                                style={{
                                  display: 'block',
                                  // x.status === PUBLISHED ? 'none' : 'block',
                                }}
                              // className="my-tooltip filter_btn"
                              // data-tooltip-content={'Internal'}
                              ></small>

                              <div>{x.question}</div>
                              <span>
                                <small>
                                  Last updated:{' '}
                                  {getDateTimesSlach(x.updatedDate)}
                                </small>
                                {/* <Badge color="success" outline style={{ display: x.status === PUBLISHED ? 'none' : 'block',}}>
                                  <img alt="Internal Use Only" src={internalIcon} /> Internal
                                </Badge> */}
                              </span>
                            </p>

                            <div>
                              <a
                                className="my-tooltip add_faq"
                                data-tooltip-content={'Add Content'}
                                onClick={() => setFaqValues(x.id)}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10.4211 1.42105C10.4211 1.04417 10.2713 0.682716 10.0048 0.416217C9.73834 0.149718 9.37689 0 9 0C8.62311 0 8.26166 0.149718 7.99516 0.416217C7.72866 0.682716 7.57895 1.04417 7.57895 1.42105V7.57895H1.42105C1.04417 7.57895 0.682716 7.72866 0.416217 7.99516C0.149718 8.26166 0 8.62311 0 9C0 9.37689 0.149718 9.73834 0.416217 10.0048C0.682716 10.2713 1.04417 10.4211 1.42105 10.4211H7.57895V16.5789C7.57895 16.9558 7.72866 17.3173 7.99516 17.5838C8.26166 17.8503 8.62311 18 9 18C9.37689 18 9.73834 17.8503 10.0048 17.5838C10.2713 17.3173 10.4211 16.9558 10.4211 16.5789V10.4211H16.5789C16.9558 10.4211 17.3173 10.2713 17.5838 10.0048C17.8503 9.73834 18 9.37689 18 9C18 8.62311 17.8503 8.26166 17.5838 7.99516C17.3173 7.72866 16.9558 7.57895 16.5789 7.57895H10.4211V1.42105Z" />
                                </svg>
                              </a>
                              <a
                                data-tooltip-content={'Remove faq'}
                                className="my-tooltip remove_faq"
                              >
                                <svg
                                  width="25"
                                  height="26"
                                  viewBox="0 0 25 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.06055 17.5292L9.06055 13.6469"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M14.2373 17.5292L14.2373 13.6469"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </a>
                            </div>
                          </li>
                        </>
                      )
                    })}
                    {props.faqTotalCount != storeFaq.length && (
                      <li className="load_more">
                        <Button
                          color="link"
                          onClick={() => {
                            setPage(page + 1)
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.55 21.67C18.84 20.54 22 16.64 22 12C22 6.48 17.56 2 12 2C5.33 2 2 7.56 2 7.56M2 7.56V3M2 7.56H4.01H6.44"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2 12C2 17.52 6.48 22 12 22"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-dasharray="3 3"
                            />
                          </svg>{' '}
                          Load more...
                        </Button>
                      </li>
                    )}

                    <li
                      className="border-0"
                      ref={loader}
                      style={{ padding: '7px' }}
                    ></li>
                  </ul>
                </div>
              </div>

              <div className="report-tab-content">
                <FieldArray
                  name="reportSection"
                  render={(arrayHelpers) => (
                    <>
                      {values.reportSection?.map((item, idx) => (
                        <div
                          id={'sectionCard' + idx}
                          className={
                            selectedSection === 'parent' + idx
                              ? 'section_card selected'
                              : 'section_card '
                          }
                          onClick={(e) =>
                            addSelectedSectionClass('parent' + idx, e)
                          }
                          key={idx}
                        >
                          <div className="section-box">
                            <div className="section-title">
                              <div className="title-input">
                                <Field
                                  name={`reportSection.${idx}.sectionTitle`}
                                  placeholder="Enter you section title here..."
                                  className="form-control"
                                  onBlur={() => closeFaqFilter()}
                                  value={item.sectionTitle != "No section" ? item.sectionTitle : ""}
                                />
                                {/* <a
                                  onClick={() => handleSubmit()}
                                  className="save-btn btn btn-sm btn-info"
                                >
                                  Save
                                </a> */}
                                {/* {validator.current.message(
                                  __t('validations.report.section_title'),
                                  item.sectionTitle,
                                  'required',
                                  {
                                    messages: {
                                      required: __t(
                                        'validations.report.section_title',
                                      ),
                                    },
                                  },
                                )} */}
                              </div>
                              <a
                                data-tooltip-content={
                                  values.reportSection?.length == 1
                                    ? ''
                                    : 'Delete'
                                }
                                className={
                                  values.reportSection?.length == 1
                                    ? ' disabled-btn delete-btn'
                                    : ' delete-btn btn btn-link p-0'
                                }
                                onClick={() => {
                                  if (values.reportSection?.length > 1) {
                                    deleteAllfaqInOneSection(
                                      item,
                                      item.sectionId ? item.sectionId : null,
                                    )
                                    arrayHelpers.remove(idx)
                                  }
                                }}
                              >
                                <svg
                                  width="25"
                                  height="26"
                                  viewBox="0 0 25 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.06055 17.5292L9.06055 13.6469"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M14.2373 17.5292L14.2373 13.6469"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                  <path
                                    d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                  />
                                </svg>
                              </a>

                              <a
                                className="add_btn"
                                onClick={() => {
                                  arrayHelpers.insert(idx + 1, {
                                    sectionTitle: '',
                                    sectionData: {
                                      description: '',
                                      orderNo: idx + 1,
                                      faqs: [],
                                    },
                                  })
                                  setScrollToSectionId(
                                    'sectionCard' + (idx + 1),
                                  )
                                }}
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10.4211 1.42105C10.4211 1.04417 10.2713 0.682716 10.0048 0.416217C9.73834 0.149718 9.37689 0 9 0C8.62311 0 8.26166 0.149718 7.99516 0.416217C7.72866 0.682716 7.57895 1.04417 7.57895 1.42105V7.57895H1.42105C1.04417 7.57895 0.682716 7.72866 0.416217 7.99516C0.149718 8.26166 0 8.62311 0 9C0 9.37689 0.149718 9.73834 0.416217 10.0048C0.682716 10.2713 1.04417 10.4211 1.42105 10.4211H7.57895V16.5789C7.57895 16.9558 7.72866 17.3173 7.99516 17.5838C8.26166 17.8503 8.62311 18 9 18C9.37689 18 9.73834 17.8503 10.0048 17.5838C10.2713 17.3173 10.4211 16.9558 10.4211 16.5789V10.4211H16.5789C16.9558 10.4211 17.3173 10.2713 17.5838 10.0048C17.8503 9.73834 18 9.37689 18 9C18 8.62311 17.8503 8.26166 17.5838 7.99516C17.3173 7.72866 16.9558 7.57895 16.5789 7.57895H10.4211V1.42105Z" />
                                </svg>
                              </a>

                              <Button
                                className="arrow_toggle"
                                color="link"
                                id={'parentSectionToggle-' + idx}
                              >
                                <svg
                                  width="14"
                                  height="9"
                                  viewBox="0 0 14 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 1L7 7L1 1"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                  />
                                </svg>
                              </Button>
                            </div>
                            <div className="section_desc">
                              <Button
                                color="link"
                                onClick={() =>
                                  descriptionToggle('description' + idx)
                                }
                              >
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {' '}
                                  <path
                                    d="M7 1L7 13"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                  />{' '}
                                  <path
                                    d="M13 7L1 7"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="round"
                                  />{' '}
                                </svg>{' '}
                                Add description
                              </Button>

                              <Collapse
                                isOpen={
                                  toggleDescriptionId === 'description' + idx
                                    ? true
                                    : false
                                }
                              >
                                <FormGroup>
                                  <JoditEditor
                                    ref={editor}
                                    config={config}
                                    rows="2"
                                    name={`reportSection.${idx}.sectionData.description`}
                                    value={item.sectionData?.description}
                                    onBlur={(updateValue) => {
                                      setFieldValue(
                                        `reportSection.${idx}.sectionData.description`,
                                        updateValue,
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </Collapse>
                            </div>
                          </div>

                          <UncontrolledCollapse
                            className="collapse_section mt-1"
                            toggler={'#parentSectionToggle-' + idx}
                            defaultOpen={true}
                          >
                            <FieldArray
                              name={`reportSection.${idx}.subSections`}
                              render={(arrayHelpers2) => (
                                <>
                                  {item?.subSections?.map((subItem, subIdx) => (
                                    <div
                                      id={'add-topic' + idx + subIdx}
                                      className="add-topic"
                                      onClick={(e) => {
                                        addSelectedSectionClass(
                                          'subSection' + subIdx,
                                          e,
                                        )
                                      }}
                                      key={subIdx}
                                    >
                                      <ul>
                                        <li
                                          className={
                                            selectedSection ===
                                            'subSection' + subIdx &&
                                            'selected'
                                          }
                                          key={subIdx}
                                        >
                                          <div className="topic_head">
                                            <div className="section-title">
                                              <Field
                                                placeholder="Enter you topic..."
                                                className="form-control"
                                                name={`reportSection.${idx}.subSections.${subIdx}.sectionTitle`}
                                                value={subItem.sectionTitle !== "No sub section" ? subItem.sectionTitle : ""}
                                                onBlur={() => closeFaqFilter()}
                                              />
                                              <a
                                                data-tooltip-content={'Delete'}
                                                className="my-tooltip delete-btn btn btn-link p-0"
                                                onClick={() => {
                                                  deleteAllfaqInOneSection(
                                                    subItem,
                                                    subItem.subSectionId
                                                      ? subItem.subSectionId
                                                      : null,
                                                  )
                                                  arrayHelpers2.remove(subIdx)
                                                }}
                                              >
                                                <svg
                                                  width="25"
                                                  height="26"
                                                  viewBox="0 0 25 26"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M9.06055 17.5292L9.06055 13.6469"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M14.2373 17.5292L14.2373 13.6469"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                </svg>
                                              </a>

                                              <a
                                                className="add_btn"
                                                onClick={() => {
                                                  arrayHelpers2.insert(
                                                    subIdx + 1,
                                                    {
                                                      sectionTitle: '',
                                                      sectionData: {
                                                        description: '',
                                                        orderNo:
                                                          item?.subSections
                                                            ?.length,
                                                        faqs: [],
                                                      },
                                                    },
                                                  )
                                                  setScrollToSectionId(
                                                    'add-topic' +
                                                    idx +
                                                    (subIdx + 1),
                                                  )

                                                  // alert(
                                                  //   'subIndex' + idx + subIdx,
                                                  // )
                                                }}
                                              >
                                                <svg
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 18 18"
                                                  fill="currentColor"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M10.4211 1.42105C10.4211 1.04417 10.2713 0.682716 10.0048 0.416217C9.73834 0.149718 9.37689 0 9 0C8.62311 0 8.26166 0.149718 7.99516 0.416217C7.72866 0.682716 7.57895 1.04417 7.57895 1.42105V7.57895H1.42105C1.04417 7.57895 0.682716 7.72866 0.416217 7.99516C0.149718 8.26166 0 8.62311 0 9C0 9.37689 0.149718 9.73834 0.416217 10.0048C0.682716 10.2713 1.04417 10.4211 1.42105 10.4211H7.57895V16.5789C7.57895 16.9558 7.72866 17.3173 7.99516 17.5838C8.26166 17.8503 8.62311 18 9 18C9.37689 18 9.73834 17.8503 10.0048 17.5838C10.2713 17.3173 10.4211 16.9558 10.4211 16.5789V10.4211H16.5789C16.9558 10.4211 17.3173 10.2713 17.5838 10.0048C17.8503 9.73834 18 9.37689 18 9C18 8.62311 17.8503 8.26166 17.5838 7.99516C17.3173 7.72866 16.9558 7.57895 16.5789 7.57895H10.4211V1.42105Z" />
                                                </svg>
                                              </a>
                                              <Button
                                                className="arrow_toggle"
                                                color="link"
                                                id={'topic_toggle-' + subIdx}
                                              >
                                                <svg
                                                  width="14"
                                                  height="9"
                                                  viewBox="0 0 14 9"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M13 1L7 7L1 1"
                                                    stroke="CurrentColor"
                                                    strokeWidth="2"
                                                  />
                                                </svg>
                                              </Button>
                                            </div>

                                            {/* {validator.current.message(
                                              __t(
                                                'validations.report.topic_field',
                                              ),
                                              subItem.sectionTitle,
                                              'required',
                                              {
                                                messages: {
                                                  required: __t(
                                                    'validations.report.topic_field',
                                                  ),
                                                },
                                              },
                                            )} */}
                                            <div className="section_desc">
                                              <Button
                                                color="link"
                                                id="add_desc"
                                                onClick={() =>
                                                  descriptionToggle(
                                                    'subDescription' + subIdx,
                                                  )
                                                }
                                              >
                                                <svg
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7 1L7 13"
                                                    stroke="CurrentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M13 7L1 7"
                                                    stroke="CurrentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>{' '}
                                                Add description
                                              </Button>
                                              <Collapse
                                                isOpen={
                                                  toggleDescriptionId ===
                                                    'subDescription' + subIdx
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <FormGroup>
                                                  <JoditEditor
                                                    ref={editor}
                                                    config={config}
                                                    rows="2"
                                                    name={`reportSection.${idx}.subSections.${subIdx}.sectionData.description`}
                                                    value={
                                                      subItem.sectionData
                                                        .description
                                                    }
                                                    onBlur={(updateValue) => {
                                                      setFieldValue(
                                                        `reportSection.${idx}.subSections.${subIdx}.sectionData.description`,
                                                        updateValue,
                                                      )
                                                    }}
                                                  />
                                                </FormGroup>
                                              </Collapse>
                                            </div>
                                          </div>
                                          <UncontrolledCollapse
                                            className="collapse_section"
                                            toggler={'#topic_toggle-' + subIdx}
                                            defaultOpen={true}
                                          >
                                            {subItem?.sectionData?.faqs
                                              ?.length == 0 && (
                                                <div className="view-section">
                                                  <a
                                                    onClick={(e) => {
                                                      addFaqFilter(
                                                        idx,
                                                        subIdx,
                                                        values,
                                                      )
                                                      addSelectedSectionClass(
                                                        'faqSub' + subIdx,
                                                        e,
                                                      )
                                                    }}
                                                  >
                                                    <svg
                                                      width="40"
                                                      height="40"
                                                      viewBox="0 0 40 40"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="19"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                      />
                                                      <circle
                                                        cx="19.9999"
                                                        cy="32.6667"
                                                        r="1.05556"
                                                        fill="currentColor"
                                                        stroke="currentColor"
                                                      />
                                                      <path
                                                        d="M20.0003 28.4445V25.4491C20.0003 23.4557 21.2759 21.6859 23.167 21.0556V21.0556C25.0581 20.4252 26.3337 18.6555 26.3337 16.6621V15.5787C26.3337 12.1909 23.5873 9.44446 20.1994 9.44446H20.0003C16.5025 9.44446 13.667 12.28 13.667 15.7778V15.7778"
                                                        stroke="currentColor"
                                                        strokeWidth="2.5"
                                                      />
                                                    </svg>
                                                    Add Content
                                                  </a>
                                                </div>
                                              )}

                                            <div
                                              className={
                                                subItem?.sectionData?.faqs
                                                  ?.length > 0
                                                  ? selectedSection ===
                                                    'faqSub' + subIdx
                                                    ? 'add-faq selected'
                                                    : 'add-faq '
                                                  : 'add-faq d-none'
                                              }
                                              onClick={(e) =>
                                                addSelectedSectionClass(
                                                  'faqSub' + subIdx,
                                                  e,
                                                )
                                              }
                                            >
                                              {subItem?.sectionData?.faqs
                                                .length > 0 && (
                                                  <Accordion defaultActiveKey={0}>
                                                    <DragDropContext
                                                      onDragEnd={(e) =>
                                                        onDragEnd(
                                                          e,
                                                          values,
                                                          idx,
                                                          subIdx,
                                                        )
                                                      }
                                                    >
                                                      <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                          <div
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                          >
                                                            {subItem?.sectionData?.faqs?.map(
                                                              (subFaq, index) =>
                                                                // add null check here
                                                                subFaq && (
                                                                  <Draggable
                                                                    key={
                                                                      subFaq.id
                                                                    }
                                                                    draggableId={
                                                                      subFaq.id
                                                                    }
                                                                    index={index}
                                                                  >
                                                                    {(
                                                                      faqProvided,
                                                                      snapshot,
                                                                    ) => (
                                                                      <div
                                                                        className={"add_faq_field " + backgroundColorChange(subFaq, subItem?.sectionData?.faqs[index + 1])}
                                                                        ref={
                                                                          faqProvided.innerRef
                                                                        }
                                                                        snapshot={
                                                                          snapshot
                                                                        }
                                                                        {...faqProvided.draggableProps}
                                                                        {...faqProvided.dragHandleProps}
                                                                      >
                                                                        <span
                                                                          role="img"
                                                                          aria-label="Drag handle"
                                                                          className="drag_btn"
                                                                        >
                                                                          <svg
                                                                            width="10"
                                                                            height="16"
                                                                            viewBox="0 0 10 16"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <path
                                                                              d="M7 14C7 14.2652 7.10536 14.5196 7.29289 14.7071C7.48043 14.8946 7.73478 15 8 15C8.26522 15 8.51957 14.8946 8.70711 14.7071C8.89464 14.5196 9 14.2652 9 14C9 13.7348 8.89464 13.4804 8.70711 13.2929C8.51957 13.1054 8.26522 13 8 13C7.73478 13 7.48043 13.1054 7.29289 13.2929C7.10536 13.4804 7 13.7348 7 14ZM1 14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15C2.26522 15 2.51957 14.8946 2.70711 14.7071C2.89464 14.5196 3 14.2652 3 14C3 13.7348 2.89464 13.4804 2.70711 13.2929C2.51957 13.1054 2.26522 13 2 13C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14ZM7 8C7 8.26522 7.10536 8.51957 7.29289 8.70711C7.48043 8.89464 7.73478 9 8 9C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8C9 7.73478 8.89464 7.48043 8.70711 7.29289C8.51957 7.10536 8.26522 7 8 7C7.73478 7 7.48043 7.10536 7.29289 7.29289C7.10536 7.48043 7 7.73478 7 8ZM1 8C1 8.26522 1.10536 8.51957 1.29289 8.70711C1.48043 8.89464 1.73478 9 2 9C2.26522 9 2.51957 8.89464 2.70711 8.70711C2.89464 8.51957 3 8.26522 3 8C3 7.73478 2.89464 7.48043 2.70711 7.29289C2.51957 7.10536 2.26522 7 2 7C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8ZM7 2C7 2.26522 7.10536 2.51957 7.29289 2.70711C7.48043 2.89464 7.73478 3 8 3C8.26522 3 8.51957 2.89464 8.70711 2.70711C8.89464 2.51957 9 2.26522 9 2C9 1.73478 8.89464 1.48043 8.70711 1.29289C8.51957 1.10536 8.26522 1 8 1C7.73478 1 7.48043 1.10536 7.29289 1.29289C7.10536 1.48043 7 1.73478 7 2ZM1 2C1 2.26522 1.10536 2.51957 1.29289 2.70711C1.48043 2.89464 1.73478 3 2 3C2.26522 3 2.51957 2.89464 2.70711 2.70711C2.89464 2.51957 3 2.26522 3 2C3 1.73478 2.89464 1.48043 2.70711 1.29289C2.51957 1.10536 2.26522 1 2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2Z"
                                                                              stroke="currentColor"
                                                                              strokeWidth="2"
                                                                              strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                            />
                                                                          </svg>
                                                                        </span>

                                                                        <AccordionItem
                                                                          eventKey={
                                                                            index
                                                                          }
                                                                          key={
                                                                            index
                                                                          }
                                                                        >
                                                                          {
                                                                            subFaq?.isParent && subFaq?.isGroup ? <a className='btn btn-primary btn-sm btn-group split-faq-btn' onClick={() => groupFaqModal(`reportSection.${idx}.subSections.${subIdx}.sectionData.faqs`, subItem?.sectionData?.faqs, subFaq.id)}>
                                                                              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7438 2.52776L2.94714 10.7802C2.65275 11.0936 2.36785 11.7109 2.31087 12.1382L1.9595 15.2151C1.83605 16.3262 2.63375 17.0859 3.73535 16.896L6.79322 16.3737C7.22057 16.2977 7.81885 15.9843 8.11324 15.6614L15.9099 7.40897C17.2584 5.98449 17.8662 4.36059 15.7674 2.37582C13.6782 0.410039 12.0923 1.10328 10.7438 2.52776Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /><path d="M9.44202 3.90436C9.85037 6.5254 11.9776 8.52916 14.6176 8.79506" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /><path d="M1 20.0011H18.0937" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                                                              FAQ Segmentation
                                                                            </a> : ""
                                                                          }


                                                                          <a
                                                                            className="delete-btn edit_faq p-0"
                                                                            onClick={async () => {
                                                                              if (
                                                                                !subFaq?.newQuestion &&
                                                                                subFaq.isReview ==
                                                                                1
                                                                              ) {
                                                                                await commonAleartBox(
                                                                                  'Please update the FAQ',
                                                                                )
                                                                              } else {
                                                                                openFaqModal(
                                                                                  `reportSection.${idx}.subSections.${subIdx}.sectionData.faqs.${index}`,
                                                                                  subFaq,
                                                                                )
                                                                              }
                                                                            }}
                                                                          >
                                                                            <svg
                                                                              width="20"
                                                                              height="20"
                                                                              viewBox="0 0 20 20"
                                                                              fill="currentColor"
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                              <g clipPath="url(#clip0_1123_13264)">
                                                                                <path
                                                                                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                                                                                  stroke="CurrentColor"
                                                                                  strokeWidth="1.66667"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"
                                                                                />
                                                                              </g>
                                                                              <defs>
                                                                                <clipPath id="clip0_1123_13264">
                                                                                  <rect
                                                                                    width="20"
                                                                                    height="20"
                                                                                    fill="CurrentColor"
                                                                                  />
                                                                                </clipPath>
                                                                              </defs>
                                                                            </svg>
                                                                          </a>
                                                                          {
                                                                            subFaq?.isParent && subFaq?.isGroup ? <Button
                                                                              outline
                                                                              color='danger'
                                                                              size='sm'
                                                                              data-tooltip-content={
                                                                                'Delete'
                                                                              }
                                                                              className="ungroup-faq"
                                                                              onClick={async () => {
                                                                                const response =
                                                                                  await commonConfirmBox(
                                                                                    'Do you want to remove FAQs segmentation?',
                                                                                  )
                                                                                if (
                                                                                  response
                                                                                ) {
                                                                                  unGroupFaq(subItem?.sectionData?.faqs, subFaq.id, `reportSection.${idx}.subSections.${subIdx}.sectionData.faqs`, setFieldValue)
                                                                                }
                                                                              }}
                                                                            ><svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8L7 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M5 4L7 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M10.3006 5.15401L14.8128 2.6102C16.5928 1.60669 19.0473 2.5044 20.295 4.61528C21.5428 6.72616 21.1113 9.25085 19.3313 10.2544L15.7859 12.2531" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M13.5764 15.1943L10.8217 20.5025C9.87079 22.3348 7.40764 22.7391 5.32009 21.4054C3.23253 20.0718 2.31108 17.5053 3.26197 15.6731L4.12283 14.0142" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M9.41006 14.5442L12.5896 9.45595" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /></svg> Remove Segmentation</Button> : ""
                                                                          }

                                                                          {
                                                                            isDeleteGroupButton(subFaq, subItem?.sectionData?.faqs[index + 1], subItem?.sectionData?.faqs[index - 1]) == true &&
                                                                            <a
                                                                              data-tooltip-content={
                                                                                'Delete'
                                                                              }
                                                                              className="my-tooltip delete-btn btn btn-link p-0"
                                                                              onClick={async () => {
                                                                                const response =
                                                                                  await commonConfirmBox(
                                                                                    'Do you want to delete this FAQ?',
                                                                                  )
                                                                                if (
                                                                                  response
                                                                                ) {
                                                                                  deleteFaqValues(
                                                                                    idx,
                                                                                    subIdx,
                                                                                    values,
                                                                                    subFaq.id,
                                                                                    subFaq.sectionFaqId
                                                                                      ? subFaq.sectionFaqId
                                                                                      : null,
                                                                                  )
                                                                                }
                                                                              }}
                                                                            >
                                                                              <svg
                                                                                width="25"
                                                                                height="26"
                                                                                viewBox="0 0 25 26"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                              >
                                                                                <path
                                                                                  d="M9.06055 17.5292L9.06055 13.6469"
                                                                                  stroke="currentColor"
                                                                                  strokeWidth="2.5"
                                                                                  strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                  d="M14.2373 17.5292L14.2373 13.6469"
                                                                                  stroke="currentColor"
                                                                                  strokeWidth="2.5"
                                                                                  strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                  d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                                                                  stroke="currentColor"
                                                                                  strokeWidth="2.5"
                                                                                  strokeLinecap="round"
                                                                                />
                                                                                <path
                                                                                  d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                                                                  stroke="currentColor"
                                                                                  strokeWidth="2.5"
                                                                                  strokeLinecap="round"
                                                                                />
                                                                              </svg>
                                                                            </a>
                                                                          }



                                                                          <AccordionHeader >

                                                                            {subFaq?.newQuestion
                                                                              ? subFaq?.newQuestion
                                                                              : subFaq?.question}
                                                                          </AccordionHeader>
                                                                          <AccordionBody>
                                                                            <div className="faq_text">
                                                                              <div
                                                                                className="JoditEditor-border"
                                                                                dangerouslySetInnerHTML={{
                                                                                  __html:
                                                                                    subFaq?.newAnswer
                                                                                      ? subFaq?.newAnswer
                                                                                      : subFaq?.answer,
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </AccordionBody>
                                                                        </AccordionItem>

                                                                      </div>


                                                                    )}

                                                                  </Draggable>
                                                                ),
                                                            )}
                                                          </div>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  </Accordion>
                                                )}
                                              <a
                                                className="btn btn-info btn-sm"
                                                onClick={() => {
                                                  addFaqFilter(
                                                    idx,
                                                    subIdx,
                                                    values,
                                                  )
                                                }}
                                              >
                                                <svg
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7 1L7 13"
                                                    stroke="CurrentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M13 7L1 7"
                                                    stroke="CurrentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                                Add Content
                                              </a>

                                              {
                                                subItem?.sectionData?.faqs?.length > 1 &&
                                                <a onClick={() => {
                                                  closeFaqFilter()
                                                  groupFaqModal(`reportSection.${idx}.subSections.${subIdx}.sectionData.faqs`, subItem?.sectionData?.faqs)
                                                }
                                                }
                                                  className='btn btn-primary btn-sm btn-group float-none'>
                                                  <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M7 1L7 13"
                                                      stroke="CurrentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="square"
                                                      strokeLinejoin="round"
                                                    />
                                                    <path
                                                      d="M13 7L1 7"
                                                      stroke="CurrentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="square"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  FAQ Segmentation
                                                </a>
                                              }
                                            </div>
                                          </UncontrolledCollapse>
                                        </li>
                                      </ul>
                                    </div>
                                  ))}

                                  <div
                                    className={
                                      item?.sectionData?.faqs?.length > 0
                                        ? selectedSection === 'faqParent' + idx
                                          ? 'add-faq selected'
                                          : 'add-faq '
                                        : 'add-faq d-none'
                                    }
                                    onClick={(e) =>
                                      addSelectedSectionClass(
                                        'faqParent' + idx,
                                        e,
                                      )
                                    }
                                  >
                                    {item?.sectionData?.faqs.length > 0 && (
                                      <Accordion defaultActiveKey={0}>
                                        <DragDropContext
                                          onDragEnd={(e) =>
                                            onDragEnd(e, values, idx, null)
                                          }
                                        >
                                          <Droppable droppableId="droppable">
                                            {(provided) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="JoditEditor-border"
                                              >
                                                {item?.sectionData?.faqs?.map(
                                                  (subItem, index) =>
                                                    // add null check here
                                                    subItem && (
                                                      <Draggable
                                                        key={subItem.id}
                                                        draggableId={subItem.id}
                                                        index={index}
                                                      >
                                                        {(
                                                          subProvided,
                                                          snapshot,
                                                        ) => (
                                                          <div
                                                            //className="add_faq_field"
                                                            className={"add_faq_field " + backgroundColorChange(subItem, item?.sectionData?.faqs[index + 1])}
                                                            ref={
                                                              subProvided.innerRef
                                                            }
                                                            snapshot={snapshot}
                                                            {...subProvided.draggableProps}
                                                            {...subProvided.dragHandleProps}
                                                          >
                                                            <span
                                                              role="img"
                                                              aria-label="Drag handle"
                                                              className="drag_btn"
                                                            >
                                                              <svg
                                                                width="10"
                                                                height="16"
                                                                viewBox="0 0 10 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <path
                                                                  d="M7 14C7 14.2652 7.10536 14.5196 7.29289 14.7071C7.48043 14.8946 7.73478 15 8 15C8.26522 15 8.51957 14.8946 8.70711 14.7071C8.89464 14.5196 9 14.2652 9 14C9 13.7348 8.89464 13.4804 8.70711 13.2929C8.51957 13.1054 8.26522 13 8 13C7.73478 13 7.48043 13.1054 7.29289 13.2929C7.10536 13.4804 7 13.7348 7 14ZM1 14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15C2.26522 15 2.51957 14.8946 2.70711 14.7071C2.89464 14.5196 3 14.2652 3 14C3 13.7348 2.89464 13.4804 2.70711 13.2929C2.51957 13.1054 2.26522 13 2 13C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14ZM7 8C7 8.26522 7.10536 8.51957 7.29289 8.70711C7.48043 8.89464 7.73478 9 8 9C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8C9 7.73478 8.89464 7.48043 8.70711 7.29289C8.51957 7.10536 8.26522 7 8 7C7.73478 7 7.48043 7.10536 7.29289 7.29289C7.10536 7.48043 7 7.73478 7 8ZM1 8C1 8.26522 1.10536 8.51957 1.29289 8.70711C1.48043 8.89464 1.73478 9 2 9C2.26522 9 2.51957 8.89464 2.70711 8.70711C2.89464 8.51957 3 8.26522 3 8C3 7.73478 2.89464 7.48043 2.70711 7.29289C2.51957 7.10536 2.26522 7 2 7C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8ZM7 2C7 2.26522 7.10536 2.51957 7.29289 2.70711C7.48043 2.89464 7.73478 3 8 3C8.26522 3 8.51957 2.89464 8.70711 2.70711C8.89464 2.51957 9 2.26522 9 2C9 1.73478 8.89464 1.48043 8.70711 1.29289C8.51957 1.10536 8.26522 1 8 1C7.73478 1 7.48043 1.10536 7.29289 1.29289C7.10536 1.48043 7 1.73478 7 2ZM1 2C1 2.26522 1.10536 2.51957 1.29289 2.70711C1.48043 2.89464 1.73478 3 2 3C2.26522 3 2.51957 2.89464 2.70711 2.70711C2.89464 2.51957 3 2.26522 3 2C3 1.73478 2.89464 1.48043 2.70711 1.29289C2.51957 1.10536 2.26522 1 2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2Z"
                                                                  stroke="currentColor"
                                                                  strokeWidth="2"
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"
                                                                />
                                                              </svg>
                                                            </span>

                                                            <AccordionItem
                                                              eventKey={index}
                                                              key={index}
                                                            >
                                                              {
                                                                subItem.isParent && subItem.isGroup ? <a className='btn btn-primary btn-sm btn-group split-faq-btn' onClick={() => groupFaqModal(`reportSection.${idx}.sectionData.faqs`, item?.sectionData?.faqs, subItem.id)}>
                                                                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7438 2.52776L2.94714 10.7802C2.65275 11.0936 2.36785 11.7109 2.31087 12.1382L1.9595 15.2151C1.83605 16.3262 2.63375 17.0859 3.73535 16.896L6.79322 16.3737C7.22057 16.2977 7.81885 15.9843 8.11324 15.6614L15.9099 7.40897C17.2584 5.98449 17.8662 4.36059 15.7674 2.37582C13.6782 0.410039 12.0923 1.10328 10.7438 2.52776Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /><path d="M9.44202 3.90436C9.85037 6.5254 11.9776 8.52916 14.6176 8.79506" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /><path d="M1 20.0011H18.0937" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                                                  FAQ Segmentation
                                                                </a> : ""
                                                              }


                                                              <a
                                                                className="delete-btn edit_faq p-0"
                                                                onClick={async () => {
                                                                  if (
                                                                    !subItem?.newAnswer &&
                                                                    subItem.isReview ==
                                                                    1
                                                                  ) {
                                                                    await commonAleartBox(
                                                                      'Please update the FAQ',
                                                                    )
                                                                  } else {
                                                                    openFaqModal(
                                                                      `reportSection.${idx}.sectionData.faqs.${index}`,
                                                                      subItem,
                                                                    )
                                                                  }
                                                                }}
                                                              >
                                                                <svg
                                                                  width="20"
                                                                  height="20"
                                                                  viewBox="0 0 20 20"
                                                                  fill="currentColor"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <g clipPath="url(#clip0_1123_13264)">
                                                                    <path
                                                                      d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                                                                      stroke="CurrentColor"
                                                                      strokeWidth="1.66667"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                    />
                                                                  </g>
                                                                  <defs>
                                                                    <clipPath id="clip0_1123_13264">
                                                                      <rect
                                                                        width="20"
                                                                        height="20"
                                                                        fill="CurrentColor"
                                                                      />
                                                                    </clipPath>
                                                                  </defs>
                                                                </svg>
                                                              </a>
                                                              {
                                                                subItem.isParent && subItem.isGroup ? <Button
                                                                  outline
                                                                  color='danger'
                                                                  size='sm'
                                                                  data-tooltip-content={
                                                                    'Delete'
                                                                  }
                                                                  className="ungroup-faq"

                                                                  onClick={async () => {
                                                                    const response =
                                                                      await commonConfirmBox(
                                                                        'Do you want to remove FAQs segmentation?',
                                                                      )
                                                                    if (
                                                                      response
                                                                    ) {
                                                                      unGroupFaq(item?.sectionData?.faqs, subItem.id, `reportSection.${idx}.sectionData.faqs`, setFieldValue)
                                                                    }
                                                                  }}
                                                                ><svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8L7 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M5 4L7 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M10.3006 5.15401L14.8128 2.6102C16.5928 1.60669 19.0473 2.5044 20.295 4.61528C21.5428 6.72616 21.1113 9.25085 19.3313 10.2544L15.7859 12.2531" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M13.5764 15.1943L10.8217 20.5025C9.87079 22.3348 7.40764 22.7391 5.32009 21.4054C3.23253 20.0718 2.31108 17.5053 3.26197 15.6731L4.12283 14.0142" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /><path d="M9.41006 14.5442L12.5896 9.45595" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" /></svg> Remove Segmentation</Button> : ""
                                                              }


                                                              {
                                                                isDeleteGroupButton(subItem, item?.sectionData?.faqs[index + 1], item?.sectionData?.faqs[index - 1]) == true &&
                                                                <a
                                                                  className=" delete-btn btn btn-link p-0"
                                                                  onClick={async () => {
                                                                    const response =
                                                                      await commonConfirmBox(
                                                                        'Do you want to delete this FAQ?',
                                                                      )
                                                                    if (
                                                                      response
                                                                    ) {
                                                                      deleteFaqValues(
                                                                        idx,
                                                                        null,
                                                                        values,
                                                                        subItem.id,
                                                                        subItem.sectionFaqId
                                                                          ? subItem.sectionFaqId
                                                                          : null,
                                                                      )
                                                                    }
                                                                  }}
                                                                >
                                                                  <svg
                                                                    width="25"
                                                                    height="26"
                                                                    viewBox="0 0 25 26"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M9.06055 17.5292L9.06055 13.6469"
                                                                      stroke="currentColor"
                                                                      strokeWidth="2.5"
                                                                      strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                      d="M14.2373 17.5292L14.2373 13.6469"
                                                                      stroke="currentColor"
                                                                      strokeWidth="2.5"
                                                                      strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                      d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                                                      stroke="currentColor"
                                                                      strokeWidth="2.5"
                                                                      strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                      d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                                                      stroke="currentColor"
                                                                      strokeWidth="2.5"
                                                                      strokeLinecap="round"
                                                                    />
                                                                  </svg>
                                                                </a>
                                                              }
                                                              <AccordionHeader  >

                                                                {subItem?.newQuestion
                                                                  ? subItem?.newQuestion
                                                                  : subItem?.question}
                                                              </AccordionHeader>
                                                              <AccordionBody>
                                                                <div className="faq_text">
                                                                  <div
                                                                    className="JoditEditor-border"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html:
                                                                        subItem?.newAnswer
                                                                          ? subItem?.newAnswer
                                                                          : subItem?.answer,
                                                                    }}
                                                                  />
                                                                </div>
                                                              </AccordionBody>
                                                            </AccordionItem>

                                                          </div>
                                                        )}

                                                      </Draggable>
                                                    ),
                                                )}
                                              </div>
                                            )}
                                          </Droppable>
                                          {
                                            item?.sectionData?.faqs.length > 1 &&
                                            <a onClick={() => {
                                              closeFaqFilter()
                                              groupFaqModal(`reportSection.${idx}.sectionData.faqs`, item?.sectionData?.faqs)
                                            }} className='btn btn-primary btn-sm btn-group float-none'>
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.01 2.92001L18.91 5.54001C20.61 6.29001 20.61 7.53001 18.91 8.28001L13.01 10.9C12.34 11.2 11.24 11.2 10.57 10.9L4.67002 8.28001C2.97002 7.53001 2.97002 6.29001 4.67002 5.54001L10.57 2.92001C11.24 2.62001 12.34 2.62001 13.01 2.92001Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M3 11C3 11.84 3.63 12.81 4.4 13.15L11.19 16.17C11.71 16.4 12.3 16.4 12.81 16.17L19.6 13.15C20.37 12.81 21 11.84 21 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M3 16C3 16.93 3.55 17.77 4.4 18.15L11.19 21.17C11.71 21.4 12.3 21.4 12.81 21.17L19.6 18.15C20.45 17.77 21 16.93 21 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                              </svg>
                                              FAQ Segmentation
                                            </a>
                                          }
                                        </DragDropContext>
                                      </Accordion>
                                    )}
                                  </div>

                                  <div className="view-section mb-3">
                                    <a
                                      onClick={(e) => {
                                        arrayHelpers2.insert(
                                          item?.subSections?.length,
                                          {
                                            sectionTitle: '',
                                            sectionData: {
                                              description: '',
                                              orderNo:
                                                item?.subSections?.length,
                                              faqs: [],
                                            },
                                          },
                                        )
                                        closeFaqFilter()
                                        setScrollToSectionId(
                                          'add-topic' +
                                          idx +
                                          (item?.subSections?.length
                                            ? item?.subSections?.length
                                            : 0),
                                        )
                                      }}
                                    >
                                      <svg
                                        width="32"
                                        height="37"
                                        viewBox="0 0 32 37"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="29.75"
                                          y="12.875"
                                          width="7.5"
                                          height="7.5"
                                          rx="3.75"
                                          transform="rotate(90 29.75 12.875)"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                        <rect
                                          x="29.75"
                                          y="27.875"
                                          width="7.5"
                                          height="7.5"
                                          rx="3.75"
                                          transform="rotate(90 29.75 27.875)"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                        <rect
                                          x="1.625"
                                          y="9.125"
                                          width="7.5"
                                          height="7.5"
                                          rx="3.75"
                                          transform="rotate(-90 1.625 9.125)"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                        <path
                                          d="M5.375 11V27.625C5.375 29.5106 5.375 30.4534 5.96079 31.0392C6.54657 31.625 7.48938 31.625 9.375 31.625H22.25"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                        <path
                                          d="M5.375 9.125V12.625C5.375 14.5106 5.375 15.4534 5.96079 16.0392C6.54657 16.625 7.48938 16.625 9.375 16.625H22.25"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                      </svg>
                                      Add Topic
                                    </a>

                                    <a
                                      onClick={(e) => {
                                        addFaqFilter(idx, null, values)
                                        addSelectedSectionClass(
                                          'faqParent' + idx,
                                          e,
                                        )
                                      }}
                                    >
                                      <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <circle
                                          cx="20"
                                          cy="20"
                                          r="19"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        />
                                        <circle
                                          cx="19.9999"
                                          cy="32.6667"
                                          r="1.05556"
                                          fill="currentColor"
                                          stroke="currentColor"
                                        />
                                        <path
                                          d="M20.0003 28.4445V25.4491C20.0003 23.4557 21.2759 21.6859 23.167 21.0556V21.0556C25.0581 20.4252 26.3337 18.6555 26.3337 16.6621V15.5787C26.3337 12.1909 23.5873 9.44446 20.1994 9.44446H20.0003C16.5025 9.44446 13.667 12.28 13.667 15.7778V15.7778"
                                          stroke="currentColor"
                                          strokeWidth="2.5"
                                        />
                                      </svg>
                                      Add Content
                                    </a>

                                  </div>
                                </>
                              )}
                            />
                          </UncontrolledCollapse>
                        </div>
                      ))}
                    </>
                  )}
                />
              </div>
              <div
                className={
                  isGeneralInfo ? 'general-info' : 'general-info d-none'
                }
              >
                <div className="general-head">
                  <a
                    data-tooltip-content={'Close'}
                    className="my-tooltip"
                    onClick={() => setisGeneralInfo(false)}
                  >
                    <svg
                      width="33"
                      height="33"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M31.8807 4.13589C32.2693 3.73354 32.4843 3.19466 32.4794 2.6353C32.4746 2.07595 32.2502 1.54088 31.8547 1.14535C31.4591 0.74981 30.9241 0.525449 30.3647 0.520588C29.8054 0.515728 29.2665 0.730757 28.8641 1.11936L16.5057 13.4778L4.14734 1.11936C3.74499 0.730757 3.2061 0.515728 2.64675 0.520588C2.08739 0.525449 1.55233 0.74981 1.15679 1.14535C0.761254 1.54088 0.536893 2.07595 0.532032 2.6353C0.527172 3.19466 0.742201 3.73354 1.1308 4.13589L13.4892 16.4943L1.1308 28.8527C0.92705 29.0495 0.764527 29.2849 0.652721 29.5452C0.540915 29.8054 0.482065 30.0854 0.479603 30.3686C0.477142 30.6519 0.531118 30.9328 0.638384 31.195C0.74565 31.4572 0.904057 31.6954 1.10436 31.8957C1.30467 32.096 1.54286 32.2544 1.80504 32.3616C2.06721 32.4689 2.34813 32.5229 2.63139 32.5204C2.91466 32.518 3.19459 32.4591 3.45487 32.3473C3.71514 32.2355 3.95054 32.073 4.14734 31.8692L16.5057 19.5108L28.8641 31.8692C29.2665 32.2578 29.8054 32.4729 30.3647 32.468C30.9241 32.4631 31.4591 32.2388 31.8547 31.8432C32.2502 31.4477 32.4746 30.9126 32.4794 30.3533C32.4843 29.7939 32.2693 29.255 31.8807 28.8527L19.5223 16.4943L31.8807 4.13589Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
                <div className="general-content">
                  <Row>
                    <Col lg="12" className="no-floating-label">
                      <FormGroup className="mb-1">
                        <Label className="form-label">
                          Service Line
                          {!isDraFSave && (
                            <span className="text-danger required">*</span>
                          )}
                        </Label>
                        <Field
                          component={Select}
                          menuPlacement="auto"
                          name="categoryId"
                          className="form-control selectbox"
                          options={props.categoryList}
                          onChange={(opt) => {
                            setFieldValue(
                              'categoryId',
                              opt?.value ? opt?.value : '',
                            )

                            filterSubCategory(
                              opt ? opt.value : '',
                              values.reportType,
                            )
                            setFieldValue('subCategoryId', [])
                            setFieldValue('otherCategory', [
                              {
                                otherCategory: '',
                              },
                            ])

                            if (opt?.value) {
                              props.getUserListRequest({
                                ...selectorFilter,
                                // countryId: values.countryId
                                //   ? values.countryId
                                //   : '',
                                countryId:
                                  countryData != undefined && countryData.id,
                                catId: opt.value ? opt.value : '',
                                subCatId: values.subCategoryId
                                  ? values.subCategoryId
                                  : '',
                              })
                            }
                          }}
                          value={props.categoryList?.filter(
                            (option) => option.value === values.categoryId,
                          )}
                          isClearable={true}
                        />
                        {!isDraFSave &&
                          validator.current.message(
                            __t('validations.report.service_line'),
                            values.categoryId,
                            'required',
                            {
                              messages: {
                                required: __t(
                                  'validations.report.service_line',
                                ),
                              },
                            },
                          )}
                      </FormGroup>
                    </Col>
                    <Col
                      lg="12"
                      className="no-floating-label my-2 add_other_category"
                    >
                      <div>
                        <div lg="12" className="add_category">
                          <FieldArray
                            name="otherCategory"
                            render={(otherCategoryArrayHelpers) => (
                              <>
                                <FormGroup className="mb-1">
                                  <div className="d-flex justify-content-between category_label">
                                    <Label className="form-label">
                                      Category
                                      {!isDraFSave && (
                                        <span className="text-danger required">
                                          *
                                        </span>
                                      )}
                                    </Label>
                                  </div>

                                  <Field
                                    component={Select}
                                    menuPlacement="auto"
                                    name="subCategoryId"
                                    id="subCategoryId"
                                    className="form-control selectbox"
                                    options={subCategoryDropdownList}
                                    onChange={(opt) => {
                                      let subCategoryId = opt
                                        ? opt?.map((x) => x.value)
                                        : []

                                      setFieldValue(
                                        'subCategoryId',
                                        subCategoryId,
                                      )

                                      let subCategory =
                                        values.subCategoryId?.filter(
                                          (x) => x == '0',
                                        )
                                      if (subCategory?.length == 0) {
                                        setFieldValue('otherCategory', [
                                          {
                                            otherCategory: '',
                                          },
                                        ])
                                      }
                                      validator.current.purgeFields()
                                    }}
                                    isClearable={true}
                                    // value ={props.subCategoryDropdownList}
                                    value={subCategoryDropdownList?.filter(
                                      (option) =>
                                        values.subCategoryId?.includes(
                                          option.value,
                                        ),
                                    )}
                                    isMulti
                                  />
                                  {!isDraFSave &&
                                    validator.current.message(
                                      __t('validations.report.service_line'),
                                      values.subCategoryId,
                                      'required',
                                      {
                                        messages: {
                                          required: __t(
                                            'validations.common.cat_required',
                                          ),
                                        },
                                      },
                                    )}
                                </FormGroup>

                                {values?.subCategoryId &&
                                  values.subCategoryId.includes('0') && (
                                    <>
                                      <Label className="">Other Category</Label>
                                      <div className="other_category_field-list small-scroll">
                                        {values?.otherCategory?.map(
                                          (item, idx) => (
                                            <div
                                              key={idx}
                                              className="other_category_field"
                                            >
                                              <div className="title-input">
                                                <FormGroup>
                                                  <Field
                                                    name={`otherCategory.${idx}.otherCategory`}
                                                    placeholder="Enter you other category here..."
                                                    className="form-control"
                                                    value={item.otherCategory}
                                                  />
                                                  {validator.current.message(
                                                    __t(
                                                      'validations.report.other_category',
                                                    ),
                                                    item.otherCategory,
                                                    'required|arrayInValueCheck:' +
                                                    subCategoryDropdownList?.map(
                                                      (x) => x.label,
                                                    ),
                                                    {
                                                      messages: {
                                                        required: __t(
                                                          'validations.report.other_category',
                                                        ),
                                                      },
                                                    },
                                                  )}
                                                </FormGroup>
                                              </div>
                                              <a
                                                data-tooltip-content="Delete"
                                                className={
                                                  values?.otherCategory
                                                    ?.length == 1
                                                    ? ' disabled-btn my-tooltip delete-btn btn btn-link p-0'
                                                    : ' my-tooltip delete-btn btn btn-link p-0'
                                                }
                                                onClick={() => {
                                                  validator.current.purgeFields()
                                                  if (
                                                    values?.otherCategory
                                                      ?.length > 1
                                                  ) {
                                                    otherCategoryArrayHelpers.remove(
                                                      idx,
                                                    )
                                                  }
                                                }}
                                              >
                                                <svg
                                                  width="25"
                                                  height="26"
                                                  viewBox="0 0 25 26"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M9.06055 17.5292L9.06055 13.6469"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M14.2373 17.5292L14.2373 13.6469"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M0 7.17662H23.2941V7.17662C21.5227 7.17662 20.637 7.17662 20.0644 7.6986C20.0188 7.74011 19.9753 7.7837 19.9337 7.82923C19.4118 8.40182 19.4118 9.28754 19.4118 11.059V20.0001C19.4118 21.8858 19.4118 22.8286 18.826 23.4144C18.2402 24.0001 17.2974 24.0001 15.4118 24.0001H7.88235C5.99673 24.0001 5.05393 24.0001 4.46814 23.4144C3.88235 22.8286 3.88235 21.8858 3.88235 20.0001V11.059C3.88235 9.28754 3.88235 8.40182 3.36037 7.82923C3.31886 7.7837 3.27528 7.74011 3.22975 7.6986C2.65716 7.17662 1.77144 7.17662 0 7.17662V7.17662Z"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                  <path
                                                    d="M9.14858 2.47959C5.50039 5.3 9.62099 2.22042 10.073 2.13371C10.525 2.047 11.0789 2 11.6486 2C12.2184 2 12.7722 2.047 13.2242 2.13371C13.6763 2.22042 17.0506 4.75 14.1487 2.47959"
                                                    stroke="currentColor"
                                                    strokeWidth="2.5"
                                                    strokeLinecap="round"
                                                  />
                                                </svg>
                                              </a>

                                              <a
                                                data-tooltip-content="Add Category"
                                                className="my-tooltip add_btn"
                                                onClick={() => {
                                                  otherCategoryArrayHelpers.insert(
                                                    idx + 1,
                                                    {
                                                      otherCategory: '',
                                                    },
                                                  )
                                                }}
                                              >
                                                <svg
                                                  width="18"
                                                  height="18"
                                                  viewBox="0 0 18 18"
                                                  fill="currentColor"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path d="M10.4211 1.42105C10.4211 1.04417 10.2713 0.682716 10.0048 0.416217C9.73834 0.149718 9.37689 0 9 0C8.62311 0 8.26166 0.149718 7.99516 0.416217C7.72866 0.682716 7.57895 1.04417 7.57895 1.42105V7.57895H1.42105C1.04417 7.57895 0.682716 7.72866 0.416217 7.99516C0.149718 8.26166 0 8.62311 0 9C0 9.37689 0.149718 9.73834 0.416217 10.0048C0.682716 10.2713 1.04417 10.4211 1.42105 10.4211H7.57895V16.5789C7.57895 16.9558 7.72866 17.3173 7.99516 17.5838C8.26166 17.8503 8.62311 18 9 18C9.37689 18 9.73834 17.8503 10.0048 17.5838C10.2713 17.3173 10.4211 16.9558 10.4211 16.5789V10.4211H16.5789C16.9558 10.4211 17.3173 10.2713 17.5838 10.0048C17.8503 9.73834 18 9.37689 18 9C18 8.62311 17.8503 8.26166 17.5838 7.99516C17.3173 7.72866 16.9558 7.57895 16.5789 7.57895H10.4211V1.42105Z" />
                                                </svg>
                                              </a>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    </>
                                  )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col lg="12" className="no-floating-label">
                      <FormGroup className="mb-1 add_tags_field">
                        <Label className="form-label">SEO Keywords</Label>
                        <ReactTags
                          name="keywords"
                          tags={tags}
                          suggestions={props.listKeywords}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          className="form-control"
                          handleDrag={handleDrag}
                          inputFieldPosition="bottom"
                          autocomplete
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col lg="12" className="no-floating-label">
                      <FormGroup className="mb-1 add_tags_field">
                        <Label className="form-label">Video Title</Label>
                        <Field
                          name="videoTitle"
                          placeholder="Enter Reference here"
                          className="form-control"
                          value={values.videoTitle}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12" className="no-floating-label">
                      <FormGroup className="mb-1 add_tags_field">
                        <Label className="form-label">Video Url</Label>
                        <Field
                          name="videoUrl"
                          placeholder="Enter Reference here"
                          className="form-control"
                          value={values.videoUrl}
                        />
                        {validator.current.message(
                          __t('validations.report.video_url'),
                          values.videoUrl,
                          'url',
                          {
                            messages: {
                              url: __t('validations.report.video_url'),
                            },
                          },
                        )}{' '}
                      </FormGroup>
                    </Col> */}
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="report-content flex-column">
            {(values?.status === APPROVED ||
              values?.status === REJECTED_BY_APPROVER ||
              values?.status === REJECTED_BY_PUBLISHER ||
              values?.status === PUBLISHED ||
              (isDraFSave === false && values.reportType != PERSONAL)) && (
                <Card className="w-100 mt-3 approval_dtl">
                  <CardBody>
                    <Row>
                      <Col lg="6">
                        <FormGroup className="mb-0">
                          <Field
                            component={Select}
                            menuPlacement="auto"
                            name="firstApprovalId"
                            className="form-control selectbox"
                            options={props.userDropdownList?.filter(
                              (x) => x.value != values.secondApprovalId,
                            )}
                            onChange={(opt) => {
                              setFieldValue('firstApprovalId', opt && opt.value)
                              props.getPublisherListRequest({
                                ...selectorFilter,
                                // countryId: values.countryId
                                //   ? values.countryId
                                //   : '',
                                countryId:
                                  countryData != undefined && countryData.id,

                                catId: values.categoryId ? values.categoryId : '',
                                subCatId: values.subCategoryId
                                  ? values.subCategoryId
                                  : '',
                              })
                            }}
                            value={props.userDropdownList
                              ?.filter((x) => x.value != values.secondApprovalId)
                              .filter(
                                (option) =>
                                  option.value === values.firstApprovalId,
                              )}
                            isClearable={true}
                          />
                          <Label className="form-label">
                            Approver
                            <span className="text-danger required">*</span>
                          </Label>
                          {!isDraFSave &&
                            validator.current.message(
                              __t('validations.report.approval_field'),
                              values.firstApprovalId,
                              'required',
                              {
                                messages: {
                                  required: __t(
                                    'validations.report.approval_field',
                                  ),
                                },
                              },
                            )}
                        </FormGroup>
                      </Col>
                      {values.reportType != INTERNAL && (
                        <Col lg="6">
                          <FormGroup className="mb-0">
                            <Field
                              component={Select}
                              menuPlacement="auto"
                              name="secondApprovalId"
                              className="form-control selectbox"
                              options={props.publisherList.filter(
                                (x) => x.value != values.firstApprovalId,
                              )}
                              onChange={(opt) => {
                                setFieldValue(
                                  'secondApprovalId',
                                  opt && opt.value,
                                )
                              }}
                              value={props.publisherList
                                .filter((x) => x.value != values.firstApprovalId)
                                ?.filter(
                                  (option) =>
                                    option.value === values.secondApprovalId,
                                )}
                              isClearable={true}
                            />
                            <Label className="form-label">
                              Publisher
                              <span className="text-danger required">*</span>{' '}
                            </Label>

                            {!isDraFSave &&
                              validator.current.message(
                                __t('validations.report.sec_approval_field'),
                                values.secondApprovalId,
                                'required',
                                {
                                  messages: {
                                    required: __t(
                                      'validations.report.sec_approval_field',
                                    ),
                                  },
                                },
                              )}
                          </FormGroup>
                        </Col>
                      )}

                      {/* <Col lg="6" className="mt-2">
                      <FormGroup className="mb-0 d-flex align-items-center">
                        <Label>
                          Version
                          <span className="text-danger required">*</span>
                        </Label>
                        <div>
                          {(values.version ||
                            isVersion === true ||
                            !props.id) && (
                            <Field
                              as={CodeComponent}
                              digits={3}
                              name="version"
                              onChange={(opt) => {
                                setFieldValue('version', opt)
                                if (opt === '') {
                                  setIsVersion(true)
                                }
                              }}
                              value={values.version}
                              autoFocus={false}
                            />
                          )}

                          {validator.current.message(
                            'version',
                            values.version,
                            'required|min:3',
                            {
                              messages: {
                                min: __t('validations.faq.faq_version'),
                              },
                            },
                          )}
                        </div>
                      </FormGroup>
                    </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              )}
            {/* navigate('/console/article') */}
            <div className="btn-box my-3 mx-0 w-100">
              <FormGroup className="d-flex m-0 p-0 justify-content-end">
                <Button
                  type="button"
                  onClick={() => resetData()}
                  color="outline-primary"
                  className="waves-effect waves-light me-3"
                >
                  Cancel
                </Button>

                {(props.reportDetails?.sectionData?.status === DRAFTED ||
                  props.reportDetails?.sectionData?.status ===
                  REJECTED_BY_APPROVER ||
                  props.reportDetails?.sectionData?.status ===
                  REJECTED_BY_PUBLISHER ||
                  props.reportDetails?.sectionData?.status === PUBLISHED ||
                  !props.id) && (
                    <Button
                      type="button"
                      onClick={() => {
                        setisDraFSave(true)
                        handleSubmit()
                        setIsRedirect(true)
                        validator.current.purgeFields()
                      }}
                      color="outline-primary"
                      className="waves-effect waves-light"
                    >
                      {values.reportType == PERSONAL ? 'Submit' : 'Save'}
                    </Button>
                  )}

                {values.reportType != PERSONAL && (
                  <Button
                    onClick={() => {
                      if (
                        !values?.categoryId ||
                        values?.subCategoryId?.length == 0 || !values?.subCategoryId ||
                        !values?.otherCategory
                        // !values?.countryId
                      ) {
                        setisGeneralInfo(true)
                      }
                      setIsRedirect(true)
                      setisDraFSave(false)
                      handleSubmit()
                      validator.current.purgeFields()
                    }}
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                  >
                    {props.id ? 'Update' : 'Submit'}
                  </Button>
                )}
              </FormGroup>
            </div>
          </div>

          <EditFaqForm
            isModalOpen={isFaqModalOpen}
            modalName="Update FAQ"
            initialValues={faqModalData.values}
            onModalDismiss={() => closeFaqModal()}
            updateFaq={(value) => {
              setFieldValue(
                faqModalData.updateIndex + '.question',
                value.question,
              )
              setFieldValue(
                faqModalData.updateIndex + '.newQuestion',
                value.question,
              )
              setFieldValue(
                faqModalData.updateIndex + '.newAnswer',
                value.answer,
              )
              setFieldValue(faqModalData.updateIndex + '.answer', value.answer)

              setFieldValue(faqModalData.updateIndex + '.isUpdated', true)
              closeFaqModal()
            }}
          />

          <GroupFaqModal
            isOpenModal={isFaqGroupModalOpen}
            faqList={groupByFaqList}
            closeModal={(e) => {
              setIsFaqGroupModalOpen(false)
              setGroupByFaqList([])
            }}
            formSubmit={((value) => {

              setFieldValue(
                groupByFaqList?.index,
                value
              )
              setIsFaqGroupModalOpen(false)
              setGroupByFaqList([])
            })}
          />
        </div>
      )}
    </Formik>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCheckReportTitleRequest: (data) => {
      dispatch(getCheckReportTitleRequest(data))
    },
    getPublisherListRequest: (data) => {
      dispatch(getPublisherListRequest(data))
    },
    getUserListRequest: (data) => {
      dispatch(getUserListRequest(data))
    },
    getServiceLineListRequest: (creds) => {
      dispatch(getServiceLineListRequest(creds))
    },
    getSubCatList: (data) => {
      dispatch(getSubCatListByCatIdRequest(data))
    },
    getReportFaqList: (data) => {
      dispatch(getReportFaqListRequest(data))
    },
    clearReportFaqList: () => {
      dispatch(clearReportFaqListResponse())
    },
  }
}
const mapStateToProps = ({
  reportReducer,
  createUserReducer,
  categoryReducer,
  userCommonReducer,
  serviceLineReducer,
}) => {
  let reportFaqList = []
  let reportDetails = []
  let userDropdownList = []
  let listKeywords = []
  let categoryList = []
  let categoryAndSubList = []
  let serviceLineDropdown = []
  let subCategoryDropdownList = []
  let status = ''
  let faqTotalCount = 0
  if (reportReducer && reportReducer.reportFaqList) {
    reportFaqList = reportReducer.reportFaqList
    faqTotalCount = reportReducer?.faqTotalCount
      ? reportReducer?.faqTotalCount
      : 0
  }

  if (reportReducer.reportDetails) {
    if (reportReducer?.reportDetails?.responseType === SUCCESS) {
      reportDetails = reportReducer?.reportDetails?.data
      status = reportReducer?.reportDetails?.responseType
    }
    if (reportReducer?.reportDetails?.responseType === ERROR) {
      reportDetails = []
      status = reportReducer?.reportDetails?.responseType
    }
  }
  if (
    createUserReducer.getUserListApiResponse &&
    createUserReducer.getUserListApiResponse?.data?.list?.length > 0
  ) {
    userDropdownList = createUserReducer.getUserListApiResponse?.data.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.fullName,
      }),
    )
  }

  if (
    reportReducer.reportKeywordList &&
    reportReducer.reportKeywordList.list?.length > 0
  ) {
    listKeywords = reportReducer.reportKeywordList?.list?.map((keyword) => {
      return {
        id: keyword,
        text: keyword,
      }
    })
  }

  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
    categoryList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  if (
    serviceLineReducer &&
    serviceLineReducer.serviceLineList?.list?.length > 0
  ) {
    serviceLineDropdown = serviceLineReducer.serviceLineList?.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt?.catName,
      }),
    )
  }

  if (
    categoryReducer.reportSubCatList.list &&
    categoryReducer.reportSubCatList.list.length > 0
  ) {
    subCategoryDropdownList = categoryReducer?.reportSubCatList.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.catName,
      }),
    )
  }

  return {
    reportFaqList,
    reportDetails,
    userDropdownList,
    listKeywords: listKeywords,
    categoryAndSubList,
    categoryList,
    status,
    publisherList: userCommonReducer?.publisherList.map((opt) => ({
      value: opt.id,
      label: opt.fullName,
    })),
    serviceLineDropdown: serviceLineDropdown,
    subCategoryDropdownList: subCategoryDropdownList,
    faqTotalCount: faqTotalCount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport)
