import './loading.css'
import { React, useState } from 'react'
import { ClipLoader } from 'react-spinners'
export function Loader() {
  let [loading] = useState(true)
  let [color] = useState('#36d7b7')

  return (
    <div className="sweet-loading">
      {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button>
      <input value={color} onChange={(input) => setColor(input.target.value)} placeholder="Color of the loader" /> */}

      <ClipLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        // size={150}
        // aria-label="Loading Spinner"
        // data-testid="loader"
      />
    </div>
  )
}
