import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import {
  createTeamResponse,
  deleteTeamMemberResponse,
  getTeamLeadResponse,
  getTeamMemberListResponse,
  getTeamUserResponse,
} from './action'

import { invokeApi } from '../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  CREATE_TEAM_REQUEST,
  DELETE_TEAM_MEMBER_REQUEST,
  GET_TEAM_LEAD_REQUEST,
  GET_TEAM_MEMBER_LIST_REQUEST,
  GET_TEAM_USER_REQUEST,
} from './actionType'

function* createTeam({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/createTeam', data)
    if (response.status) {
      yield put(createTeamResponse(response.status, SUCCESS))
    } else {
      yield put(createTeamResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getTeamLead() {
  try {
    const response = yield call(invokeApi, 'get', `/teamLead`)

    if (response.status) {
      yield put(getTeamLeadResponse(response.data.list, SUCCESS))
    } else {
      yield put(getTeamLeadResponse([], DANGER))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getTeamUser({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', `/teamUser`, data)

    if (response.status) {
      yield put(getTeamUserResponse(response.data.list, SUCCESS))
    } else {
      yield put(getTeamUserResponse([], DANGER))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getTeamMemberList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', `/teamMemberList`, data)

    if (response.status) {
      yield put(getTeamMemberListResponse(response.data.list, SUCCESS))
    } else {
      yield put(getTeamMemberListResponse([], DANGER))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* deleteTeamMember({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', `/teamMemberDelete`, data)

    if (response.status) {
      yield put(deleteTeamMemberResponse(response.status, SUCCESS))
    } else {
      yield put(deleteTeamMemberResponse(response.status, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchTeamCreation() {
  yield takeEvery(CREATE_TEAM_REQUEST, createTeam)
  yield takeEvery(GET_TEAM_LEAD_REQUEST, getTeamLead)
  yield takeEvery(GET_TEAM_USER_REQUEST, getTeamUser)
  yield takeEvery(GET_TEAM_MEMBER_LIST_REQUEST, getTeamMemberList)
  yield takeEvery(DELETE_TEAM_MEMBER_REQUEST, deleteTeamMember)
}
function* teamCreation() {
  yield all([fork(watchTeamCreation)])
}
export default teamCreation
