import {
  ADD_LAW_REQUEST,
  ADD_LAW_RESPONSE,
  GET_LAW_LIST_REQUEST,
  GET_LAW_LIST_RESPONSE,
  EDIT_LAW_REQUEST,
  EDIT_LAW_RESPONSE,
  GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE,
  GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST,
  GET_LAW_SECTION_LIST_REQUEST,
  GET_LAW_SECTION_LIST_RESPONSE,
  GET_LAW_LIST_BY_CATEGORY_ID_REQUEST,
  GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE,
  ADD_LAW_REVISION_REQUEST,
  ADD_LAW_REVISION_RESPONSE,
} from './actionType'

//add law function

export const addLawRequest = (data) => {
  return {
    type: ADD_LAW_REQUEST,
    payload: { data },
  }
}

//Law Response function
export const addLawResponse = (message, status) => {
  return {
    type: ADD_LAW_RESPONSE,
    payload: { message, status },
  }
}

//get law request
export const getLawListRequest = (data) => {
  return {
    type: GET_LAW_LIST_REQUEST,
    payload: { data },
  }
}

//get law response
export const getLawListResponse = (data) => {
  return {
    type: GET_LAW_LIST_RESPONSE,
    payload: { data },
  }
}

export const editLawRequest = (data) => {
  return {
    type: EDIT_LAW_REQUEST,
    payload: { data },
  }
}

//get law response update
export const editLawResponse = (message, responseType) => {
  return {
    type: EDIT_LAW_RESPONSE,
    payload: { message, responseType },
  }
}
export const getLawSectionListRequest = () => {
  return {
    type: GET_LAW_SECTION_LIST_REQUEST,
  }
}
export const getLawSectionListResponse = (data) => {
  return {
    type: GET_LAW_SECTION_LIST_RESPONSE,
    payload: { data },
  }
}
export const getLawListByCategoryIdRequest = (data) => {
  return {
    type: GET_LAW_LIST_BY_CATEGORY_ID_REQUEST,
    payload: { data },
  }
}
export const getLawListByCatogeryIdResponse = (data) => {
  return {
    type: GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE,
    payload: { data },
  }
}

export const getLawStatusRequest = (data) => {
  return {
    type: GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST,
    payload: { data },
  }
}

export const getLawStatusResponse = (message, responseType, data) => {
  return {
    type: GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const addLawRevisionRequest = (data) => {
  return {
    type: ADD_LAW_REVISION_REQUEST,
    payload: { data },
  }
}

export const addLawRevisionResponse = (message, responseType) => {
  return {
    type: ADD_LAW_REVISION_RESPONSE,
    payload: { message, responseType },
  }
}
