import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import HelmetComponent from './Helmet/HelmetComponent'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap'
import aboutusImg1 from '../../images/about-us_6.png'
import { Link } from 'react-router-dom'


const LegalDisclaimer = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main bg-white">
        <div className="top_section mb-0">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>Legal Disclaimer</h1>
                  <span>Legal Disclaimer</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/home"> Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Legal Disclaimer</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='inner-section'>
          <div className='container'>
            <div className='disclaimer-data'>
              <h3>Disclaimer</h3>
              <p>similique consequuntur repellendus vitae illum? Incidunt totam mollis tincidunt cumque aenean corporis quia placerat laboriosam massa? Anim reprehenderit odio suscipit, magna turpis dis architecto maiores eos, netus molestiae ducimus habitasse mollit, dolore. Consequuntur leo curabitur rem. Illum! Tellus eleifend, sociosqu integer hendrerit aliquid occaecati maecenas risus. Malesuada architecto quisque laudantium potenti esse suspendisse eos! Venenatis dictumst! Ullamco assumenda molestias tristique quam deleniti vel ut, eget incididunt. Officia. Aspernatur, adipisicing? Primis deleniti parturient sapien eos quibusdam, laoreet.</p>
              <p>similique consequuntur repellendus vitae illum? Incidunt totam mollis tincidunt cumque aenean corporis quia placerat laboriosam massa? Anim reprehenderit odio suscipit, magna turpis dis architecto maiores eos, netus molestiae ducimus habitasse mollit, dolore. Consequuntur leo curabitur rem. Illum! Tellus eleifend, sociosqu integer hendrerit aliquid occaecati maecenas risus. Malesuada architecto quisque laudantium potenti esse suspendisse eos! Venenatis dictumst! Ullamco assumenda molestias tristique quam deleniti vel ut, eget incididunt. Officia. Aspernatur, adipisicing? Primis deleniti parturient sapien eos quibusdam, laoreet.</p>
              <p>similique consequuntur repellendus vitae illum? Incidunt totam mollis tincidunt cumque aenean corporis quia placerat laboriosam massa? Anim reprehenderit odio suscipit, magna turpis dis architecto maiores eos, netus molestiae ducimus habitasse mollit, dolore. Consequuntur leo curabitur rem. Illum! Tellus eleifend, sociosqu integer hendrerit aliquid occaecati maecenas risus. Malesuada architecto quisque laudantium potenti esse suspendisse eos! Venenatis dictumst! Ullamco assumenda molestias tristique quam deleniti vel ut, eget incididunt. Officia. Aspernatur, adipisicing? Primis deleniti parturient sapien eos quibusdam, laoreet.</p>
            </div>
          </div>
          <Row className='disclaimer-data-list'>
            <Col className='col-md-6'>
              <img alt="Grant Thornton about" src={aboutusImg1} />
            </Col>
            <Col className='col-md-6'>
              <p>Consectetur quisquam reprehenderit ut, doloremque morbi sunt diamlorem ridiculus explicabo laoreet? Dui. Soluta, ex lorem autem. Culpa, habitant. Dapibus voluptate labore, ex bibendum vivamus fugiat fugit! Sagittis, occaecati ultrices! Ipsa! Nostrum dicta nulla?</p>
              <p>Distinctio ad nostra. Minima. Nascetur. In vestibulum placeat adipiscing! Suspendisse, iure adipisci rutrum, leo ac aliquip feugiat, explicabo tincidunt, reiciendis porta.</p>
              <p>Quis elit excepturi viverra wisi non alias consequat porttitor, fuga delectus sapien fames neque eveniet eum. Egestas ad nobis,</p>
              <h6>Disclaimer list</h6>
              <ul className='common-list'>
                <li>Reprehenderit. Luctus placeat? Vestibulum! Expedita suscipit praesent</li>
                <li>dolorem irure molestiae, ad neque nesciunt voluptatum saepe</li>
                <li>Reprehenderit. Luctus placeat? Vestibulum! Expedita suscipit praesent</li>
                <li>dolorem irure molestiae, ad neque nesciunt voluptatum saepe</li>
                <li>Reprehenderit. Luctus placeat? Vestibulum! Expedita suscipit praesent</li>
                <li>dolorem irure molestiae, ad neque nesciunt voluptatum saepe</li>
              </ul>
            </Col>
          </Row>
          <div className='container'>
            <div className='disclaimer-data border-bottom'>
              <h6>Nam deleniti lobortis a commodi</h6>
              <p>Ipsa lacinia iste adipisci explicabo nulla fermentum corporis, mauris aut ridiculus, egestas earum montes. Rerum. Harum, debitis quaerat faucibus eveniet! Distinctio. Laboris commodi ornare illum libero placerat! Senectus esse fusce architecto arcu, dolorum fusce cupidatat ratione! Deserunt quasi cras posuere, integer fames elementum malesuada eius consequuntur tristique?</p>
              <p className='m-0'>pariatur accusantium imperdiet hendrerit viverra earum. Voluptatem voluptatibus molestie integer consequat ullam feugiat? Adipisicing voluptas quas exercitation earum conubia! Officia ultrices porro illo neque egestas, consequatur optio? Suscipit, eaque nulla, tenetur pellentesque aliquam voluptate quia similique rhoncus! Facere lobortis perferendis ut illo tortor quis venenatis quod aut accusamus pede, assumenda, quos eget per tortor necessitatibus deleniti repellat. Illum, rhoncus? Nisi a quisque ullamcorper? Animi proin! Congue pretium,</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default (LegalDisclaimer)
