import {
  RESET_PASSWORD_API_REQUEST,
  RESET_PASSWORD_API_RESPONSE,
  CHECK_RESET_PASSWORD_TOKE_REQUEST,
  CHECK_RESET_PASSWORD_TOKE_RESPONSE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_RESPONSE,
  RESET_PASSWORD_BY_EMAIL_API_REQUEST,
  RESET_PASSWORD_BY_EMAIL_API_RESPONSE,
} from './actionType'

export const forGetPassword = (data) => {
  return {
    type: FORGET_PASSWORD_REQUEST,
    payload: { data },
  }
}
export const forGetPasswordResponse = (message, responseType) => {
  return {
    type: FORGET_PASSWORD_RESPONSE,
    payload: { message, responseType },
  }
}

export const checkResetPasswordTokeRequest = (token) => {
  return {
    type: CHECK_RESET_PASSWORD_TOKE_REQUEST,
    payload: { token },
  }
}
export const checkResetPasswordTokeResponse = (
  isValid,
  message,
  responseType,
) => {
  return {
    type: CHECK_RESET_PASSWORD_TOKE_RESPONSE,
    payload: { isValid, responseType, message },
  }
}

export const resetPasswordApiRequest = (token) => {
  return {
    type: RESET_PASSWORD_API_REQUEST,
    payload: { token },
  }
}

export const resetPasswordApiResponse = (message, status) => {
  return {
    type: RESET_PASSWORD_API_RESPONSE,
    payload: { message, status },
  }
}

export const resetPasswordByEmailApiRequest = (data) => {
  return {
    type: RESET_PASSWORD_BY_EMAIL_API_REQUEST,
    payload: { data },
  }
}

export const resetPasswordByEmailApiResponse = (message, status) => {
  return {
    type: RESET_PASSWORD_BY_EMAIL_API_RESPONSE,
    payload: { message, status },
  }
}
