//Add Law

export const ADD_LAW_REQUEST = 'ADD_LAW_REQUEST'
export const ADD_LAW_RESPONSE = 'ADD_LAW_RESPONSE'

// Law List
export const GET_LAW_LIST_REQUEST = 'GET_LAW_LIST_REQUEST'
export const GET_LAW_LIST_RESPONSE = 'GET_LAW_LIST_RESPONSE'

//Edit Law

export const EDIT_LAW_REQUEST = 'EDIT_LAW_REQUEST'
export const EDIT_LAW_RESPONSE = 'EDIT_LAW_RESPONSE'
export const GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE =
  'GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE'
export const GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST =
  'GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_REQUEST'

export const GET_LAW_SECTION_LIST_REQUEST = 'GET_LAW_SECTION_LIST_REQUEST'
export const GET_LAW_SECTION_LIST_RESPONSE = 'GET_LAW_SECTION_LIST_RESPONSE'
export const GET_LAW_LIST_BY_CATEGORY_ID_REQUEST =
  'GET_LAW_LIST_BY_CATEGORY_ID_REQUEST'
export const GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE =
  'GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE'

export const ADD_LAW_REVISION_REQUEST = 'ADD_LAW_REVISION_REQUEST'
export const ADD_LAW_REVISION_RESPONSE = 'ADD_LAW_REVISION_RESPONSE'
