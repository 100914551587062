import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'
import Select from 'react-select'
import { getLawListRequest } from 'store/master/law/action'
import { LawListFilter } from './helper'

const LawSelector = (props) => {
  const {
    values,
    setValue,
    id,
    errors,
    touched,
    isReactSimpleValidator,
    isDisabled,
  } = props

  useEffect(() => {
    props.getLawListRequest(LawListFilter)
  }, [])

  return (
    <FormGroup className="col mb-0">
      <Field
        isDisabled={isDisabled}
        component={Select}
        menuPlacement="auto"
        name="lawId"
        id="lawId"
        className="form-control selectbox"
        options={props?.lawList}
        onChange={(opt) => {
          setValue(id, opt.value)
        }}
        value={props?.lawList?.filter(
          (option) => option.value === values.lawId,
        )}
      />
      <Label className="form-label" htmlFor="sectionName">
        Select law
        <span className="text-danger required">*</span>
      </Label>

      {isReactSimpleValidator ? (
        <div className="text-danger">{errors}</div>
      ) : errors[id] && touched[id] ? (
        <div className="text-danger">{errors[id]}</div>
      ) : null}
    </FormGroup>
  )
}

//redux for addcountry and countrylist
const mapStateToProps = ({ lawReducer }) => {
  let lawList = []
  if (lawReducer.lawList.list && lawReducer.lawList.list?.length > 0) {
    lawList = lawReducer.lawList.list.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }
  return {
    lawList: lawList,
  }
}

//country redux for addCountry
const mapDispatchToProps = (dispatch) => {
  return {
    getLawListRequest: (data) => {
      dispatch(getLawListRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LawSelector)
