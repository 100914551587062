import {
  CREATE_USER_API_REQUEST,
  CREATE_USER_API_RESPONSE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_RESPONSE,
  USER_STATUS_RESPONSE,
  USER_STATUS_REQUEST,
} from './actionType'

// Add User Data
export const createUserRequest = (data) => {
  return {
    type: CREATE_USER_API_REQUEST,
    payload: { data },
  }
}

export const createUserResponse = (message, responseType, data) => {
  return {
    type: CREATE_USER_API_RESPONSE,
    payload: { message, responseType, data },
  }
}

// Get User List Data

export const getUserListRequest = (data) => {
  return {
    type: GET_USER_LIST_REQUEST,
    payload: { data },
  }
}

export const getUserListResponse = (message, responseType, data) => {
  return {
    type: GET_USER_LIST_RESPONSE,
    payload: { message, responseType, data },
  }
}

// User Status

export const getUserDeleteRequest = (data) => {
  return {
    type: USER_STATUS_REQUEST,
    payload: { data },
  }
}

export const getUserDeleteResponse = (message, responseType, data) => {
  return {
    type: USER_STATUS_RESPONSE,
    payload: { message, responseType, data },
  }
}
