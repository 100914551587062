import produce from 'immer'
import {
  CREATE_ARTICAL_RESPONSE,
  CREATE_ARTICLE_REVIEW_RESPONSE,
  CREATE_REVIEW_LAW_RESPONSE,
  GET_ARTICAL_DETAILS_RESPONSE,
  GET_ARTICAL_HISTORY_RESPONSE,
  GET_LIST_ARTICAL_RESPONSE,
  GET_LIST_KEYWORDS_RESPONSE,
} from './actionType'

export const initialState = {
  addFaq: null,
  listArticle: null,
  listKeywords: null,
  detailArticle: null,
  articleHistoryApiResponse: null,
  faqReviewApiResponse: null,
  reviewLawApiResponse: null,
}

export const articleReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_ARTICAL_RESPONSE:
        if (action.payload.message == undefined) {
          draft.addFaq = null
        } else {
          draft.addFaq = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break

      case GET_LIST_KEYWORDS_RESPONSE:
        draft.listKeywords = action.payload.data
        break
      case GET_LIST_ARTICAL_RESPONSE:
        draft.listArticle = action.payload.data
        break
      case GET_ARTICAL_DETAILS_RESPONSE:
        draft.detailArticle = action.payload.data
        break
      case GET_ARTICAL_HISTORY_RESPONSE:
        draft.articleHistoryApiResponse = action.payload.data
        break

      case CREATE_ARTICLE_REVIEW_RESPONSE:
        draft.addFaq = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_REVIEW_LAW_RESPONSE:
        draft.reviewLawApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      default:
        state = { ...state }
        break
    }
  })
