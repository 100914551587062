import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { createReviewLawRequest } from 'store/faq/action'
import moment from 'moment'

function RevisionModal(props) {
  return (
    <>
      <Modal
        show={props.isOpenModal}
        className="category-modal revision_modal"
        centered
      >
        <Modal.Header
          closeButton
          onClick={() => {
            props.closeModal(false)
          }}
        >
          <Modal.Title>Revise Details</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props?.revisionType == 'SECTION' && (
            <div>
              <label>Law Name </label>
              {props.lawDescription?.map((x, index) => (
                <>
                  {x.name}{' '}
                  {index === props.lawDescription.length - 1 ? '' : ', '}
                </>
              ))}
            </div>
          )}

          {props?.lawList?.map(
            (x, index) =>
              x?.revisionDescription && (
                <React.Fragment key={index}>
                  <div>
                    <label>
                      {props?.revisionType == 'LAW'
                        ? 'Law Name'
                        : 'Section Name'}
                    </label>
                    <span>{x.name}</span>
                  </div>
                  <div>
                    <label>Revision Note</label>
                    <span>{x.revisionDescription}</span>
                  </div>
                  <div>
                    <label>Revise Date</label>
                    <span>
                      {x.revisionDate == null
                        ? 'Not available'
                        : moment(x.revisionDate).format('DD-MM-YYYY')}
                    </span>
                  </div>
                </React.Fragment>
              ),
          )}
          <div className="d-flex justify-content-center flex-wrap pt-2">
            <a
              onClick={async () => {
                const response = await commonConfirmBox(
                  props?.revisionType == 'LAW'
                    ? 'Do you want to mark as read update Law?'
                    : 'Do you want to mark as read this Section?',
                )
                if (response) {
                  props.createReviewLawRequest({
                    faqId: props.faqId,
                    isReviewed: false,
                    type: props?.revisionType,
                  })
                }
              }}
              className="btn btn-outline-info markread_btn"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  fillOpacity="1"
                />
                <path
                  d="M3 8L6.23299 10.9097C6.29003 10.961 6.37663 10.961 6.43368 10.9097L13 5"
                  stroke="#fff"
                  strokeWidth="1.2"
                />
              </svg>
              mark as read
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    createReviewLawRequest: (data) => {
      dispatch(createReviewLawRequest(data))
    },
  }
}
const mapStateToProps = ({ faqReducer }) => {
  let faqDetails = []

  return {
    faqDetails,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RevisionModal)
