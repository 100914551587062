import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Auth() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/console/home')
  }, [])

  return <></>
}
