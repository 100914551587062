import React, { useState, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import {
  Container,
  Button,
  Row,
  Col,
  FormGroup,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  Card,
  CardBody,
  Input,
} from 'reactstrap'
import {
  clearUserFaqListResponse,
  getUserFaqListRequest,
  updateFaqListRequest,
} from 'store/frontEnd/faq/action'
import Categories from '../Categories'
import { verificationData } from '../Helper/Helper'
import { askForSubscribe, getCountRequest } from 'store/frontEnd/common/action'
import { SearchIcon } from '../Icons/icons'
import HelmetComponent from '../Helmet/HelmetComponent'
import {
  clearKeywordResponse,
  getKeywordRequest,
} from 'store/frontEnd/home/action'

const Faq = (props) => {
  const { state } = useLocation()
  const { id, subCatId } = useParams()
  const [currentOpenFaq, setCurrentOpenFaq] = useState('')
  const [openFaqId, setOpenFaqId] = useState(0)
  const toggle = (faqId) => setOpenFaqId(faqId === openFaqId ? -1 : faqId)
  const [page, setPage] = useState(0)
  const loader = useRef(null)
  const [searchText, setSearchText] = useState(
    state?.searchText ? state?.searchText : '',
  )

  const [faqList, setFaqList] = useState([])

  const categoriesChange = () => {
    if (searchText.trim().length > 0) {
      setSearchText('')
      props.clearFaqList()
      props.getFaqList({
        searchText: '',
        offset: 0,
      })
    }
  }
  const handleObserver = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting) {
      setPage((prev) => prev + 1)
    }
  }, [])

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])
  const getSearchRequest = (newSearch = false) => {
    let SearchRequest = {
      offset: newSearch ? 0 : props.faqList?.length,
    }
    if (searchText && searchText.trim().length > 0) {
      SearchRequest = { ...SearchRequest, searchText: searchText }
    } else if (id && searchText.trim().length == 0) {
      SearchRequest = { ...SearchRequest, catId: id, subCatId: subCatId }
    }
    return SearchRequest
  }
  useEffect(() => {
    props.clearFaqList()
    setPage(0)
    props.getFaqList(getSearchRequest(true))
  }, [id, subCatId])

  useEffect(() => {
    if (page > 1) {
      props.getFaqList(getSearchRequest(false))
    }
  }, [page])
  useEffect(() => {
    props.getKeywordList('faq')
    return () => {
      props.clearFaqList()
      props.clearKeywordResponse()
      setPage(0)
    }
  }, [])

  const onFaqSearch = () => {
    props.clearFaqList()
    props.getFaqList(getSearchRequest(true))
  }
  useEffect(() => {
    setFaqList(props.faqList)
  }, [props.faqList])

  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main">
        <div className="top_section">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>FAQs</h1>
                  <span>FAQs...</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/"> Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>FAQs</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
              <Col lg={4} className="inner-search">
                <FormGroup className="mb-0">
                  <Input
                    type="search"
                    className="form-control selectbox"
                    value={searchText}
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchText(e.target.value)
                      if (e.target.value?.length <= 0) {
                        props.clearFaqList()
                        props.getFaqList({
                          searchText: '',
                          pagination: {
                            limit: 0,
                            page: 0,
                            orderKey: 'createdDate',
                            orderBy: 'ASC',
                          },
                          filter: {},
                        })
                      }
                    }}
                  ></Input>
                  <Button color="primary" onClick={onFaqSearch}>
                    {SearchIcon}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-section">
          <Categories
            value={id}
            getAllList={() => { }}
            onChange={categoriesChange}
            navigateTo="/faq"
          ></Categories>
          <div className="report-list">
            {/*================ Looping section =========== */}

            {faqList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`report-list-item ${currentOpenFaq === item.id ? '' : 'unsubscribe'
                    }`}
                  onClick={() => {
                    if (verificationData()) {
                      props.getCountApiRequest({
                        type: 'faq',
                        referenceId: item.faqId,
                      })
                      if (searchText) {
                        props.updateFaqListRequest({
                          faqId: item.id,
                        })
                      }
                      setCurrentOpenFaq(item.faqId)
                    } else {
                      props.askForSubscribe()
                    }
                  }}
                >
                  <Button color="link" onClick={() => toggle(index)}>
                    {item.question}
                  </Button>
                  <Collapse isOpen={openFaqId === index}>
                    <Card>
                      <CardBody>
                        {currentOpenFaq !== item.faqId ? (
                          <>
                            <div
                              className="JoditEditor-border"
                              dangerouslySetInnerHTML={{
                                __html: item.answer?.substring(0, 200),
                              }}
                              style={{ maxHeight: '10em', overflow: 'hidden' }}
                            ></div>
                            {/* <div className="detail-view">
                              <Button
                                className="btn btn-primary"                                
                              >
                                Read more
                              </Button>
                            </div> */}
                          </>
                        ) : (
                          <div
                            className="JoditEditor-border"
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></div>
                        )}
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              )
            })}

            {(faqList == undefined || faqList.length <= 0) && (
              <div className="no-data-found">
                <svg
                  width="96"
                  height="114"
                  viewBox="0 0 96 114"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                    fill="#4F2D7F"
                    fillOpacity="0.12"
                  />
                  <circle
                    cx="57.5592"
                    cy="67.2599"
                    r="27.36"
                    fill="#4F2D7F"
                    fillOpacity="0.3"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                  />
                  <path
                    d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M92.8968 102.6L79.2168 88.9204"
                    stroke="#4F2D7F"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                  />
                </svg>

                <h1>Nothing Found</h1>
                <p>Try to search using another term via the from above</p>
              </div>
            )}
            {/*================ End Looping section =========== */}
            <div ref={loader} />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ userFaqReducer, userHomeReducer }) => {
  return {
    faqList: userFaqReducer.faqList,
    keywordsList: userHomeReducer.keywordsList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFaqList: (data) => {
      dispatch(getUserFaqListRequest(data))
    },
    clearFaqList: () => {
      dispatch(clearUserFaqListResponse())
    },
    askForSubscribe: () => {
      dispatch(askForSubscribe())
    },
    updateFaqListRequest: (data) => {
      dispatch(updateFaqListRequest(data))
    },
    getCountApiRequest: (data) => {
      dispatch(getCountRequest(data))
    },
    getKeywordList: (data) => {
      dispatch(getKeywordRequest(data))
    },
    clearKeywordResponse: () => {
      dispatch(clearKeywordResponse())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Faq)
