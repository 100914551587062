import {
  GET_POPULAR_FAQ_RESPONSE,
  GET_POPULAR_FAQ_REQUEST,
  GET_RECENT_FAQ_RESPONSE,
  GET_RECENT_FAQ_REQUEST,
  SUBSCRIBE_FAQ_REQUEST,
  SUBSCRIBE_FAQ_RESPONSE,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_RESPONSE,
  RESEND_OTP_VERIFICATION_REQUEST,
  RESEND_OTP_VERIFICATION_RESPONSE,
  CLEAR_SUBSCRIBE_RESPONSE,
  GET_KEYWORDS_REQUEST,
  GET_KEYWORDS_RESPONSE,
  GET_QUERY_NOTIFICATION_REQUEST,
  GET_QUERY_NOTIFICATION_RESPONSE,
  CLEAR_KEYWORDS_RESPONSE,
  GET_SEARCH_REQUEST,
  GET_SEARCH_RESPONSE,
} from './actionType'

export const subscribeFaqRequest = (data) => {
  return {
    type: SUBSCRIBE_FAQ_REQUEST,
    payload: { data },
  }
}

export const subscribeFaqResponse = (message, responseType, data, reqData) => {
  return {
    type: SUBSCRIBE_FAQ_RESPONSE,
    payload: { message, responseType, data, reqData },
  }
}

export const getRecentFaqRequest = (data) => {
  return {
    type: GET_RECENT_FAQ_REQUEST,
    payload: { data },
  }
}

export const getRecentFaqResponse = (message, responseType, data) => {
  return {
    type: GET_RECENT_FAQ_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const getPopularFaqRequest = (data) => {
  return {
    type: GET_POPULAR_FAQ_REQUEST,
    payload: { data },
  }
}

export const getPopularFaqResponse = (message, responseType, data) => {
  return {
    type: GET_POPULAR_FAQ_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const otpVerificationRequest = (data) => {
  return {
    type: OTP_VERIFICATION_REQUEST,
    payload: { data },
  }
}

export const otpVerificationResponse = (message, responseType, data, name) => {
  return {
    type: OTP_VERIFICATION_RESPONSE,
    payload: { message, responseType, data, name },
  }
}

export const resendOtpVerificationRequest = (data) => {
  return {
    type: RESEND_OTP_VERIFICATION_REQUEST,
    payload: { data },
  }
}

export const resendOtpVerificationResponse = (message, responseType, data) => {
  return {
    type: RESEND_OTP_VERIFICATION_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const clearSubscribeResponse = () => {
  return {
    type: CLEAR_SUBSCRIBE_RESPONSE,
  }
}

export const getKeywordRequest = (type) => {
  return {
    type: GET_KEYWORDS_REQUEST,
    payload: { type },
  }
}

export const getKeywordResponse = (data) => {
  return {
    type: GET_KEYWORDS_RESPONSE,
    payload: { data },
  }
}

export const getQueryNotificationRequest = () => {
  return {
    type: GET_QUERY_NOTIFICATION_REQUEST,
  }
}

export const getQueryNotificationResponse = (data) => {
  return {
    type: GET_QUERY_NOTIFICATION_RESPONSE,
    payload: { data },
  }
}

export const clearKeywordResponse = () => {
  return {
    type: CLEAR_KEYWORDS_RESPONSE,
  }
}

export const getSearchRequest = (data) => {
  return {
    type: GET_SEARCH_REQUEST,
    payload: { data },
  }
}

export const getSearchResponse = (message, responseType, data) => {
  return {
    type: GET_SEARCH_RESPONSE,
    payload: { message, responseType, data },
  }
}
