import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { GET_SUBSCIBER_EXPORT_REQUEST, GET_SUBSCRIBER_LIST_REQUEST } from './actionType'

import { getSubsciberExportResponse, getSubsciberListResponse } from './action'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { handleApiCallException } from 'store/shared/action'
import { invokeApi } from 'helpers/axiosHelper'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function* subsciberList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/subscriberlist', data)
    if (response.status) {
      yield put(
        getSubsciberListResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getSubsciberListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* subsciberExportList() {
  try {
    const response = yield call(invokeApi, 'post', '/subsciberExport')
    if (response.status) {
      var urlWithCacheBuster = response.data.url
      window.open(urlWithCacheBuster);
      yield put(
        getSubsciberExportResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getSubsciberExportResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(GET_SUBSCRIBER_LIST_REQUEST, subsciberList)
  yield takeEvery(GET_SUBSCIBER_EXPORT_REQUEST, subsciberExportList)
  
  // yield takeEvery(CREATE_USER_API_REQUEST, user)
  // yield takeEvery(USER_STATUS_REQUEST, userStatus)
}
function* subsciberSaga() {
  yield all([fork(watchLaw)])
}
export default subsciberSaga
