import produce from 'immer'
import {
  GET_SERVICE_LINE_LIST_RESPONSE,
  CLEAR_SERVICE_LINE_LIST_RESPONSE,
  ADD_SERVICE_LINE_RESPONSE,
  IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE,
} from './actionType'

export const initialState = {
  statusApiResponse: null,
  serviceLineList: [],
  addServiceResponse: null,
}

export const serviceLineReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_SERVICE_LINE_LIST_RESPONSE:
        draft.serviceLineList = action.payload.serviceLineList
        break

      case CLEAR_SERVICE_LINE_LIST_RESPONSE:
        return {
          ...state,
          serviceLineList: [],
        }

      case ADD_SERVICE_LINE_RESPONSE:
        if (action.payload.message == undefined) {
          draft.addServiceResponse = null
        } else {
          draft.addServiceResponse = {
            message: action.payload.message,
            responseType: action.payload.responseType,
          }
        }
        break

      case IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE:
        draft.statusApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      default:
        state = { ...state }
        break
    }
  })
