import {
  CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE,
  CREATE_RESEND_QUERY_REQUEST,
  CREATE_RESEND_QUERY_RESPONSE,
  GET_CONVERSATION_DETAILS_QUERY_REQUEST,
  GET_CONVERSATION_DETAILS_QUERY_RESPONSE,
  GET_SUBSCRIBER_QUERY_REQUEST,
  GET_SUBSCRIBER_QUERY_RESPONSE,
  SUBSCRIBER_TICKET_INDICATOR_REQUEST,
  SUBSCRIBER_TICKET_INDICATOR_RESPONSE,
} from './actionType'

export const getSubscriberQueryListRequest = (data) => {
  return {
    type: GET_SUBSCRIBER_QUERY_REQUEST,
    payload: { data },
  }
}

export const getSubscriberQueryListResponse = (response, offset) => {
  return {
    type: GET_SUBSCRIBER_QUERY_RESPONSE,
    payload: { response, offset },
  }
}

export const getConversationDetailsRequest = (id) => {
  return {
    type: GET_CONVERSATION_DETAILS_QUERY_REQUEST,
    payload: { id },
  }
}

export const getConversationDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_CONVERSATION_DETAILS_QUERY_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const createResendQueryRequest = (data) => {
  return {
    type: CREATE_RESEND_QUERY_REQUEST,
    payload: { data },
  }
}

export const createResendQueryResponse = (message, responseType) => {
  return {
    type: CREATE_RESEND_QUERY_RESPONSE,
    payload: { message, responseType },
  }
}
export const cleareResendQueryResponse = () => {
  return {
    type: CLEAR_SUBSCRIBER_QUERY_LIST_RESPONSE,
  }
}

export const subscriberTicketIndicatorRequest = (data) => {
  return {
    type: SUBSCRIBER_TICKET_INDICATOR_REQUEST,
    payload: { data },
  }
}

export const subscriberTicketIndicatorResponse = (message, responseType) => {
  return {
    type: SUBSCRIBER_TICKET_INDICATOR_RESPONSE,
    payload: { message, responseType },
  }
}
