import produce from 'immer';
import { GET_MODULE_LIST_RESPONSE } from './actionType';
export const initialState = {
  getModuleList: [],
};
//country function for operation
export const moduleReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_MODULE_LIST_RESPONSE:
      draft.getModuleList = action.payload.data;
      break;

    default:
      state = { ...state };
      break;
    }
  });
