import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  GET_ROLE_LIST_REQUEST,
  CREATE_ROLE_REQUEST,
  ROLE_PERMISSION_REQUEST,
  ROLE_PERMISSION_UPDATE_REQUEST,
  UPDATE_ROLE_REQUEST,
} from './actionType'

import { handleApiCallException } from '../../shared/action'
import {
  getRoleListResponse,
  createRoleResponse,
  getRoleListRequest,
  updateRoleResponse,
  rolePermissionResponse,
  rolePermissionRequest,
  rolePermissionUpdateResponse,
} from './action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { SUCCESS, DANGER } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function* getRoleList() {
  try {
    const response = yield call(invokeApi, 'get', '/roleList')
    if (response.status) {
      yield put(getRoleListResponse(response.data.list))
    } else {
      yield put(getRoleListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* createRole({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/role', data)
    if (response.status) {
      yield put(createRoleResponse(response))
      yield put(getRoleListRequest())
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(createRoleResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* updateRole({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/role', data)
    if (response.status) {
      yield put(updateRoleResponse(response.message))
      yield put(getRoleListRequest())
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(updateRoleResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* rolePermission({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'get', '/rolePermission/' + data)
    if (response.status) {
      yield put(rolePermissionResponse(response.data.role))
    } else {
      yield put(rolePermissionResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* rolePermissionUpdate({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/rolePermission/', data)
    if (response.status) {
      yield put(rolePermissionRequest(data.roleId))
      yield put(rolePermissionUpdateResponse(response))
    } else {
      yield put(rolePermissionUpdateResponse([]))
    }
    ApiResponseMessage(response.message, SUCCESS)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(GET_ROLE_LIST_REQUEST, getRoleList)
  yield takeEvery(CREATE_ROLE_REQUEST, createRole)
  yield takeEvery(UPDATE_ROLE_REQUEST, updateRole)
  yield takeEvery(ROLE_PERMISSION_REQUEST, rolePermission)
  yield takeEvery(ROLE_PERMISSION_UPDATE_REQUEST, rolePermissionUpdate)

  // yield takeEvery(CREATE_ROLE_REQUEST, createRole);
}
function* roleSaga() {
  yield all([fork(watchLaw)])
}
export default roleSaga
