import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import SimpleReactValidator from 'simple-react-validator'

import { getUserContactDetailsRequest } from 'store/frontEnd/contactus/action'
import {
  Container,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import { isValidNumberField } from 'helpers/SimpleReactValidators'
import { __t } from 'i18n/translator'
import HelmetComponent from '../Helmet/HelmetComponent'
import { getQueryNotificationRequest } from 'store/frontEnd/home/action'

const initialContactUsState = () => {
  return {
    name: '',
    email: '',
    number: '',
    message: '',
  }
}
const ContactUs = (props) => {
  const [initialValues] = useState(initialContactUsState())

  const validator = useRef(
    new SimpleReactValidator({
      validators: Object.assign(isValidNumberField()),
    }),
  )

  useEffect(() => {
    if (localStorage.getItem('subscriberToken')) {
      props.getQueryNotificationRequest()
    }
  }, [])
  const formSubmit = async (values, { resetForm }) => {
    if (validator.current.allValid()) {
      let modValues = {
        name: values.name,
        email: values.email,
        phoneNumber: values?.number,
        message: values.message,
      }
      props.getUserContactDetailsRequest(modValues)
      resetForm()
      validator.current.hideMessages()
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main">
        <div className="top_section mb-0">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>Contact Us</h1>
                  <span>Contact Us</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="/home">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Contact Us</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <div className="contact_form contact_main_bg">
              <div className="container">
                <Row>
                  <Col className="contact_text" lg={4}>
                    <div>
                      <div>
                        <h3>Connect with us</h3>
                        <p>
                          Thank you for your interest in Grant Thornton.
                          Whatever your enquiry, we'll help direct you to the appropriate place.
                        </p>
                      </div>
                      <div className="contact_details">
                        <ul>
                          <li>
                            <a href='mailto:gtmitra@in.gt.com' title='gtmitra@in.gt.com'>
                              <svg
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.0132028 4.15129C-3.38676e-10 4.69022 0 5.30205 0 6V8C0 10.8284 0 12.2426 0.87868 13.1213C1.75736 14 3.17157 14 6 14H12C14.8284 14 16.2426 14 17.1213 13.1213C18 12.2426 18 10.8284 18 8V6C18 5.30205 18 4.69022 17.9868 4.15129L9.97129 8.60436C9.36724 8.93994 8.63276 8.93994 8.02871 8.60436L0.0132028 4.15129ZM0.242967 2.02971C0.325845 2.05052 0.407399 2.08237 0.485643 2.12584L9 6.85604L17.5144 2.12584C17.5926 2.08237 17.6742 2.05052 17.757 2.02971C17.6271 1.55619 17.4276 1.18491 17.1213 0.87868C16.2426 0 14.8284 0 12 0H6C3.17157 0 1.75736 0 0.87868 0.87868C0.572448 1.18491 0.372942 1.55619 0.242967 2.02971Z"
                                  fill="currentColor"
                                />
                              </svg>
                              gtmitra@in.gt.com
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.67962 1.32038L3.29289 0.707107C3.68342 0.316583 4.31658 0.316582 4.70711 0.707106L7.29289 3.29289C7.68342 3.68342 7.68342 4.31658 7.29289 4.70711L5.50048 6.49952C5.2016 6.7984 5.1275 7.255 5.31653 7.63307C6.40929 9.81858 8.18142 11.5907 10.3669 12.6835C10.745 12.8725 11.2016 12.7984 11.5005 12.4995L13.2929 10.7071C13.6834 10.3166 14.3166 10.3166 14.7071 10.7071L17.2929 13.2929C17.6834 13.6834 17.6834 14.3166 17.2929 14.7071L16.6796 15.3204C14.5683 17.4317 11.2257 17.6693 8.83698 15.8777L7.62857 14.9714C5.88504 13.6638 4.33622 12.115 3.02857 10.3714L2.12226 9.16302C0.330722 6.7743 0.568269 3.43173 2.67962 1.32038Z"
                                  fill="currentColor"
                                />
                              </svg>
                              +91 11 4952 7400
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <svg
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.39804 15.804C8.88097 15.0348 14 12.0163 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 12.0163 5.11903 15.0348 6.60196 15.804C6.85483 15.9351 7.14517 15.9351 7.39804 15.804ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z"
                                  fill="currentColor"
                                />
                              </svg>6th Floor Worldmark 2, Aerocity New Delhi - 110037
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="contact_field">
                    <Form className="row">
                      <FormGroup className="col-12">
                        <Label for="name">First Name <span className="text-danger required">*</span></Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter Name"
                        />

                        {validator.current.message(
                          __t('validations.common.allow_only_alpha'),
                          values.name,
                          'required|alpha_space',
                          {
                            messages: {
                              required: __t(
                                'validations.common.allow_only_alpha_required',
                              ),
                              alpha_space: __t(
                                'validations.common.allow_only_alpha',
                              ),
                            },
                          },
                        )}
                      </FormGroup>

                      <FormGroup className="col-12">
                        <Label for="exampleEmail">Your Email <span className="text-danger required">*</span></Label>
                        <Field
                          className="form-control"
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Enter Email"
                        />

                        {validator.current.message(
                          __t('validations.user.email_required'),
                          values.email,
                          'required|email',
                          {
                            messages: {
                              required: __t('validations.user.email_required'),
                              email:
                                __t(
                                  'validations.common.email_valid'
                                ),
                            },
                          },
                        )}
                      </FormGroup>
                      <FormGroup className="col-12">
                        <Label for="number">Phone Number <span className="text-danger required">*</span></Label>
                        <Field
                          name="number"
                          placeholder="Enter your number"
                          className="form-control"
                          type="Number"
                          onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                        />
                        {validator.current.message(
                          __t('validations.common.phone_number_required'),
                          values.number,
                          'required|isValidNumber:',
                          {
                            messages: {
                              required: __t(
                                'validations.common.phone_number_required',
                              ),
                            },
                          },
                        )}
                      </FormGroup>
                      <FormGroup className="col-12">
                        <Label for="message">Message <span className="text-danger required">*</span></Label>
                        <Field
                          as="textarea"
                          type="textarea"
                          name="message"
                          id="message"
                          className="form-control"
                        />

                        {validator.current.message(
                          'message',
                          values.message,
                          'required',
                        )}
                      </FormGroup>
                      <Button
                        type="submit"
                        color="primary"
                        className=" btn-secound-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  )
}

const mapStateToProps = ({ userContactUsReducer }) => {
  return {
    UserContactDetailsApiResponse:
      userContactUsReducer.getUserContactDetailsApiResponse,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getUserContactDetailsRequest: (data) => {
      dispatch(getUserContactDetailsRequest(data))
    },
    getQueryNotificationRequest: () => {
      dispatch(getQueryNotificationRequest())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
