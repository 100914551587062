import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { Formik } from 'formik'
import CreateSection from './CreateSection'

import {
  getAllSectionListRequest,
  addSectionRequest,
  activeSectionRequest,
} from 'store/master/section/action'
import { connect } from 'react-redux'
import { dateFormater } from '../../../helpers/generalUtils'
import { SectionInitialState } from './CommonFunction'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { ViewTable } from 'components/Table/viewTable'
import { getLawListRequest } from 'store/master/law/action'
import AuditLogModal from 'pages/AuditLog/AuditLogModal'
import { getFormattedDescription } from '../Law/CommonFunction'

const Section = (props) => {
  const [sectionData] = useState(SectionInitialState())

  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(10)
  const [tableRefresh, setTableRefresh] = useState(false)

  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [auditId, setAuditId] = useState('')
  const [SectionList, setSectionList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [paginationData, setPaginationData] = useState({
    searchText: searchText,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  })
  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    setAuditId('')
  }

  const openAuditLogModal = (id) => {
    setIsAuditModalOpen(true)
    setAuditId(id)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [dataPerPage])
  useEffect(() => {
    props.getSectionList({
      searchText: searchText,
      pagination: {
        limit: dataPerPage,
        page: currentPage,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: paginationData.filter,
    })
  }, [currentPage, dataPerPage, searchText])

  useEffect(() => {
    setSectionList(props.sectionList)
    if (!tableRefresh) {
      setTableRefresh(true)
    }
  }, [props.sectionList])

  useEffect(() => {
    if (tableRefresh) {
      setTableRefresh(false)
    }
  }, [tableRefresh])

  useEffect(() => {
    props.getSectionList(paginationData)
    // props.getRoleList()
  }, [])

  useEffect(() => {
    props?.getSectionList(paginationData)
  }, [paginationData])

  const columns = [
    {
      dataField: 'Law.lawName',
      text: 'Law Name',
      formatter: (cell, row) => row.Law.lawName,
      sort: true,
    },
    {
      dataField: 'sectionName',
      text: 'Section/Rule Number',
      sort: true,
      style: {
        width: '550px',
        minWidth: '550px',
        maxWidth: '550px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'break-spaces',
        cursor: 'pointer',
      },
      formatter: (cell, row) => (cell ? getFormattedDescription(cell) : ''),
    },

    {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      formatter: dateFormater,
    },
    {
      dataField: 'status',
      text: 'Status',
      headerClasses: 'text-center',

      formatter: (cell, row) => (
        <Form className="text-center">
          <Form.Check
            onChange={async () => {
              const response = await commonConfirmBox(
                !row.isActive
                  ? 'Do you want to activate this section?'
                  : 'Do you want to deactivate this section?',
              )
              if (response) {
                setTableRefresh(true)
                // props.activeSectionStatus(row.id)
                props.activeSectionStatus({
                  sectionId: row.id,
                  isActive: !row.isActive,
                  currentPage,
                  dataPerPage,
                })
              }
            }}
            type="switch"
            id="custom-switch"
            className="justify-content-center toggle_switch_btn"
            checked={row.isActive}
          />
        </Form>
      ),
    },
    {
      dataField: 'Action',
      text: '',
      headerClasses: 'action_col',
      events: {},
      formatter: (cell, row) => (
        <div className="action_col text-center">
          <Link
            className="my-tooltip btn btn-link edit_btn"
            data-tooltip-content={'Edit'}
            to={'edit/' + row.id}
            state={{ section: row }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1123_13264)">
                <path
                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1123_13264">
                  <rect width="20" height="20" fill="CurrentColor" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <a
            className="my-tooltip btn btn-link history_btn"
            data-tooltip-content={'History'}
            onClick={(e) => openAuditLogModal(row.id)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                fill="currentColor"
              />
            </svg>
          </a>
        </div>
      ),
    },
  ]
  return (
    <>
      <Formik initialValues={sectionData}>
        <ViewTable
          tableRefresh={tableRefresh}
          getFilterDataBySection={(lawid) => {
            setTableRefresh(true)
            setCurrentPage(1)
            setPaginationData({
              ...paginationData,
              filter: {
                lawId: lawid.value,
              },
            })
          }}
          getRefreshList={() => {
            setCurrentPage(1)
            setPaginationData({
              ...paginationData,
              filter: {},
            })
            // props.getSectionList(paginationData)
          }}
          isSectionModule={true}
          hedingName="Section"
          buttonName="Add Section"
          component={CreateSection}
          href="/console/section/create"
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          totalRecords={props?.totalRecords}
          updateData={(e) => {
            if (e.searchText) {
              setSearchText(e.searchText)
            } else {
              setSearchText('')
            }
            setCurrentPage(1)
            props.getSectionList(e)
          }}
          data={SectionList ? SectionList : []}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
          columns={columns}
        ></ViewTable>
      </Formik>
      <AuditLogModal
        isModalOpen={isAuditModalOpen}
        modalName="Section History"
        auditType="sectionrule"
        auditId={auditId}
        onModalDismiss={() => closeAuditLogModal()}
      />
    </>
  )
}

const mapStateToProps = (sectionReducer) => {
  let sectionData = []
  let totalRecords
  if (
    sectionReducer.sectionReducer.sectionList.list &&
    sectionReducer.sectionReducer.sectionList?.list.length > 0
  ) {
    sectionData = sectionReducer?.sectionReducer?.sectionList?.list
    totalRecords = sectionReducer.sectionReducer.sectionList.totalRecords
  }

  return {
    sectionList: sectionData,
    totalRecords: totalRecords,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSectionList: (data) => {
      dispatch(getAllSectionListRequest(data))
    },
    activeSectionStatus: (sectionId, data) => {
      dispatch(activeSectionRequest(sectionId, data))
    },
    addSectionRequest: (data) => {
      dispatch(addSectionRequest(data))
    },
    getLawListRequest: (data) => {
      dispatch(getLawListRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Section)
