import produce from 'immer'
import {
  CLEAR_IS_REPORT_REVISION_RESPONSE,
  CLEAR_REPORT_DETAILS_RESPONSE,
  CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE,
  CLEAR_REPORT_FAQ_LIST_RESPONSE,
  CLEAR_REPORT_RESPONSE,
  CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE,
  CREATE_REPORT_RESPONSE,
  CREATE_REPORT_REVIEW_RESPONSE,
  GET_CHECK_REPORT_TITLE_RESPONSE,
  GET_OTHER_CATEGORY_RESPONSE,
  GET_REPORT_DETAILS_RESPONSE,
  GET_REPORT_DOWNLOAD_LIST_RESPONSE,
  GET_REPORT_IS_REVIEW_RESPONSE,
  GET_REPORT_KEYWORDS_RESPONSE,
  GET_REPORT_LIST_FAQ_COUNT_RESPONSE,
  GET_REPORT_LIST_FAQ_RESPONSE,
  GET_REPORT_RESPONSE,
} from './actionType'
let reportDownloadListOffset = []
let reportFaqListOffset = []
export const initialState = {
  reportFaqList: [],
  reportDetails: null,
  reportList: null,
  reportReviewApiresponse: null,
  reportKeywordList: null,
  createreportDetails: null,
  reportDownloadList: [],
  reportDownloadApproveApiResponse: null,
  isReviewApiResponse: null,
  CheckReportTitleApiResponse: null,
  otherCategoryApiResponse: null,
  faqTotalCount: null,
}

export const reportReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // case GET_REPORT_LIST_FAQ_RESPONSE:
      //   draft.reportFaqList = action.payload.data
      //   break

      case GET_REPORT_LIST_FAQ_RESPONSE: {
        if (!reportFaqListOffset.includes(action.payload.offset)) {
          reportFaqListOffset.push(action.payload.offset)

          draft.reportFaqList = draft.reportFaqList.concat(
            action.payload.response,
          )
        }
        break
      }

      case GET_REPORT_LIST_FAQ_COUNT_RESPONSE: {
        draft.faqTotalCount = action.payload.count
        break
      }
      case CLEAR_REPORT_FAQ_LIST_RESPONSE: {
        reportFaqListOffset = []
        draft.reportFaqList = []

        break
      }
      case GET_REPORT_RESPONSE:
        draft.reportList = action.payload.data
        break
      case CREATE_REPORT_RESPONSE:
      case GET_REPORT_DETAILS_RESPONSE:
        draft.reportDetails = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CREATE_REPORT_REVIEW_RESPONSE:
        draft.reportReviewApiresponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case GET_REPORT_KEYWORDS_RESPONSE:
        draft.reportKeywordList = action.payload.data
        break

      case GET_REPORT_DOWNLOAD_LIST_RESPONSE: {
        if (
          !reportDownloadListOffset.includes(action.payload.offset) &&
          action.payload.response &&
          action.payload.response.length > 0
        ) {
          reportDownloadListOffset.push(action.payload.offset)
          draft.reportDownloadList = draft.reportDownloadList.concat(
            action.payload.response,
          )
        }
        break
      }
      case CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE: {
        reportDownloadListOffset = []
        draft.reportDownloadList = []
        break
      }

      case CLEAR_REPORT_RESPONSE:
        draft.reportList = []
        break

      case CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE:
        draft.reportDownloadApproveApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case GET_REPORT_IS_REVIEW_RESPONSE:
        draft.isReviewApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case CLEAR_IS_REPORT_REVISION_RESPONSE:
        draft.isReviewApiResponse = null
        break

      case GET_CHECK_REPORT_TITLE_RESPONSE:
        draft.CheckReportTitleApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case GET_OTHER_CATEGORY_RESPONSE:
        draft.otherCategoryApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_REPORT_DETAILS_RESPONSE:
        draft.reportDetails = {}
        break

      default:
        state = { ...state }
        break
    }
  })
