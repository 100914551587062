import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import {
  createResendQueryResponse,
  getConversationDetailsRequest,
  getConversationDetailsResponse,
  getSubscriberQueryListResponse,
  subscriberTicketIndicatorResponse,
} from './action'
import {
  CREATE_RESEND_QUERY_REQUEST,
  GET_CONVERSATION_DETAILS_QUERY_REQUEST,
  GET_SUBSCRIBER_QUERY_REQUEST,
  SUBSCRIBER_TICKET_INDICATOR_REQUEST,
} from './actionType'

function* getSubscriberQueryList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/subscriberQueryList', data)
    if (response.status) {
      yield put(getSubscriberQueryListResponse(response.data, data.offset))
    } else {
      yield put(getSubscriberQueryListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getConversationDetails({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/conversationDetails/${id}`)

    if (response.status) {
      yield put(
        getConversationDetailsResponse(
          response.message,
          SUCCESS,
          response.data,
        ),
      )
    } else {
      yield put(getConversationDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* subscriberTicketIndicator({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/subscriberTicketIndicator',
      data,
    )
    if (response.status) {
      yield put(subscriberTicketIndicatorResponse(response.message, SUCCESS))
    } else {
      yield put(subscriberTicketIndicatorResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createResendQuery({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/resendQuery', data)
    if (response.status) {
      yield put(createResendQueryResponse(response.message, SUCCESS))
      yield put(getConversationDetailsRequest(data.queryId))
    } else {
      yield put(createResendQueryResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchReport() {
  yield takeEvery(GET_SUBSCRIBER_QUERY_REQUEST, getSubscriberQueryList)
  yield takeEvery(
    GET_CONVERSATION_DETAILS_QUERY_REQUEST,
    getConversationDetails,
  )
  yield takeEvery(CREATE_RESEND_QUERY_REQUEST, createResendQuery)
  yield takeEvery(
    SUBSCRIBER_TICKET_INDICATOR_REQUEST,
    subscriberTicketIndicator,
  )
}
function* userTicketSaga() {
  yield all([fork(watchReport)])
}
export default userTicketSaga
