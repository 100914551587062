import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Logo from '../images/logo-light.webp'
import Dropdown from 'react-bootstrap/Dropdown'
import { getValueByKey, isHaveModuleAccess } from 'helpers/authUtils'
import {
  CATEGORY_MODULE_ID,
  CONSULTATION_MODULE_ID,
  COUNTRY_MODULE_ID,
  DASHBOARD,
  FAQ_MODULE_ID,
  HELP_AND_SUPPORT_MODULE_ID,
  LAW_MODULE_ID,
  REPORT_DOWNLOAD_MODULE_ID,
  REPORT_MODULE_ID,
  ROLE_MODULE_ID,
  SECTION_MODULE_ID,
  SERVICELINE_MODULE_ID,
  SUBSCIBER_MODULE_ID,
  TEAM_CREATION_MODULE_ID,
  TEMPLATE_MODULE_ID,
  USER_MODULE_ID,
} from 'constant/databaseConstants'
import { getInitials, setActiveStatus } from 'helpers/generalUtils'
import AzureAD from 'react-aad-msal'
import { authProvider } from './Auth/authProvider'
import * as authConstants from '../constant/authConstants'

const Header = () => {
  const navigate = useNavigate()
  const auth = localStorage.getItem('user')
  const countryData = JSON.parse(localStorage.getItem('countryData'))
  const getCurrentModule = location.pathname?.split('/')[2]

  const setModuleActiveClass = (moduleId) => {
    return setActiveStatus(getCurrentModule, moduleId)
  }

  return (
    <>
      {auth && (
        <Navbar className={location.pathname === '/' ? 'dashboard-page' : ''}>
          <Container>
            <Navbar.Brand>
              <Link to="/console/home">
                <img alt="Logo" className="logo_main" src={Logo} />
              </Link>
            </Navbar.Brand>

            <Nav className="">
              <Nav.Link
                as={Link}
                to="/console/home"
                className={setModuleActiveClass(DASHBOARD)}
              >
                Dashboard
              </Nav.Link>

              {isHaveModuleAccess([
                CATEGORY_MODULE_ID,
                ROLE_MODULE_ID,

                SECTION_MODULE_ID,
                LAW_MODULE_ID,
                USER_MODULE_ID,
                COUNTRY_MODULE_ID,
                SERVICELINE_MODULE_ID,
              ]) && (
                  <NavDropdown
                    title="Masters"
                    id="collasible-nav-dropdown"
                    direction="end"
                    horizontal="end"
                    className={setModuleActiveClass('master')}
                  >
                    {isHaveModuleAccess(SERVICELINE_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/serviceline">
                        Service Line
                      </NavDropdown.Item>
                    )}

                    {isHaveModuleAccess(CATEGORY_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/category">
                        Category
                      </NavDropdown.Item>
                    )}

                    {isHaveModuleAccess(COUNTRY_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/country">
                        Country
                      </NavDropdown.Item>
                    )}
                    {isHaveModuleAccess(LAW_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/law">
                        Law
                      </NavDropdown.Item>
                    )}
                    {isHaveModuleAccess(SECTION_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/section">
                        Section
                      </NavDropdown.Item>
                    )}
                    {isHaveModuleAccess(ROLE_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/role">
                        Role
                      </NavDropdown.Item>
                    )}
                    {isHaveModuleAccess(USER_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/user">
                        Users
                      </NavDropdown.Item>
                    )}

                    {isHaveModuleAccess(TEAM_CREATION_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/team-creation">
                        Team Creation
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                )}
              {isHaveModuleAccess(FAQ_MODULE_ID) && (
                <Nav.Link
                  as={Link}
                  to="/console/faq"
                  className={setModuleActiveClass(FAQ_MODULE_ID)}
                >
                  FAQs
                </Nav.Link>
              )}
              {isHaveModuleAccess(REPORT_MODULE_ID) && (
                <Nav.Link
                  as={Link}
                  to={{
                    pathname: 'console/report',
                    state: {},
                  }}
                  className={setModuleActiveClass(REPORT_MODULE_ID)}
                >
                  Reports
                </Nav.Link>
              )}
              {isHaveModuleAccess(CONSULTATION_MODULE_ID) && (
                <Nav.Link
                  as={Link}
                  to="/console/consultationRequest"
                  className={setModuleActiveClass(CONSULTATION_MODULE_ID)}
                >
                  Consultations
                </Nav.Link>
              )}

              {isHaveModuleAccess(SUBSCIBER_MODULE_ID) && (
                <Nav.Link
                  as={Link}
                  to="/console/subscriber"
                  className={setModuleActiveClass(SUBSCIBER_MODULE_ID)}
                >
                  Subscribers
                </Nav.Link>
              )}

              {(isHaveModuleAccess(REPORT_DOWNLOAD_MODULE_ID) ||
                isHaveModuleAccess(TEMPLATE_MODULE_ID) ||
                isHaveModuleAccess(HELP_AND_SUPPORT_MODULE_ID)) && (
                  <NavDropdown
                    title="Request & Support"
                    id="collasible-nav-dropdown"
                    direction="end"
                    horizontal="end"
                    className={setModuleActiveClass('otherModule')}
                  >
                    {isHaveModuleAccess(REPORT_DOWNLOAD_MODULE_ID) && (
                      <>
                        {location.pathname == '/console/reportRequest' ? (
                          <NavDropdown.Item onClick={() => navigate(0)}>
                            Download Request
                          </NavDropdown.Item>
                        ) : (
                          <NavDropdown.Item as={Link} to="/console/reportRequest">
                            Download Request
                          </NavDropdown.Item>
                        )}
                      </>
                    )}

                    {isHaveModuleAccess(HELP_AND_SUPPORT_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/help">
                        Help & Support
                      </NavDropdown.Item>
                    )}

                    {isHaveModuleAccess(TEMPLATE_MODULE_ID) && (
                      <NavDropdown.Item as={Link} to="/console/template/create">
                        Email Template
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                )}

              <div className="right_header">
                <Dropdown className="notification_btn">
                  {/* <Dropdown.Toggle
                    id="dropdown-basic"
                    className="position-relative"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M25.3677 18.9391V9.86768C25.3677 4.70215 21.1655 0.5 16 0.5C10.8345 0.5 6.63232 4.70215 6.63232 9.86768V18.9397C4.96704 19.4224 3.73828 20.9544 3.73828 22.8374C3.73828 25.0386 5.5293 26.8296 7.73096 26.8296H11.377V26.877C11.377 29.4263 13.4507 31.5 16 31.5C18.5493 31.5 20.6231 29.4263 20.6231 26.8769V26.8296H24.2691C26.4707 26.8296 28.2617 25.0386 28.2617 22.7583C28.2617 20.9406 27.033 19.4198 25.3677 18.9391ZM9.63232 9.86768C9.63232 6.35645 12.4888 3.5 16 3.5C19.5112 3.5 22.3677 6.35645 22.3677 9.86768V18.7661H9.63232V9.86768ZM17.6231 26.8769C17.6231 27.772 16.895 28.5 16 28.5C15.105 28.5 14.377 27.772 14.377 26.8769V26.8296H17.623V26.8769H17.6231ZM24.269 23.8296H7.73096C7.1836 23.8296 6.73828 23.3843 6.73828 22.7583C6.73828 22.2114 7.18359 21.7661 7.73096 21.7661H24.2691C24.8164 21.7661 25.2617 22.2114 25.2617 22.8374C25.2617 23.3843 24.8164 23.8296 24.269 23.8296Z" />
                    </svg>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      0
                    </span>
                  </Dropdown.Toggle> */}

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Lorem ipsum dolor
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">consectetur</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Proin dolor orci
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="profile_btn">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="position-relative"
                  >
                    <div className="profile_name">
                      <h5>{JSON.parse(auth)}</h5>
                      <div>
                        <p>{localStorage.getItem('role')}</p>
                        <span
                          className="my-tooltip profile-country-box"
                          data-tooltip-content={
                            countryData != undefined && countryData.countryName
                          }
                        >
                          {countryData != undefined && countryData.countryName}
                        </span>
                      </div>
                      {/* <p>{countryData != undefined && countryData.countryName}</p> */}
                    </div>
                    <span className="user-profile">
                      {getInitials(JSON.parse(auth))}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {getValueByKey(authConstants.LOGIN_TYPE) === 'SSO' ? (
                      <AzureAD provider={authProvider} forceLogin={false}>
                        {({ logout }) => {
                          return (
                            <Dropdown.Item onClick={logout}>
                              <svg
                                width="22"
                                height="20"
                                viewBox="0 0 22 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z" />
                              </svg>
                              Logout
                            </Dropdown.Item>
                          )
                        }}
                      </AzureAD>
                    ) : (
                      <Dropdown.Item onClick={() => navigate('/console/logout')}>
                        <svg
                          width="22"
                          height="20"
                          viewBox="0 0 22 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z" />
                        </svg>
                        Logout
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Nav>
          </Container>
        </Navbar>
      )}
    </>
  )
}

export default Header
