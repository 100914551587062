import { Field } from 'formik'
import CustomPagination from './CustomPagination'
import React, { useState } from 'react'

import {
  Card,
  CardBody,
  Progress,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from 'reactstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { faqStatus, statusLableChanges } from 'helpers/generalUtils'
import {
  APPROVED,
  DRAFTED,
  PUBLISHED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'
import FaqFilterSelectorFormik from 'components/Selector/FaqFilterSelectorFormik'
import AuditLogModal from 'pages/AuditLog/AuditLogModal'
import { getValueByKey } from 'helpers/authUtils'
import { formatDateForApi } from 'helpers/DataTimeHelpers'
import moment from 'moment'
import { getFirstLetters } from 'pages/ConsultationRequest/CommonFunction'

const ConsultationTable = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [customFilter, setCustomFilter] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [auditId, setAuditId] = useState('')
  const [userId] = useState(JSON.parse(getValueByKey('userId')))
  const navigate = useNavigate()
  const [isActiveTab, setIsActiveTab] = useState(
    permissions.approve || permissions.add
      ? 'myConsultation'
      : 'delegateToTeam',
  )
  const {
    hedingName,
    showHeding = true,
    buttonName = 'Create',
    component,
    href,
    totalRecords,
    updateData,
    data,
    isConsultationFilterCategory,
    dataPerPage,
    currentPage,
    setCurrentPage,
    setDataPerPage,
    getViewDetailPageData,
    currentActiveTab,
  } = props
  const [search, setSearch] = useState('')
  const paginate = (pageNumber, dataPerPageForPaginate) => {
    const Data = {
      searchText: '',
      pagination: {
        limit: dataPerPageForPaginate || 10,
        page: pageNumber,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },

      filter: {},
    }
    updateData(Data)
    setDataPerPage(dataPerPage)
    setCurrentPage(pageNumber)
  }

  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    setAuditId('')
  }

  const openAuditLogModal = (id, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    setIsAuditModalOpen(true)
    setAuditId(id)
  }

  const filterWiseReport = (type) => {
    setIsActiveTab(type)
  }

  const getUserName = (row) => {
    let userList = []
    const approvalUsers = row.approvalWorkFlow?.approvalDetails?.filter(
      (x) => x?.approvalWorkFlowId == row?.approvalWorkFlowId,
    )
    userList = approvalUsers
    return userList
  }
  let status = ''
  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }

  return (
    <div className="container">
      <div className="section-head">
        {showHeding && (
          <div>
            <h3>{hedingName}</h3>
          </div>
        )}
        <div className="header-action-item">
          <Link to={href}>
            {buttonName && (
              <Button className="creat_btn" component={component}>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 2L11 20"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M20 11L2 11"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
                {buttonName}
              </Button>
            )}
          </Link>
          <div className="search_box">
            <Button
              color="link"
              className="p-0"
              // data-tooltip-content={'Search'}
              onClick={() => {
                if (search?.length > 0) {
                  setCurrentPage(1)
                }
                updateData({
                  searchText: search?.length > 0 ? search : '',
                  pagination: {
                    limit: dataPerPage,
                    page: 0,
                    orderKey: 'createdDate',
                    orderBy: 'ASC',
                  },

                  filter: {},
                })
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
              </svg>
            </Button>
            <Field
              name="searchbox"
              type="search"
              onChange={(e) => {
                setSearch(e.target.value)
                if (e.target.value?.length <= 0) {
                  updateData({
                    searchText: '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },

                    filter: {},
                  })
                }
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter' && !evt.shiftKey) {
                  if (search?.length > 0) {
                    setCurrentPage(1)
                  }
                  updateData({
                    searchText: search?.length > 0 ? search : '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },

                    filter: {},
                  })
                }
              }}
              placeholder="Search box"
              value={search}
            />
          </div>
          <Dropdown
            className="my-tooltip filter_btn"
            data-tooltip-content={'Apply Filter'}
            onClick={() => {
              setCustomFilter(true)
            }}
          >
            <Dropdown.Toggle id="dropdown-basic">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2.66669"
                  y="4"
                  width="26.6667"
                  height="12"
                  rx="2"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M21.3334 24V24C21.3334 24.62 21.3334 24.93 21.2652 25.1843C21.0803 25.8745 20.5412 26.4136 19.851 26.5985C19.5967 26.6667 19.2867 26.6667 18.6667 26.6667H13.3334C12.7134 26.6667 12.4034 26.6667 12.1491 26.5985C11.4589 26.4136 10.9198 25.8745 10.7348 25.1843C10.6667 24.93 10.6667 24.62 10.6667 24V24"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M25.3334 18.6666V18.6666C25.3334 19.2866 25.3334 19.5966 25.2652 19.8509C25.0803 20.5411 24.5412 21.0802 23.851 21.2652C23.5967 21.3333 23.2867 21.3333 22.6667 21.3333H9.33335C8.71337 21.3333 8.40338 21.3333 8.14905 21.2652C7.45887 21.0802 6.91977 20.5411 6.73483 19.8509C6.66669 19.5966 6.66669 19.2866 6.66669 18.6666V18.6666"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>

      <Row>
        <Col>
          <Nav tabs className="reports_tab">
            <>
              {permissions.approve || permissions.add ? (
                <NavItem>
                  <NavLink
                    className={isActiveTab == 'myConsultation' && 'active'}
                    onClick={() => {
                      if (isActiveTab != 'myConsultation') {
                        props.clearConsultationList()
                      }
                      filterWiseReport('myConsultation')
                      currentActiveTab('myConsultation')
                      setCurrentPage(1)
                    }}
                  >
                    Consultation Request
                  </NavLink>
                </NavItem>
              ) : (
                ''
              )}

              {isAdmin() == false && (
                <NavItem>
                  <NavLink
                    className={isActiveTab == 'delegateToTeam' && 'active'}
                    onClick={() => {
                      if (isActiveTab != 'delegateToTeam') {
                        props.clearConsultationList()
                      }
                      filterWiseReport('delegateToTeam')
                      setCurrentPage(1)
                      currentActiveTab('delegateToTeam')
                      // setCustomFilter(false)
                    }}
                  >
                    Delegated Consultation by Others
                  </NavLink>
                </NavItem>
              )}
            </>
          </Nav>
        </Col>
      </Row>

      <TabContent activeTab="1" className="report_tab-content">
        <TabPane tabId="1" className="">
          {isConsultationFilterCategory && customFilter && (
            <Card className="filter_section">
              <CardBody>
                <h6 className="separate-head">Filter</h6>
                <div className="faq_filter">
                  <FaqFilterSelectorFormik
                    Type={'CONSULTATION'}
                    moduleName="consultationRequest"
                    isFaqModule={true}
                    getFaqListFilter={(
                      categoryId,
                      subCategoryId,
                      law,
                      section,
                      filterStatus,
                      date,
                    ) => {
                      props.getConsultationDataByCategory(
                        categoryId,
                        subCategoryId,
                        law,
                        section,
                        filterStatus,
                        date ? formatDateForApi(date) : '',
                      )
                    }}
                    cancelFilterSection={(e) => {
                      setSearch('')
                      props.getRefreshList(true)
                      setCustomFilter(e ? false : true)
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          )}

          <Table className="faq-table">
            <tbody>
              {data.map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={row?.id}>
                      <td>
                        <a
                          onClick={() => {
                            getViewDetailPageData(row)
                          }}
                        >
                          <p className="mb-0">{row?.heading}</p>
                          <div className="consultation_name">
                            {row?.createdByUser && row?.createdByUser?.fullName && (
                              <small>
                                <b>Created By : </b>{' '}
                                {row?.createdByUser?.fullName}
                              </small>
                            )}
                            {row?.TeamDelegatesQueries[0]?.delegateUser && row?.TeamDelegatesQueries[0]?.delegateUser
                              .fullName && (
                                <small>
                                  <b>Delegated User : </b>{' '}
                                  {
                                    row?.TeamDelegatesQueries[0]?.delegateUser
                                      .fullName
                                  }
                                </small>
                              )}

                            {row?.approvalWorkFlow?.approvalDetails[0]?.user
                              ?.fullName && (
                                <small>
                                  <b>Approver : </b>{' '}
                                  {
                                    row?.approvalWorkFlow?.approvalDetails[0]
                                      ?.user?.fullName
                                  }
                                </small>
                              )}

                            {row?.updatedDate && (
                              <small>
                                <b>Updated Date : </b>{' '}
                                {row?.updatedDate == null
                                  ? 'Not available'
                                  : moment(
                                    row?.updatedDate,
                                    'DD-MM-YYYY HH:mm:ss',
                                  ).format('DD-MM-YYYY')}
                              </small>
                            )}
                          </div>
                        </a>
                      </td>
                      <td className="user-box faq_user_box consultation_btn_box">
                        <div className="d-flex">
                          {isAdmin() == false && (
                            <>
                              {permissions.add && permissions.update ? (
                                row?.isReviewedByAdminUser && (
                                  <div className="comments_btn">
                                    <Button
                                      onClick={() => {
                                        //  setIsAuditModalOpen(!isAuditModalOpen)
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="42"
                                        height="32"
                                        viewBox="0 0 42 32"
                                        fill="none"
                                      >
                                        <path
                                          d="M35.652 14.023C35.5256 13.9828 35.3884 13.9945 35.2707 14.0555C35.1529 14.1164 35.0641 14.2216 35.024 14.348C34.9838 14.4744 34.9955 14.6116 35.0564 14.7293C35.1174 14.8471 35.2226 14.9358 35.349 14.976C39.257 16.221 41 18.078 41 21C41 23.599 38.629 25.616 37.217 26.588C37.1501 26.6339 37.0954 26.6954 37.0576 26.7672C37.0198 26.839 37 26.9189 37 27V30.823C35.7993 30.3676 34.7267 29.6282 33.874 28.668C33.8161 28.6036 33.7427 28.5552 33.6608 28.5273C33.5788 28.4995 33.4911 28.4932 33.406 28.509C33.121 28.564 32.83 28.642 32.535 28.721C32.025 28.858 31.499 29 31 29C28.432 29 26.634 28.448 24.796 27.097C24.6891 27.0184 24.5553 26.9854 24.4242 27.0054C24.293 27.0254 24.1751 27.0966 24.0965 27.2035C24.0178 27.3104 23.9849 27.4441 24.0048 27.5753C24.0248 27.7065 24.0961 27.8244 24.203 27.903C26.23 29.393 28.199 30 31 30C31.631 30 32.223 29.841 32.795 29.687C32.973 29.638 33.15 29.59 33.325 29.549C35.194 31.523 37.308 31.972 37.4 31.99C37.4726 32.0051 37.5477 32.0037 37.6197 31.9859C37.6917 31.968 37.7588 31.9343 37.816 31.887C37.8735 31.8403 37.9199 31.7813 37.9517 31.7143C37.9836 31.6474 38 31.5742 38 31.5V27.261C40.582 25.42 42 23.204 42 21C42 17.619 39.983 15.402 35.652 14.023Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M33 13.5C33 5.804 25.982 0 16.677 0C7.325 0 0 5.931 0 13.502C0 18.041 3.211 21.293 6 23.261V29.897C6.00052 29.9803 6.02175 30.0621 6.06179 30.1352C6.10183 30.2082 6.15941 30.2702 6.22935 30.3154C6.29929 30.3606 6.37939 30.3878 6.46242 30.3943C6.54546 30.4009 6.62882 30.3867 6.705 30.353C6.851 30.288 10.264 28.737 13.184 25.544C14.449 25.779 15.88 26.005 17.178 26.005C26.641 26.005 33 20.979 33 13.5ZM17.177 25.005C15.867 25.005 14.378 24.754 13.094 24.509C13.0089 24.4934 12.9212 24.4998 12.8393 24.5276C12.7574 24.5554 12.684 24.6038 12.626 24.668C10.576 26.98 8.167 28.449 7 29.082V23C6.99997 22.9189 6.9802 22.839 6.94239 22.7672C6.90458 22.6954 6.84988 22.6339 6.783 22.588C4.145 20.773 1 17.725 1 13.502C1 6.491 7.886 1 16.677 1C25.413 1 32 6.374 32 13.5C32 20.382 26.043 25.005 17.177 25.005Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M16.5 11.5C15.397 11.5 14.5 12.397 14.5 13.5C14.5 14.603 15.397 15.5 16.5 15.5C17.603 15.5 18.5 14.603 18.5 13.5C18.5 12.397 17.603 11.5 16.5 11.5ZM16.5 14.5C16.2425 14.4885 15.9994 14.3781 15.8213 14.1918C15.6431 14.0055 15.5437 13.7577 15.5437 13.5C15.5437 13.2423 15.6431 12.9945 15.8213 12.8082C15.9994 12.6219 16.2425 12.5115 16.5 12.5C16.7575 12.5115 17.0006 12.6219 17.1787 12.8082C17.3569 12.9945 17.4563 13.2423 17.4563 13.5C17.4563 13.7577 17.3569 14.0055 17.1787 14.1918C17.0006 14.3781 16.7575 14.4885 16.5 14.5ZM23.5 11.5C22.397 11.5 21.5 12.397 21.5 13.5C21.5 14.603 22.397 15.5 23.5 15.5C24.603 15.5 25.5 14.603 25.5 13.5C25.5 12.397 24.603 11.5 23.5 11.5ZM23.5 14.5C23.2425 14.4885 22.9994 14.3781 22.8213 14.1918C22.6431 14.0055 22.5437 13.7577 22.5437 13.5C22.5437 13.2423 22.6431 12.9945 22.8213 12.8082C22.9994 12.6219 23.2425 12.5115 23.5 12.5C23.7575 12.5115 24.0006 12.6219 24.1787 12.8082C24.3569 12.9945 24.4563 13.2423 24.4563 13.5C24.4563 13.7577 24.3569 14.0055 24.1787 14.1918C24.0006 14.3781 23.7575 14.4885 23.5 14.5ZM9.5 11.595C8.397 11.595 7.5 12.492 7.5 13.595C7.5 14.698 8.397 15.595 9.5 15.595C10.603 15.595 11.5 14.698 11.5 13.595C11.5 12.492 10.603 11.595 9.5 11.595ZM9.5 14.595C9.24252 14.5835 8.9994 14.4731 8.82128 14.2868C8.64315 14.1005 8.54373 13.8527 8.54373 13.595C8.54373 13.3373 8.64315 13.0895 8.82128 12.9032C8.9994 12.7169 9.24252 12.6065 9.5 12.595C9.75748 12.6065 10.0006 12.7169 10.1787 12.9032C10.3569 13.0895 10.4563 13.3373 10.4563 13.595C10.4563 13.8527 10.3569 14.1005 10.1787 14.2868C10.0006 14.4731 9.75748 14.5835 9.5 14.595Z"
                                          fill="black"
                                        />
                                      </svg>
                                      <span className="notification_badge"></span>
                                    </Button>
                                  </div>
                                )
                              ) : (
                                <>
                                  {row.TeamDelegatesQueries?.length > 0 && (
                                    <>
                                      {row?.TeamDelegatesQueries[0].delegateUser && row?.TeamDelegatesQueries[0].delegateUser
                                        .id != userId &&
                                        !row?.isReviewedByQueryUser && (
                                          <div className="comments_btn">
                                            <Button
                                              onClick={() => {
                                                //  setIsAuditModalOpen(!isAuditModalOpen)
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="42"
                                                height="32"
                                                viewBox="0 0 42 32"
                                                fill="none"
                                              >
                                                <path
                                                  d="M35.652 14.023C35.5256 13.9828 35.3884 13.9945 35.2707 14.0555C35.1529 14.1164 35.0641 14.2216 35.024 14.348C34.9838 14.4744 34.9955 14.6116 35.0564 14.7293C35.1174 14.8471 35.2226 14.9358 35.349 14.976C39.257 16.221 41 18.078 41 21C41 23.599 38.629 25.616 37.217 26.588C37.1501 26.6339 37.0954 26.6954 37.0576 26.7672C37.0198 26.839 37 26.9189 37 27V30.823C35.7993 30.3676 34.7267 29.6282 33.874 28.668C33.8161 28.6036 33.7427 28.5552 33.6608 28.5273C33.5788 28.4995 33.4911 28.4932 33.406 28.509C33.121 28.564 32.83 28.642 32.535 28.721C32.025 28.858 31.499 29 31 29C28.432 29 26.634 28.448 24.796 27.097C24.6891 27.0184 24.5553 26.9854 24.4242 27.0054C24.293 27.0254 24.1751 27.0966 24.0965 27.2035C24.0178 27.3104 23.9849 27.4441 24.0048 27.5753C24.0248 27.7065 24.0961 27.8244 24.203 27.903C26.23 29.393 28.199 30 31 30C31.631 30 32.223 29.841 32.795 29.687C32.973 29.638 33.15 29.59 33.325 29.549C35.194 31.523 37.308 31.972 37.4 31.99C37.4726 32.0051 37.5477 32.0037 37.6197 31.9859C37.6917 31.968 37.7588 31.9343 37.816 31.887C37.8735 31.8403 37.9199 31.7813 37.9517 31.7143C37.9836 31.6474 38 31.5742 38 31.5V27.261C40.582 25.42 42 23.204 42 21C42 17.619 39.983 15.402 35.652 14.023Z"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M33 13.5C33 5.804 25.982 0 16.677 0C7.325 0 0 5.931 0 13.502C0 18.041 3.211 21.293 6 23.261V29.897C6.00052 29.9803 6.02175 30.0621 6.06179 30.1352C6.10183 30.2082 6.15941 30.2702 6.22935 30.3154C6.29929 30.3606 6.37939 30.3878 6.46242 30.3943C6.54546 30.4009 6.62882 30.3867 6.705 30.353C6.851 30.288 10.264 28.737 13.184 25.544C14.449 25.779 15.88 26.005 17.178 26.005C26.641 26.005 33 20.979 33 13.5ZM17.177 25.005C15.867 25.005 14.378 24.754 13.094 24.509C13.0089 24.4934 12.9212 24.4998 12.8393 24.5276C12.7574 24.5554 12.684 24.6038 12.626 24.668C10.576 26.98 8.167 28.449 7 29.082V23C6.99997 22.9189 6.9802 22.839 6.94239 22.7672C6.90458 22.6954 6.84988 22.6339 6.783 22.588C4.145 20.773 1 17.725 1 13.502C1 6.491 7.886 1 16.677 1C25.413 1 32 6.374 32 13.5C32 20.382 26.043 25.005 17.177 25.005Z"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M16.5 11.5C15.397 11.5 14.5 12.397 14.5 13.5C14.5 14.603 15.397 15.5 16.5 15.5C17.603 15.5 18.5 14.603 18.5 13.5C18.5 12.397 17.603 11.5 16.5 11.5ZM16.5 14.5C16.2425 14.4885 15.9994 14.3781 15.8213 14.1918C15.6431 14.0055 15.5437 13.7577 15.5437 13.5C15.5437 13.2423 15.6431 12.9945 15.8213 12.8082C15.9994 12.6219 16.2425 12.5115 16.5 12.5C16.7575 12.5115 17.0006 12.6219 17.1787 12.8082C17.3569 12.9945 17.4563 13.2423 17.4563 13.5C17.4563 13.7577 17.3569 14.0055 17.1787 14.1918C17.0006 14.3781 16.7575 14.4885 16.5 14.5ZM23.5 11.5C22.397 11.5 21.5 12.397 21.5 13.5C21.5 14.603 22.397 15.5 23.5 15.5C24.603 15.5 25.5 14.603 25.5 13.5C25.5 12.397 24.603 11.5 23.5 11.5ZM23.5 14.5C23.2425 14.4885 22.9994 14.3781 22.8213 14.1918C22.6431 14.0055 22.5437 13.7577 22.5437 13.5C22.5437 13.2423 22.6431 12.9945 22.8213 12.8082C22.9994 12.6219 23.2425 12.5115 23.5 12.5C23.7575 12.5115 24.0006 12.6219 24.1787 12.8082C24.3569 12.9945 24.4563 13.2423 24.4563 13.5C24.4563 13.7577 24.3569 14.0055 24.1787 14.1918C24.0006 14.3781 23.7575 14.4885 23.5 14.5ZM9.5 11.595C8.397 11.595 7.5 12.492 7.5 13.595C7.5 14.698 8.397 15.595 9.5 15.595C10.603 15.595 11.5 14.698 11.5 13.595C11.5 12.492 10.603 11.595 9.5 11.595ZM9.5 14.595C9.24252 14.5835 8.9994 14.4731 8.82128 14.2868C8.64315 14.1005 8.54373 13.8527 8.54373 13.595C8.54373 13.3373 8.64315 13.0895 8.82128 12.9032C8.9994 12.7169 9.24252 12.6065 9.5 12.595C9.75748 12.6065 10.0006 12.7169 10.1787 12.9032C10.3569 13.0895 10.4563 13.3373 10.4563 13.595C10.4563 13.8527 10.3569 14.1005 10.1787 14.2868C10.0006 14.4731 9.75748 14.5835 9.5 14.595Z"
                                                  fill="black"
                                                />
                                              </svg>
                                              <span className="notification_badge"></span>
                                            </Button>
                                          </div>
                                        )}
                                      {row.TeamDelegatesQueries[0].delegateUser && row.TeamDelegatesQueries[0].delegateUser
                                        .id == userId &&
                                        !row?.isReviewedByTeam && (
                                          <div className="comments_btn">
                                            <Button
                                              onClick={() => {
                                                //  setIsAuditModalOpen(!isAuditModalOpen)
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="42"
                                                height="32"
                                                viewBox="0 0 42 32"
                                                fill="none"
                                              >
                                                <path
                                                  d="M35.652 14.023C35.5256 13.9828 35.3884 13.9945 35.2707 14.0555C35.1529 14.1164 35.0641 14.2216 35.024 14.348C34.9838 14.4744 34.9955 14.6116 35.0564 14.7293C35.1174 14.8471 35.2226 14.9358 35.349 14.976C39.257 16.221 41 18.078 41 21C41 23.599 38.629 25.616 37.217 26.588C37.1501 26.6339 37.0954 26.6954 37.0576 26.7672C37.0198 26.839 37 26.9189 37 27V30.823C35.7993 30.3676 34.7267 29.6282 33.874 28.668C33.8161 28.6036 33.7427 28.5552 33.6608 28.5273C33.5788 28.4995 33.4911 28.4932 33.406 28.509C33.121 28.564 32.83 28.642 32.535 28.721C32.025 28.858 31.499 29 31 29C28.432 29 26.634 28.448 24.796 27.097C24.6891 27.0184 24.5553 26.9854 24.4242 27.0054C24.293 27.0254 24.1751 27.0966 24.0965 27.2035C24.0178 27.3104 23.9849 27.4441 24.0048 27.5753C24.0248 27.7065 24.0961 27.8244 24.203 27.903C26.23 29.393 28.199 30 31 30C31.631 30 32.223 29.841 32.795 29.687C32.973 29.638 33.15 29.59 33.325 29.549C35.194 31.523 37.308 31.972 37.4 31.99C37.4726 32.0051 37.5477 32.0037 37.6197 31.9859C37.6917 31.968 37.7588 31.9343 37.816 31.887C37.8735 31.8403 37.9199 31.7813 37.9517 31.7143C37.9836 31.6474 38 31.5742 38 31.5V27.261C40.582 25.42 42 23.204 42 21C42 17.619 39.983 15.402 35.652 14.023Z"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M33 13.5C33 5.804 25.982 0 16.677 0C7.325 0 0 5.931 0 13.502C0 18.041 3.211 21.293 6 23.261V29.897C6.00052 29.9803 6.02175 30.0621 6.06179 30.1352C6.10183 30.2082 6.15941 30.2702 6.22935 30.3154C6.29929 30.3606 6.37939 30.3878 6.46242 30.3943C6.54546 30.4009 6.62882 30.3867 6.705 30.353C6.851 30.288 10.264 28.737 13.184 25.544C14.449 25.779 15.88 26.005 17.178 26.005C26.641 26.005 33 20.979 33 13.5ZM17.177 25.005C15.867 25.005 14.378 24.754 13.094 24.509C13.0089 24.4934 12.9212 24.4998 12.8393 24.5276C12.7574 24.5554 12.684 24.6038 12.626 24.668C10.576 26.98 8.167 28.449 7 29.082V23C6.99997 22.9189 6.9802 22.839 6.94239 22.7672C6.90458 22.6954 6.84988 22.6339 6.783 22.588C4.145 20.773 1 17.725 1 13.502C1 6.491 7.886 1 16.677 1C25.413 1 32 6.374 32 13.5C32 20.382 26.043 25.005 17.177 25.005Z"
                                                  fill="black"
                                                />
                                                <path
                                                  d="M16.5 11.5C15.397 11.5 14.5 12.397 14.5 13.5C14.5 14.603 15.397 15.5 16.5 15.5C17.603 15.5 18.5 14.603 18.5 13.5C18.5 12.397 17.603 11.5 16.5 11.5ZM16.5 14.5C16.2425 14.4885 15.9994 14.3781 15.8213 14.1918C15.6431 14.0055 15.5437 13.7577 15.5437 13.5C15.5437 13.2423 15.6431 12.9945 15.8213 12.8082C15.9994 12.6219 16.2425 12.5115 16.5 12.5C16.7575 12.5115 17.0006 12.6219 17.1787 12.8082C17.3569 12.9945 17.4563 13.2423 17.4563 13.5C17.4563 13.7577 17.3569 14.0055 17.1787 14.1918C17.0006 14.3781 16.7575 14.4885 16.5 14.5ZM23.5 11.5C22.397 11.5 21.5 12.397 21.5 13.5C21.5 14.603 22.397 15.5 23.5 15.5C24.603 15.5 25.5 14.603 25.5 13.5C25.5 12.397 24.603 11.5 23.5 11.5ZM23.5 14.5C23.2425 14.4885 22.9994 14.3781 22.8213 14.1918C22.6431 14.0055 22.5437 13.7577 22.5437 13.5C22.5437 13.2423 22.6431 12.9945 22.8213 12.8082C22.9994 12.6219 23.2425 12.5115 23.5 12.5C23.7575 12.5115 24.0006 12.6219 24.1787 12.8082C24.3569 12.9945 24.4563 13.2423 24.4563 13.5C24.4563 13.7577 24.3569 14.0055 24.1787 14.1918C24.0006 14.3781 23.7575 14.4885 23.5 14.5ZM9.5 11.595C8.397 11.595 7.5 12.492 7.5 13.595C7.5 14.698 8.397 15.595 9.5 15.595C10.603 15.595 11.5 14.698 11.5 13.595C11.5 12.492 10.603 11.595 9.5 11.595ZM9.5 14.595C9.24252 14.5835 8.9994 14.4731 8.82128 14.2868C8.64315 14.1005 8.54373 13.8527 8.54373 13.595C8.54373 13.3373 8.64315 13.0895 8.82128 12.9032C8.9994 12.7169 9.24252 12.6065 9.5 12.595C9.75748 12.6065 10.0006 12.7169 10.1787 12.9032C10.3569 13.0895 10.4563 13.3373 10.4563 13.595C10.4563 13.8527 10.3569 14.1005 10.1787 14.2868C10.0006 14.4731 9.75748 14.5835 9.5 14.595Z"
                                                  fill="black"
                                                />
                                              </svg>
                                              <span className="notification_badge"></span>
                                            </Button>
                                          </div>
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          <div className="d-flex align-items-center">
                            <div className="first_name_td">
                              <div className="approver_names">
                                {(() => {
                                  let userList = getUserName(row)

                                  return (
                                    <>
                                      {userList?.map((user) => (
                                        <span
                                          className="my-tooltip short-name"
                                          data-tooltip-content={
                                            user?.user?.fullName
                                          }
                                        >
                                          {getFirstLetters(user?.user.fullName)}
                                        </span>
                                      ))}
                                    </>
                                  )
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="progress-box">
                        {(() => {
                          let obj = []

                          const approvalUsers =
                            row.approvalWorkFlow?.approvalDetails?.filter(
                              (x) =>
                                x?.userId == userId &&
                                x?.approvalWorkFlowId ==
                                row?.approvalWorkFlowId,
                            )?.[0]

                          {
                            JSON.stringify(approvalUsers)
                          }
                          if (
                            (permissions?.approve == 1 ||
                              permissions?.publish == 1 ||
                              permissions?.add == 1) &&
                            row?.approvalWorkFlow?.approvalDetails &&
                            approvalUsers
                          ) {
                            status = approvalUsers?.status

                            obj = faqStatus?.find(
                              (item) => item.status === status,
                            )
                          } else {
                            status = row.status
                            if (status === 'Drafted' || status === 'Draft') {
                              status = 'Saved'
                            }
                            obj = faqStatus?.find(
                              (item) => item.status === row.status,
                            )
                          }

                          return (
                            <>
                              <span>
                                {status ? statusLableChanges(status) : ''}
                                <Progress
                                  value={obj?.value}
                                  color={obj?.class}
                                />
                              </span>
                            </>
                          )
                        })()}
                      </td>
                      <td>
                        {((permissions?.add === 1 &&
                          (row.status == REJECTED_BY_APPROVER ||
                            row.status == REJECTED_BY_PUBLISHER ||
                            row.status == PUBLISHED ||
                            row.status == APPROVED)) ||
                          row.status == DRAFTED) &&
                          isAdmin() == false && (
                            <a
                              className="my-tooltip btn btn-link edit_btn"
                              data-tooltip-content={'Edit'}
                              // to={'view/' + row.id}
                              onClick={() => {
                                navigate(
                                  '/console/consultationRequest/edit/' + row.id,
                                  {},
                                )
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_1123_13264)">
                                  <path
                                    d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                                    stroke="CurrentColor"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1123_13264">
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="CurrentColor"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          )}
                        <a
                          className="my-tooltip btn btn-link history_btn"
                          data-tooltip-content={'History'}
                          onClick={(e) => openAuditLogModal(row.id, e)}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>

          {totalRecords > 0 ? (
            <CustomPagination
              setDataPerPage={setDataPerPage}
              setCurrentPage={setCurrentPage}
              dataPerPage={dataPerPage}
              totalData={totalRecords}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="no-data-found">
              <svg
                width="96"
                height="114"
                viewBox="0 0 96 114"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                  fill="#4F2D7F"
                  fillOpacity="0.12"
                />
                <circle
                  cx="57.5592"
                  cy="67.2599"
                  r="27.36"
                  fill="#4F2D7F"
                  fillOpacity="0.3"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                />
                <path
                  d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                  strokeLinecap="round"
                />
                <path
                  d="M92.8968 102.6L79.2168 88.9204"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                  strokeLinecap="round"
                />
              </svg>
              <h1>No Consultation Requests Found</h1>
            </div>
          )}

          <AuditLogModal
            isModalOpen={isAuditModalOpen}
            modalName="Consultation Request"
            auditType="consultationrequest"
            auditId={auditId}
            onModalDismiss={() => closeAuditLogModal()}
          />
        </TabPane>
      </TabContent>
    </div>
  )
}

export { ConsultationTable }
