import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  GET_KEYWORDS_REQUEST,
  GET_POPULAR_FAQ_REQUEST,
  GET_QUERY_NOTIFICATION_REQUEST,
  GET_RECENT_FAQ_REQUEST,
  GET_SEARCH_REQUEST,
  OTP_VERIFICATION_REQUEST,
  RESEND_OTP_VERIFICATION_REQUEST,
  SUBSCRIBE_FAQ_REQUEST,
} from './actionType'
import {
  getKeywordResponse,
  getPopularFaqResponse,
  getQueryNotificationRequest,
  getQueryNotificationResponse,
  getRecentFaqResponse,
  getSearchResponse,
  otpVerificationResponse,
  resendOtpVerificationResponse,
  subscribeFaqResponse,
} from './action'

function* subscribe({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/subscriber', data)
    if (response.status === true) {

      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(
        subscribeFaqResponse(response.message, SUCCESS, response.data, data),
      )
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(subscribeFaqResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* recentFaqDetails({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/recentFaq')
    if (response.status) {
      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(getRecentFaqResponse(response.message, SUCCESS, response.data))
    } else {
      // ApiResponseMessage(response.message, DANGER)
      yield put(getRecentFaqResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* popularFaqDetails({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/popularFaq')
    if (response.status) {
      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(getPopularFaqResponse(response.message, SUCCESS, response.data))
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(getPopularFaqResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* otpVerification({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/verification', data)
    if (response.status) {
      const setDataToLocal = JSON.stringify(response.data.userData)
      localStorage.setItem('verificationData', setDataToLocal)

      localStorage.setItem('subscriberToken', response.data.accessToken)


      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(
        otpVerificationResponse(response.message, SUCCESS, response.data),
      )
      yield put(getQueryNotificationRequest())
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(otpVerificationResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* resendOtpVerification({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/resendOtp', data)
    if (response.status) {
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(
        resendOtpVerificationResponse(response.message, SUCCESS, response.data),
      )
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(resendOtpVerificationResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* getKeywordList({ payload: { type } }) {
  let response
  try {
    response = yield call(invokeApi, 'get', `/keyword/${type}`)
    if (response.status) {
      yield put(getKeywordResponse(response?.data?.list))
    } else {
      yield put(getKeywordResponse([]))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* getQueryNotification() {
  let response
  try {
    response = yield call(invokeApi, 'post', `/queryNotification`)
    if (response.status) {
      if (response?.data?.queryNotificationRead) {
        localStorage.setItem('queryNotification', true)
      } else {
        localStorage.setItem('queryNotification', false)
      }

      yield put(getQueryNotificationResponse(response.data))
    } else {
      yield put(getQueryNotificationResponse([]))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

function* getSearchList({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/search', data)

    if (response.status) {
      yield put(getSearchResponse(response.message, SUCCESS, response.data))
    } else {
      getSearchResponse(response.message, DANGER, [])
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

export function* watchFaq() {
  yield takeEvery(SUBSCRIBE_FAQ_REQUEST, subscribe)

  yield takeEvery(GET_POPULAR_FAQ_REQUEST, popularFaqDetails)

  yield takeEvery(GET_RECENT_FAQ_REQUEST, recentFaqDetails)
  yield takeEvery(OTP_VERIFICATION_REQUEST, otpVerification)
  yield takeEvery(RESEND_OTP_VERIFICATION_REQUEST, resendOtpVerification)

  yield takeEvery(GET_KEYWORDS_REQUEST, getKeywordList)
  yield takeEvery(GET_QUERY_NOTIFICATION_REQUEST, getQueryNotification)
  yield takeEvery(GET_SEARCH_REQUEST, getSearchList)
}
function* userHomeSaga() {
  yield all([fork(watchFaq)])
}
export default userHomeSaga
