import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { getCountryListRequest } from '../../../store/master/country/action'

import { FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'
import { countryListFilter } from './helpers'
import Select from 'react-select'

//Country State
const CountrySelector = (props) => {
  const { values, setValue, id, validate, isUserModule } = props
  const [countryList, setcountryList] = useState([])

  useEffect(() => {
    props.getCountryListRequest(countryListFilter)
  }, [])

  useEffect(() => {
    // in old code direct pass props.countryList to the dropdown and now we're handle by the useeffect
    setcountryList(props.countryList)
  }, [props.countryList])

  return (
    <FormGroup className="col-lg-4 mb-0">
      <Field
        component={Select}
        menuPlacement="auto"
        name="id"
        className="form-control selectbox"
        options={countryList}
        onChange={(opt) => {
          if (isUserModule) {
            setValue(id, opt.value)
          } else {
            let Country = opt ? opt.map((x) => x.value) : []
            setValue(id, Country)
          }
        }}
        isMulti={!isUserModule}
        value={
          isUserModule
            ? countryList?.filter((option) => option.value === values.countryId)
            : countryList?.filter((option) =>
              values.countryId?.includes(option.value),
            )
        }
      />
      <Label className="form-label">
        Country
        <span className="text-danger required">*</span>
      </Label>
      {validate}
    </FormGroup>
  )
}

//redux for addcountry and countrylist
const mapStateToProps = ({ countryReducer }) => {
  let countryList
  if (countryReducer.countryList && countryReducer.countryList.list) {
    countryList = countryReducer.countryList.list.map((opt) => ({
      value: opt.id,
      label: opt.countryName,
    }))
  }

  return {
    countryList: countryList,
  }
}

//country redux for addCountry
const mapDispatchToProps = (dispatch) => {
  return {
    getCountryListRequest: (getCountryList) => {
      dispatch(getCountryListRequest(getCountryList))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector)
