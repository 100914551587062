import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Button,
  NavbarText,
  Collapse,
} from 'reactstrap'
import logolight from 'images/logo.webp'
import logosm from 'images/logo.webp'
import gtlogolight from 'images/gtbharat_logo_color.png'
import Subscribe from './Subscribe'
import { verificationData } from './Helper/Helper'
import { getInitials, setActiveStatusFrontend } from 'helpers/generalUtils'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { autoLogout } from 'helpers/authUtils'
import { logoutRequest } from 'store/auth/login/action'
import LogoutModal from './LogoutModal'
import { clearSubscribeResponse } from 'store/frontEnd/home/action'
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle'
const Header = (props) => {
  const [isScrolled, setIsScrolled] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation()
  const getCurrentModule = location.pathname?.split('/')[1]
  const [isSubscribeOpen, setSubscribeOpen] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)

  const navigate = useNavigate()
  const subscribeToggle = () => {
    setSubscribeOpen(!isSubscribeOpen)
    if (!isSubscribeOpen) {

      props.clearSubscribeResponse()
    }
    //localStorage.clear()
  }
  const setModuleActiveClass = (moduleId) => {
    return setActiveStatusFrontend(getCurrentModule, moduleId)
  }

  const isSubscribed = JSON.parse(localStorage.getItem('verificationData'))

  // useEffect(() => {
  //   if (props.subscribeFaqApiResponse?.data?.isOtpScreenVisible === true) {

  //     setSubscribeOpen(true)
  //   } else {
  //     setSubscribeOpen(false)
  //   }
  // }, [props.subscribeFaqApiResponse])

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      toggle()
    }
  }, [])

  useEffect(() => {
    if (
      !isSubscribeOpen &&
      !verificationData() &&
      //changes issubscribe func tp
      // !verificationData() &&
      props.askForSubscribeId &&
      props.askForSubscribeId.length > 0
    ) {
      setSubscribeOpen(true)
    }
  }, [props.askForSubscribeId])

  useEffect(() => {
    if (props?.isAuthenticated === 'success') {
      setInterval(() => {
        autoLogout()
        //24hour timeout
      }, 1000 * 60 * 60 * 24)

      if (isSubscribeOpen === true) {
        setSubscribeOpen(false)
      }
    }
  }, [props.isAuthenticated])

  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    toggle()

  }

  const openAuditLogModal = () => {
    setIsAuditModalOpen(true)
  }

  return (
    <>
      <Subscribe
        subscribe={isSubscribeOpen}
        subscribeToggle={subscribeToggle}
      ></Subscribe>
      <Navbar
        color="dark"
        expand="md"
        className={isScrolled ? 'front-head header-fix' : 'front-head'}
      >
        <Container>
          <NavbarBrand href="/home">
            <div className="loging-head">
              <img alt="Grant Thornton Logo" className="logo-dark" src={logosm} />
              <img alt="Grant Thornton Logo" className="logo-light" src={logolight} />
            </div>
          </NavbarBrand>
          <NavbarToggle onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto me-0" navbar>
              {/* <NavItem>
                <Link to="/home" className={setModuleActiveClass('home')}>
                  Home
                </Link>
              </NavItem> */}
              {/* <NavItem>
                <Link to="/about" className={setModuleActiveClass('about')}>
                  About Us
                </Link>
              </NavItem> */}
              <NavItem>
                <Link
                  to="/report"
                  className={
                    setModuleActiveClass('report') ||
                    setModuleActiveClass('reportDetails')
                  }
                  onClick={() => {
                    toggle()
                  }}
                >
                  Our Reports
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/contact-us"
                  className={setModuleActiveClass('contact-us')}
                  onClick={() => {
                    toggle()
                  }}
                >
                  Contact Us
                </Link>
              </NavItem>
              {isSubscribed?.name?.length && (
                <NavItem>
                  <Link
                    to="/tickets"
                    className={
                      setModuleActiveClass('tickets') ||
                      setModuleActiveClass('ticketsDetails')
                    }
                    onClick={() => {
                      toggle()
                    }}
                  >
                    My Queries
                    {props.queryNotification == true && (
                      <div className="circle_animation bg-danger"></div>
                    )}
                  </Link>
                </NavItem>
              )}
              {isSubscribed?.userId ? (
                <NavItem className='logout-btn'>
                  <Button
                    color='link'
                    onClick={() => {
                      openAuditLogModal()
                    }}
                  >
                    <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z" /></svg>
                    Logout
                  </Button>
                </NavItem>
              ) : (
                <></>
              )}
              <NavItem>
                {isSubscribed?.userId ? (
                  <Dropdown className="profile_btn">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="my-tooltip position-relative"
                    >
                      <span>
                        {getInitials(
                          isSubscribed?.name
                            ? isSubscribed?.name
                            : isSubscribed?.email
                              ? isSubscribed?.email
                              : '',
                        )}
                      </span>
                      {isSubscribed?.name
                        ? isSubscribed?.name
                        : isSubscribed?.email
                          ? isSubscribed?.email
                          : ''}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          openAuditLogModal()
                        }}
                      >
                        <svg
                          width="22"
                          height="20"
                          viewBox="0 0 22 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5 9H13V11H5V14L0 10L5 6V9ZM4 16H6.708C7.86269 17.0183 9.28669 17.6819 10.8091 17.9109C12.3316 18.14 13.8878 17.9249 15.291 17.2915C16.6942 16.6581 17.8849 15.6332 18.7201 14.3398C19.5553 13.0465 19.9995 11.5396 19.9995 10C19.9995 8.46042 19.5553 6.95354 18.7201 5.66019C17.8849 4.36683 16.6942 3.34194 15.291 2.7085C13.8878 2.07506 12.3316 1.85998 10.8091 2.08906C9.28669 2.31815 7.86269 2.98167 6.708 4H4C4.93066 2.75718 6.13833 1.74851 7.52707 1.05414C8.91581 0.359775 10.4473 -0.00116364 12 2.81829e-06C17.523 2.81829e-06 22 4.477 22 10C22 15.523 17.523 20 12 20C10.4473 20.0012 8.91581 19.6402 7.52707 18.9459C6.13833 18.2515 4.93066 17.2428 4 16Z" />
                        </svg>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Button color="primary" className="btn-outline-secound-primary" onClick={subscribeToggle}>
                    Sign in
                  </Button>
                )}
              </NavItem>
              <NavbarText>
                <div className="loging-head">
                  <img alt="Grant Thornton Bharat shaping a vibrant india Logo" className="logo-light" src={gtlogolight} />
                </div>
              </NavbarText>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      <LogoutModal
        isModalOpen={isAuditModalOpen}
        modalName="Logout"
        onModalDismiss={() => closeAuditLogModal()}
      />
    </>
  )
}

const mapStateToProps = ({ userHomeReducer, userCommonReducer }) => {
  let isAuthenticated
  let queryNotification = false
  if (
    userHomeReducer &&
    userHomeReducer?.otpApiResponse?.responseType == 'success'
  ) {
    isAuthenticated = userHomeReducer?.otpApiResponse?.responseType
  }

  if (userHomeReducer && userHomeReducer?.queryNotificationApiResponse) {
    queryNotification =
      userHomeReducer.queryNotificationApiResponse.queryNotificationRead
  }
  return {
    isAuthenticated: isAuthenticated,
    subscribeFaqApiResponse: userHomeReducer?.subscribeFaqApiResponse,
    askForSubscribeId: userCommonReducer?.askForSubscribeId,
    queryNotification: queryNotification,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: (creds) => {
      dispatch(logoutRequest(creds))
    },
    clearSubscribeResponse: () => {
      dispatch(clearSubscribeResponse())
    },

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
