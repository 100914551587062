import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import { GET_USER_CONTACT_DETAILS_REQUEST } from './actionType'
import { getUserContactDetailsResponse } from './action'

function* countactUs({ payload: { data } }) {
  //POsT
  let response
  try {
    response = yield call(invokeApi, 'post', '/contactUsRequest', data)
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    if (response.status) {
      yield put(
        getUserContactDetailsResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getUserContactDetailsResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
    ApiResponseMessage(response.message, DANGER)
  }
}

export function* watchFaq() {
  yield takeEvery(GET_USER_CONTACT_DETAILS_REQUEST, countactUs)
}
function* userContactUsSaga() {
  yield all([fork(watchFaq)])
}
export default userContactUsSaga
