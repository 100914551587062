import {
  ACTIVE_SECTION_REQUEST,
  ACTIVE_SECTION_RESPONSE,
  ADD_SECTION_REQUEST,
  ADD_SECTION_RESPONSE,
  DELETE_SECTION_BY_SECTION_ID_REQUEST,
  DELETE_SECTION_BY_SECTION_ID_RESPONSE,
  GET_ALL_SECTION_LIST_REQUEST,
  GET_ALL_SECTION_LIST_RESPONSE,
  GET_SECTION_BY_SECTION_ID_REQUEST,
  GET_SECTION_BY_SECTION_ID_RESPONSE,
  GET_SECTION_LIST_BY_LAW_IDS_REQUEST,
  GET_SECTION_LIST_BY_LAW_IDS_RESPONSE,
  GET_SECTION_LIST_BY_LAW_REQUEST,
  GET_SECTION_LIST_BY_LAW_RESPONSE,
  GET_SECTION_LIST_BY_STATUS_REQUEST,
  GET_SECTION_LIST_BY_STATUS_RESPONSE,
  UPDATE_SECTION_RESPONSE,
  UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST,
  UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE,
  UPDATE_SECTION_STATUS_REQUEST,
} from './actionType';

//add section request
export const addSectionRequest = (data) => {
  return {
    type: ADD_SECTION_REQUEST,
    payload: { data },
  };
};

//add section reaponse
export const addSectionResponse = (message, responseType) => {
  return {
    type: ADD_SECTION_RESPONSE,
    payload: { message, responseType },
  };
};

//get section request
export const getAllSectionListRequest = (data) => {
  return {
    type: GET_ALL_SECTION_LIST_REQUEST,
    payload: { data },
  };
};

//get allsection list
export const getAllSectionListResponse = (data) => {
  return {
    type: GET_ALL_SECTION_LIST_RESPONSE,
    payload: { data },
  };
};

//get section request
export const getSectionBySectionIdRequest = (sectionId) => {
  return {
    type: GET_SECTION_BY_SECTION_ID_REQUEST,
    payload: { sectionId: sectionId },
  };
};


//get section
export const getSectionBySectionIdResponse = (message, responseType, data) => {
  return {
    type: GET_SECTION_BY_SECTION_ID_RESPONSE,
    payload: { message, responseType, data },
  };
};

//update section request
export const activeSectionRequest = (data) => {
  return {
    type: ACTIVE_SECTION_REQUEST,
    payload: { data },
  };
};

//update section response
export const activeSectionResponse = (message, responseType) => {
  return {
    type: ACTIVE_SECTION_RESPONSE,
    payload: { message, responseType },
  };
};
export const updateSectionStatusRequest = (sectionId, data) => {
  return {
    type: UPDATE_SECTION_STATUS_REQUEST,
    payload: { sectionId, data },
  };
};

//update section response
export const updateSectionResponse = (message, responseType) => {
  return {
    type: UPDATE_SECTION_RESPONSE,
    payload: { message, responseType },
  };
};

//update section by request
export const updateSectionStatusBySectionIdRequest = (sectionId, status) => {
  return {
    type: UPDATE_SECTION_STATUS_BY_SECTION_ID_REQUEST,
    payload: { sectionId, status },
  };
};

//update section by section request
export const updateSectionStatusBySectionIdResponse = (
  message,
  responseType
) => {
  return {
    type: UPDATE_SECTION_STATUS_BY_SECTION_ID_RESPONSE,
    payload: { message, responseType },
  };
};


//get section by status request
export const getSectionListByStatusRequest = (status) => {
  return {
    type: GET_SECTION_LIST_BY_STATUS_REQUEST,
    payload: { status },
  };
};

//get section status response
export const getSectionListByStatusResponse = (lawCategoryList) => {
  return {
    type: GET_SECTION_LIST_BY_STATUS_RESPONSE,
    payload: { lawCategoryList },
  };
};


//delete section
export const deleteSectionBySectionIdRequest = (sectionId) => {
  return {
    type: DELETE_SECTION_BY_SECTION_ID_REQUEST,
    payload: { sectionId },
  };
};

//delete section id response

export const deleteSectionBySectionIdResponse = (message, responseType) => {
  return {
    type: DELETE_SECTION_BY_SECTION_ID_RESPONSE,
    payload: { message, responseType },
  };
};

// get section by law
export const getSectionListByLawRequest = (lawId) => {
  return {
    type: GET_SECTION_LIST_BY_LAW_REQUEST,
    payload: { lawId },
  };
};

//get section by law
export const getSectionListByLawResponse = (sectionList) => {
  return {
    type: GET_SECTION_LIST_BY_LAW_RESPONSE,
    payload: { sectionList },
  };
};
export const getSectionListByLawIdsRequest = (data) => {
  return {
    type: GET_SECTION_LIST_BY_LAW_IDS_REQUEST,
    payload: { data },
  };
};

//get section by law
export const getSectionListByLawIdsResponse = (data) => {
  return {
    type: GET_SECTION_LIST_BY_LAW_IDS_RESPONSE,
    payload: { data },
  };
};

