import produce from 'immer'
import {
  CLEAR_USER_ARTICLE_DETAILS_RESPONSE,
  CLEAR_USER_ARTICLE_LIST_RESPONSE,
  GET_USER_ARTICLE_DETAILS_RESPONSE,
  GET_USER_ARTICLE_LIST_RESPONSE,
} from './actionType'
let faqListOffset = []
export const initialState = {
  articleList: [],
  articleDetails: null,
}
export const UserArticleReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_ARTICLE_LIST_RESPONSE: {
        if (!faqListOffset.includes(action.payload.offset)) {
          faqListOffset.push(action.payload.offset)
          draft.articleList = draft.articleList.concat(action.payload.response)
        }
        break
      }
      case CLEAR_USER_ARTICLE_LIST_RESPONSE: {
        faqListOffset = []
        draft.articleList = []
        break
      }
      case GET_USER_ARTICLE_DETAILS_RESPONSE:
        draft.articleDetails = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break

      case CLEAR_USER_ARTICLE_DETAILS_RESPONSE:
        draft.articleDetails = {
          data: [],
          message: null,
          responseType: null,
        }
        break

      default:
        state = { ...state }
        break
    }
  })
