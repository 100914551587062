import {
  GET_CATEGORY_LIST_REQUEST,
  GET_CATEGORY_LIST_RESPONSE,
  ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST,
  ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE,
  GET_CATEGORY_DELETE_REQUEST,
  GET_CATEGORY_DELETE_RESPONSE,
  CLEAR_CATEGORY_LIST_RESPONSE,
  GET_SUBCAT_LIST_BY_CAT_ID_REQUEST,
  GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
  CLEAR_SUB_CATEGORY_LIST_RESPONSE,
  GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST,
  GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
  GET_FILTER_CATEGORY_LIST_REQUEST,
  GET_FILTER_CATEGORY_LIST_RESPONSE,
} from './actionType'

export const addCategoryRequest = (data) => {
  return {
    type: ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST,
    payload: { data },
  }
}

export const addCategoryResponse = (message, responseType) => {
  return {
    type: ADD_CATEGORY_AND_SUB_CATEGORY_RESPONSE,
    payload: { message, responseType },
  }
}

export const getCategoryListRequest = (Data) => {
  return {
    payload: { Data },
    type: GET_CATEGORY_LIST_REQUEST,
  }
}
export const getCategoryListResponse = (categoryList) => {
  return {
    type: GET_CATEGORY_LIST_RESPONSE,
    payload: { categoryList },
  }
}

export const getFilterCategoryListRequest = (Data) => {
  return {
    payload: { Data },
    type: GET_FILTER_CATEGORY_LIST_REQUEST,
  }
}
export const getFilterCategoryListResponse = (categoryList) => {
  return {
    type: GET_FILTER_CATEGORY_LIST_RESPONSE,
    payload: { categoryList },
  }
}

export const CleartCategoryListResponse = () => {
  return {
    type: CLEAR_CATEGORY_LIST_RESPONSE,
    payload: {},
  }
}

export const getCategoryDeleteRequest = (data) => {
  return {
    type: GET_CATEGORY_DELETE_REQUEST,
    payload: { data },
  }
}

export const getCategoryDeleteResponse = (message, responseType, data) => {
  return {
    type: GET_CATEGORY_DELETE_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const getSubCatListByCatIdRequest = (Data) => {
  return {
    type: GET_SUBCAT_LIST_BY_CAT_ID_REQUEST,
    payload: { Data },
  }
}

export const getSubCatListByCatIdResponse = (subCatList) => {
  return {
    type: GET_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
    payload: { subCatList },
  }
}

export const ClearSubCatListByCatIdResponse = () => {
  return {
    type: CLEAR_SUB_CATEGORY_LIST_RESPONSE,
    payload: {},
  }
}

export const getReportSubCatListByCatIdRequest = (Data) => {
  return {
    type: GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST,
    payload: { Data },
  }
}

export const getReportSubCatListByCatIdResponse = (reportSubCatList) => {
  return {
    type: GET_REPORT_SUBCAT_LIST_BY_CAT_ID_RESPONSE,
    payload: { reportSubCatList },
  }
}
