import {
  CLEARE_ADMIN_RESEND_QUERY_RESPONSE,
  CLEAR_QUERY_LIST_RESPONSE,
  CREATE_ADMIN_RESEND_QUERY_REQUEST,
  CREATE_ADMIN_RESEND_QUERY_RESPONSE,
  GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST,
  GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE,
  GET_QUERY_REQUEST,
  GET_QUERY_RESPONSE,
} from './actionType'

export const getQueryListRequest = (data) => {
  return {
    type: GET_QUERY_REQUEST,
    payload: { data },
  }
}

export const getQueryListResponse = (response, offset) => {
  return {
    type: GET_QUERY_RESPONSE,
    payload: { response, offset },
  }
}

export const getAdminConversationDetailsRequest = (id) => {
  return {
    type: GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST,
    payload: { id },
  }
}

export const getAdminConversationDetailsResponse = (
  message,
  responseType,
  data,
) => {
  return {
    type: GET_ADMIN_CONVERSATION_DETAILS_QUERY_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const createAdminResendQueryRequest = (data) => {
  return {
    type: CREATE_ADMIN_RESEND_QUERY_REQUEST,
    payload: { data },
  }
}

export const createAdminResendQueryResponse = (message, responseType) => {
  return {
    type: CREATE_ADMIN_RESEND_QUERY_RESPONSE,
    payload: { message, responseType },
  }
}

export const cleareAdminResendListResponse = () => {
  return {
    type: CLEAR_QUERY_LIST_RESPONSE,
  }
}

export const cleareAdminResendQueryResponse = () => {
  return {
    type: CLEARE_ADMIN_RESEND_QUERY_RESPONSE,
  }
}
