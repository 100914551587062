import {
  ADMIN_OTP_VERIFICATION_REQUEST,
  ADMIN_OTP_VERIFICATION_RESPONSE,
  CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE,
  CLEAR_LOGOUT_RESPONSE,
  CLEAR_VERIFY_TOKEN_RESPONSE,
  LOGIN_API_REQUEST,
  LOGIN_API_RESPONSE,
  LOGOUT_API_REQUEST,
  LOGOUT_API_RESPONSE,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_RESPONSE,
} from './actionType'

export const LoginRequest = (data) => {
  return {
    type: LOGIN_API_REQUEST,
    payload: { data },
  }
}

export const LoginResponse = (message, responseType, data) => {
  return {
    type: LOGIN_API_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const verifyTokenRequest = (data) => {
  return {
    type: VERIFY_TOKEN_REQUEST,
    payload: { data },
  }
}

export const verifyTokenResponse = (message, responseType, data) => {
  return {
    type: VERIFY_TOKEN_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const clearVerifyTokenResponse = () => {
  return {
    type: CLEAR_VERIFY_TOKEN_RESPONSE,
  }
}


export const logoutRequest = (data) => {
  return {
    type: LOGOUT_API_REQUEST,
    payload: { data },
  }
}

export const logoutResponse = (message, responseType) => {
  return {
    type: LOGOUT_API_RESPONSE,
    payload: { message, responseType },
  }
}


export const clearLogoutResponse = () => {
  return {
    type: CLEAR_LOGOUT_RESPONSE,
  }
}

export const adminOtpVerificationRequest = (data) => {
  return {
    type: ADMIN_OTP_VERIFICATION_REQUEST,
    payload: { data },
  }
}

export const adminOtpVerificationResponse = (message, responseType, data, name) => {
  return {
    type: ADMIN_OTP_VERIFICATION_RESPONSE,
    payload: { message, responseType, data, name },
  }
}

export const clearOtpVerificationResponse = () => {
  return {
    type: CLEAR_ADMIN_OTP_VERIFICATION_RESPONSE,

  }
}