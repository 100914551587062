import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import {
  GET_USER_ARTICLE_DETAILS_REQUEST,
  GET_USER_ARTICLE_LIST_REQUEST,
  UPDATE_POPULAR_FAQ_REQUEST,
} from './actionType'
import {
  getUserArticleDetailsResponse,
  getUserArticleListResponse,
} from './action'

function* getFaqList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/getUserArticleList', data)
    if (response.status) {
      yield put(getUserArticleListResponse(response.data, data.offset))
    } else {
      yield put(getUserArticleListResponse([], data.offset))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* updatePopularFaqList({ payload: { data } }) {
  try {
    yield call(invokeApi, 'post', '/updatePopularFaq', data)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getUserArticleDetails({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/userArticleDetails/${id}`)
    if (response.status) {
      yield put(
        getUserArticleDetailsResponse(response.message, SUCCESS, response.data),
      )
    } else {
      yield put(getUserArticleDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(GET_USER_ARTICLE_LIST_REQUEST, getFaqList)
  yield takeEvery(UPDATE_POPULAR_FAQ_REQUEST, updatePopularFaqList)
  yield takeEvery(GET_USER_ARTICLE_DETAILS_REQUEST, getUserArticleDetails)
}
function* userArticleSaga() {
  yield all([fork(watchLaw)])
}
export default userArticleSaga
