import {
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_RESPONSE,
  CREATE_REPORT_REVIEW_REQUEST,
  CREATE_REPORT_REVIEW_RESPONSE,
  DELETE_FAQ_SECTION_REQUEST,
  DELETE_FAQ_SECTION_RESPONSE,
  DELETE_REPORT_FAQ_REQUEST,
  DELETE_REPORT_FAQ_RESPONSE,
  GET_REPORT_DETAILS_REQUEST,
  GET_REPORT_DETAILS_RESPONSE,
  GET_REPORT_DOWNLOAD_LIST_REQUEST,
  GET_REPORT_DOWNLOAD_LIST_RESPONSE,
  GET_REPORT_KEYWORDS_REQUEST,
  GET_REPORT_KEYWORDS_RESPONSE,
  GET_REPORT_LIST_FAQ_REQUEST,
  GET_REPORT_LIST_FAQ_RESPONSE,
  GET_REPORT_REQUEST,
  GET_REPORT_RESPONSE,
  GET_REPORT_IS_REVIEW_REQUEST,
  GET_REPORT_IS_REVIEW_RESPONSE,
  CLEAR_IS_REPORT_REVISION_RESPONSE,
  CLEAR_REPORT_DETAILS_RESPONSE,
  CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE,
  CLEAR_REPORT_FAQ_LIST_RESPONSE,
  CLEAR_REPORT_RESPONSE,
  CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST,
  CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE,
  GET_CHECK_REPORT_TITLE_REQUEST,
  GET_CHECK_REPORT_TITLE_RESPONSE,
  GET_OTHER_CATEGORY_REQUEST,
  GET_OTHER_CATEGORY_RESPONSE,
  GET_REPORT_DOWNLOAD_REQUEST,
  GET_REPORT_DOWNLOAD_RESPONSE,
  GET_REPORT_LIST_FAQ_COUNT_RESPONSE,
} from './actionType'

export const getReportFaqListRequest = (data) => {
  return {
    type: GET_REPORT_LIST_FAQ_REQUEST,
    payload: { data },
  }
}

export const getFaqResponse = (response, offset) => {
  return {
    type: GET_REPORT_LIST_FAQ_RESPONSE,
    payload: { response, offset },
  }
}
export const getFaqTotalCountResponse = (count) => {
  return {
    type: GET_REPORT_LIST_FAQ_COUNT_RESPONSE,
    payload: { count },
  }
}
export const clearReportFaqListResponse = () => {
  return {
    type: CLEAR_REPORT_FAQ_LIST_RESPONSE,
  }
}

export const createReportRequest = (data) => {
  return {
    type: CREATE_REPORT_REQUEST,
    payload: { data },
  }
}

export const createReportResponse = (message, responseType, data) => {
  return {
    type: CREATE_REPORT_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const deleteReportFaqRequest = (data, reportId) => {
  return {
    type: DELETE_REPORT_FAQ_REQUEST,
    payload: { data, reportId },
  }
}

export const deleteReportFaqResponse = (data) => {
  return {
    type: DELETE_REPORT_FAQ_RESPONSE,
    payload: { data },
  }
}

export const deleteFaqSectionRequest = (data, reportId) => {
  return {
    type: DELETE_FAQ_SECTION_REQUEST,
    payload: { data, reportId },
  }
}

export const deleteFaqSectionResponse = (data) => {
  return {
    type: DELETE_FAQ_SECTION_RESPONSE,
    payload: { data },
  }
}

export const getReportRequest = (data) => {
  return {
    type: GET_REPORT_REQUEST,
    payload: { data },
  }
}

export const getReportResponse = (data) => {
  return {
    type: GET_REPORT_RESPONSE,
    payload: { data },
  }
}

export const clearReportResponse = () => {
  return {
    type: CLEAR_REPORT_RESPONSE,
  }
}

export const getReportDetailsRequest = (id) => {
  return {
    type: GET_REPORT_DETAILS_REQUEST,
    payload: { id },
  }
}

export const getReportDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_REPORT_DETAILS_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const createReportReviewRequest = (data) => {
  return {
    type: CREATE_REPORT_REVIEW_REQUEST,
    payload: { data },
  }
}

export const createReportReviewResponse = (message, responseType) => {
  return {
    type: CREATE_REPORT_REVIEW_RESPONSE,
    payload: { message, responseType },
  }
}

export const getReportsKeywordsRequest = () => {
  return {
    type: GET_REPORT_KEYWORDS_REQUEST,
  }
}

export const createReportsKeywordsResponse = (data) => {
  return {
    type: GET_REPORT_KEYWORDS_RESPONSE,
    payload: { data },
  }
}

export const getReportDownloadListRequest = (data) => {
  return {
    type: GET_REPORT_DOWNLOAD_LIST_REQUEST,
    payload: { data },
  }
}

export const getReportDownloadListResponse = (response, offset) => {
  return {
    type: GET_REPORT_DOWNLOAD_LIST_RESPONSE,
    payload: { response, offset },
  }
}

export const clearReportDownloadListResponse = () => {
  return {
    type: CLEAR_REPORT_DOWNLOAD_LIST_RESPONSE,
  }
}

export const createReportDownloadApproverRequest = (data) => {
  return {
    type: CREATE_REPORT_DOWNLOAD_APPROVE_REQUEST,
    payload: { data },
  }
}

export const createReportDownloadApproveResponse = (message, responseType) => {
  return {
    type: CREATE_REPORT_DOWNLOAD_APPROVE_RESPONSE,
    payload: { message, responseType },
  }
}

export const getReportDownloadRequest = (id) => {
  return {
    type: GET_REPORT_DOWNLOAD_REQUEST,
    payload: { id },
  }
}

export const getReportDownloadResponse = (data) => {
  return {
    type: GET_REPORT_DOWNLOAD_RESPONSE,
    payload: { data },
  }
}

export const getIsReportRevisionRequest = (data) => {
  return {
    type: GET_REPORT_IS_REVIEW_REQUEST,
    payload: { data },
  }
}

export const getIsReportRevisionResponse = (message, responseType, data) => {
  return {
    type: GET_REPORT_IS_REVIEW_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const getCheckReportTitleRequest = (data) => {
  return {
    type: GET_CHECK_REPORT_TITLE_REQUEST,
    payload: { data },
  }
}

export const getCheckReportTitleResponse = (message, responseType, data) => {
  return {
    type: GET_CHECK_REPORT_TITLE_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const getOtherCategoryRequest = () => {
  return {
    type: GET_OTHER_CATEGORY_REQUEST,
  }
}

export const getOtherCategoryResponse = (message, responseType, data) => {
  return {
    type: GET_OTHER_CATEGORY_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const clearReportDetailsResponse = () => {
  return {
    type: CLEAR_REPORT_DETAILS_RESPONSE,
  }
}

export const clearIsReportRevisionResponse = () => {
  return {
    type: CLEAR_IS_REPORT_REVISION_RESPONSE,
  }
}
