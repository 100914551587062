export const countryListFilter = {
  searchText: '',
  pagination: {
    limit: 0,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: {},
}
