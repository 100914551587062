import produce from 'immer'
import {
  CLEAR_KEYWORDS_RESPONSE,
  CLEAR_SUBSCRIBE_RESPONSE,
  GET_KEYWORDS_RESPONSE,
  GET_POPULAR_FAQ_RESPONSE,
  GET_QUERY_NOTIFICATION_RESPONSE,
  GET_RECENT_FAQ_RESPONSE,
  GET_SEARCH_RESPONSE,
  OTP_VERIFICATION_RESPONSE,
  RESEND_OTP_VERIFICATION_RESPONSE,
  SUBSCRIBE_FAQ_RESPONSE,
} from './actionType'
export const initialState = {
  getRecentFaq: null,
  subscribeFaqApiResponse: null,
  getPopularFaq: null,
  otpApiResponse: null,
  resendOtpApiResponse: null,
  keywordsList: null,
  queryNotificationApiResponse: null,
  searchApiResponse: null
}
export const UserHomeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUBSCRIBE_FAQ_RESPONSE: {
        draft.subscribeFaqApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }
      case GET_RECENT_FAQ_RESPONSE: {
        draft.getRecentFaq = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      case GET_POPULAR_FAQ_RESPONSE: {
        draft.getPopularFaq = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      case OTP_VERIFICATION_RESPONSE: {
        draft.otpApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }
      case RESEND_OTP_VERIFICATION_RESPONSE: {
        draft.resendOtpApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      case CLEAR_SUBSCRIBE_RESPONSE: {
        draft.subscribeFaqApiResponse = null
        draft.otpApiResponse = null
        break
      }

      case GET_KEYWORDS_RESPONSE: {
        draft.keywordsList = action.payload.data
        break
      }
      case GET_QUERY_NOTIFICATION_RESPONSE: {
        draft.queryNotificationApiResponse = action.payload.data
        break
      }

      case CLEAR_KEYWORDS_RESPONSE: {
        draft.keywordsList = null
        break
      }



      case GET_SEARCH_RESPONSE: {
        draft.searchApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      default:
        state = { ...state }
        break
    }
  })
