import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  CREATE_ARTICAL_REQUEST,
  CREATE_ARTICLE_REVIEW_REQUEST,
  CREATE_REVIEW_LAW_REQUEST,
  GET_ARTICAL_DETAILS_REQUEST,
  GET_ARTICAL_HISTORY_REQUEST,
  GET_LIST_ARTICAL_REQUEST,
  GET_LIST_KEYWORDS_REQUEST,
} from './actionType'
import {
  getListKeywordsResponse,
  getArticleDetailsResponse,
  getArticleHistoryResponse,
  createArticleReviewResponse,
  createreReviewLawResponse,
  getArticleDetailsRequest,
  createArticleResponse,
  getListArticleResponse,
} from './action'

import { invokeApi } from '../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

function* createArticle({ payload: { data } }) {
  try {
    let url = '/createArticle'
    if (data?.articleId) {
      url = '/updateArticle'
    }
    const response = yield call(invokeApi, 'post', url, data)

    if (response.status) {
      yield put(createArticleResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(createArticleResponse(response.message, DANGER))
      ApiResponseMessage(response.message, response.status, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* listArticle({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/articleList', data)
    if (response.status) {
      yield put(getListArticleResponse(response.data))
    } else {
      yield put(getListArticleResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* articleDetails({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'get', `/articleDetails/${data}`)

    if (response.status) {
      yield put(getArticleDetailsResponse(response.data))
    } else {
      yield put(getArticleDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* listKeywords() {
  try {
    const response = yield call(invokeApi, 'post', '/keywordsList')
    if (response.status) {
      yield put(getListKeywordsResponse(response.data))
    } else {
      yield put(getListKeywordsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getArticleHistory({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/articleHistory/${id}`)

    if (response.status) {
      yield put(getArticleHistoryResponse(response.data))
    } else {
      yield put(getArticleHistoryResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createArticleReview({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/articleReview', data)
    if (response.status) {
      yield put(createArticleReviewResponse(response.message, SUCCESS))
    } else {
      yield put(createArticleReviewResponse([]))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createReviewLaw({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reviewLaw', data)
    if (response.status) {
      if (data.articleId) {
        yield put(getArticleDetailsRequest(data.articleId))
      }

      yield put(createreReviewLawResponse(response.message, SUCCESS))
    } else {
      yield put(createreReviewLawResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(CREATE_ARTICAL_REQUEST, createArticle)
  yield takeEvery(GET_LIST_ARTICAL_REQUEST, listArticle)
  yield takeEvery(GET_LIST_KEYWORDS_REQUEST, listKeywords)
  yield takeEvery(GET_ARTICAL_DETAILS_REQUEST, articleDetails)
  yield takeEvery(GET_ARTICAL_HISTORY_REQUEST, getArticleHistory)
  yield takeEvery(CREATE_ARTICLE_REVIEW_REQUEST, createArticleReview)
  yield takeEvery(CREATE_REVIEW_LAW_REQUEST, createReviewLaw)
}
function* articleSaga() {
  yield all([fork(watchLaw)])
}
export default articleSaga
