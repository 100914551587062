import { useEffect, useRef, useState, React } from 'react'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button, Card, CardBody } from 'reactstrap'
import { connect } from 'react-redux'
import { createConsultationInitialState, statusBox } from './CommonFunction'
import { __t } from 'i18n/translator'
import { useParams, useNavigate, Link } from 'react-router-dom'
import JoditEditor from 'jodit-react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import {
  getCategoryListRequest,
  getSubCatListByCatIdRequest,
} from 'store/master/category/action'
import { getLawListByCategoryIdRequest } from 'store/master/law/action'
import { DANGER } from 'constant/commonConstants'
import { getUserListRequest } from 'store/master/user/action'
import { commonConfirmBox } from 'helpers/messagehelpers'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

import { checkFileExtension, removeTags, statusLableChanges } from 'helpers/generalUtils'
import {
  APPROVED,
  DRAFTED,
  REJECTED_BY_APPROVER,
} from 'constant/databaseConstants'
import { getServiceLineListRequest } from 'store/master/serviceline/action'
import { customFileValidator } from 'helpers/SimpleReactValidators'
import {
  createConsultationRequest,
  createConsultationResponse,
  deleteConsultationDocumentRequest,
  getConsultationDetailsRequest,
} from 'store/consultation/action'
import { getBaseUrl } from 'helpers/axiosHelper'

const selectorFilter = {
  searchText: '',
  pagination: {
    limit: 0,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: {
    roleId: '',
  },
}
const CreateConsultation = (props) => {
  const params = useParams()

  const navigate = useNavigate()

  const editor = useRef(null)

  const [consultationDetails, setConsultationDetails] = useState([])

  const [initialConsultationValues, setConsultationValues] = useState(
    createConsultationInitialState(),
  )
  const [subCategoryDropdownList, setSubCategoryDropdownList] = useState([])
  const [isDraFSave, setisDraFSave] = useState(true)

  const validator = useRef(new SimpleReactValidator())
  const countryData = JSON.parse(localStorage.getItem('countryData'))
  const [selectedFiles, setSelectedFiles] = useState([])

  const [isCategory, setIsCategory] = useState(true)

  const config = {
    removeButtons: [
      'cut',
      'copy',
      'paste',
      'change mode',
      'font',
      'print',
      'insert',
      'about',
      'subscript',
      'superscript',
      'source',
      'classSpan',
      'copyformat',
      'video',
      'image',
      'file',
    ],
  }

  useEffect(() => {
    props.getCategoryListRequest({
      searchText: '',
      type: 'CONSULTATION',
      pagination: {
        limit: 0,
        page: 0,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    })

    // handleFileUpload = handleFileUpload.bind(this)
  }, [])

  useEffect(() => {
    setConsultationDetails(props.consultationDetails)

    if (props.consultationDetails?.categoryDetails?.parentCategoryId) {
      filterSubCategory(
        props.consultationDetails?.categoryDetails?.parentCategoryId,
      )
    }
  }, [props.consultationDetails])

  useEffect(() => {
    if (props.categoryList?.length == 1 && !params.id && isCategory) {
      let categoryId = props.categoryList[0]?.value
        ? props.categoryList[0]?.value
        : ''

      if (categoryId != '') {
        setIsCategory(false)
        props.getUserListRequest({
          ...selectorFilter,
          countryId: countryData != undefined && countryData.id,
          catId: categoryId,
          subCatId: '',
        })

        filterSubCategory(categoryId)
        setConsultationValues({
          ...createConsultationInitialState(),
          categoryId: categoryId,
        })
      }
    }
  }, [props.categoryList])

  const handleFileUpload = (files) => {
    const chosenFiles = Array.prototype.slice.call(files)

    let errorsArray = []
    chosenFiles.map((file) => {
      if (!customFileValidator(file.name)) {
        errorsArray.push(
          'Must be a file of type: zip, pdf, jpg, jpeg, png, txt, doc, docx, csv, xlsx, xls.',
        )
        return false
      }
      if (file.size >= 5 * 1024 * 1024) {
        // Max size is 5 MB
        errorsArray.push('File size exceeds the limit of 5 MB.')
        return false
      }
    })
    if (errorsArray.length > 0) {
      ApiResponseMessage(__t(errorsArray[0]), DANGER)

      return false
    }
    if (chosenFiles) {
      let formData = new FormData()
      chosenFiles.map((x) => formData.append('file', x))

      chosenFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      setSelectedFiles([...selectedFiles, ...chosenFiles])
    }
  }

  const removeFile = async (file) => {
    const response = await commonConfirmBox(
      __t('alerts.are_you_sure'),
      __t('alerts.document_delete'),
    )
    if (response) {
      var array = [...selectedFiles] // make a separate copy of the array
      var index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1)
        setSelectedFiles(array)
      }
    }
  }

  useEffect(() => {
    if (props.ConsultationCreate?.responseType != DANGER) {
      if (props.ConsultationCreate != null) {
        props.clearConsultationReponse(null)
        navigate('/console/consultationRequest')
      }
    }

    if (params.id) {
      props.getConsultationDetails(params.id)
    }
  }, [props.ConsultationCreate])

  useEffect(() => {
    if (consultationDetails && params.id) {
      if (consultationDetails?.categoryDetails?.parentCategoryId) {
        props.getUserListRequest({
          ...selectorFilter,
          countryId: consultationDetails?.countryId
            ? consultationDetails?.countryId
            : '',
          catId: consultationDetails.categoryDetails.parentCategoryId
            ? consultationDetails.categoryDetails.parentCategoryId
            : '',
          subCatId: consultationDetails.categoryDetails?.subcategoryId
            ? consultationDetails.categoryDetails?.subcategoryId
            : '',
        })
      }

      setConsultationValues(createConsultationInitialState(consultationDetails))

      getLawListByCatogeryId(
        consultationDetails.categoryDetails?.subcategoryId?.toString(),
      )
    }
  }, [consultationDetails])

  useEffect(() => {
    if (initialConsultationValues?.firstApprovalId) {
      if (initialConsultationValues?.firstApprovalId) {
        let checkApprovalId = props.userDropdownList?.filter(
          (x) => x.value == initialConsultationValues.firstApprovalId,
        )
        if (checkApprovalId.length == 0) {

          setConsultationValues({ ...initialConsultationValues, firstApprovalId: "" })
        }
      }
    }
  }, [props.userDropdownList])


  const getLawListByCatogeryId = (id) => {
    if (id) {
      props.getLawListByCategoryIdRequest(id)
    }
  }

  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      let laws = props.lawDropdownList
        ?.filter((x) => values?.laws?.includes(x.value))
        ?.map((opt) => ({
          id: opt.value,
          name: opt.label,
        }))
      let formData = new FormData()
      formData.append('laws', JSON.stringify(laws))
      formData.append('countryId', countryData != undefined && countryData.id)
      formData.append('categoryId', values.categoryId)
      formData.append('subCategoryId', values.subCategoryId)
      formData.append('heading', values.heading)
      formData.append('answer', values[editorId])
      formData.append('firstApprovalId', values.firstApprovalId)
      formData.append('isDraft', isDraFSave)
      selectedFiles.forEach((file) => {
        formData.append('files', file)
      })
      if (params.id) {
        formData.append('consultationId', params.id)
      }
      props.createConsultation(formData, params.id ? 'Update' : 'Create')
    } else {
      validator.current.showMessages()
    }
  }

  const filterSubCategory = (id) => {
    if (id) {
      let filterSubCategoryValue = props.categoryAndSubList
        ?.filter((item) => item.id == id)[0]
        ?.subCat.map((opt) => ({
          value: opt.id,
          label: opt.catName,
        }))

      setSubCategoryDropdownList(filterSubCategoryValue)
    } else {
      setSubCategoryDropdownList([])
    }
  }

  const editorId = 'answer'
  return (
    <Formik
      initialValues={initialConsultationValues}
      enableReinitialize={true}
      onSubmit={formSubmit}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <>
          <div className="container">
            <div className="section-head mt-3">
              <div>
                <Button
                  data-tooltip-content={'Back'}
                  className="my-tooltip back_btn"
                  // className="back_btn"
                  onClick={() => {
                    navigate('/console/consultationRequest')
                  }}
                >
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 9L1.29289 8.29289L0.585786 9L1.29289 9.70711L2 9ZM22 10C22.5523 10 23 9.55229 23 9C23 8.44772 22.5523 8 22 8V10ZM9.29289 0.292893L1.29289 8.29289L2.70711 9.70711L10.7071 1.70711L9.29289 0.292893ZM1.29289 9.70711L9.29289 17.7071L10.7071 16.2929L2.70711 8.29289L1.29289 9.70711ZM2 10H22V8H2V10Z" />
                  </svg>
                </Button>
                <h3>
                  {' '}
                  {!params.id ? 'Create Consultation' : ' Update Consultation'}
                </h3>
              </div>
              <div className="status-drop-box gap-2">
                {params.id && (
                  <div className="version-box">
                    <p>
                      Published Version :{' '}
                      {props.consultationDetails?.publishedVersion}{' '}
                    </p>
                  </div>
                )}

                {params.id && props.consultationDetails?.status && (
                  <div className="notification-list">
                    <span
                      className={
                        'faq-list-status-box ' +
                        statusBox(
                          props.consultationDetails?.status
                            ? props.consultationDetails?.status
                            : 'Draft',
                        )
                      }
                    ></span>
                    {props.consultationDetails?.status == 'Drafted'
                      ? 'Saved'
                      : statusLableChanges(props.consultationDetails?.status)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Form className="faq-create">
            <Card className="container p-0 mb-3">
              <CardBody className="py-3">
                {/* <Row className="col-md-12">{JSON.stringify(values)}</Row> */}
                <Row>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="categoryId"
                        className="form-control selectbox"
                        options={props.categoryList}
                        onChange={(opt) => {
                          setFieldValue('categoryId', opt && opt?.value)

                          filterSubCategory(opt ? opt.value : '')
                          setFieldValue('subCategoryId', '')
                          setFieldValue('laws', '')

                          getLawListByCatogeryId(null)

                          props.getUserListRequest({
                            ...selectorFilter,
                            // type: 'CONTENT',
                            countryId:
                              countryData != undefined && countryData.id,
                            subCatId: values.subCategoryId
                              ? values.subCategoryId
                              : '',
                            catId: opt?.value,
                          })
                        }}
                        value={props?.categoryList.filter(
                          (option) => option?.value === values?.categoryId,
                        )}
                        isClearable={true}
                      // closeMenuOnSelect={false}
                      />
                      <Label className="form-label">
                        Service Line
                        <span className="text-danger required">*</span>
                      </Label>

                      {validator.current.message(
                        __t('validations.common.service'),
                        values.categoryId,
                        'required',
                        {
                          messages: {
                            required: __t('validations.common.service'),
                          },
                        },
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="subCategoryId"
                        className="form-control selectbox"
                        options={subCategoryDropdownList}
                        onChange={(opt) => {
                          setFieldValue('subCategoryId', opt && opt?.value)
                          getLawListByCatogeryId(opt && opt?.value)

                          setFieldValue('laws', '')

                          // props.getPublisherListRequest({
                          //   ...selectorFilter,
                          //   countryId: values.countryId ? values.countryId : '',
                          //   catId: values.categoryId ? values.categoryId : '',
                          //   subCatId: opt.value,
                          // })

                          props.getUserListRequest({
                            ...selectorFilter,
                            countryId:
                              countryData != undefined && countryData.id,
                            catId: values.categoryId ? values.categoryId : '',
                            subCatId: opt?.value,
                          })
                        }}
                        value={subCategoryDropdownList?.filter(
                          (option) => option?.value === values.subCategoryId,
                        )}
                        isClearable={true}
                      />
                      <Label className="form-label">
                        Category
                        <span className="text-danger required">*</span>
                      </Label>
                      {validator.current.message(
                        'Category' + __t('validations.common.required'),
                        values.subCategoryId,
                        'required',
                        {
                          messages: {
                            required:
                              'Category' + __t('validations.common.required'),
                          },
                        },
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="no-floating-label">
                    <FormGroup className="mb-0">
                      <Field
                        component={Select}
                        menuPlacement="auto"
                        name="laws"
                        className="form-control selectbox"
                        options={props.lawDropdownList}
                        onChange={(opt) => {
                          let lawIds = opt && opt?.map((x) => x.value)
                          setFieldValue(`laws`, lawIds)
                        }}
                        value={props.lawDropdownList.filter((option) =>
                          values.laws?.includes(option?.value),
                        )}
                        isClearable={true}
                        isMulti={true}
                      />
                      <Label className="form-label">Laws</Label>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="container p-0 mb-3">
              <CardBody className="row">
                <div className="col">
                  <Label className="separate-head mb-2">
                    Heading
                    <span className="text-danger required">*</span>
                  </Label>
                  <FormGroup className="mb-3">
                    <Label className="form-label">
                      {/* Question/Heading{' '}
                      <span className="text-danger required">*</span> */}
                    </Label>
                    <Field
                      name="heading"
                      placeholder="Heading here..."
                      className="form-control"
                    />

                    {validator.current.message(
                      __t('validations.faq.faq_question_required'),
                      values.heading,
                      'required',
                      {
                        messages: {
                          required: __t(
                            'validations.faq.faq_question_required',
                          ),
                        },
                      },
                    )}
                  </FormGroup>
                </div>
                <div className="answer-editer-box">
                  <Label className="separate-head mb-2">
                    Details <span className="text-danger required">*</span>
                  </Label>
                  <JoditEditor
                    ref={editor}
                    config={config}
                    name={editorId}
                    value={values[editorId]}
                    onBlur={(updateValue) => {
                      setFieldValue(editorId, updateValue)
                    }}
                  />
                  {validator.current.message(
                    __t('validations.faq.faq_details_required'),
                    removeTags(values.answer),
                    'required',
                    {
                      messages: {
                        required: __t('validations.faq.faq_details_required'),
                      },
                    },
                  )}
                </div>
              </CardBody>
            </Card>
            <Row className="container">
              <Col className="ps-0">
                <Card className="p-0 mb-3">
                  <CardBody className="py-3 no-floating-label">
                    <FormGroup className="mb-0">
                      <Label className="form-label" htmlFor="reference">
                        Documents{' '}
                        <span className="text-danger required">*</span>
                      </Label>
                      <Dropzone
                        multiple={true}
                        onDrop={(acceptedFiles) => {
                          handleFileUpload(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="drop_msg">
                                <div className="file-icon">
                                  <svg
                                    width="22"
                                    height="25"
                                    viewBox="0 0 22 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    {' '}
                                    <rect
                                      x="1"
                                      y="1"
                                      width="17.5"
                                      height="22.5"
                                      rx="2"
                                      fill="currentColor"
                                      fillOpacity="0.25"
                                    />{' '}
                                    <path
                                      d="M4.75 14.75L12.25 14.75"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                    <path
                                      d="M4.75 11L9.75 11"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                    <path
                                      d="M4.75 18.5L9.75 18.5"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                    <path
                                      d="M18.5 13.5V17.5C18.5 20.3284 18.5 21.7426 17.6213 22.6213C16.7426 23.5 15.3284 23.5 12.5 23.5H7C4.17157 23.5 2.75736 23.5 1.87868 22.6213C1 21.7426 1 20.3284 1 17.5V7C1 4.17157 1 2.75736 1.87868 1.87868C2.75736 1 4.17157 1 7 1H9.75"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                    <path
                                      d="M17.25 1L17.25 8.5"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                    <path
                                      d="M21 4.75L13.5 4.75"
                                      stroke="currentColor"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                    />{' '}
                                  </svg>
                                </div>
                                <div className="dropzone-title">
                                  Drag and drop your files or
                                  <span className="browse"> browse</span> your
                                  files ( zip, pdf, jpg, jpeg, png, txt, doc,
                                  docx, csv, xlsx, xls.)
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      {values.documentDetails.length > 0
                        ? validator.current.message(
                          __t(
                            'validations.consultationRequest.consultation_documet',
                          ),
                          values.documentDetails,
                          'required',
                          {
                            messages: {
                              required: __t(
                                'validations.consultationRequest.consultation_documet',
                              ),
                            },
                          },
                        )
                        : validator.current.message(
                          __t(
                            'validations.consultationRequest.consultation_documet',
                          ),
                          selectedFiles,
                          'required',
                          {
                            messages: {
                              required: __t(
                                'validations.consultationRequest.consultation_documet',
                              ),
                            },
                          },
                        )}
                    </FormGroup>

                    <div className="attachment_preview">
                      {values.documentDetails.map((file) => (
                        <div className="attachment-data-box">
                          <a
                            href={
                              getBaseUrl() +
                              '/consultationRequests/' +
                              params.id +
                              '/' +
                              file.documentPath
                            }
                            title={file.documentPath}
                            target="_blank"
                          >
                            {checkFileExtension(file?.documentPath)}
                            <a
                              href="javascript:void(0);"
                              className="remove-box"
                              title="remove"
                              onClick={async () => {
                                const response = await commonConfirmBox(
                                  __t('alerts.are_you_sure'),
                                  __t('alerts.document_delete'),
                                )
                                if (response) {
                                  props.removeConsultationDocument({
                                    consultationRequestsId: params.id,
                                    id: file?.consultationRequestsDocumentsId,
                                  })

                                  setFieldValue(
                                    'documentDetails',
                                    values?.documentDetails?.filter(
                                      (x) =>
                                        x.consultationRequestsDocumentsId !=
                                        file.consultationRequestsDocumentsId,
                                    ),
                                  )
                                }
                              }}
                            >
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1.3335 4H14.6668"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M6.3335 7.33325V12.3333"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M9.6665 7.33325V12.3333"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </a>
                            <span className="attchment-file_name">
                              {file.documentPath}
                            </span>
                          </a>
                        </div>
                      ))}
                      {selectedFiles &&
                        selectedFiles?.map((f, i) => {
                          return (
                            <div className="attachment-data-box">
                              <Link
                                to={f.preview}
                                title={f.name}
                                target="_blank"
                              >
                                {checkFileExtension(f.name)}
                                <a
                                  href="javascript:void(0);"
                                  className="remove-box"
                                  title="remove"
                                  onClick={async () => {
                                    removeFile(f)
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3 4L3.80569 15.2797C3.82127 15.4978 4.0027 15.6667 4.2213 15.6667H11.7787C11.9973 15.6667 12.1787 15.4978 12.1943 15.2797L13 4"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      strokeLinecap="round"
                                    />
                                    <path
                                      d="M1.3335 4H14.6668"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      strokeLinecap="round"
                                    />
                                    <path
                                      d="M6.3335 7.33325V12.3333"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      strokeLinecap="round"
                                    />
                                    <path
                                      d="M9.6665 7.33325V12.3333"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      strokeLinecap="round"
                                    />
                                    <path
                                      d="M10.5 4C10.5 2.61929 9.38075 1.5 8 1.5C6.61925 1.5 5.5 2.61929 5.5 4"
                                      stroke="currentColor"
                                      strokeWidth="1"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </a>
                                <span className="attchment-file_name">
                                  {f.name}
                                </span>
                              </Link>
                            </div>
                          )
                        })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {(!isDraFSave || params.id) && (
                <Col lg={4} className="pe-0">
                  <Card className="p-0 mb-3">
                    <CardBody className="py-3 no-floating-label">
                      <FormGroup className="mb-0">
                        <Label className="form-label">
                          Approver
                          <span className="text-danger required">*</span>
                        </Label>
                        <Field
                          component={Select}
                          menuPlacement="auto"
                          name="firstApprovalId"
                          className="form-control selectbox"
                          options={props.userDropdownList?.filter(
                            (x) =>
                              x.value != values.secondApprovalId &&
                              x.value !=
                              props?.consultationDetails?.delegateUserId,
                          )}
                          onChange={(opt) => {
                            setFieldValue('firstApprovalId', opt && opt.value)
                          }}
                          value={props.userDropdownList
                            ?.filter((x) => x.value != values.secondApprovalId)
                            .filter(
                              (option) =>
                                option.value === values.firstApprovalId,
                            )}
                          isClearable={true}
                        />
                        {!isDraFSave &&
                          validator.current.message(
                            __t('validations.common.first_apvl_required'),
                            values.firstApprovalId,
                            'required',
                            {
                              messages: {
                                required: __t(
                                  'validations.common.first_apvl_required',
                                ),
                              },
                            },
                          )}
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Form>
          <Row className="container">
            <Col className="d-flex justify-content-end my-3 px-0">
              <FormGroup className="d-flex m-0 p-0">
                <Button
                  type="button"
                  color="outline-primary"
                  block
                  className="w-100 waves-effect waves-light ms-3"
                  onClick={() => {
                    navigate('/console/consultationRequest')
                  }}
                >
                  Cancel
                </Button>

                {(consultationDetails?.status === DRAFTED ||
                  consultationDetails?.status === REJECTED_BY_APPROVER ||
                  consultationDetails?.status === APPROVED ||
                  !params.id) && (
                    <Button
                      type="button"
                      color="outline-primary"
                      id="drtf"
                      className="w-100 waves-effect waves-light ms-3 overflow-visible text-nowrap"
                      onClick={async () => {
                        setisDraFSave(true)
                        handleSubmit()
                        validator.current.purgeFields()
                      }}
                    >
                      Save
                    </Button>
                  )}

                <Button
                  type="submit"
                  color="primary"
                  block
                  className="w-100 waves-effect waves-light ms-3"
                  onClick={() => {
                    setisDraFSave(false)
                    handleSubmit()
                    validator.current.purgeFields()
                  }}
                >
                  {!params.id ? 'Submit' : 'Update'}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({
  consultationReducer,
  categoryReducer,
  lawReducer,
  createUserReducer,
  userCommonReducer,
  serviceLineReducer,
}) => {
  let consultationDetails = []
  let categoryAndSubList = []
  let categoryList = []
  let lawDropdownList = []

  let ConsultationCreate = null
  let userDropdownList = []
  let publisherList = []
  let serviceLineDropdown = []
  let subCategoryList = []

  if (
    consultationReducer.consultationDetails &&
    consultationReducer.consultationDetails.details
  ) {
    consultationDetails = consultationReducer.consultationDetails.details
  }
  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
    categoryList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  if (lawReducer.lawList && lawReducer.lawList.list?.length > 0) {
    lawDropdownList = lawReducer.lawList.list?.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }

  if (
    createUserReducer.getUserListApiResponse &&
    createUserReducer.getUserListApiResponse?.data?.list?.length > 0
  ) {
    userDropdownList = createUserReducer.getUserListApiResponse?.data.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt.fullName,
      }),
    )
  }

  if (
    userCommonReducer?.publisherList &&
    userCommonReducer?.publisherList?.length > 0
  ) {
    publisherList = userCommonReducer?.publisherList.map((opt) => ({
      value: opt.id,
      label: opt.fullName,
    }))
  }

  if (consultationReducer?.addConsultation) {
    ConsultationCreate = consultationReducer?.addConsultation
  }

  if (
    serviceLineReducer &&
    serviceLineReducer.serviceLineList?.list?.length > 0
  ) {
    serviceLineDropdown = serviceLineReducer.serviceLineList?.list?.map(
      (opt) => ({
        value: opt.id,
        label: opt?.catName,
      }),
    )
  }

  if (
    categoryReducer.subCatList &&
    categoryReducer.subCatList?.list?.length > 0
  ) {
    subCategoryList = categoryReducer?.subCatList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }

  return {
    consultationDetails,
    categoryAndSubList,
    categoryList,
    lawDropdownList,
    ConsultationCreate,
    userDropdownList: userDropdownList,
    publisherList: publisherList,
    serviceLineDropdown: serviceLineDropdown,
    subCategoryList: subCategoryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserListRequest: (data) => {
      dispatch(getUserListRequest(data))
    },
    clearConsultationReponse: () => {
      dispatch(createConsultationResponse(null))
    },
    createConsultation: (data, operationType) => {
      dispatch(createConsultationRequest(data, operationType))
    },

    getSubCatList: (data) => {
      dispatch(getSubCatListByCatIdRequest(data))
    },
    getServiceLineList: (data) => {
      dispatch(getServiceLineListRequest(data))
    },

    getLawListByCategoryIdRequest: (data) => {
      dispatch(getLawListByCategoryIdRequest(data))
    },
    getCategoryListRequest: (data) => {
      dispatch(getCategoryListRequest(data))
    },
    getConsultationDetails: (data) => {
      dispatch(getConsultationDetailsRequest(data))
    },
    removeConsultationDocument: (data) => {
      dispatch(deleteConsultationDocumentRequest(data))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateConsultation)
