import React, { useEffect } from 'react'
import Select from 'react-select'
import { Formik, Form, Field } from 'formik'
import { Button, FormGroup, Label, Row } from 'reactstrap'
import { getRoleListRequest } from 'store/master/role/action'
import { connect } from 'react-redux'

const RoleSelectorFormik = (props) => {
  const initialVales = { roleId: '' }
  useEffect(() => {
    props.getRoleListRequest()
  }, [])

  return (
    <Formik initialValues={initialVales}>
      {({ values }) => (
        <>
          <Form className="form-horizontal form-login">
            <Row className="mt-3">
              <FormGroup className="col mb-0">
                <Field
                  component={Select}
                  menuPlacement="auto"
                  name="roleId"
                  id="roleId"
                  className="form-control selectbox"
                  options={props?.RoleList}
                  onChange={(value) => {
                    props.getListByRoleIdFilter({ ...values, roleId: value })
                  }}
                />
                <Label className="form-label">Role</Label>
              </FormGroup>
            </Row>
            <Button
              className="my-tooltip cancel_filter"
              data-tooltip-content={'Close filter'}
              color="link"
              onClick={() => {
                props.cancelFilterSection(true)
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L13 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Form>
        </>
      )}
    </Formik>
  )
}

const mapStateToProps = ({ roleReducer }) => {
  let RoleList
  if (roleReducer.getRoleList && roleReducer.getRoleList?.length > 0) {
    RoleList = roleReducer.getRoleList.map((opt) => ({
      value: opt.id,
      label: opt?.roleName,
    }))
  }
  return {
    RoleList: RoleList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleListRequest: (getRoleList) => {
      dispatch(getRoleListRequest(getRoleList))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleSelectorFormik)
