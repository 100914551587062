import produce from 'immer'
import { GET_USER_CONTACT_DETAILS_RESPONSE } from './actionType'
export const initialState = {
  getUserContactDetailsApiResponse: null,
}
export const UserContactUsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_USER_CONTACT_DETAILS_RESPONSE: {
        draft.getUserContactDetailsApiResponse = {
          data: new Date(),
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }

      default:
        state = { ...state }
        break
    }
  })
