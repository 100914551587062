import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import HelmetComponent from './Helmet/HelmetComponent'
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'


const TermsConditions = (props) => {
  useEffect(() => {
    // window.scrollTo(0, 0)
    const topSection = document.querySelector('.inner-main');
    if (topSection) {
      topSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Element with class "top_section" not found.');
    }
  }, [])


  const [IsShow, setIsShow] = useState(true)
  useEffect(() => {
    const isCookies = Cookies.get('isTermsConditions');
    if (isCookies) {
      setIsShow(false)
    } else {
      setIsShow(true)
    }
  }, [location.pathname]);


  return (
    <>
      <HelmetComponent title={null} description={null} keywords={null} />
      <div className="inner-main bg-white inner-main">
        <div className="top_section mb-0">
          <Container>
            <Row>
              <Col>
                <div className="innerpage-title">
                  <h1>Terms & Conditions</h1>
                  <span>Terms & Conditions</span>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/home"> Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Terms & Conditions</BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='inner-section'>
          <div className='container'>
            <div className='terms-conditions-data text-center'>
              <h3>Terms and Conditions</h3>
              <p>Please read these terms and conditions (&quot;terms and conditions&quot;, &quot;terms&quot;) carefully before using <strong>{window.parent.location.hostname}</strong> operated by Grant Thornton Bharat LLP (&quot;us&quot;, &#39;we&quot;, &quot;our&quot;).</p>
            </div>
          </div>
          <div className='terms-conditions-data-list'>
            <h6>Conditions of use</h6>
            <p>By using this <strong>GTMitra platform</strong> (‘platform’), you agree that you have read and reviewed these terms and that you agree to comply with them. If you don’t agree with the terms, then please do not move forward and you may exit now.</p>
            <p>You represent that you are a major as per the law of land where you reside, and that you are in any event at least 18 years old. You further represent that the email id which you have used or propose to use to access the platform is the email id which belongs to your employer or an organization with which you are connected, associated or employed with. You confirm that the email id which is being used or proposed to be used by you is genuine and is not fake or incorrect or not created fraudulently or is not being impersonated by you and that you have requisite authority and permissions available with you to use that email id.</p>
            <ul className='common-list'>
              <li>We only grant use and access of this platform and its contents to those who have accepted its terms.</li>
              <li>You will not use or put in content on this platform which is unlawful, harmful, disgraceful, degrading, sexual, racists, intimidating, defamatory, fraudulent, indecent, obscene, invasive or otherwise inputted or ingested with the intent to cause harm, loss, damage, destruction, disruption, hijack, overload the platform with an intent to bring it down, monitor the use of the platform and/or to introduce any viruses, time bombs, trojans, spyware, etc over the platform at any time. You will not post any material or information or data on the platform which infringes any third-party rights in any manner. You will not indulge in any commercial use of the content or reports generated by use of this platform.</li>
              <li>You are not permitted to use any crawlers, robots, bots or other technological means to access or for bulk download or scrapping of materials or contents from this platform.</li>
              <li>You will not try to reverse engineer or decipher the source and/or object code of this platform. You will not remove or attempt to remove any copyright or trademark or any other intellectual property right notices from the platform.</li>
              <li>You may note that the platform may include open-source components or any other third-party contents or hyperlinks, which are licensed for use and distribution by us under applicable open-source licenses or any other appropriate licenses. Use of these open-source or third-party components or hyperlinks is governed by and subject to the terms and conditions of the applicable open-source license.</li>
              <li>You may further note that we may utilize cookies, and other online identification technologies to provide users with an improved &amp; tailored experience for you which will enable better navigation through the platform. Likewise, we may also use technology to gather usage information and other statistical details regarding your use of the platform. If you choose to become a registered user of the platform, you hereby provide your consent that for us to use cookies to facilitate your registration and remembering your preferences and interests.</li>
            </ul>
            <p>We reserve all rights to terminate your account, restrict your access, edit or remove content and/or refuse to provide access to content on the platform at any time as per our sole discretion. We reserve the right that we may at any time modify or discontinue, temporarily or permanently, the platform (or any part thereof) without notice and shall not be liable to you or to any third party for any modification, suspension or discontinuance of the platform.</p>
            <p>The report which may be generated using this platform are generic in nature should be used for guidance purposes only. It should not form the sole basis for any decision as to a potential course of action without independent confirmation of its findings, nor should it be relied upon as preferred advice. Further, such generic reports should not be considered a definitive pronouncement of facts and you remain responsible to make your own assessment and use your judgement before taking any decisions and actions. You shall be fully and solely responsible for applying independent judgment, with respect to the findings included in any such generic reports which are provided to you as an output of your queries posted on the platform. We shall not take responsibility for the consequences resulting from decisions based on information included in the report.</p>
            <p>The information on this platform is provided to you on an “as-is” basis for a general, indicative purpose only without any express or implied warranty as to the accuracy, completeness, relevance or fitness of the materials on this platform for any particular purpose. As the rules and regulations keeping changing and are dynamic in nature, there may be delays, omissions or inaccuracies in keeping the information updated as contained in the platform. This platform does not, in any event, construe or replace the professional guidance on matters concerning accounting, tax, legal, audit or other such specific areas of your concern.</p>
            <p>This platform, its contents, reports and/or information contained therein is not intended to be a substitute or a replacement for seeking independent professional advice and you are expected and requested to obtain any appropriate professional advice relevant to your particular facts and circumstances. Please reach out to us on <a href="mailto:gtmitra@in.gt.com" className='btn-link' title='gtmitra@in.gt.com'>gtmitra@in.gt.com</a> for a detailed conversation wherein we can explore the possibility to help you further.</p>
            <p>The platform, its contents, reports, data provided by you over the platform and all other intellectual property rights in it will and shall remain to be owned by us at all times to come on a worldwide basis. If you intend to reproduce, distribute or otherwise use any contents published on or through our platform or provide any hyperlinks to the platform / domain, you must seek to obtain our prior written permission.</p>
            <p>We shall not be liable for any errors, omissions or inaccuracies, or misstatements or misrepresentations (whether express or implied) concerning any such information, including reports, published on or through the platform. You further agree and acknowledge that we will not have or accept any liability, obligation or responsibility whatsoever for any loss, destruction or damage (including without limitation consequential loss, destruction or damage) however arising from or in respect of any use or misuse of or reliance on the platform or on the information, including reports, published on or through the platform or the inability to use the platform as we do not guarantee or assure error free or interrupted access to the platform.</p>
            <p>You agree to indemnify and hold us and our partners, personnel, staff, contractors, affiliates and related parties harmless from any claim or demand, including all legal fees, made by any third party due to or arising out of your use of the platform and/or its contents (including by putting reliance on the reports generated using the platform).</p>
            <h6>Use of Your Personal Data</h6>
            <ul className='common-list'>
              <li>You further confirm and acknowledge that by agreeing to use this platform you confirm that you have provided your consent to Grant Thornton Bharat for processing and sharing your personal information (such as your corporate email id, name, etc) with the affiliates or related parties or other service providers and/ or by third parties contractors engaged by Grant Thornton Bharat and/or its affiliates or other related parties which may be located on world-wide basis.</li>
              <li>You are not expected and advised not to share any personal details including sensitive personal data with us while interacting though this platform such as PAN Card number, Aadhar numbers, etc.</li>
              <li>As a user of this platform, you would be required to register with us and provide personal information. Hence, you further confirm to keep your personal information complete, updated and accurate at all times.</li>
              <li>You will not share the account credentials (including username and passwords, if any) provided to you with anyone else and you will remain solely and completely responsible for your acts and/or use of your account.</li>
              <li>You confirm that you have understood the contents written in English, and you do not require any translation or explanation of the same. For further information please refer to the privacy policy of Grant Thornton Bharat available on its platform at <a target="_blank" href="https://www.grantthornton.in/en/privacy-policy/" className='btn-link' title='https://www.grantthornton.in/en/privacy-policy/'>https://www.grantthornton.in/en/privacy-policy/</a></li>
            </ul>
            <div className='note-box'>
              <h6>Note : </h6>
              <ul className='common-list'>
                <li><strong>We reserve the right to edit, modify, and change these terms at any time.</strong></li>
                <li><strong>You agree to abide by these terms while using this platform.</strong></li>
                <li><strong>You accord your consent to these terms by clicking on the ‘Agree’ button below.</strong></li>
              </ul>
            </div>
            <p>These terms will be governed by laws of the India and any dispute of any sort that might come between you and us will be subject to exclusive jurisdiction of the Courts at <strong>New Delhi, India.</strong></p>
            {
              IsShow && <div className='btn-box text-center'>
                <Button className="btn btn-outline-light" title='Decline' onClick={() => setIsShow(false)}>
                  Decline
                </Button>
                <Button className="btn btn-primary ms-3" title='Agree' onClick={() => {
                  Cookies.set('isTermsConditions', true);
                  setIsShow(false)
                }}>
                  Agree
                </Button>
              </div>
            }

          </div>
        </div>
      </div>
    </>
  )
}


export default (TermsConditions)
