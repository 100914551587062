export const userListFilter = {
  searchText: '',
  pagination: {
    limit: 10,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: { roleId: 0 },
}

export const allUserListFilter = {
  searchText: '',
  pagination: {
    limit: 0,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: { roleId: 0 },
}

export const categoryListFilter = {
  searchText: '',
  pagination: {
    limit: 10,
    page: 0,
    orderKey: 'createdDate',
    orderBy: 'ASC',
  },
  filter: { roleId: 0 },
}
