import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'
import { getDashboardRequest } from 'store/dashboard/action'
import { generateChartMix, generatechartRadialbar } from './ReactChartDetails'
import { TotalCards } from './CommonModals'

const Home = (props) => {
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])
  const [chartRadialbarData, setChartRadialbarData] = useState([])
  const [chartMixData, setChartMixData] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    props.getDashboardList()
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.chartQueriesCount) {
        setChartRadialbarData(generatechartRadialbar(props.chartQueriesCount))
      }

      if (props.chartColumnsData) {
        setChartMixData(generateChartMix(props.chartColumnsData))
      }
    }, 300)

    return () => clearTimeout(timeout)
  }, [props.chartColumnsData, props.chartQueriesCount])

  useEffect(() => {
    setSeries([
      {
        name: props.chartName1,
        data: props.graph1.map((x) => x.count),
      },
      {
        name: props.chartName2,
        data: props.graph2.map((x) => x.count),
      },
    ])
    setOptions({
      chart: {
        toolbar: {
          show: false,
        },
        height: '150px',
        type: 'area',
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      xaxis: {
        type: 'category',
        show: true,
        categories: props.graph1.map((x) => x.month),
        labels: {
          show: true,
          align: 'left',
          colors: ['#fff'],
          style: {
            fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
            colors: '#fff',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            colors: ['#fff'],
            fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#00A7B5', '#9bd732'],
      legend: {
        show: true,
        labels: {
          colors: ['#fff'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    })
  }, [props.graph1])

  return (
    <div className="container">
      <div className="dashboard">
        <Row>
          <Col lg="8" className="dashboard_count_main">
            {props.counts?.faqCounts != null && (
              <TotalCards
                Counts={props.counts?.faqCounts}
                ModuleName={'FAQs'}
                text={'FAQs'}
                href="/console/faq"
              />
            )}
            {props.counts?.reportCounts != null && (
              <TotalCards
                Counts={props.counts?.reportCounts}
                ModuleName={'Reports'}
                text={'Reports'}
                href="/console/report"
              />
            )}
            {props.counts?.totalSubscriberCounts != null && (
              <TotalCards
                Counts={props.counts?.totalSubscriberCounts}
                ModuleName={'Subscriber'}
                text={'Subscribers'}
                href="/console/subscriber"
              />
            )}

            {props.counts?.articleCounts != null && (
              <TotalCards
                Counts={props.counts?.consultationCounts}
                ModuleName={'Consultation'}
                text={'Consultations'}
                href="/console/consultationRequest"
              />
            )}
          </Col>

          <Col lg="4">
            <div id="chart">
              {series?.length > 0 && (
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height="200"
                />
              )}
            </div>
          </Col>
        </Row>
        {/* <img alt="Dashboard charts" src={dashboard} /> */}
      </div>

      <Row className="dashboard-charts mt-4">
        <Col lg={8}>
          <Card className="mb-0 h-100">
            <h5>Report based on Visitor views</h5>

            {chartMixData?.series?.length > 0 && (
              <ReactApexChart
                options={chartMixData.options}
                series={chartMixData.series}
                type="line"
                height="350"
              />
            )}
          </Card>
        </Col>

        <Col lg={4}>
          <Card
            className="mb-0 h-100 cursor-pe"
            onClick={() => navigate('/console/help')}
          >
            <h5>Subscriber Query</h5>
            {chartRadialbarData?.series?.length > 0 && (
              <ReactApexChart
                options={chartRadialbarData.options}
                series={chartRadialbarData.series}
                type="radialBar"
                height="375"
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ dashboardReducer }) => {
  let counts = []
  let graph1 = []
  let graph2 = []
  let chartName1 = ''
  let chartName2 = ''
  let chartColumnsData = []
  let chartQueriesCount = {}

  if (dashboardReducer.dashboardApiResponse) {
    counts = dashboardReducer?.dashboardApiResponse?.counts

    // *DashBoard Charts column , area , line
    chartColumnsData = dashboardReducer?.dashboardApiResponse
    chartQueriesCount = dashboardReducer?.dashboardApiResponse.QueriesCount

    if (
      dashboardReducer?.dashboardApiResponse?.graphs?.subscriberLastSixMonth &&
      dashboardReducer?.dashboardApiResponse?.graphs?.visitorLastSixMonth
    ) {
      graph1 =
        dashboardReducer?.dashboardApiResponse?.graphs?.subscriberLastSixMonth

      graph2 =
        dashboardReducer?.dashboardApiResponse?.graphs?.visitorLastSixMonth
      chartName1 = 'Subscriber'
      chartName2 = 'Visitor'
    } else if (
      dashboardReducer?.dashboardApiResponse?.graphs
        ?.reportRequestDownloadLastSixMonth &&
      dashboardReducer?.dashboardApiResponse?.graphs
        ?.subscriberQueryLastSixMonth
    ) {
      graph1 =
        dashboardReducer?.dashboardApiResponse?.graphs
          ?.reportRequestDownloadLastSixMonth
      graph2 =
        dashboardReducer?.dashboardApiResponse?.graphs
          ?.subscriberQueryLastSixMonth
      chartName1 = 'Report'
      chartName2 = 'Subscriber Query'
    } else if (
      dashboardReducer?.dashboardApiResponse?.graphs?.faqLastSixMonth &&
      dashboardReducer?.dashboardApiResponse?.graphs?.reportLastSixMonth
    ) {
      graph1 = dashboardReducer?.dashboardApiResponse?.graphs?.faqLastSixMonth
      graph2 =
        dashboardReducer?.dashboardApiResponse?.graphs?.reportLastSixMonth
      chartName1 = 'FAQ'
      chartName2 = 'Report'
    }
  }
  return {
    // React Chart Columns Counts
    chartColumnsData: chartColumnsData,
    chartQueriesCount: chartQueriesCount,
    counts: counts,
    graph1: graph1,
    graph2: graph2,
    chartName1: chartName1,
    chartName2: chartName2,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardList: () => {
      dispatch(getDashboardRequest())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
