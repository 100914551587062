import {
  GET_USER_CONTACT_DETAILS_REQUEST,
  GET_USER_CONTACT_DETAILS_RESPONSE,
} from './actionType'

//Contact Us Page
export const getUserContactDetailsRequest = (data) => {
  return {
    type: GET_USER_CONTACT_DETAILS_REQUEST,
    payload: { data },
  }
}

export const getUserContactDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_USER_CONTACT_DETAILS_RESPONSE,
    payload: { message, responseType, data },
  }
}
