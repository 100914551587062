import { modules } from 'constant/databaseConstants'
import * as authConstants from '../constant/authConstants'
import { decodeToken } from 'react-jwt'
import { ApiResponseMessage } from './tosterHelpers'
import { DANGER } from 'constant/commonConstants'
//Set the logged in user data in local session
const setUserDetails = (userDetails) => {
  localStorage.setItem(authConstants.TOKEN, userDetails.accessToken)
  localStorage.setItem(authConstants.USER_NAME, userDetails.userName)
  localStorage.setItem(authConstants.ROLE_ID, userDetails.roleId)
}

const getValueByKey = (key) => {
  return localStorage.getItem(key)
}
const getUserName = () => {
  return localStorage.getItem(authConstants.USER_NAME)
}
const removeLoggedInUserDetails = () => {
  //localStorage.clear()
  localStorage.removeItem('permissions')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('email')
  localStorage.removeItem('user')
  localStorage.removeItem('role')
  localStorage.removeItem('userId')
  localStorage.removeItem('login_type')
  localStorage.removeItem('userDetails')
  localStorage.removeItem('authenticationLogId')
}
const isAdmin = () => {
  const roleId = localStorage.getItem(authConstants.ROLE_ID)
  return roleId == 1
}
const getModuleId = (moduleName) => {
  let data = modules.filter((x) => x.name == moduleName)[0]?.id
  if (data) {
    return data
  }
  return -1
}
const getModulePermissions = (moduleId) => {
  let accessToken = getValueByKey('accessToken')
  let userDetails = getValueByKey('userDetails')
  if (userDetails) {
    accessToken = userDetails
  }
  let localStorageData = decodeToken(accessToken)
  localStorageData = localStorageData?.permissions
  if (localStorageData && localStorageData?.length > 0) {
    if (Array.isArray(moduleId)) {
      return localStorageData.filter(
        (x) =>
          moduleId.includes(x.moduleId) &&
          (x.add === 1 ||
            x.update === 1 ||
            x.delete === 1 ||
            x.approve === 1 ||
            x.download === 1 ||
            x.view === 1 ||
            x.publish === 1),
      )
    } else {
      return localStorageData.filter(
        (x) =>
          x.moduleId === moduleId &&
          (x.add === 1 ||
            x.update === 1 ||
            x.delete === 1 ||
            x.approve === 1 ||
            x.download === 1 ||
            x.view === 1 ||
            x.publish === 1),
      )
    }
  }
  return []
}

const checkDataWithLocalStorage = () => {

  let accessToken = getValueByKey('accessToken')
  let checkDecodeTokenData = decodeToken(accessToken)
  let loginType = getValueByKey(authConstants.LOGIN_TYPE)

  if (checkDecodeTokenData && checkDecodeTokenData.name && loginType != 'SSO') {
    checkDecodeTokenData = (({ iat, exp, ...rest }) => rest)(checkDecodeTokenData);
    let localStorageJson = {
      email: JSON.parse(getValueByKey('email')),
      name: JSON.parse(getValueByKey('user')),
      permissions: checkDecodeTokenData?.permissions,
      roleId: checkDecodeTokenData?.roleId,
      type: checkDecodeTokenData?.type,
      userId: JSON.parse(getValueByKey('userId')),
    }
    const sortedObject1 = Object.fromEntries(Object.entries(checkDecodeTokenData).sort());
    const sortedObject2 = Object.fromEntries(Object.entries(localStorageJson).sort());

    if (JSON.stringify(sortedObject1) !== JSON.stringify(sortedObject2)) {
      ApiResponseMessage("Unauthorized User", DANGER)
      localStorage.clear();
      setTimeout(function () {
        window.location.href = '/console/login';
      }, 2000);
    }
  }


}

const isHaveModuleAccess = (moduleId) => {
  checkDataWithLocalStorage()

  return getModulePermissions(moduleId)?.length > 0



}

const isUserAuthenticated = () => {
  const token = localStorage.getItem(authConstants.TOKEN)
  return !!token
}

export const setLoginUserData = (data) => {
  localStorage.setItem('userId', JSON.stringify(data.user.userId))
  localStorage.setItem('user', JSON.stringify(data.user.fullName))
  localStorage.setItem('email', JSON.stringify(data.user.email))
  localStorage.setItem(authConstants.TOKEN, data.accessToken)
  localStorage.setItem('role', data?.user?.roles)
  localStorage.setItem(
    'countryData',
    JSON.stringify(data?.user?.userCountry ? data?.user?.userCountry : []),
  )
  localStorage.setItem('authenticationLogId', data?.user?.authenticationLogId)

  if (data.user.userDetails) {
    localStorage.setItem('userDetails', JSON.stringify(data.user.userDetails))
  }
}

const setValueByKey = (key, value) => {
  if (value) {
    return localStorage.setItem(key, value)
  }
}

const authUserType = () => {
  let internalUser = ''
  const sentence = JSON.parse(getValueByKey(authConstants.EMAIL))
  if (sentence) {
    const regexes = [/honeycombsoftwares/, /outlook/] // Note that regexes are defined without quotes and slashes

    for (const regex of regexes) {
      if (regex.test(sentence)) {
        internalUser = 'INTERNAL'
        break // Exit the loop if a match is found
      }
    }
  }
  return internalUser
}

const autoLogout = () => {
  localStorage.removeItem('verificationData')
  localStorage.removeItem('subscriberToken')
  localStorage.removeItem('_grecaptcha')
  localStorage.removeItem('visitorId')
}
export {
  setUserDetails,
  isUserAuthenticated,
  removeLoggedInUserDetails,
  getValueByKey,
  setValueByKey,
  isAdmin,
  getUserName,
  isHaveModuleAccess,
  getModuleId,
  getModulePermissions,
  autoLogout,
  authUserType,
}
