export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_RESPONSE = 'GET_CATEGORIES_RESPONSE'

export const GET_USER_REPORT_LIST_REQUEST = 'GET_USER_REPORT_LIST_REQUEST'
export const GET_USER_REPORT_LIST_RESPONSE = 'GET_USER_REPORT_LIST_RESPONSE'

export const GET_USER_REPORT_DETAILS_REQUEST = 'GET_USER_REPORT_DETAILS_REQUEST'
export const GET_USER_REPORT_DETAILS_RESPONSE =
  'GET_USER_REPORT_DETAILS_RESPONSE'

export const CLEAR_USER_REPORT_LIST_RESPONSE = 'CLEAR_USER_REPORT_LIST_RESPONSE'

export const CREATE_SUBSCRIBER_QUERY_REQUEST = 'CREATE_SUBSCRIBER_QUERY_REQUEST'
export const CREATE_SUBSCRIBER_QUERY_RESPONSE =
  'CREATE_SUBSCRIBER_QUERY_RESPONSE'

export const CLEAR_SUBSCRIBER_QUERY_RESPONSE = 'CLEAR_SUBSCRIBER_QUERY_RESPONSE'

export const CREATE_USER_REPORT_DOWNLOAD_REQUEST =
  'CREATE_USER_REPORT_DOWNLOAD_REQUEST'
export const CREATE_USER_REPORT_DOWNLOAD_RESPONSE =
  'CREATE_USER_REPORT_DOWNLOAD_RESPONSE'

export const CLEAR_USER_REPORT_DETAILS_RESPONSE =
  'CLEAR_USER_REPORT_DETAILS_RESPONSE'
