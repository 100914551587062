import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { getAllSectionListRequest } from 'store/master/section/action'
import { connect } from 'react-redux'
import { consultationInitialState } from './CommonFunction'

import { Link, useOutletContext } from 'react-router-dom'
import { DEFAULT_PAGE_LENGTH } from 'constant/commonConstants'
import { APPROVEDPERMISSION } from 'constant/databaseConstants'
import ViewConsultationDetails from './ViewConsultationDetails'
import CreateConsultation from './CreateConsultation'
import {
  clearConsultationListResponse,
  getListConsultationRequest,
} from 'store/consultation/action'
import { ConsultationTable } from 'components/Table/ConsultationTable'

const ConsultationRequest = (props) => {
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [consultationData] = useState(consultationInitialState())

  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState(DEFAULT_PAGE_LENGTH)
  const [searchText, setSearchText] = useState('')

  const [isViewDetailPage, setIsViewDetailPage] = useState(false)
  const [viewDetailPageId, setViewDetailPageId] = useState('')
  const [currentActiveTab, setcurrentActiveTab] = useState(
    permissions.approve || permissions.add
      ? 'myConsultation'
      : 'delegateToTeam',
  )
  const [paginationData, setPaginationData] = useState({
    searchText: '',
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  })

  useEffect(() => {
    if (viewDetailPageId != '') {
      setIsViewDetailPage(true)
    }
  }, [viewDetailPageId])
  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }
  useEffect(() => {
    setCurrentPage(1)
  }, [dataPerPage])
  useEffect(() => {
    if (!isViewDetailPage) {
      props.getConsultationList({
        searchText: searchText,
        pagination: {
          limit: dataPerPage,
          page: currentPage,
          orderKey: 'createdDate',
          orderBy: 'ASC',
        },
        type: currentActiveTab ? currentActiveTab : 'myConsultation',

        filter: paginationData.filter,
      })
    }

  }, [currentPage, dataPerPage, searchText, paginationData, currentActiveTab, isViewDetailPage])

  const columns = [
    {
      dataField: 'question',
      text: 'Question',
      sort: true,
    },
    {
      dataField: 'Approvel',
      text: 'Approvel',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Stutus',
      sort: true,
    },
    {
      dataField: 'Action',
      text: '',
      headerClasses: 'action_col',
      events: {},
      formatter: (cell, row) => (
        <div className="action_col text-center">
          {/* <HistoryBtn></HistoryBtn> */}

          <Link
            className="btn btn-link edit_btn"
            to={'view/' + row.id}
            state={{ faq: row }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1123_13264)">
                <path
                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                  stroke="CurrentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1123_13264">
                  <rect width="20" height="20" fill="CurrentColor" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <Formik initialValues={consultationData}>
      <>
        <div className={isViewDetailPage && 'd-none'}>
          <ConsultationTable
            getViewDetailPageData={(row) => {
              setViewDetailPageId(row)
            }}
            currentActiveTab={(e) => {
              setcurrentActiveTab(e)
            }}
            clearConsultationList={() => {
              props.clearConsultationList()
            }}
            isConsultationFilterCategory={true}
            getConsultationDataByCategory={(
              catId,
              subCatId,
              law,
              section,
              status,
              date,
            ) => {
              setCurrentPage(1)
              setPaginationData({
                ...paginationData,
                filter: {
                  categoryId: parseInt(catId),
                  subCategoryId: parseInt(subCatId),
                  law,
                  section,
                  status,
                  date,
                },
              })
              // filterData(categoryId, subCategoryId, law, section, status, date)
            }}
            getRefreshList={() => {
              setCurrentPage(1)
              setPaginationData({
                ...paginationData,
                filter: {},
              })
              // props.getConsultationList(paginationData)
            }}
            hedingName="Consultation Request"
            buttonName={
              permissions?.add === APPROVEDPERMISSION && isAdmin() == false
                ? 'Create Consultation Request'
                : ''
            }
            //buttonName="Create Consultation Request"
            component={CreateConsultation}
            href="/console/consultationRequest/create"
            dataPerPage={dataPerPage}
            currentPage={currentPage}
            totalRecords={props?.totalRecords}
            updateData={(e) => {
              if (e.searchText) {
                setSearchText(e.searchText)
              } else {
                setSearchText('')
              }
              setCurrentPage(1)
              // props.getConsultationList(e)
            }}
            data={props?.consultationList ? props?.consultationList : []}
            columns={columns}
            setDataPerPage={setDataPerPage}
            setCurrentPage={setCurrentPage}
          ></ConsultationTable>
        </div>

        <div className={!isViewDetailPage && 'd-none'}>
          <ViewConsultationDetails
            viewDetailPageData={viewDetailPageId}
            resetViewDetailsData={(value) => {
              if (value) {
                setCurrentPage(1)
                setPaginationData({
                  ...paginationData,
                  filter: {},
                })
              }
              setViewDetailPageId('')
              setIsViewDetailPage(false)
            }}
          />
        </div>
      </>
    </Formik>
  )
}

const mapStateToProps = ({ categoryReducer, consultationReducer }) => {
  let consultationList = []
  let categoryList = []
  let categoryAndSubList = []
  let totalRecords = 0
  if (
    consultationReducer.listconsultation &&
    consultationReducer.listconsultation.list?.length > 0
  ) {
    consultationList = consultationReducer.listconsultation.list
    totalRecords = consultationReducer.listconsultation.totalRecords
  }
  if (
    categoryReducer.categoryList.list &&
    categoryReducer.categoryList.list?.length > 0
  ) {
    categoryAndSubList = categoryReducer.categoryList.list
    categoryList = categoryReducer.categoryList.list?.map((opt) => ({
      value: opt.id,
      label: opt.catName,
    }))
  }
  return {
    consultationList: consultationList,
    categoryAndSubList,
    categoryList,

    totalRecords,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSectionList: (data) => {
      dispatch(getAllSectionListRequest(data))
    },
    getConsultationList: (data) => {
      dispatch(getListConsultationRequest(data))
    },
    clearConsultationList: () => {
      dispatch(clearConsultationListResponse())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConsultationRequest)
