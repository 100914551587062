import { commonConfirmBox } from 'helpers/messagehelpers'
import React from 'react'
import Form from 'react-bootstrap/Form'

export default function ReactSwitch(props) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <label htmlFor="d-flex">Send Notification</label>
      <Form.Check
        onChange={async () => {
          const response = await commonConfirmBox(
            !props.status
              ? 'Do you want to activate this template?'
              : 'Do you want to deactivate this template?',
          )
          if (response) {
            // setIsActive(!isActive)
            props.checkSwitch(!props.status)
          }
        }}
        type="switch"
        id="custom-switch"
        className="justify-content-center toggle_switch_btn"
        checked={props.status}
      />
    </div>
  )
}
