import React from 'react'
import laptop from '../../../src/images/laptop.png'

export default function Unauthorized() {
    return (
        <>
            <div class="wrapper error_wrapper error-unauthorized">
                <main class="container">
                    <div class="row error_main">
                        <div class="col-md-4 errorcontent">
                            <article>
                                <h1>Unauthorized: Access is denied due to invalid credentials.</h1>
                                <p>You do not have permission to view this directory or page using the credentials that you supplied.</p>
                                {/* <a href="../Login/AdminLogin" class="btn" role="button">Home</a> */}
                            </article>
                        </div>
                        <div class="col-md-8 laptop hidden-xs">
                            <img src={laptop} alt="laptop" />
                            <div class="glitch">401</div>
                        </div>

                    </div>
                </main>
            </div>
        </>
    )
}
