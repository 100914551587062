export const LoginInitialState = () => {
  return {
    email: '',
    password: '',
    recaptcha: '',
    code: "",
    user_captcha_input: "",
  }
}

export const ResetPasswordState = () => {
  return {
    password: '',
    confirmPassword: '',
  }
}
