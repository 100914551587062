import { takeEvery, call, put, all } from 'redux-saga/effects'
import {
  CHECK_RESET_PASSWORD_TOKE_REQUEST,
  CHECK_RESET_PASSWORD_TOKE_RESPONSE,
  FORGET_PASSWORD_REQUEST,
  RESET_PASSWORD_API_REQUEST,
  RESET_PASSWORD_BY_EMAIL_API_REQUEST,
} from './actionType'
import { invokeApi } from '../../../helpers/axiosHelper'
import { handleApiCallException } from '../../shared/action'
import {
  checkResetPasswordTokeResponse,
  forGetPasswordResponse,
  resetPasswordApiResponse,
  resetPasswordByEmailApiResponse,
} from './action'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER, SUCCESS } from 'constant/commonConstants'

function* forgetPassword({ payload: { data } }) {
  let response = yield call(invokeApi, 'post', '/forgotPassword', data)
  try {
    if (response.status) {
      ApiResponseMessage(
        response.message.message,
        response.status ? SUCCESS : DANGER,
      )
      yield put(forGetPasswordResponse([]))
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(forGetPasswordResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//Here we checking reset password token
function* userPasswordResetTokenCheck({ payload: { token } }) {
  try {
    const response = yield call(invokeApi, 'get', '/forgotPassword/' + token)
    yield put(
      checkResetPasswordTokeResponse(
        response.status,
        response.message,
        CHECK_RESET_PASSWORD_TOKE_RESPONSE + new Date(),
      ),
    )
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//reset pass
function* resetPassword({ payload: { token } }) {
  let FormattingData = {
    confirmPassword: token.confirmPassword,
    newPassword: token.newPassword,
    token: token.token.id,
  }
  let response = yield call(invokeApi, 'post', '/resetPassword', FormattingData)
  try {
    if (response.status) {
      yield put(resetPasswordApiResponse(response.message, response.status))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(resetPasswordApiResponse([]))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//reset pass
function* resetPasswordByEmail({ payload: { data } }) {
  let response = yield call(invokeApi, 'post', '/resendEmail', data)
  try {
    if (response.status) {
      yield put(
        resetPasswordByEmailApiResponse(response.message, response.status),
      )
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(
        resetPasswordByEmailApiResponse(response.message, response.status),
      )
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//resetPassword
function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD_REQUEST, forgetPassword)
  // yield takeEvery(types.ADMIN_CHANGE_PASSWORD_REQUEST, adminChangePassword)
  yield takeEvery(
    CHECK_RESET_PASSWORD_TOKE_REQUEST,
    userPasswordResetTokenCheck,
  )
  yield takeEvery(RESET_PASSWORD_API_REQUEST, resetPassword)
  yield takeEvery(RESET_PASSWORD_BY_EMAIL_API_REQUEST, resetPasswordByEmail)
}
function* ForgetPasswordSaga() {
  yield all([watchForgetPassword()])
}
export default ForgetPasswordSaga
