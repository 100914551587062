import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  GET_CATEGORY_LIST_REQUEST,
  ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST,
  GET_CATEGORY_DELETE_REQUEST,
  GET_SUBCAT_LIST_BY_CAT_ID_REQUEST,
  GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST,
  GET_FILTER_CATEGORY_LIST_REQUEST,
} from './actionType'
import { handleApiCallException } from '../../shared/action'
import { invokeApi } from '../../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../../constant/commonConstants'
import {
  getCategoryListResponse,
  addCategoryResponse,
  getCategoryListRequest,
  getCategoryDeleteResponse,
  getSubCatListByCatIdResponse,
  getReportSubCatListByCatIdResponse,
  getFilterCategoryListResponse,
} from './action'
import { categoryListFilter } from 'pages/masterPages/User/helpers'
import { ApiResponseMessage } from 'helpers/tosterHelpers'

function* addCategoryAndSubCategory({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/category', data)
    if (response.status) {
      yield put(addCategoryResponse(response.message, SUCCESS))

      ApiResponseMessage(
        response.message,
        response.status === true ? SUCCESS : DANGER,
      )

      yield put(getCategoryListRequest(categoryListFilter))
      // ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      ApiResponseMessage(
        response.message,
        response.status === true ? SUCCESS : DANGER,
      )
      yield put(addCategoryResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getCategoryList({ payload: { Data } }) {
  try {
    let response = yield call(invokeApi, 'post', '/categoryList', Data)

    const modifiedData = response.data.list.map((listItem) => {
      const modifiedSubCat = listItem.subCat.map((subCatItem) => {
        return { ...subCatItem, isTrue: true }
      })
      return { ...listItem, subCat: modifiedSubCat }
    })

    response = {
      ...response,
      data: {
        ...response.data,
        list: [...modifiedData],
      },
    }

    if (response.status) {
      yield put(getCategoryListResponse(response && response.data))
    } else {
      yield put(getCategoryListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* categoryStatus({ payload: { data } }) {
  const { currentPage, dataPerPage, ...modData } = data
  try {
    const response = yield call(invokeApi, 'post', '/categoryActive', modData)
    if (response.status) {
      yield put(
        getCategoryListRequest({
          searchText: '',
          pagination: {
            limit: dataPerPage,
            page: currentPage,
            orderKey: 'createdDate',
            orderBy: 'ASC',
          },
          filter: { roleId: 0 },
        }),
      )
      yield put(
        getCategoryDeleteResponse(response.message, SUCCESS, response.data),
      )
    } else {
      // yield put(getCategoryDeleteRequest(response.message, DANGER))
      yield put(getCategoryDeleteResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getSubCatList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/subCategoryList', Data)
    if (response.status) {
      yield put(getSubCatListByCatIdResponse(response.data))
    } else {
      yield put(getSubCatListByCatIdResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getReportSubCatList({ payload: { Data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/subCategoryList', Data)
    if (response.status) {
      yield put(getReportSubCatListByCatIdResponse(response.data))
    } else {
      yield put(getReportSubCatListByCatIdResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getFilterCategoryList({ payload: { Data } }) {
  try {
    let response = yield call(invokeApi, 'post', '/categoryList', Data)

    const modifiedData = response.data.list.map((listItem) => {
      const modifiedSubCat = listItem.subCat.map((subCatItem) => {
        return { ...subCatItem, isTrue: true }
      })
      return { ...listItem, subCat: modifiedSubCat }
    })

    response = {
      ...response,
      data: {
        ...response.data,
        list: [...modifiedData],
      },
    }

    if (response.status) {
      yield put(getFilterCategoryListResponse(response && response.data))
    } else {
      yield put(getFilterCategoryListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchCountry() {
  yield takeEvery(
    ADD_CATEGORY_AND_SUB_CATEGORY_REQUEST,
    addCategoryAndSubCategory,
  )
  yield takeEvery(GET_CATEGORY_LIST_REQUEST, getCategoryList)
  yield takeEvery(GET_CATEGORY_DELETE_REQUEST, categoryStatus)
  yield takeEvery(GET_SUBCAT_LIST_BY_CAT_ID_REQUEST, getSubCatList)
  yield takeEvery(GET_REPORT_SUBCAT_LIST_BY_CAT_ID_REQUEST, getReportSubCatList)
  yield takeEvery(GET_FILTER_CATEGORY_LIST_REQUEST, getFilterCategoryList)
}

function* CategorySaga() {
  yield all([fork(watchCountry)])
}
export default CategorySaga
