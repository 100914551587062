import axios from 'axios'
import { removeLoggedInUserDetails, getValueByKey } from './authUtils'
import * as authConstants from '../constant/authConstants'
import FileSaver from 'file-saver'
const invokeApi = (method, url, data, headers) => {
  if (getValueByKey(authConstants.LOGIN_TYPE) == 'SSO') {
    headers = { ...headers, loginType: 'SSO' }
  } else {
    headers = { ...headers, loginType: 'LC' }
  }
  const baseUrl = getBaseUrl()
  headers = {
    ...headers,
    'x-content-type-options': 'nosniff',
    'x-frame-options': 'SAMEORIGIN',
    'x-xss-protection': '1; mode=block',
    'Strict-Transport-Security': 'max-age',
    'content-security-policy':
      "default-src 'self'; script-src 'self'; style-src 'self';",
    'Cache-Control': 'no-cache, private',
    Pragma: 'no-cache',
    'Access-Control-Allow-Origin': baseUrl,
    'Access-Control-Allow-Credentials': false,
  }


  url = baseUrl + url



  let accessToken = ''
  if (window.location.pathname.split('/')[1] == 'console') {
    accessToken = getValueByKey(authConstants.TOKEN)
  } else {
    accessToken = getValueByKey(authConstants.SUBSCRIBER_TOKEN)
  }
  return axios({
    method: method,
    url: url,
    headers: {
      ...headers,
      Authorization: 'Bearer ' + accessToken,
    },
    data: data,
  })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err.response.data
    })
}
const invokeApiProgress = (method, url, data, headers, onUploadProgress) => {
  const baseUrl = getBaseUrl()

  url = baseUrl + url

  return axios({
    method: method,
    url: url,
    headers: {
      Authorization: 'Bearer ' + getValueByKey(authConstants.TOKEN),
    },
    data: data,
    onUploadProgress: onUploadProgress,
  })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      return err.response.data
    })
}

const getBaseUrl = () => {
  let baseUrl = window.location.hostname
  if (baseUrl === 'localhost') {
   baseUrl = 'https://taxfaq.hcshub.in/taxfaq-api'
    //  baseUrl = 'http://localhost:3000'
  } else {
    baseUrl = window.location.origin + '/taxfaq-api'
  }

  return baseUrl
}

//Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear()
      removeLoggedInUserDetails()
      window.location.href = '/'
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  },
)
const download = async (url, filename) => {
  fetch('http://localhost:3100/VendorMaster.xlsx', { responseType: 'blob' })
    .then((res) => res.blob())
    .then((blob) => {
      FileSaver.saveAs(blob, filename)
    })
}
export { invokeApi, getBaseUrl, invokeApiProgress, download }
