import i18next from 'i18next'
export const __t = (messageKey, replaceData = {}) => {
  let message = ''
  if (messageKey) {
    message = i18next.t(messageKey, replaceData)
  }
  if (message === '') {
    message = messageKey
  }
  return message
}
