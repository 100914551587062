import produce from 'immer'
import {
  ADD_LAW_RESPONSE,
  GET_LAW_LIST_RESPONSE,
  EDIT_LAW_RESPONSE,
  GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE,
  GET_LAW_SECTION_LIST_RESPONSE,
  GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE,
  ADD_LAW_REVISION_RESPONSE,
} from './actionType'
export const initialState = {
  lawApiResponse: null,
  lawDetailsApiResponse: null,
  lawStatusUpdateApiResponse: null,
  lawSearchApiResponse: null,
  lawList: [],
  lawSearchList: [],
  getLawstatusApiResponse: null,
  lawSectionList: null,
  lawListByCategoryId: null,
  lawRevision: null,
}
export const lawReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_LAW_RESPONSE:
        if (action.payload.message == undefined) {
          draft.lawApiResponse = null
        } else {
          draft.lawApiResponse = {
            message: action.payload.message,
            status: action.payload.status,
          }
        }
        break
      case GET_LAW_LIST_RESPONSE:
        draft.lawList = action.payload.data
        break
      case GET_LAW_LIST_BY_CATEGORY_ID_RESPONSE:
        draft.lawListBySectionId = action.payload.data
        break
      case EDIT_LAW_RESPONSE:
        draft.lawApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case GET_LAW_STATUS_BY_ACTIVE_DEACTIVE_RESPONSE:
        draft.getLawstatusApiResponse = {
          data: action.payload.data,
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      case GET_LAW_SECTION_LIST_RESPONSE: {
        draft.lawSectionList = action.payload.data
        break
      }
      case ADD_LAW_REVISION_RESPONSE: {
        draft.lawRevision = action.payload
        break
      }

      default:
        state = { ...state }
    }
  })
