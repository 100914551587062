export const UserInitialState = () => {
  return {
    email: '',
    password: '',
  }
}

export const createTamplateInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    details: value.details,
    subject: value.subject,
    templateName: value.templateName,
  }
}

export const config = {
  buttons: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'ul',
    'ol',
    '|',
    'center',
    'left',
    'right',
    'justify',
    '|',
    'link',
  ],
  uploader: { insertImageAsBase64URI: true },
  removeButtons: ['brush', 'file'],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
}
