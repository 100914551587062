import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { GET_AUDITLOG_REQUEST, GET_AUTHENTICATION_LOGS_REQUEST } from './actionType'
import { invokeApi } from '../../helpers/axiosHelper'
import { handleApiCallException } from 'store/shared/action'
import { getAuditLogResponse, getAuthenticationLogsResponse } from './action'

function* auditLogListList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/audit-trail/view', data)

    if (response.status) {
      yield put(getAuditLogResponse(response.data))
    } else {
      yield put(getAuditLogResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* authenticationLogsList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/authenticationLogs', data)

    if (response.status) {
      yield put(getAuthenticationLogsResponse(response.data))
    } else {
      yield put(getAuthenticationLogsResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchAuditLog() {
  yield takeEvery(GET_AUDITLOG_REQUEST, auditLogListList)
  yield takeEvery(GET_AUTHENTICATION_LOGS_REQUEST, authenticationLogsList)

}

function* auditLogSaga() {
  yield all([fork(watchAuditLog)])
}
export default auditLogSaga
