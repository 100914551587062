import {
  CREATE_ARTICAL_REQUEST,
  CREATE_ARTICAL_RESPONSE,
  CREATE_ARTICLE_REVIEW_REQUEST,
  CREATE_ARTICLE_REVIEW_RESPONSE,
  CREATE_REVIEW_LAW_REQUEST,
  CREATE_REVIEW_LAW_RESPONSE,
  GET_ARTICAL_HISTORY_REQUEST,
  GET_ARTICAL_HISTORY_RESPONSE,
  GET_ARTICAL_DETAILS_REQUEST,
  GET_ARTICAL_DETAILS_RESPONSE,
  GET_LIST_ARTICAL_REQUEST,
  GET_LIST_ARTICAL_RESPONSE,
  GET_LIST_KEYWORDS_REQUEST,
  GET_LIST_KEYWORDS_RESPONSE,
} from './actionType'

export const createArticleRequest = (data) => {
  return {
    type: CREATE_ARTICAL_REQUEST,
    payload: { data },
  }
}

export const createArticleResponse = (message, responseType) => {
  return {
    type: CREATE_ARTICAL_RESPONSE,
    payload: { message, responseType },
  }
}

export const getListArticleRequest = (data) => {
  return {
    type: GET_LIST_ARTICAL_REQUEST,
    payload: { data },
  }
}
export const getListArticleResponse = (data) => {
  return {
    type: GET_LIST_ARTICAL_RESPONSE,
    payload: { data },
  }
}
export const getListKeywordsRequest = () => {
  return {
    type: GET_LIST_KEYWORDS_REQUEST,
    // payload: { },
  }
}
export const getListKeywordsResponse = (data) => {
  return {
    type: GET_LIST_KEYWORDS_RESPONSE,
    payload: { data },
  }
}
export const getArticleDetailsRequest = (data) => {
  return {
    type: GET_ARTICAL_DETAILS_REQUEST,
    payload: { data },
  }
}
export const getArticleDetailsResponse = (data) => {
  return {
    type: GET_ARTICAL_DETAILS_RESPONSE,
    payload: { data },
  }
}

export const getArticleHistoryRequest = (id) => {
  return {
    type: GET_ARTICAL_HISTORY_REQUEST,
    payload: { id },
  }
}
export const getArticleHistoryResponse = (data) => {
  return {
    type: GET_ARTICAL_HISTORY_RESPONSE,
    payload: { data },
  }
}

export const createArticleReviewRequest = (data) => {
  return {
    type: CREATE_ARTICLE_REVIEW_REQUEST,
    payload: { data },
  }
}

export const createArticleReviewResponse = (message, responseType) => {
  return {
    type: CREATE_ARTICLE_REVIEW_RESPONSE,
    payload: { message, responseType },
  }
}

export const createReviewLawRequest = (data) => {
  return {
    type: CREATE_REVIEW_LAW_REQUEST,
    payload: { data },
  }
}

export const createreReviewLawResponse = (message, responseType) => {
  return {
    type: CREATE_REVIEW_LAW_RESPONSE,
    payload: { message, responseType },
  }
}
