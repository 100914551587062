export const popularFaqList = [
    {
        "id": "1",
        "question": "Which category of Visa will be granted to family members of foreign nationals coming to India for employment? How long it is valid?",
        "answer": "Family members of foreign nationals coming to India for employment will be eligible for ‘X’ visa subject to the standard security checks and provided the family members are otherwise eligible for the grant of such a visa. The validity of such visa will be concurrent with that of the principal visa holder [or for a shorter period as determined by the Indian Mission].<br> [The Passport (Entry into India) Act, 1920 read with the Passport (Entry into India) Rules, 1950]"
    },
    {
        "id": "2",
        "question": "Are the designated partners of LLP required to be resident Indian individuals?",
        "answer": "Answer: LLP must have atleast two designated partners who are individuals and one of them shall be a resident individual. <br> [Section 7 of the Limited Liability Partnership Act, 2008]"
    },
    {
        "id": "3",
        "question": "Are there any thin-capitalisation rules applicable on interest payments?",
        "answer": "Where an Indian company has raised debt from its associated enterprises overseas and the interest payment in a financial year exceeds Rs. 10 million, then thin capitalisation rules become applicable. The interest deduction in such case is restricted to 30% of the EBIDTA (Earning before Interest, depreciation, tax, and amortisation) of the previous year or interest payable to the non-resident associated enterprise for that previous year, whichever is less. In case interest payment is not fully deducted due to the limitation, then such excess can be carried forward and set off against future profits, subject to the limit, up to 8 years. <br> [Section 94B of the Income-tax Act, 1961]"
    },
    {
        "id": "4",
        "question": "Is government or regulatory approval required for non-residents, who want to set up a private limited company in India? Is this dependent on the sector in which the company will operate?",
        "answer": "As per the Foreign exchange regulations, under the Automatic Route (applicable in most sectors), the foreign investor does not require any approval from the RBI / Government of India for the proposed investment in India. However, if the sector fall under the Government Route such as Air Transport services beyond 49%, publishing of scientific and technical magazines, print media etc., a prior approval of the RBI / GoI shall be required.<br> Further, there is a requirement to appoint at least 1 Resident Director on the Board of an Indian Company.<br> [Section 149(3) of the Companies Act, 2013 and Master Direction – Foreign Investment in India]"
    }

]


