import { getLawListRequest } from 'store/master/law/action'
import { LawListFilter } from '../../pages/masterPages/Law/helper'

import React, { useEffect } from 'react'
import Select from 'react-select'
import { Formik, Form, Field } from 'formik'
import { Button, FormGroup, Label, Row } from 'reactstrap'
import { connect } from 'react-redux'

const SectionSelectorFormik = (props) => {
  useEffect(() => {
    props.getLawListRequest(LawListFilter)
  }, [])

  return (
    <Formik initialValues={{ lawId: '' }}>
      {({ values }) => (
        <>
          <Form className="form-horizontal form-login">
            <Row className="mt-3">
              <FormGroup className="col mb-0">
                <Field
                  component={Select}
                  menuPlacement="auto"
                  name="lawId"
                  id="lawId"
                  className="form-control selectbox"
                  options={props?.lawList}
                  onChange={(value) => {
                    props.getListBySectionIdFilter(value)
                  }}
                />
                <Label className="form-label" htmlFor="sectionName">
                  Select Law
                </Label>
              </FormGroup>
            </Row>
            <Button
              className="my-tooltip cancel_filter"
              data-tooltip-content={'Close filter'}
              color="link"
              onClick={() => {
                props.cancelFilterSection(true)
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L13 13"
                  stroke="CurrentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </Form>
        </>
      )}
    </Formik>
  )
}

//redux for addcountry and countrylist
const mapStateToProps = ({ lawReducer }) => {
  let lawList = []
  if (lawReducer.lawList.list && lawReducer.lawList.list?.length > 0) {
    lawList = lawReducer.lawList.list.map((opt) => ({
      value: opt.id,
      label: opt.lawName,
    }))
  }
  return {
    lawList: lawList,
  }
}

//country redux for addCountry
const mapDispatchToProps = (dispatch) => {
  return {
    getLawListRequest: (data) => {
      dispatch(getLawListRequest(data))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionSelectorFormik)
