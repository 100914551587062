import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Field, Formik } from 'formik'

import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import {
  cleareAdminResendListResponse,
  getQueryListRequest,
} from 'store/tickets/action'
import { getDateTimesSlach } from 'helpers/DataTimeHelpers'
import { getInitials, randomColor } from 'helpers/generalUtils'

const HelpSupport = (props) => {
  const loader = useRef(null)
  const [page, setPage] = useState(0)
  const [IsListView, setIsListView] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [filterType, setFilterType] = useState('')
  const [subscriberQueryList, setSubscriberQueryList] = useState([])

  const navigate = useNavigate()

  const handleObserver = useCallback((entries) => {
    const target = entries[0]
    if (target.isIntersecting) {
      setPage((prev) => prev + 1)
    }
  }, [])

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    }
    const observer = new IntersectionObserver(handleObserver, option)
    if (loader.current) observer.observe(loader.current)
  }, [handleObserver])
  const getSearchRequest = (newSearch = false) => {
    let SearchRequest = {
      offset: newSearch ? 0 : props.subscriberQueryList?.length,
      filterType: filterType,
    }
    if (searchText.trim().length > 0) {
      SearchRequest = { ...SearchRequest, searchText: searchText }
    }
    return SearchRequest
  }
  useEffect(() => {
    props.getQueryList(getSearchRequest(false))
  }, [page, filterType])

  const filterText = (value) => {
    const text =
      value?.reportSectionName ||
      value?.question ||
      value?.reportSubSectionName ||
      value?.reportTitle

    return text
  }

  const onReportSearch = () => {
    props.clearReportList()
    props.getQueryList(getSearchRequest(true))
  }

  const onReportFilter = (values) => {
    if (filterType !== values) {
      props.clearReportList()
    }
    setFilterType(values)
  }
  useEffect(() => {
    return () => {
      props.clearReportList()
      setPage(0)
    }
  }, [])

  useEffect(() => {
    const sortedData = [...props?.subscriberQueryList].map((item) => ({
      ...item,
      createdDate: new Date(item.createdDate),
    }))
    sortedData.sort((a, b) => b.createdDate - a.createdDate)
    setSubscriberQueryList(sortedData)
  }, [props.subscriberQueryList])
  return (
    <Formik>
      <div className="container help_support">
        <div className="section-head">
          <div>
            <h3>Help & Support</h3>
          </div>

          <div className="header-action-item">
            <div className="search_box">
              <Button
                color="link"
                className=" p-0"
                // data-tooltip-content={'Search'}
                onClick={onReportSearch}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
                </svg>
              </Button>

              <Field
                type="search"
                placeholder="Search box"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                  if (e.target.value?.length <= 0) {
                    //onReportFilter('')
                    props.clearReportList()
                    setPage(0)
                    props.getQueryList({
                      offset: 0,
                      searchText: '',
                      filterType: filterType,
                    })
                  }
                }}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter' && !evt.shiftKey) {
                    onReportSearch()
                  }
                }}
              />
            </div>

            <ul className="card-tabs-custom">
              <li className="card-tab-item">
                <a
                  className={
                    IsListView
                      ? 'card-tab-link my-tooltip'
                      : 'card-tab-link active my-tooltip'
                  }
                  data-tooltip-content={'Card'}
                  onClick={() => setIsListView(false)}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1"
                      y="1"
                      width="10.5"
                      height="10.5"
                      rx="1"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="1"
                      y="18.5"
                      width="10.5"
                      height="10.5"
                      rx="1"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="18.5"
                      y="18.5"
                      width="10.5"
                      height="10.5"
                      rx="1"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="18.5"
                      y="1"
                      width="10.5"
                      height="10.5"
                      rx="1"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </li>
              <li className="card-tab-item">
                <a
                  className={
                    IsListView
                      ? 'card-tab-link active my-tooltip'
                      : 'card-tab-link my-tooltip'
                  }
                  data-tooltip-content={'List'}
                  onClick={() => setIsListView(true)}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2H23"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <path
                      d="M2 12H16.9032"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                    <path
                      d="M2 22H23"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <ul className="reports_tab request_tab nav nav-tabs">
          <li className="nav-item">
            <button
              className={'nav-link ' + (filterType ? '' : 'active')}
              onClick={() => {
                onReportFilter('')
              }}
            >
              All Tickets
            </button>
          </li>
          <li className="nav-item">
            <button
              className={
                'nav-link ' + (filterType === 'Pending' ? 'active ' : '')
              }
              onClick={() => {
                onReportFilter('Pending')
              }}
            >
              Pending
              {/* <span className="badge">0</span> */}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={
                'nav-link ' + (filterType === 'Respond' ? 'active ' : '')
              }
              onClick={() => {
                onReportFilter('Respond')
              }}
            >
              Replied
              {/* <span className="badge">0</span> */}
            </button>
          </li>
        </ul>
        <div
          className={
            IsListView
              ? 'help_support_cards help_support_list tab-content report_tab-content request_tab-content'
              : 'help_support_cards tab-content report_tab-content request_tab-content'
          }
        >
          <div>
            {subscriberQueryList?.map((x, index) => {
              return (
                <div
                  key={index}
                  className="help_support_detail d-flex flex-column justify-content-between"
                  onClick={() =>
                    navigate(
                      '/console/help/ticketsDetails/' + x.subscriberQueryId,
                      {
                        state: {
                          reportTitle: x.reportTitle,
                          ticketId: x?.ticketId,
                          createdDate: x.createdDate
                            ? getDateTimesSlach(x.createdDate)
                            : '',
                          sectionTitle: filterText(x),
                          createdBy: x?.createdBy ? x?.createdBy : '',
                        },
                      },
                    )
                  }
                >
                  <div>
                    <div className="">
                      <div className="first_name_td d-flex justify-content-between">
                        <span
                          className="short-name"
                          style={{ background: randomColor() }}
                        >
                          {getInitials(x?.name)}
                        </span>
                        <div>
                          <h6>{x?.name}</h6>
                          <p>{x?.email}</p>
                        </div>
                      </div>
                      <span className="outline-badge">{x?.ticketId}</span>
                    </div>
                    <p>{filterText(x)}</p>
                  </div>
                  <div className="status-box">
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <div className="date-notification-box">
                        {x?.isReview == 1 && (
                          <span className="help-support-notification">
                            <svg
                              width="31"
                              height="34"
                              viewBox="0 0 31 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.9705 0.875C13.4019 0.875 11.2946 2.98233 11.2946 5.55092C11.2946 5.55326 11.2946 5.55559 11.2946 5.55793C11.2992 6.34896 11.5029 7.12662 11.8905 7.81826C11.9654 7.95232 12.066 8.0703 12.1865 8.16546C12.3071 8.26063 12.4452 8.33111 12.593 8.37288C12.7408 8.41465 12.8953 8.42689 13.0478 8.40891C13.2003 8.39092 13.3478 8.34306 13.4818 8.26805C13.6159 8.19313 13.7339 8.09253 13.8291 7.97199C13.9242 7.85146 13.9947 7.71335 14.0365 7.56556C14.0782 7.41778 14.0905 7.26321 14.0725 7.1107C14.0545 6.95818 14.0066 6.81069 13.9316 6.67668C13.7389 6.33279 13.6356 5.94469 13.6325 5.54881C13.6337 4.24483 14.6662 3.21312 15.9705 3.21312C17.2748 3.21312 18.3072 4.24483 18.3084 5.54881C18.3049 5.94063 18.2045 6.32519 18.0139 6.66756C17.8633 6.93827 17.8264 7.25768 17.9111 7.55564C17.9959 7.85359 18.1955 8.10571 18.466 8.25663C18.7367 8.40721 19.0561 8.44417 19.3541 8.35941C19.652 8.27465 19.9041 8.07509 20.0551 7.80456C20.4377 7.11732 20.6416 6.3445 20.6464 5.55793C20.6464 5.55559 20.6464 5.55326 20.6464 5.55092C20.6464 2.98233 18.5391 0.875 15.9705 0.875Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.9705 5.5509C10.1739 5.5509 5.44969 10.2751 5.44969 16.0717V23.0856C3.52707 23.0856 1.94275 24.6699 1.94275 26.5925C1.94275 28.5152 3.52707 30.0995 5.44969 30.0995H26.4913C28.414 30.0995 29.9983 28.5152 29.9983 26.5925C29.9983 24.6699 28.414 23.0856 26.4913 23.0856V16.0717C26.4913 10.2751 21.7672 5.5509 15.9705 5.5509ZM15.9705 7.88886C20.5036 7.88886 24.1534 11.5386 24.1534 16.0717V24.2546C24.1534 24.5646 24.2766 24.8619 24.4958 25.0811C24.715 25.3004 25.0123 25.4235 25.3223 25.4236H26.4913C27.1591 25.4236 27.6603 25.9247 27.6603 26.5925C27.6603 27.2603 27.1591 27.7615 26.4913 27.7615H5.44969C4.78189 27.7615 4.28071 27.2603 4.28071 26.5925C4.28071 25.9247 4.78189 25.4236 5.44969 25.4236H6.61867C6.92869 25.4235 7.22599 25.3004 7.44521 25.0811C7.66443 24.8619 7.78762 24.5646 7.78765 24.2546V16.0717C7.78765 11.5386 11.4374 7.88886 15.9705 7.88886Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.1256 31.2684C9.81561 31.2684 9.51829 31.3916 9.29907 31.6108C9.07984 31.83 8.95667 32.1274 8.95667 32.4374C8.95667 32.7474 9.07984 33.0448 9.29907 33.264C9.51829 33.4832 9.81561 33.6064 10.1256 33.6064H21.8154C22.1255 33.6064 22.4228 33.4832 22.642 33.264C22.8612 33.0448 22.9844 32.7474 22.9844 32.4374C22.9844 32.1274 22.8612 31.83 22.642 31.6108C22.4228 31.3916 22.1255 31.2684 21.8154 31.2684H10.1256Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M25.9913 4.68787C25.8762 4.58616 25.7422 4.50814 25.5969 4.45826C25.4516 4.40837 25.2979 4.38761 25.1446 4.39716C24.9913 4.4067 24.8414 4.44636 24.7035 4.51387C24.5655 4.58139 24.4422 4.67542 24.3406 4.79062C24.2389 4.90572 24.1609 5.03974 24.111 5.18502C24.0611 5.33029 24.0403 5.48398 24.0499 5.63728C24.0594 5.79058 24.0991 5.94051 24.1666 6.07847C24.2341 6.21644 24.3281 6.33974 24.4433 6.44135C26.6158 8.35784 28.1455 10.9845 28.6489 13.9347C28.6746 14.0861 28.7299 14.231 28.8117 14.3611C28.8934 14.4912 29 14.6038 29.1253 14.6927C29.2506 14.7815 29.3922 14.8448 29.542 14.8789C29.6918 14.913 29.8468 14.9172 29.9983 14.8913C30.3037 14.8389 30.5758 14.6673 30.7548 14.4142C30.9338 14.1612 31.0049 13.8475 30.9526 13.542C30.356 10.0457 28.5506 6.94561 25.9913 4.68787Z"
                                fill="currentColor"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.80812 4.38881C6.6548 4.37911 6.50108 4.39972 6.35573 4.44948C6.21039 4.49923 6.07628 4.57715 5.96107 4.67877C3.39547 6.93753 1.58303 10.0422 0.986054 13.5443C0.960184 13.6957 0.964418 13.8508 0.998504 14.0005C1.03259 14.1503 1.09584 14.2919 1.18469 14.4172C1.27354 14.5426 1.38622 14.6491 1.51628 14.7309C1.64634 14.8126 1.79125 14.8679 1.9427 14.8936C2.09412 14.9195 2.24915 14.9153 2.39894 14.8812C2.54873 14.8471 2.69034 14.7838 2.81565 14.695C2.94097 14.6061 3.04756 14.4935 3.1293 14.3634C3.21105 14.2334 3.26634 14.0884 3.29205 13.937C3.79579 10.9819 5.32681 8.35177 7.50449 6.43452C7.61985 6.33308 7.71406 6.2099 7.78178 6.07202C7.84951 5.93413 7.88939 5.78424 7.89915 5.63094C7.90891 5.47763 7.88838 5.32391 7.83869 5.17855C7.789 5.03319 7.71115 4.89905 7.60958 4.78379C7.40529 4.55121 7.11702 4.40916 6.80812 4.38881Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        )}
                        <div className="ticket-date d-flex justify-content-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="4"
                              width="18"
                              height="15"
                              rx="2"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              d="M1 8C1 6.11438 1 5.17157 1.58579 4.58579C2.17157 4 3.11438 4 5 4H15C16.8856 4 17.8284 4 18.4142 4.58579C19 5.17157 19 6.11438 19 8H1Z"
                              fill="currentColor"
                            />
                            <path
                              d="M5 1L5 4"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M15 1L15 4"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <rect
                              x="5"
                              y="10"
                              width="4"
                              height="2"
                              rx="0.5"
                              fill="currentColor"
                            />
                            <rect
                              x="5"
                              y="14"
                              width="4"
                              height="2"
                              rx="0.5"
                              fill="currentColor"
                            />
                            <rect
                              x="11"
                              y="10"
                              width="4"
                              height="2"
                              rx="0.5"
                              fill="currentColor"
                            />
                            <rect
                              x="11"
                              y="14"
                              width="4"
                              height="2"
                              rx="0.5"
                              fill="currentColor"
                            />
                          </svg>
                          {x?.createdDate
                            ? getDateTimesSlach(x?.createdDate)
                            : ''}
                        </div>
                      </div>

                      {/* ======User "badge-success" for Replied item and "badge-primary" for Pending item======== */}
                      <span
                        className={
                          x?.status === 'Pending'
                            ? 'badge badge-pill badge-primary'
                            : 'badge badge-pill badge-success'
                        }
                      >
                        {x?.status}
                      </span>
                    </div>
                    {/* <h5 className="bg-primary">
                      <b>Responded by:</b> Winston Russel
                    </h5> */}
                  </div>
                </div>
              )
            })}
            <div ref={loader} />

            {(subscriberQueryList == undefined ||
              subscriberQueryList?.length <= 0) && (
                <div className="no-data-found">
                  <svg
                    width="96"
                    height="114"
                    viewBox="0 0 96 114"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                      fill="#4F2D7F"
                      fillOpacity="0.12"
                    />
                    <circle
                      cx="57.5592"
                      cy="67.2599"
                      r="27.36"
                      fill="#4F2D7F"
                      fillOpacity="0.3"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                    />
                    <path
                      d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M92.8968 102.6L79.2168 88.9204"
                      stroke="#4F2D7F"
                      strokeWidth="5.2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <h1>No Tickets Found</h1>
                </div>
              )}
          </div>
        </div>
      </div>
    </Formik>
  )
}

const mapStateToProps = ({ ticketReducer }) => {
  return {
    subscriberQueryList: ticketReducer.subscriberQueryList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQueryList: (data) => {
      dispatch(getQueryListRequest(data))
    },
    clearReportList: () => {
      dispatch(cleareAdminResendListResponse())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport)
