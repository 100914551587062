import produce from 'immer'
import { GET_AUDITLOG_RESPONSE, GET_AUTHENTICATION_LOGS_RESPONSE } from './actionType'

export const initialState = {
  auditLogListList: null,
  authenticationLogsList: null
}

export const AuditLogReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_AUDITLOG_RESPONSE:
        draft.auditLogListList = action.payload.data
        break
      case GET_AUTHENTICATION_LOGS_RESPONSE:
        draft.authenticationLogsList = action.payload.data
        break
      default:
        state = { ...state }
        break
    }
  })
