import { GET_AUDITLOG_REQUEST, GET_AUDITLOG_RESPONSE, GET_AUTHENTICATION_LOGS_REQUEST, GET_AUTHENTICATION_LOGS_RESPONSE } from './actionType'

export const getAuditLogListRequest = (data) => {
  return {
    type: GET_AUDITLOG_REQUEST,
    payload: { data },
  }
}

export const getAuditLogResponse = (data) => {
  return {
    type: GET_AUDITLOG_RESPONSE,
    payload: { data },
  }
}


export const getAuthenticationLogsRequest = (data) => {
  return {
    type: GET_AUTHENTICATION_LOGS_REQUEST,
    payload: { data },
  }
}

export const getAuthenticationLogsResponse = (data) => {
  return {
    type: GET_AUTHENTICATION_LOGS_RESPONSE,
    payload: { data },
  }
}
