import {
  GET_TEMPLATE_LIST_REQUEST,
  GET_TEMPLATE_LIST_RESPONSE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_RESPONSE,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_RESPONSE,
  GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE,
  GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST,
  GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST,
  GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE,
  UPDATE_EMAIL_TEMPLATE_RESPONSE,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
  UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE,
} from '../emailTemplate/actionType'

// create Template

export const createTemplateRequest = (data) => {
  return {
    type: CREATE_TEMPLATE_REQUEST,
    payload: { data },
  }
}
export const createTemplateResponse = (data) => {
  return {
    type: CREATE_TEMPLATE_RESPONSE,
    payload: { data },
  }
}

// update Template

export const updateTemplateRequest = (data) => {
  return {
    type: UPDATE_TEMPLATE_REQUEST,
    payload: { data },
  }
}
export const updateTemplateResponse = (updateRole) => {
  return {
    type: UPDATE_TEMPLATE_RESPONSE,
    payload: { updateRole },
  }
}

// get  Template List

export const getTemplateListRequest = () => {
  return {
    type: GET_TEMPLATE_LIST_REQUEST,
  }
}

export const getTemplateListResponse = (data) => {
  return {
    type: GET_TEMPLATE_LIST_RESPONSE,
    payload: { data },
  }
}

// get  custom emails

export const getCustomEmailFieldListRequest = () => {
  return {
    type: GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST,
  }
}

export const getCustomEmailFieldListResponse = (data) => {
  return {
    type: GET_CUSTOM_EMAIL_FIELD_LIST_RESPONSE,
    payload: { data },
  }
}

// get  Template List By id

export const getTemplateDetailsListByIdRequest = (Data) => {
  return {
    type: GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST,
    payload: { Data },
  }
}

export const getTemplateDetailsListByIdResponse = (data) => {
  return {
    type: GET_TEMPLATE_DETAILS_LIST_BY_ID_RESPONSE,
    payload: { data },
  }
}

export const updateEmailTemplateRequest = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_REQUEST,
    payload: { data },
  }
}
export const updateEmailTemplateResponse = (message, responseType) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_RESPONSE,
    payload: { message, responseType },
  }
}

export const updateEmailTemplateStatusRequest = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
    payload: { data },
  }
}
export const updateEmailTemplateStatusResponse = (message, responseType) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE,
    payload: { message, responseType },
  }
}
