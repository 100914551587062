export const CREATE_CONSULTATION_REQUEST = 'CREATE_CONSULTATION_REQUEST'
export const CREATE_CONSULTATION_RESPONSE = 'CREATE_CONSULTATION_RESPONSE'

export const GET_LIST_CONSULTATION_REQUEST = 'GET_LIST_CONSULTATION_REQUEST'
export const GET_LIST_CONSULTATION_RESPONSE = 'GET_LIST_CONSULTATION_RESPONSE'

export const GET_LIST_KEYWORDS_REQUEST = 'GET_LIST_KEYWORDS_REQUEST'
export const GET_LIST_KEYWORDS_RESPONSE = 'GET_LIST_KEYWORDS_RESPONSE'

export const GET_CONSULTATION_DETAILS_REQUEST =
  'GET_CONSULTATION_DETAILS_REQUEST'
export const GET_CONSULTATION_DETAILS_RESPONSE =
  'GET_CONSULTATION_DETAILS_RESPONSE'

export const GET_CONSULTATION_HISTORY_REQUEST =
  'GET_CONSULTATION_HISTORY_REQUEST'
export const GET_CONSULTATION_HISTORY_RESPONSE =
  'GET_CONSULTATION_HISTORY_RESPONSE'

export const CREATE_CONSULTATION_REVIEW_REQUEST =
  'CREATE_CONSULTATION_REVIEW_REQUEST'
export const CREATE_CONSULTATION_REVIEW_RESPONSE =
  'CREATE_CONSULTATION_REVIEW_RESPONSE'

export const CREATE_REVIEW_LAW_REQUEST = 'CREATE_REVIEW_LAW_REQUEST'
export const CREATE_REVIEW_LAW_RESPONSE = 'CREATE_REVIEW_LAW_RESPONSE'

export const DELETE_CONSULTATION_DOCUMENT_REQUEST =
  'DELETE_CONSULTATION_DOCUMENT_REQUEST'
export const DELETE_CONSULTATION_DOCUMENT_RESPONSE =
  'DELETE_CONSULTATION_DOCUMENT_RESPONSE'

export const GET_TEAM_LIST_REQUEST = 'GET_TEAM_LIST_REQUEST'
export const GET_TEAM_LIST_RESPONSE = 'GET_TEAM_LIST_RESPONSE'

export const CREATE_TEAM_DELEGATE_QUERY_REQUEST =
  'CREATE_TEAM_DELEGATE_QUERY_REQUEST'
export const CREATE_TEAM_DELEGATE_QUERY_RESPONSE =
  'CREATE_TEAM_DELEGATE_QUERY_RESPONSE'

export const CLEAR_CONSULTATION_LIST_RESPONSE =
  'CLEAR_CONSULTATION_LIST_RESPONSE'

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST'
export const CREATE_DOCUMENT_RESPONSE = 'CREATE_DOCUMENT_RESPONSE'
export const CLEAR_DOCUMENT_RESPONSE = 'CLEAR_DOCUMENT_RESPONSE'

export const QUERY_RESPOND_REQUEST = 'QUERY_RESPOND_REQUEST'
export const QUERY_RESPOND_RESPONSE = 'QUERY_RESPOND_RESPONSE'

export const HIDE_NOTIFICATION_REQUEST = 'HIDE_NOTIFICATION_REQUEST'
export const HIDE_NOTIFICATION_RESPONSE = 'HIDE_NOTIFICATION_RESPONSE'

export const CLEAR_TEAM_DELEGATE_QUERY_RESPONSE =
  'CLEAR_TEAM_DELEGATE_QUERY_RESPONSE'
