export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_RESPONSE = 'FORGET_PASSWORD_RESPONSE'

export const CHECK_RESET_PASSWORD_TOKE_REQUEST =
  'CHECK_RESET_PASSWORD_TOKE_REQUEST'
export const CHECK_RESET_PASSWORD_TOKE_RESPONSE =
  'CHECK_RESET_PASSWORD_TOKE_RESPONSE'

export const RESET_PASSWORD_API_REQUEST = 'RESET_PASSWORD_API_REQUEST'
export const RESET_PASSWORD_API_RESPONSE = 'RESET_PASSWORD_API_RESPONSE'

export const RESET_PASSWORD_BY_EMAIL_API_REQUEST =
  'RESET_PASSWORD_BY_EMAIL_API_REQUEST'
export const RESET_PASSWORD_BY_EMAIL_API_RESPONSE =
  'RESET_PASSWORD_BY_EMAIL_API_RESPONSE'
