import {
  FORGET_PASSWORD_RESPONSE,
  CHECK_RESET_PASSWORD_TOKE_RESPONSE,
  RESET_PASSWORD_API_RESPONSE,
  RESET_PASSWORD_BY_EMAIL_API_RESPONSE,
} from './actionType'
import produce from 'immer'

export const initialState = {
  ApiResponse: null,
  tokenIsValid: null,
  resetPasswordApiRsponse: null,
  resetPasswordByEmailApiRsponse: null,
}

export const ForgetPasswordReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FORGET_PASSWORD_RESPONSE: {
        draft.ApiResponse = {
          message: action.payload.message,
          responseType: action.payload.responseType,
        }
        break
      }
      case CHECK_RESET_PASSWORD_TOKE_RESPONSE: {
        draft.tokenIsValid = {
          message: action.payload.message,
          isValid: action.payload.isValid,
          responseType: action.payload.responseType,
        }
        break
      }

      case RESET_PASSWORD_API_RESPONSE: {
        draft.resetPasswordApiRsponse = {
          message: action.payload.message,
          status: action.payload.status,
        }
        break
      }

      case RESET_PASSWORD_BY_EMAIL_API_RESPONSE: {
        draft.resetPasswordByEmailApiRsponse = {
          message: action.payload.message,
          status: action.payload.status,
        }
        break
      }

      default:
        state = { ...state }
        break
    }
  })
