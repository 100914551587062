import { Field } from 'formik'
import CustomPagination from './CustomPagination'
import React, { useState } from 'react'

import {
  Card,
  CardBody,
  Progress,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
} from 'reactstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { faqStatus, statusLableChanges } from 'helpers/generalUtils'
import {
  APPROVED,
  CLIENT_REPORT,
  DRAFTED,
  INTERNAL,
  PENDING,
  PENDING_FOR_PUBLISHER,
  PUBLISHED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'
import LawSelectorFormik from 'components/Selector/LawSelectorFormik'
import AuditLogModal from 'pages/AuditLog/AuditLogModal'
import { getValueByKey } from 'helpers/authUtils'
import { formatDateForApi, getDateTimesSlach } from 'helpers/DataTimeHelpers'
import { getFirstLetters } from 'pages/ConsultationRequest/CommonFunction'

const ReportTable = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isActiveTab, setIsActiveTab] = useState(
    location.state?.isActiveTab ? location.state?.isActiveTab : 'All',
  )
  const getPermissions = useOutletContext()
  const permissions = getPermissions ? getPermissions[0] : 0
  const [customFilter, setCustomFilter] = useState(false)
  const [isAuditModalOpen, setIsAuditModalOpen] = useState(false)
  const [auditId, setAuditId] = useState('')
  const [userId] = useState(JSON.parse(getValueByKey('userId')))
  const {
    hedingName,
    showHeding = true,
    buttonName = 'Create',
    totalRecords,
    updateData,
    data,
    action,
    dataPerPage,
    currentPage,
    setCurrentPage,
    setDataPerPage,
    currentActiveTab,
    getViewDetailPageData,
    getCurrentActiveFilter,
  } = props
  const [search, setSearch] = useState('')
  const paginate = (pageNumber, paginateForDataPerPage) => {
    const Data = {
      type: isActiveTab,
      searchText: '',
      pagination: {
        limit: paginateForDataPerPage || 10,
        page: pageNumber,
        orderKey: 'createdDate',
        orderBy: 'ASC',
      },
      filter: {},
    }
    updateData(Data)
    setDataPerPage(dataPerPage)
    setCurrentPage(pageNumber)
  }
  const closeAuditLogModal = () => {
    setIsAuditModalOpen(false)
    setAuditId('')
  }

  const openAuditLogModal = (id, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    setIsAuditModalOpen(true)
    setAuditId(id)
  }

  const getUserName = (row) => {
    let userList = []
    if (
      (permissions?.approve == 1 || permissions?.publish == 1) &&
      permissions?.add != 1
    ) {
      const approvalUsers = row.approvalWorkFlow?.approvalDetails?.filter(
        (x) =>
          x?.userId == userId &&
          x?.approvalWorkFlowId == row?.approvalWorkFlowId,
      )
      userList = approvalUsers
    } else {
      const approvalUsers = row.approvalWorkFlow?.approvalDetails?.filter(
        (x) => x?.approvalWorkFlowId == row?.approvalWorkFlowId,
      )
      userList = approvalUsers
    }
    return userList
  }
  let status = ''

  const filterWiseReport = (type) => {
    setIsActiveTab(type)
    // updateData({
    //   type: type,
    //   searchText: search.length > 0 ? search : '',
    //   pagination: {
    //     limit: dataPerPage,
    //     page: 0,
    //     orderKey: 'createdDate',
    //     orderBy: 'ASC',
    //   },
    //   type: isActiveTab,

    //   filter: {},
    // })
  }
  const isAdmin = () => {
    let isUserAdmin =
      permissions.add &&
        permissions.update &&
        permissions.approve &&
        permissions.view &&
        permissions.publish &&
        permissions.delete
        ? true
        : false

    return isUserAdmin
  }
  return (
    <div className="container">
      <div className="section-head">
        {showHeding && (
          <div>
            <h3>{hedingName}</h3>
          </div>
        )}
        <div className="header-action-item">
          {buttonName && (
            <Button
              className="creat_btn"
              onClick={() =>
                action({
                  action: 'save',
                  id: '',
                })
              }
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 2L11 20"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M20 11L2 11"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
              {buttonName}
            </Button>
          )}

          <div className="search_box">
            <Button
              className=" p-0"
              // data-tooltip-content={'Search'}
              color="link"
              onClick={() => {
                if (search?.length > 0) {
                  setCurrentPage(1)
                }
                updateData({
                  searchText: search.length > 0 ? search : '',
                  pagination: {
                    limit: dataPerPage,
                    page: 0,
                    orderKey: 'createdDate',
                    orderBy: 'ASC',
                  },
                  type: isActiveTab,

                  filter: {},
                })
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M27.6 25.8L22 20.2C23.3 18.5 24.1 16.4 24.1 14.1C24.1 8.59998 19.6 4.09998 14.1 4.09998C8.6 4.09998 4 8.59998 4 14.1C4 19.6 8.5 24.1 14 24.1C16.3 24.1 18.5 23.3 20.2 21.9L25.8 27.5C26 27.7 26.4 27.9 26.7 27.9C27 27.9 27.3 27.8 27.6 27.5C28.1 27.1 28.1 26.3 27.6 25.8ZM6.5 14.1C6.5 9.99998 9.9 6.59998 14 6.59998C18.1 6.59998 21.5 9.99998 21.5 14.1C21.5 18.2 18.1 21.6 14 21.6C9.9 21.6 6.5 18.3 6.5 14.1Z" />
              </svg>
            </Button>
            <Field
              name="searchbox"
              type="search"
              onChange={(e) => {
                setSearch(e.target.value)
                if (e.target.value?.length <= 0) {
                  updateData({
                    searchText: '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },
                    type: isActiveTab,

                    filter: {},
                  })
                }
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter' && !evt.shiftKey) {
                  if (search?.length > 0) {
                    setCurrentPage(1)
                  }
                  updateData({
                    searchText: search.length > 0 ? search : '',
                    pagination: {
                      limit: dataPerPage,
                      page: 0,
                      orderKey: 'createdDate',
                      orderBy: 'ASC',
                    },
                    type: isActiveTab,

                    filter: {},
                  })
                }
              }}
              placeholder="Search box"
              value={search}
            />
          </div>

          <div>
            <Dropdown
              className="my-tooltip filter_btn"
              data-tooltip-content={'Apply Filter'}
              onClick={() => {
                setCustomFilter(true)
              }}
            >
              <Dropdown.Toggle id="dropdown-basic">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.66669"
                    y="4"
                    width="26.6667"
                    height="12"
                    rx="2"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <path
                    d="M21.3334 24V24C21.3334 24.62 21.3334 24.93 21.2652 25.1843C21.0803 25.8745 20.5412 26.4136 19.851 26.5985C19.5967 26.6667 19.2867 26.6667 18.6667 26.6667H13.3334C12.7134 26.6667 12.4034 26.6667 12.1491 26.5985C11.4589 26.4136 10.9198 25.8745 10.7348 25.1843C10.6667 24.93 10.6667 24.62 10.6667 24V24"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                  <path
                    d="M25.3334 18.6666V18.6666C25.3334 19.2866 25.3334 19.5966 25.2652 19.8509C25.0803 20.5411 24.5412 21.0802 23.851 21.2652C23.5967 21.3333 23.2867 21.3333 22.6667 21.3333H9.33335C8.71337 21.3333 8.40338 21.3333 8.14905 21.2652C7.45887 21.0802 6.91977 20.5411 6.73483 19.8509C6.66669 19.5966 6.66669 19.2866 6.66669 18.6666V18.6666"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </Dropdown.Toggle>
              {/* <AdvancedFilter /> */}
            </Dropdown>
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <Nav tabs className="reports_tab">
            {permissions?.add === 1 && (
              <>
                <NavItem>
                  <NavLink
                    className={isActiveTab == 'All' && 'active'}
                    onClick={() => {
                      if (isActiveTab != 'All') {
                        props.clearReportResponse()
                      }
                      filterWiseReport('All')
                      currentActiveTab('All')
                      setCurrentPage(1)
                      // setCustomFilter(false)
                    }}
                  >
                    Client Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActiveTab == 'Published' && 'active'}
                    onClick={() => {
                      if (isActiveTab != 'Published') {
                        props.clearReportResponse()
                      }
                      filterWiseReport('Published')
                      setCurrentPage(1)
                      currentActiveTab('Published')
                      // setCustomFilter(false)
                    }}
                  >
                    Published Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={isActiveTab == 'Other' && 'active'}
                    onClick={() => {
                      if (isActiveTab != 'Other') {
                        props.clearReportResponse()
                      }
                      filterWiseReport('Other')
                      setCurrentPage(1)
                      currentActiveTab('Other')
                      // setCustomFilter(false)
                    }}
                  >
                    Templates
                  </NavLink>
                </NavItem>

                {props?.userType == INTERNAL && (
                  <NavItem>
                    <NavLink
                      className={isActiveTab == 'Internal' && 'active'}
                      onClick={() => {
                        if (isActiveTab != 'Internal') {
                          props.clearReportResponse()
                        }
                        filterWiseReport('Internal')
                        setCurrentPage(1)
                        currentActiveTab('Internal')
                        //setCustomFilter(false)
                      }}
                    >
                      General Reports
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
          </Nav>
        </Col>
      </Row>
      <TabContent activeTab="1" className="report_tab-content">
        <TabPane
          tabId="1"
          className={isActiveTab == 'Other-published' ? 'other_reports' : ''}
        >
          {customFilter && (
            <Card className="filter_section">
              <CardBody>
                <h6 className="separate-head">Filter</h6>
                <LawSelectorFormik
                  type={'REPORT'}
                  isActiveTab={isActiveTab}
                  isReportModule={true}
                  getListByLawIdFilter={(
                    cat,
                    subcat,
                    date,
                    publishedReportType,
                  ) => {
                    getCurrentActiveFilter(
                      {
                        categoryId: cat,
                        subCategoryId: subcat,
                        date: date != null ? formatDateForApi(date) : '',
                        publishedReportType: publishedReportType
                          ? publishedReportType
                          : '',
                      },
                      isActiveTab,
                    )
                    props.getFilterDataByLaw(
                      cat,
                      subcat,
                      date,
                      isActiveTab,
                      publishedReportType,
                    )
                  }}
                  cancelFilterSection={(e) => {
                    props.getRefreshList(isActiveTab)
                    setCustomFilter(e ? false : true)
                  }}
                />
              </CardBody>
            </Card>
          )}

          <Table className="faq-table report-table">
            <tbody>
              {data.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>
                      <a
                        // to={`/console/report/view/${row.id}`}
                        state={{
                          isReviewData: row.isReview ? row.isReview : false,
                          isActiveTab: isActiveTab,
                        }}
                        onClick={(e) => {
                          getViewDetailPageData(
                            row.id,
                            row.isReview ? row.isReview : false,
                          )
                          if (
                            isActiveTab === 'Other' ||
                            isActiveTab === 'Other-published'
                          ) {
                            e.preventDefault()
                          }
                        }}
                      >
                        <p className="mb-0">{row?.reportTitle}</p>
                        <small>
                          <b>Created by : </b> {row?.createdByUser?.fullName}{' '}
                          <span>
                            <b>Report Type : </b>{' '}
                            {row?.reportType == 'Personal'
                              ? CLIENT_REPORT
                              : row?.reportType}
                          </span>
                        </small>
                      </a>
                    </td>
                    <td className="user-box">
                      <div className="first_name_td">
                        <div className="indicator_div">
                          {row.isReview &&
                            (isAdmin() == true || row?.createdBy == userId) && (
                              <div className="circle_animation"></div>
                            )}
                        </div>
                        <div className="update_date">
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4 2.93245C4 2.77684 4 2.69903 3.9491 2.65423C3.89819 2.60944 3.82244 2.61915 3.67094 2.63857C2.54965 2.78229 1.76806 3.07508 1.17157 3.67157C0 4.84315 0 6.72876 0 10.5C0 14.2712 0 16.1569 1.17157 17.3284C2.34315 18.5 4.22876 18.5 8 18.5H10C13.7712 18.5 15.6569 18.5 16.8284 17.3284C18 16.1569 18 14.2712 18 10.5C18 6.72876 18 4.84315 16.8284 3.67157C16.2319 3.07508 15.4504 2.78229 14.3291 2.63857C14.1776 2.61915 14.1018 2.60944 14.0509 2.65424C14 2.69903 14 2.77684 14 2.93245L14 5C14 5.82843 13.3284 6.5 12.5 6.5C11.6716 6.5 11 5.82843 11 5L11 2.80005C11 2.65898 11 2.58844 10.9561 2.54451C10.9123 2.50059 10.8418 2.5005 10.7009 2.50031C10.4748 2.5 10.2412 2.5 10 2.5H8C7.75876 2.5 7.52524 2.5 7.29912 2.50031C7.15819 2.5005 7.08773 2.50059 7.04386 2.54452C7 2.58844 7 2.65898 7 2.80005L7 5C7 5.82843 6.32843 6.5 5.50001 6.5C4.67158 6.5 4 5.82843 4 5L4 2.93245Z"
                              fill="currentColor"
                              fillOpacity="0.25"
                            />
                            <path
                              d="M5.5 1L5.5 5"
                              stroke="currentColor"
                              strokeLinecap="round"
                            />
                            <path
                              d="M12.5 1L12.5 5"
                              stroke="currentColor"
                              strokeLinecap="round"
                            />
                            <circle cx="4.5" cy="9" r="0.5" fill="white" />
                            <circle cx="7.5" cy="9" r="0.5" fill="white" />
                            <circle cx="10.5" cy="9" r="0.5" fill="white" />
                            <circle cx="13.5" cy="9" r="0.5" fill="white" />
                            <circle cx="4.5" cy="12" r="0.5" fill="white" />
                            <circle cx="7.5" cy="12" r="0.5" fill="white" />
                            <circle cx="10.5" cy="12" r="0.5" fill="white" />
                            <circle cx="13.5" cy="12" r="0.5" fill="white" />
                            <circle cx="4.5" cy="15" r="0.5" fill="white" />
                            <circle cx="7.5" cy="15" r="0.5" fill="white" />
                            <circle cx="10.5" cy="15" r="0.5" fill="white" />
                            <circle cx="13.5" cy="15" r="0.5" fill="white" />
                            <path
                              d="M13.6918 10.0017C11.1838 9.93176 9.12911 11.9764 9.12911 14.5009H8.24712C8.02539 14.5009 7.91699 14.7708 8.07467 14.9258L9.44939 16.3255C9.54793 16.4255 9.70068 16.4255 9.79923 16.3255L11.1739 14.9258C11.208 14.8905 11.231 14.8459 11.2401 14.7974C11.2491 14.7489 11.2438 14.6988 11.2249 14.6533C11.2059 14.6079 11.1741 14.5692 11.1335 14.542C11.0928 14.5149 11.0452 14.5006 10.9966 14.5009H10.1146C10.1146 12.5512 11.6815 10.9766 13.613 11.0015C15.4459 11.0265 16.9882 12.5912 17.0128 14.4509C17.0375 16.4055 15.4853 18.0002 13.5637 18.0002C12.7704 18.0002 12.0362 17.7252 11.4548 17.2603C11.3604 17.1849 11.242 17.1473 11.1221 17.1547C11.0022 17.162 10.8891 17.2139 10.8044 17.3003C10.5975 17.5103 10.6122 17.8652 10.8438 18.0452C11.618 18.6663 12.5767 19.0029 13.5637 19C16.052 19 18.0673 16.9154 17.9983 14.3709C17.9342 12.0263 16.0027 10.0667 13.6918 10.0017ZM13.4405 12.5013C13.2385 12.5013 13.071 12.6712 13.071 12.8762V14.7158C13.071 14.8908 13.1646 15.0558 13.3124 15.1457L14.8497 16.0706C15.0271 16.1755 15.2538 16.1155 15.3572 15.9406C15.4607 15.7606 15.4016 15.5307 15.2291 15.4257L13.8101 14.5708V12.8712C13.8101 12.6712 13.6425 12.5013 13.4405 12.5013Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>{getDateTimesSlach(row.updatedDate)}</span>
                        </div>
                        <div className="approver_names">
                          {(() => {
                            let userList = getUserName(row)
                            return (
                              <>
                                {userList?.map((user, index) => (
                                  <span
                                    key={index}
                                    className="my-tooltip short-name"
                                    data-tooltip-content={user?.user.fullName}
                                  >
                                    {getFirstLetters(user?.user.fullName)}
                                  </span>
                                ))}
                              </>
                            )
                          })()}
                        </div>
                      </div>
                    </td>
                    <td className="progress-box">
                      {(() => {
                        let obj = []
                        const approvalUsers =
                          row.approvalWorkFlow?.approvalDetails?.filter(
                            (x) => x?.userId == userId,
                          )?.[0]

                        if (
                          (permissions?.approve == 1 ||
                            permissions?.publish == 1) &&
                          permissions?.add != 1 &&
                          row?.approvalWorkFlow?.approvalDetails
                        ) {
                          status = approvalUsers?.status
                          obj = faqStatus?.find(
                            (item) => item.status === status,
                          )
                        } else {
                          status = row.status
                          if (status === DRAFTED) {
                            status = 'Saved'
                          }
                          obj = faqStatus?.find(
                            (item) => item.status === row.status,
                          )
                        }
                        if (status == APPROVED) {
                          let checkPendingList =
                            row.approvalWorkFlow?.approvalDetails?.filter(
                              (x) => x.status == PENDING,
                            )
                          if (checkPendingList?.length > 0) {
                            status = PENDING_FOR_PUBLISHER
                            obj = faqStatus?.find(
                              (item) => item.status === PENDING_FOR_PUBLISHER,
                            )
                          }
                        }

                        return (
                          <>
                            <span>
                              {status ? statusLableChanges(status) : ''}
                              <Progress value={obj?.value} color={obj?.class} />
                            </span>
                          </>
                        )
                      })()}
                    </td>
                    <td>
                      {((permissions?.add == 1 &&
                        (row.status == REJECTED_BY_APPROVER ||
                          row.status == REJECTED_BY_PUBLISHER ||
                          row.status == PUBLISHED)) ||
                        (row?.reportType == 'Internal' &&
                          row.status == APPROVED) ||
                        row.status == DRAFTED) && (
                          <a
                            className={
                              isActiveTab == 'Published'
                                ? permissions.approve == 1 ||
                                  userId == row?.createdByUser?.id
                                  ? 'my-tooltip btn btn-link edit_btn'
                                  : 'd-none'
                                : (isActiveTab == 'Other' ||
                                  isActiveTab == 'Internal') &&
                                  userId != row?.createdByUser?.id &&
                                  isAdmin() == false
                                  ? 'd-none'
                                  : 'my-tooltip btn btn-link edit_btn'
                            }
                            // className={
                            //   isActiveTab == 'Other' ||
                            //   isActiveTab == 'Other-published'
                            //     ? 'd-none'
                            //     : 'my-tooltip btn btn-link edit_btn'
                            // }
                            data-tooltip-content={'Edit'}
                            onClick={() => {
                              navigate('/console/report', {
                                state: { action: 'save', id: row.id },
                              })
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_1123_13264)">
                                <path
                                  d="M14.1667 2.50005C14.3856 2.28118 14.6454 2.10756 14.9314 1.98911C15.2173 1.87066 15.5238 1.80969 15.8334 1.80969C16.1429 1.80969 16.4494 1.87066 16.7353 1.98911C17.0213 2.10756 17.2812 2.28118 17.5 2.50005C17.7189 2.71892 17.8925 2.97875 18.011 3.26472C18.1294 3.55069 18.1904 3.85719 18.1904 4.16671C18.1904 4.47624 18.1294 4.78274 18.011 5.06871C17.8925 5.35468 17.7189 5.61451 17.5 5.83338L6.25002 17.0834L1.66669 18.3334L2.91669 13.75L14.1667 2.50005Z"
                                  stroke="CurrentColor"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1123_13264">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="CurrentColor"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        )}
                      <a
                        // className={
                        //   isActiveTab == 'Other' ||
                        //   isActiveTab == 'Other-published'
                        //     ? 'd-none'
                        //     : 'my-tooltip btn btn-link history_btn'
                        // }
                        // className={
                        //   isActiveTab == 'Published'
                        //     ? userId == row?.createdByUser?.id
                        //       ? 'my-tooltip btn btn-link history_btn'
                        //       : 'd-none'
                        //     : 'my-tooltip btn btn-link history_btn'
                        // }
                        className="my-tooltip btn btn-link history_btn"
                        data-tooltip-content={'History'}
                        onClick={(e) => openAuditLogModal(row.id, e)}
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 8.6L12.5 11.1C12.6833 11.2833 12.775 11.5167 12.775 11.8C12.775 12.0833 12.6833 12.3167 12.5 12.5C12.3167 12.6833 12.0833 12.775 11.8 12.775C11.5167 12.775 11.2833 12.6833 11.1 12.5L8.3 9.7C8.2 9.6 8.125 9.48733 8.075 9.362C8.025 9.23733 8 9.10833 8 8.975V5C8 4.71667 8.096 4.479 8.288 4.287C8.47933 4.09567 8.71667 4 9 4C9.28333 4 9.521 4.09567 9.713 4.287C9.90433 4.479 10 4.71667 10 5V8.6ZM9 18C7.75 18 6.57933 17.7627 5.488 17.288C4.396 16.8127 3.446 16.1707 2.638 15.362C1.82933 14.554 1.18733 13.604 0.712 12.512C0.237333 11.4207 0 10.25 0 9C0 7.75 0.237333 6.579 0.712 5.487C1.18733 4.39567 1.82933 3.44567 2.638 2.637C3.446 1.829 4.396 1.18733 5.488 0.712C6.57933 0.237333 7.75 0 9 0C10.3667 0 11.6627 0.291667 12.888 0.875C14.1127 1.45833 15.15 2.28333 16 3.35V2C16 1.71667 16.096 1.479 16.288 1.287C16.4793 1.09567 16.7167 1 17 1C17.2833 1 17.5207 1.09567 17.712 1.287C17.904 1.479 18 1.71667 18 2V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7H13C12.7167 7 12.4793 6.904 12.288 6.712C12.096 6.52067 12 6.28333 12 6C12 5.71667 12.096 5.479 12.288 5.287C12.4793 5.09567 12.7167 5 13 5H14.75C14.0667 4.06667 13.225 3.33333 12.225 2.8C11.225 2.26667 10.15 2 9 2C7.05 2 5.396 2.679 4.038 4.037C2.67933 5.39567 2 7.05 2 9C2 10.95 2.67933 12.604 4.038 13.962C5.396 15.3207 7.05 16 9 16C10.5333 16 11.921 15.55 13.163 14.65C14.4043 13.75 15.2417 12.5667 15.675 11.1C15.7583 10.8167 15.9127 10.579 16.138 10.387C16.3627 10.1957 16.6167 10.1333 16.9 10.2C17.2 10.2667 17.4207 10.4333 17.562 10.7C17.704 10.9667 17.7333 11.25 17.65 11.55C17.1 13.4667 16.025 15.0207 14.425 16.212C12.825 17.404 11.0167 18 9 18Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>
                      <a
                        className="my-tooltip btn btn-link"
                        data-tooltip-content={'Download'}
                        onClick={(e) => props.download(row)}
                      >
                        <svg
                          width="22"
                          height="20"
                          viewBox="0 0 22 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 13V1M11 13L7 9M11 13L15 9M1 15L1.621 17.485C1.72915 17.9177 1.97882 18.3018 2.33033 18.5763C2.68184 18.8508 3.11501 18.9999 3.561 19H18.439C18.885 18.9999 19.3182 18.8508 19.6697 18.5763C20.0212 18.3018 20.2708 17.9177 20.379 17.485L21 15"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          {totalRecords > 0 ? (
            <CustomPagination
              dataPerPage={dataPerPage}
              totalData={totalRecords}
              setDataPerPage={setDataPerPage}
              setCurrentPage={setCurrentPage}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="no-data-found report-data">
              <svg
                width="96"
                height="114"
                viewBox="0 0 96 114"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M49.8008 114V76.1002L49.8008 76.0313V76.0312C49.8006 75.621 49.8004 75.1775 49.8508 74.8031C49.9097 74.3644 50.0614 73.804 50.533 73.3324C51.0046 72.8608 51.565 72.7092 52.0036 72.6502C52.3781 72.5998 52.8217 72.6 53.2319 72.6002L53.3008 72.6002H91.2V34.2C91.2 18.078 91.2 10.0169 86.1915 5.00848C81.1831 0 73.122 0 57 0H34.2C18.078 0 10.0169 0 5.00847 5.00848C0 10.0169 0 18.078 0 34.2V79.8C0 95.922 0 103.983 5.00847 108.992C10.0169 114 18.078 114 34.2 114H49.8008ZM52.8008 114V76.1002C52.8008 75.902 52.8008 75.7414 52.8031 75.6025C52.942 75.6003 53.1026 75.6002 53.3008 75.6002H91.2V76.0559C91.2 80.7157 91.2 83.0456 90.3322 85.1406C89.4645 87.2355 87.817 88.883 84.5221 92.1779L84.522 92.178L69.378 107.322C66.083 110.617 64.4355 112.264 62.3406 113.132C60.2456 114 57.9157 114 53.2559 114H52.8008ZM25.5 51.3003C25.5 52.9571 26.8431 54.3003 28.5 54.3003L62.7 54.3003C64.3569 54.3003 65.7 52.9571 65.7 51.3003C65.7 49.6434 64.3568 48.3003 62.7 48.3003H28.5C26.8431 48.3003 25.5 49.6434 25.5 51.3003ZM28.5 31.5002C26.8431 31.5002 25.5 30.1571 25.5 28.5002C25.5 26.8434 26.8431 25.5002 28.5 25.5002H51.3C52.9569 25.5002 54.3 26.8434 54.3 28.5002C54.3 30.1571 52.9569 31.5002 51.3 31.5002H28.5Z"
                  fill="#4F2D7F"
                  fillOpacity="0.12"
                />
                <circle
                  cx="57.5592"
                  cy="67.2599"
                  r="27.36"
                  fill="#4F2D7F"
                  fillOpacity="0.3"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                />
                <path
                  d="M57.5609 53.5795C55.7644 53.5795 53.9855 53.9333 52.3258 54.6208C50.666 55.3083 49.1579 56.3159 47.8876 57.5862C46.6173 58.8566 45.6097 60.3646 44.9222 62.0244C44.2347 63.6841 43.8809 65.463 43.8809 67.2595"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                  strokeLinecap="round"
                />
                <path
                  d="M92.8968 102.6L79.2168 88.9204"
                  stroke="#4F2D7F"
                  strokeWidth="5.2"
                  strokeLinecap="round"
                />
              </svg>
              <h1>No Reports Found</h1>
            </div>
          )}

          <AuditLogModal
            isModalOpen={isAuditModalOpen}
            modalName="Report History"
            auditType="report"
            auditId={auditId}
            onModalDismiss={() => closeAuditLogModal()}
          />
        </TabPane>
      </TabContent>
    </div>
  )
}

export { ReportTable }
