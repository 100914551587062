export const generateChartMix = (Data) => {
  const Report = Data.ReportViewCount?.map((obj) => obj.count)

  const chartMix = {
    series: [
      {
        name: 'Report ',
        type: 'column',
        data: Report,
      },
    ],
    options: {
      chart: {
        fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
        height: '400px',
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth',
        colors: ['#9bd732', '#00A7B5'],
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          color: ['#4f2d7f'],
        },
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        colors: ['#4f2d7f', '#00A7B5'],
        gradient: {
          inverseColors: true,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      markers: {
        size: 0,
      },
      colors: ['#4f2d7f', '#00A7B5', '#9bd732'],
      legend: {
        show: true,
        fontSize: '15px',
        // labels: {
        //   colors: '#fff',
        // },
      },
      grid: {
        show: false,
      },
      xaxis: {
        labels: {
          show: true,
          // style: {
          //   colors: '#fff',
          // },
        },
        axisBorder: {
          show: true,
          color: undefined,
        },
      },
      yaxis: {
        labels: {
          show: true,
          // style: {
          //   colors: '#fff',
          // },
        },
        axisBorder: {
          show: true,
          color: '#c4c4c4',
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0) + ' views'
            }
            return y
          },
        },
      },
      responsive: [
        {
          breakpoint: 1599,
          options: {
            chart: {
              height: '360px',
            },
          },
        },
        {
          breakpoint: 1439,
          options: {
            chart: {
              height: '300px',
            },
          },
        },
      ],
    },
  }

  return chartMix
}

export const generatechartRadialbar = (item) => {
  console.log("pendingTotalQueries===", item.pendingTotalQueries)
  console.log("respondTotalQueries===", item.respondTotalQueries)
  const chartRadialbar = {
    series: [item.pendingTotalQueries, item.respondTotalQueries],
    options: {
      chart: {
        fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
        height: '400px',
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
              // color: '#ffffff',
            },
            total: {
              show: true,
              label: 'Total',
              formatter: (w) => {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
          hollow: {
            margin: 15,
            size: '60%',
          },
        },
      },
      colors: ['#4f2d7f', '#9bd732'],
      legend: {
        show: true,
        fontSize: '15px',
        position: 'bottom',
        offsetY: -20,
        horizontalAlign: 'center',
        floating: false,
        labels: {
          show: true,
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
      },
      labels: ['Pending', 'Respond'],
      responsive: [
        {
          breakpoint: 1599,
          options: {
            chart: {
              height: '360px',
            },
          },
        },
        {
          breakpoint: 1439,
          options: {
            chart: {
              height: '300px',
            },
          },
        },
      ],
    },
  }

  chartRadialbar.options.plotOptions.radialBar.dataLabels.value.formatter =
    function (val) {
      return val.toString() // Return the value without percentage sign
    }

  return chartRadialbar
}

//Old important Code
// mixed chart
// const [chartMix] = useState({
//   series: [
//     {
//       name: 'Report',
//       type: 'column',
//       data: props.ChartColumns && props.ChartColumns,
//     },
//     {
//       name: 'FAQ',
//       type: 'area',
//       data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 18],
//     },
//     {
//       name: 'Artical',
//       type: 'line',
//       data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 17],
//     },
//   ],
//   options: {
//     chart: {
//       fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
//       height: '400px',
//       type: 'line',
//       stacked: false,
//       toolbar: {
//         show: false,
//       },
//     },
//     stroke: {
//       width: [0, 2, 5],
//       curve: 'smooth',
//       colors: ['#9bd732', '#00A7B5'],
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: '40%',
//         color: ['#4f2d7f'],
//       },
//     },

//     fill: {
//       opacity: [0.85, 0.25, 1],
//       colors: ['#4f2d7f', '#00A7B5'],
//       gradient: {
//         inverseColors: true,
//         shade: 'light',
//         type: 'vertical',
//         opacityFrom: 0.85,
//         opacityTo: 0.55,
//         stops: [0, 100, 100, 100],
//       },
//     },
//     labels: [
//       'Jan',
//       'Feb',
//       'Mar',
//       'Apr',
//       'May',
//       'Jun',
//       'Jul',
//       'Aug',
//       'Sep',
//       'Oct',
//       'Nov',
//       'Dec',
//     ],
//     markers: {
//       size: 0,
//     },
//     colors: ['#4f2d7f', '#00A7B5', '#9bd732'],
//     legend: {
//       show: true,
//       fontSize: '15px',
//       // labels: {
//       //   colors: '#fff',
//       // },
//     },
//     grid: {
//       show: false,
//     },
//     xaxis: {
//       labels: {
//         show: true,
//         // style: {
//         //   colors: '#fff',
//         // },
//       },
//       axisBorder: {
//         show: true,
//         color: undefined,
//       },
//     },
//     yaxis: {
//       labels: {
//         show: true,
//         // style: {
//         //   colors: '#fff',
//         // },
//       },
//       axisBorder: {
//         show: true,
//         color: '#c4c4c4',
//       },
//       min: 0,
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       y: {
//         formatter: function (y) {
//           if (typeof y !== 'undefined') {
//             return y.toFixed(0) + ' points'
//           }
//           return y
//         },
//       },
//     },
//     responsive: [
//       {
//         breakpoint: 1599,
//         options: {
//           chart: {
//             height: '360px',
//           },
//         },
//       },
//       {
//         breakpoint: 1439,
//         options: {
//           chart: {
//             height: '300px',
//           },
//         },
//       },
//     ],
//   },
// })

//** Old important Code
// radialbar chart
// const [chartRadialbar] = useState({
//   series: [44, 83],
//   options: {
//     chart: {
//       fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
//       height: '400px',
//       type: 'radialBar',
//     },
//     plotOptions: {
//       radialBar: {
//         dataLabels: {
//           name: {
//             fontSize: '22px',
//           },
//           value: {
//             fontSize: '16px',
//             // color: '#ffffff',
//           },
//           total: {
//             show: true,
//             label: 'Total',
//             formatter: function (w) {
//               // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
//               return 249
//             },
//           },
//         },
//         hollow: {
//           margin: 15,
//           size: '60%',
//         },
//       },
//     },
//     colors: ['#4f2d7f', '#9bd732'],
//     labels: ['Report Approve', 'Report Reject'],
//     legend: {
//       show: true,
//       fontSize: '15px',
//       position: 'bottom',
//       offsetY: -20,
//       horizontalAlign: 'center',
//       floating: false,
//       labels: {
//         show: true,
//         useSeriesColors: true,
//       },
//       markers: {
//         size: 0,
//       },
//     },
//     labels: ['Queries', 'Response'],
//     responsive: [
//       {
//         breakpoint: 1599,
//         options: {
//           chart: {
//             height: '360px',
//           },
//         },
//       },
//       {
//         breakpoint: 1439,
//         options: {
//           chart: {
//             height: '300px',
//           },
//         },
//       },
//     ],
//   },
// })

// using use state

// useEffect(() => {
//   setxSeries([
//     {
//       series: [
//         props.chartQueriesCount.pendingTotalQueries,
//         props.chartQueriesCount.respondTotalQueries,
//       ],
//     },
//   ])
//   setxOptions({
//     options: {
//       chart: {
//         fontFamily: 'GT Walsheim Pro, Roboto, sans-serif',
//         height: '400px',
//         type: 'radialBar',
//       },
//       plotOptions: {
//         radialBar: {
//           dataLabels: {
//             name: {
//               fontSize: '22px',
//             },
//             value: {
//               fontSize: '16px',
//               // color: '#ffffff',
//             },
//             total: {
//               show: true,
//               label: 'Total',
//               formatter: function (w) {
//                 // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
//                 return props.chartQueriesCount.totalQueries
//               },
//             },
//           },
//           hollow: {
//             margin: 15,
//             size: '60%',
//           },
//         },
//       },
//       colors: ['#4f2d7f', '#9bd732'],
//       labels: ['Report Approve', 'Report Reject'],
//       legend: {
//         show: true,
//         fontSize: '15px',
//         position: 'bottom',
//         offsetY: -20,
//         horizontalAlign: 'center',
//         floating: false,
//         labels: {
//           show: true,
//           useSeriesColors: true,
//         },
//         markers: {
//           size: 0,
//         },
//       },
//       labels: ['Queries', 'Response'],
//       responsive: [
//         {
//           breakpoint: 1599,
//           options: {
//             chart: {
//               height: '360px',
//             },
//           },
//         },
//         {
//           breakpoint: 1439,
//           options: {
//             chart: {
//               height: '300px',
//             },
//           },
//         },
//       ],
//     },
//   })
// }, [props.chartQueriesCount])
