import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button, Card, CardBody, Input } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { DANGER } from 'constant/commonConstants'

export default function GroupFaqModal(props) {
    const [activeCheckBox, setActiveCheckBox] = useState(null)

    useEffect(() => {
        if (props?.faqList?.isEdit) {
            const array = props?.faqList?.faqList.map(x => x.isGroup)
            let lastIndex = -1;
            // Iterate through the array in reverse
            for (let i = array.length - 1; i >= 0; i--) {
                // Check if the current element is 1
                if (array[i] === 1 || array[i] === true) {
                    lastIndex = i;
                    break; // Exit the loop once the last occurrence of 1 is found
                }
            }
            const lastObject = lastIndex;
            setActiveCheckBox(lastObject)
        }
    }, [props?.faqList?.isEdit])

    const isSequence = (arr) => {
        for (let i = 1; i < arr.length; i++) {
            if (arr[i] - arr[i - 1] !== 1) {
                return false;
            }
        }
        return true;
    }
    const formSubmit = async (values) => {
        let checkFaq = values?.faqList?.filter(x => x.isGroup)
        if (checkFaq?.length > 1) {
            let faqList = props.faqList.oldFaq.map((faq) => {
                const updateFaq = values?.faqList.find((x) => x.id === faq.id);
                return {
                    ...faq,
                    ...updateFaq
                };
            });
            let getSequence = checkFaq?.map(x => x.oldIndex)
            if (!isSequence(getSequence)) {
                let faqIndex = faqList.findIndex(item => item.id === checkFaq[0].id);
                let selectedFaqIds = checkFaq.map(x => x.id)
                faqList = faqList?.filter((x) => !selectedFaqIds.includes(x.id))
                faqList.splice(faqIndex, 0, ...checkFaq);
            }
            setActiveCheckBox(null)
            props.formSubmit(faqList)
        } else {
            ApiResponseMessage("User must select more then one FAQ.", DANGER)
        }
    }



    const manageCheckBox = (index) => {
        if (activeCheckBox == null) {
            return false
        } else if (activeCheckBox == index) {
            return false
        } else if (activeCheckBox == index - 1) {
            return false
        }
        else {
            return true
        }
    }

    const closeModal = () => {
        setActiveCheckBox(null)
        props.closeModal(false)
    }
    return (
        <Modal
            show={props.isOpenModal}
            className=""
            size='lg'
            centered
        >
            <Modal.Header
                closeButton
                onClick={() => {
                    closeModal()
                }}
            >
                <Modal.Title>FAQ Segmentation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={{ faqList: props?.faqList?.faqList || [] }}
                    // validationSchema={createFaqSchema}

                    enableReinitialize={true}
                    onSubmit={formSubmit}
                >
                    {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                        <>
                            <Form className="faq-create">
                                {values?.faqList?.map((faq, idx) => (
                                    <div className='faq-group-list'>
                                        {/* {
                                            JSON.stringify(faq.isParent)
                                        }
                                        {
                                            JSON.stringify(faq.isGroup)
                                        } */}
                                        <Field
                                            type="checkbox"
                                            disabled={manageCheckBox(idx)}
                                            name={`faqList.${idx}.isGroup`}
                                            checked={faq.isGroup}
                                            className="form-check-input"
                                            onChange={(e) => {
                                                let isParent = values?.faqList.filter(x => x.isParent);
                                                let isGroup = values?.faqList.filter(x => x.isGroup);
                                                if (isParent?.length == 0 || isParent[0]?.id == faq.id && faq.isParent && isGroup?.length <= 1) {
                                                    if (e.target.checked) {
                                                        setActiveCheckBox(idx)
                                                    } else {
                                                        setActiveCheckBox(null)
                                                    }
                                                    setFieldValue(`faqList.${idx}.isParent`, e.target.checked)
                                                    setFieldValue(`faqList.${idx}.isGroup`, e.target.checked)

                                                } else if (isParent[0]?.id == faq.id && faq.isParent && isGroup?.length > 1) {
                                                    setFieldValue(`faqList.${idx}.isGroup`, true)
                                                } else {
                                                    if (e.target.checked) {
                                                        setActiveCheckBox(idx)
                                                    } else {
                                                        setActiveCheckBox(idx - 1)
                                                    }
                                                    setFieldValue(`faqList.${idx}.isGroup`, e.target.checked)
                                                    setFieldValue(`faqList.${idx}.isParent`, false)
                                                }
                                            }}
                                        />
                                        <label>
                                            {faq?.newQuestion
                                                ? faq?.newQuestion
                                                : faq?.question}
                                        </label>


                                    </div>
                                ))}
                                <FormGroup className="d-flex justify-content-center m-0 mt-3 p-0">
                                    <Button
                                        type="button"
                                        color="outline-primary"
                                        className="waves-effect waves-light ms-3"
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary" className="waves-effect waves-light ms-3">Submit</Button>
                                </FormGroup>
                            </Form>
                        </>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}
