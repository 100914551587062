export const GET_SERVICE_LINE_LIST_REQUEST = 'GET_SERVICE_LINE_LIST_REQUEST'
export const GET_SERVICE_LINE_LIST_RESPONSE = 'GET_SERVICE_LINE_LIST_RESPONSE'
export const CLEAR_SERVICE_LINE_LIST_RESPONSE =
  'CLEAR_SERVICE_LINE_LIST_RESPONSE'

export const ADD_SERVICE_LINE_REQUEST = 'ADD_SERVICE_LINE_REQUEST'
export const ADD_SERVICE_LINE_RESPONSE = 'ADD_SERVICE_LINE_RESPONSE'

export const IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST =
  'IS_ACTIVE_DEACTIVE_SERVICE_LINE_REQUEST'
export const IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE =
  'IS_ACTIVE_DEACTIVE_SERVICE_LINE_RESPONSE'
