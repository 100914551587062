import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import { GET_USER_DETAILS_REQUEST } from './actionType'
import { getUserDetailsResponse } from './action'

function* UserFormSubmitDetails({ payload: { data } }) {
  let response
  try {
    response = yield call(invokeApi, 'post', '/login', data)
    if (response.status) {
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
      yield put(
        getUserDetailsResponse(response.message, SUCCESS, response.data),
      )
    } else {
      ApiResponseMessage(response.message, DANGER)
      yield put(getUserDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchFaq() {
  yield takeEvery(GET_USER_DETAILS_REQUEST, UserFormSubmitDetails)
}
function* userAboutSaga() {
  yield all([fork(watchFaq)])
}
export default userAboutSaga
