import { GET_MODULE_LIST_REQUEST, GET_MODULE_LIST_RESPONSE } from './actionType';


export const getModuleListRequest = () => {
  return {
    type: GET_MODULE_LIST_REQUEST,

  };
};

export const getModuleListResponse = (data) => {
  return {
    type: GET_MODULE_LIST_RESPONSE,
    payload: { data },
  };
};