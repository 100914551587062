export const SectionInitialState = () => {
  return {
    sectionName: '',
    lawId: '',
  }
}

export const createSectionInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    sectionName: value.sectionName,
    lawId: value.Law?.id,
  }
}
