import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  CREATE_CONSULTATION_REQUEST,
  CREATE_CONSULTATION_REVIEW_REQUEST,
  CREATE_DOCUMENT_REQUEST,
  CREATE_REVIEW_LAW_REQUEST,
  CREATE_TEAM_DELEGATE_QUERY_REQUEST,
  DELETE_CONSULTATION_DOCUMENT_REQUEST,
  GET_CONSULTATION_DETAILS_REQUEST,
  GET_CONSULTATION_HISTORY_REQUEST,
  GET_LIST_CONSULTATION_REQUEST,
  GET_LIST_KEYWORDS_REQUEST,
  GET_TEAM_LIST_REQUEST,
  HIDE_NOTIFICATION_REQUEST,
  QUERY_RESPOND_REQUEST,
} from './actionType'
import {
  createConsultationResponse,
  getListConsultationResponse,
  getListKeywordsResponse,
  getConsultationDetailsResponse,
  getConsultationHistoryResponse,
  createConsultationReviewResponse,
  createreReviewLawResponse,
  getConsultationDetailsRequest,
  deleteConsultationDocumentResponse,
  getTeamListResponse,
  createTeamDelegateQueryResponse,
  createDocumentResponse,
  queryRespondResponse,
  hideNotificationResponse,
} from './action'

import { invokeApi } from '../../helpers/axiosHelper'
import { DANGER, SUCCESS } from '../../constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

function* createConsultation({ payload: { data, operationType } }) {
  try {
    let url = '/createConsultation'
    if (operationType == 'Update') {
      url = '/updateConsultation'
    }
    const response = yield call(invokeApi, 'post', url, data)

    if (response.status) {
      yield put(createConsultationResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } else {
      yield put(createConsultationResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* listConsultation({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/consultationList', data)

    if (response.status) {
      if (response?.data?.list?.length > 0) {
        response?.data.list?.forEach((faq) => {
          faq?.approvalWorkFlow?.approvalDetails?.sort((user1, user2) => {
            return user1.orderNo - user2.orderNo
          })
        })
      }

      yield put(getListConsultationResponse(response.data))
      yield put(getConsultationDetailsResponse([]))
    } else {
      yield put(getListConsultationResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* consultationDetails({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'get',
      `/consultationDetails/${data}`,
    )

    if (response.status) {
      if (response?.data?.details?.length > 0) {
        response?.data?.details?.forEach((faq) => {
          faq?.approvalWorkFlow?.approvalDetails?.sort((user1, user2) => {
            return user1.orderNo - user2.orderNo
          })
        })
      }
      yield put(getConsultationDetailsResponse(response.data))
    } else {
      yield put(getConsultationDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* listKeywords() {
  try {
    const response = yield call(invokeApi, 'post', '/keywordsList')
    if (response.status) {
      yield put(getListKeywordsResponse(response.data))
    } else {
      yield put(getListKeywordsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}
function* getConsultationHistory({ payload: { id } }) {
  try {
    const response = yield call(invokeApi, 'get', `/faqHistory/${id}`)

    if (response.status) {
      yield put(getConsultationHistoryResponse(response.data))
    } else {
      yield put(getConsultationHistoryResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createConsultationReview({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/consultationReview', data)

    if (response.status) {
      yield put(createConsultationReviewResponse(response.message, SUCCESS))
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(createConsultationReviewResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createReviewLaw({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/reviewLaw', data)
    if (response.status) {
      if (data.faqId) {
        yield put(getConsultationDetailsRequest(data.faqId))
      }
      yield put(createreReviewLawResponse(response.message, SUCCESS))
    } else {
      yield put(createreReviewLawResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* deleteConsultationDocument({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/removeConsultationDocument',
      data,
    )
    if (response.status) {
      yield put(deleteConsultationDocumentResponse(response.message, SUCCESS))
    } else {
      yield put(deleteConsultationDocumentResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getTeamList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/teamList', data)
    if (response.status) {
      yield put(getTeamListResponse(response.data))
    } else {
      yield put(getTeamListResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createTeamDelegateQuery({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/teamDelegateQuery', data)

    if (response.status) {
      yield put(createTeamDelegateQueryResponse(response.message, SUCCESS))
    } else {
      yield put(createTeamDelegateQueryResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createDocument({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/addDocument', data)

    if (response.status) {
      yield put(createDocumentResponse(response.message, SUCCESS))
    } else {
      yield put(createDocumentResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* queryRespond({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/queryRespond', data)

    if (response.status) {
      yield put(getConsultationDetailsRequest(data.consultationRequestsId))
      yield put(queryRespondResponse(response.message, SUCCESS))
    } else {
      yield put(queryRespondResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* hideNotification({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/hideNotification', data)

    if (response.status) {
      if (data.consultationRequestsId) {
        yield put(getConsultationDetailsRequest(data.consultationRequestsId))
      }

      yield put(hideNotificationResponse(response.message, SUCCESS))
    } else {
      yield put(hideNotificationResponse(response.message, DANGER))
    }
    //ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchLaw() {
  yield takeEvery(CREATE_CONSULTATION_REQUEST, createConsultation)
  yield takeEvery(GET_LIST_CONSULTATION_REQUEST, listConsultation)
  yield takeEvery(GET_LIST_KEYWORDS_REQUEST, listKeywords)
  yield takeEvery(GET_CONSULTATION_DETAILS_REQUEST, consultationDetails)
  yield takeEvery(GET_CONSULTATION_HISTORY_REQUEST, getConsultationHistory)
  yield takeEvery(CREATE_CONSULTATION_REVIEW_REQUEST, createConsultationReview)
  yield takeEvery(CREATE_REVIEW_LAW_REQUEST, createReviewLaw)
  yield takeEvery(
    DELETE_CONSULTATION_DOCUMENT_REQUEST,
    deleteConsultationDocument,
  )
  yield takeEvery(GET_TEAM_LIST_REQUEST, getTeamList)
  yield takeEvery(CREATE_TEAM_DELEGATE_QUERY_REQUEST, createTeamDelegateQuery)

  yield takeEvery(CREATE_DOCUMENT_REQUEST, createDocument)
  yield takeEvery(QUERY_RESPOND_REQUEST, queryRespond)

  yield takeEvery(HIDE_NOTIFICATION_REQUEST, hideNotification)
}
function* consultationSaga() {
  yield all([fork(watchLaw)])
}
export default consultationSaga
