import CustomisedModal from 'components/Modal/CustomisedModal'
import React, { useRef } from 'react'
import { Formik, Field, Form } from 'formik'
import { Row, Col, Label, FormGroup, Button } from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import JoditEditor from 'jodit-react'
import { __t } from 'i18n/translator'

export default function EditFaqForm(props) {
  const validator = useRef(new SimpleReactValidator())
  const editor = useRef(null)
  const editorId = 'answer'
  const config = {
    removeButtons: [
      'cut',
      'copy',
      'paste',
      'font',
      'change mode',
      'print',
      'insert',
      'about',
      'subscript',
      'superscript',
      'source',
      'classSpan',
      'copyformat',
      'video',
      'image',
      'file',
    ],
  }
  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      props.updateFaq(values)
    } else {
      validator.current.showMessages()
    }
  }

  return (
    <React.Fragment>
      <CustomisedModal
        isModalOpen={props.isModalOpen}
        modalSize="modal-dialog-scrollable modal-xl report-update"
        onModalDismiss={() => props.onModalDismiss()}
        modalName={props.modalName}
      >
        <Formik
          initialValues={props.initialValues}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Form className="faq-create">
                <div className="col">
                  <Label className="separate-head mb-2">
                    Question/Heading
                    <span className="text-danger required">*</span>
                  </Label>
                  <FormGroup className="mb-3">
                    <Field
                      name="question"
                      placeholder="Question/Heading here..."
                      className="form-control"
                    />
                    {validator.current.message(
                      __t('validations.faq.faq_question_required'),
                      values.question,
                      'required',
                      {
                        messages: {
                          required: __t(
                            'validations.faq.faq_question_required',
                          ),
                        },
                      },
                    )}
                  </FormGroup>
                </div>
                <div className="answer-editer-box">
                  <Label className="separate-head mb-2">
                    Answer <span className="text-danger required">*</span>
                  </Label>
                  <JoditEditor
                    ref={editor}
                    config={config}
                    name={editorId}
                    value={values[editorId]}
                    onBlur={(updateValue) => {
                      setFieldValue(editorId, updateValue)
                    }}
                  />
                  {validator.current.message(
                    __t('validations.faq.faq_answer_required'),
                    values.answer,
                    'required',
                    {
                      messages: {
                        required: __t('validations.faq.faq_answer_required'),
                      },
                    },
                  )}
                </div>
              </Form>

              <Row className="container align-items-center mt-2">
                <Col className="d-flex justify-content-center my-3 p-0">
                  <FormGroup className="d-flex m-0 p-0">
                    <Button
                      type="button"
                      color="outline-primary"
                      block
                      className="w-100 waves-effect waves-light ms-3"
                      onClick={() => {
                        props.onModalDismiss()
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      block
                      className="w-100 waves-effect waves-light ms-3"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Update
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </CustomisedModal>
    </React.Fragment>
  )
}
