export const GET_LIST_KEYWORDS_REQUEST = 'GET_LIST_KEYWORDS_REQUEST'
export const GET_LIST_KEYWORDS_RESPONSE = 'GET_LIST_KEYWORDS_RESPONSE'

export const GET_ARTICAL_DETAILS_REQUEST = 'GET_ARTICAL_DETAILS_REQUEST'
export const GET_ARTICAL_DETAILS_RESPONSE = 'GET_ARTICAL_DETAILS_RESPONSE'

export const GET_ARTICAL_HISTORY_REQUEST = 'GET_ARTICAL_HISTORY_REQUEST'
export const GET_ARTICAL_HISTORY_RESPONSE = 'GET_ARTICAL_HISTORY_RESPONSE'

export const CREATE_ARTICLE_REVIEW_REQUEST = 'CREATE_ARTICLE_REVIEW_REQUEST'
export const CREATE_ARTICLE_REVIEW_RESPONSE = 'CREATE_ARTICLE_REVIEW_RESPONSE'

export const CREATE_REVIEW_LAW_REQUEST = 'CREATE_REVIEW_LAW_REQUEST'
export const CREATE_REVIEW_LAW_RESPONSE = 'CREATE_REVIEW_LAW_RESPONSE'

export const CREATE_ARTICAL_REQUEST = 'CREATE_ARTICAL_REQUEST'
export const CREATE_ARTICAL_RESPONSE = 'CREATE_ARTICAL_RESPONSE'

export const GET_LIST_ARTICAL_REQUEST = 'GET_LIST_ARTICAL_REQUEST'
export const GET_LIST_ARTICAL_RESPONSE = 'GET_LIST_ARTICAL_RESPONSE'
