import produce from 'immer'
import {
  CLEARE_CREATE_TEAM_RESPONSE,
  CREATE_TEAM_RESPONSE,
  DELETE_TEAM_MEMBER_RESPONSE,
  GET_TEAM_LEAD_RESPONSE,
  GET_TEAM_MEMBER_LIST_RESPONSE,
  GET_TEAM_USER_RESPONSE,
} from './actionType'

export const initialState = {
  createTeamApiResponse: null,
  teamLeadApiResponse: null,
  teamUserApiResponse: null,
  getTeamMeberListApiResponse: null,
  deleteTeamMemberApiResponse: null,
}

export const teamCreationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_TEAM_RESPONSE:
        draft.createTeamApiResponse = action.payload.responseType
        break

      case GET_TEAM_LEAD_RESPONSE:
        draft.teamLeadApiResponse = action.payload.data
        break

      case GET_TEAM_USER_RESPONSE:
        draft.teamUserApiResponse = action.payload.data
        break

      case CLEARE_CREATE_TEAM_RESPONSE:
        draft.createTeamApiResponse = null
        break

      case GET_TEAM_MEMBER_LIST_RESPONSE:
        draft.getTeamMeberListApiResponse = action.payload.data
        break

      case DELETE_TEAM_MEMBER_RESPONSE:
        draft.createTeamApiResponse = action.payload.responseType
        break

      default:
        state = { ...state }
        break
    }
  })
