import {
  CLEAR_CONSULTATION_LIST_RESPONSE,
  CLEAR_DOCUMENT_RESPONSE,
  CLEAR_TEAM_DELEGATE_QUERY_RESPONSE,
  CREATE_CONSULTATION_REQUEST,
  CREATE_CONSULTATION_RESPONSE,
  CREATE_CONSULTATION_REVIEW_REQUEST,
  CREATE_CONSULTATION_REVIEW_RESPONSE,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_RESPONSE,
  CREATE_REVIEW_LAW_REQUEST,
  CREATE_REVIEW_LAW_RESPONSE,
  CREATE_TEAM_DELEGATE_QUERY_REQUEST,
  CREATE_TEAM_DELEGATE_QUERY_RESPONSE,
  DELETE_CONSULTATION_DOCUMENT_REQUEST,
  DELETE_CONSULTATION_DOCUMENT_RESPONSE,
  GET_CONSULTATION_DETAILS_REQUEST,
  GET_CONSULTATION_DETAILS_RESPONSE,
  GET_CONSULTATION_HISTORY_REQUEST,
  GET_CONSULTATION_HISTORY_RESPONSE,
  GET_LIST_CONSULTATION_REQUEST,
  GET_LIST_CONSULTATION_RESPONSE,
  GET_LIST_KEYWORDS_REQUEST,
  GET_LIST_KEYWORDS_RESPONSE,
  GET_TEAM_LIST_REQUEST,
  GET_TEAM_LIST_RESPONSE,
  HIDE_NOTIFICATION_REQUEST,
  HIDE_NOTIFICATION_RESPONSE,
  QUERY_RESPOND_REQUEST,
  QUERY_RESPOND_RESPONSE,
} from './actionType'

export const createConsultationRequest = (data, operationType) => {
  return {
    type: CREATE_CONSULTATION_REQUEST,
    payload: { data, operationType },
  }
}

export const createConsultationResponse = (message, responseType) => {
  return {
    type: CREATE_CONSULTATION_RESPONSE,
    payload: { message, responseType },
  }
}
export const getListConsultationRequest = (data) => {
  return {
    type: GET_LIST_CONSULTATION_REQUEST,
    payload: { data },
  }
}
export const getListConsultationResponse = (data) => {
  return {
    type: GET_LIST_CONSULTATION_RESPONSE,
    payload: { data },
  }
}

export const clearConsultationListResponse = () => {
  return {
    type: CLEAR_CONSULTATION_LIST_RESPONSE,
  }
}

export const getListKeywordsRequest = () => {
  return {
    type: GET_LIST_KEYWORDS_REQUEST,
    // payload: { },
  }
}
export const getListKeywordsResponse = (data) => {
  return {
    type: GET_LIST_KEYWORDS_RESPONSE,
    payload: { data },
  }
}
export const getConsultationDetailsRequest = (data) => {
  return {
    type: GET_CONSULTATION_DETAILS_REQUEST,
    payload: { data },
  }
}
export const getConsultationDetailsResponse = (data) => {
  return {
    type: GET_CONSULTATION_DETAILS_RESPONSE,
    payload: { data },
  }
}

export const getConsultationHistoryRequest = (id) => {
  return {
    type: GET_CONSULTATION_HISTORY_REQUEST,
    payload: { id },
  }
}
export const getConsultationHistoryResponse = (data) => {
  return {
    type: GET_CONSULTATION_HISTORY_RESPONSE,
    payload: { data },
  }
}

export const createConsultationReviewRequest = (data) => {
  return {
    type: CREATE_CONSULTATION_REVIEW_REQUEST,
    payload: { data },
  }
}

export const createConsultationReviewResponse = (message, responseType) => {
  return {
    type: CREATE_CONSULTATION_REVIEW_RESPONSE,
    payload: { message, responseType },
  }
}

export const createReviewLawRequest = (data) => {
  return {
    type: CREATE_REVIEW_LAW_REQUEST,
    payload: { data },
  }
}

export const createreReviewLawResponse = (message, responseType) => {
  return {
    type: CREATE_REVIEW_LAW_RESPONSE,
    payload: { message, responseType },
  }
}

export const deleteConsultationDocumentRequest = (data) => {
  return {
    type: DELETE_CONSULTATION_DOCUMENT_REQUEST,
    payload: { data },
  }
}
export const deleteConsultationDocumentResponse = (message, responseType) => {
  return {
    type: DELETE_CONSULTATION_DOCUMENT_RESPONSE,
    payload: { message, responseType },
  }
}

export const getTeamListRequest = (data) => {
  return {
    type: GET_TEAM_LIST_REQUEST,
    payload: { data },
  }
}

export const getTeamListResponse = (data) => {
  return {
    type: GET_TEAM_LIST_RESPONSE,
    payload: { data },
  }
}

export const createTeamDelegateQueryRequest = (data) => {
  return {
    type: CREATE_TEAM_DELEGATE_QUERY_REQUEST,
    payload: { data },
  }
}

export const createTeamDelegateQueryResponse = (message, responseType) => {
  return {
    type: CREATE_TEAM_DELEGATE_QUERY_RESPONSE,
    payload: { message, responseType },
  }
}
export const clearTeamDelegateQueryResponse = () => {
  return {
    type: CLEAR_TEAM_DELEGATE_QUERY_RESPONSE,
  }
}
export const createDocumentRequest = (data) => {
  return {
    type: CREATE_DOCUMENT_REQUEST,
    payload: { data },
  }
}

export const createDocumentResponse = (message, responseType) => {
  return {
    type: CREATE_DOCUMENT_RESPONSE,
    payload: { message, responseType },
  }
}

export const clearCreateDocumentResponse = () => {
  return {
    type: CLEAR_DOCUMENT_RESPONSE,
  }
}

export const queryRespondRequest = (data) => {
  return {
    type: QUERY_RESPOND_REQUEST,
    payload: { data },
  }
}

export const queryRespondResponse = (message, responseType) => {
  return {
    type: QUERY_RESPOND_RESPONSE,
    payload: { message, responseType },
  }
}

export const hideNotificationRequest = (data) => {
  return {
    type: HIDE_NOTIFICATION_REQUEST,
    payload: { data },
  }
}

export const hideNotificationResponse = (message, responseType) => {
  return {
    type: HIDE_NOTIFICATION_RESPONSE,
    payload: { message, responseType },
  }
}
