import {
  APPROVED,
  PENDING,
  PUBLISHED,
  REJECTED,
  REJECTED_BY_APPROVER,
  REJECTED_BY_PUBLISHER,
} from 'constant/databaseConstants'

export const faqInitialState = () => {
  return {
    // sectionName: '',
    // lawId: '',
    // sadasdsd
    faqId: '',
    categoryId: '',
    subCategoryId: '',
    // lawId: '',
    sections: '',
    question: '',
    answer: '',
    reference: '',
    keywords: [],
    firstApprovalId: '',
    secondApprovalId: '',
  }
}

export const createFaqInitialState = (values) => {
  const value = values === undefined ? '' : values
  const userList = value?.approvalDetails?.filter(
    (x) => x.workFlowId == value.workFlowId,
  )

  return {
    countryId: value?.countryId?.toString(),
    categoryId: value?.categoryDetails?.parentCategoryId?.toString(),
    subCategoryId: value?.categoryDetails?.subcategoryId?.toString(),
    laws: value?.lawList ? value?.lawList?.map((x) => x.id) : '',
    sections: value.sectionsList ? value.sectionsList.map((x) => x.id) : '',
    question: value.question ? value.question : '',
    answer: value.answer ? value.answer : '',
    reference: value.reference ? value.reference : '',
    keywords: value.keywords ? value.keywords : '',

    firstApprovalId: userList?.length > 0 ? userList[0]?.approverId : '',
    secondApprovalId: userList?.length > 0 ? userList[1]?.approverId : '',
    // version: values?.currentVersion
    //   ? values?.currentVersion.replace(/\./g, '')
    //   : '',
  }
}

export const createFaqFilterSelectionInitialState = (values) => {
  return {
    categoryId: '',
    subCategoryId: '',
    laws: '',
    sections: '',
    question: '',
    answer: '',
    reference: '',
    keywords: '',
    firstApprovalId: '',
    secondApprovalId: '',
    status: '',
    date: '',
  }
}

export const statusBox = (statusName) => {
  let statusBoxColor = ''
  if (statusName) {
    switch (statusName) {
      case APPROVED:
        statusBoxColor += 'bg-success'
        break
      case REJECTED:
        statusBoxColor += 'bg-danger'
        break
      case REJECTED_BY_APPROVER:
        statusBoxColor += 'bg-danger'
        break
      case REJECTED_BY_PUBLISHER:
        statusBoxColor += 'bg-danger'
        break
      case PENDING:
        statusBoxColor += 'bg-warning'
        break
      case PUBLISHED:
        statusBoxColor += 'bg-success'
        break
      default:
        statusBoxColor += 'bg-secondary'
        break
    }
  }

  return statusBoxColor
}

// pagination: {
//   limit: currentPage,
//   page: dataPerPage,
//   orderKey: 'createdDate',
//   orderBy: 'ASC',
// },


export const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip
  } catch (error) {
    console.error('Error fetching IP address:', error);
  }
}