export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST'
export const CREATE_FAQ_RESPONSE = 'CREATE_FAQ_RESPONSE'

export const GET_LIST_FAQ_REQUEST = 'GET_LIST_FAQ_REQUEST'
export const GET_LIST_FAQ_RESPONSE = 'GET_LIST_FAQ_RESPONSE'

export const GET_LIST_KEYWORDS_REQUEST = 'GET_LIST_KEYWORDS_REQUEST'
export const GET_LIST_KEYWORDS_RESPONSE = 'GET_LIST_KEYWORDS_RESPONSE'

export const GET_FAQ_DETAILS_REQUEST = 'GET_FAQ_DETAILS_REQUEST'
export const GET_FAQ_DETAILS_RESPONSE = 'GET_FAQ_DETAILS_RESPONSE'

export const GET_FAQ_HISTORY_REQUEST = 'GET_FAQ_HISTORY_REQUEST'
export const GET_FAQ_HISTORY_RESPONSE = 'GET_FAQ_HISTORY_RESPONSE'

export const CREATE_FAQ_REVIEW_REQUEST = 'CREATE_FAQ_REVIEW_REQUEST'
export const CREATE_FAQ_REVIEW_RESPONSE = 'CREATE_FAQ_REVIEW_RESPONSE'

export const CREATE_REVIEW_LAW_REQUEST = 'CREATE_REVIEW_LAW_REQUEST'
export const CREATE_REVIEW_LAW_RESPONSE = 'CREATE_REVIEW_LAW_RESPONSE'


export const IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST = 'IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_REQUEST'
export const IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE = 'IS_ACTIVE_DEACTIVE_FAQ_FOR_FRONTEND_RESPONSE'

