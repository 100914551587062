import swal from 'sweetalert'
export const commonConfirmBox = async (title, text, containHtml = false) => {
  let confirmBox = {
    title: title,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: {
        text: 'No',
        value: false,
        visible: true,
      },
      confirm: {
        text: 'Yes',
        value: true,
        visible: true,
      },
    },
    closeOnClickOutside: false,
    closeOnEsc: false,
  }
  if (containHtml) {
    // create separate div element for the HTML error messages
    var element = document.createElement('div')
    element.innerHTML = text
    confirmBox.content = element
  } else {
    confirmBox.text = text
  }
  return swal(confirmBox)
}
export const commonAleartBox = async (title, text, containHtml = false) => {
  let confirmBox = {
    title: title,
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: {
        text: 'ok',
        value: false,
        visible: true,
      },
    },
    closeOnClickOutside: false,
    closeOnEsc: false,
  }
  if (containHtml) {
    // create separate div element for the HTML error messages
    var element = document.createElement('div')
    element.innerHTML = text
    confirmBox.content = element
  } else {
    confirmBox.text = text
  }
  return swal(confirmBox)
}
