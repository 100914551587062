import React, { useEffect } from 'react'
import {
  logoutRequest,
} from 'store/auth/login/action'
import { connect } from 'react-redux'
import { __t } from 'i18n/translator'
import { removeLoggedInUserDetails } from 'helpers/authUtils'
import { SignOutTostrMessage } from 'helpers/tosterHelpers'
import { useNavigate } from 'react-router-dom'
import { SUCCESS } from 'constant/commonConstants'

const Logout = (props) => {

  const navigate = useNavigate()
  useEffect(() => {
    let authenticationLogId = localStorage.getItem("authenticationLogId")
    if (!authenticationLogId) {
      localStorage.clear()
      window.location.pathname = '/console/login'
    }
    props.logoutRequest({ authenticationLogId: authenticationLogId, type: "admin" })

  }, [])

  useEffect(() => {
    if (props.logoutApiResponse?.responseType == SUCCESS) {
      SignOutTostrMessage()
      removeLoggedInUserDetails()
      navigate('/console/login')
    }
  }, [props.logoutApiResponse])
  return (
    <React.Fragment>
      <h1>&nbsp;</h1>
    </React.Fragment>
  )
}

const mapStateToProps = ({ loginReducer }) => {
  return {
    logoutApiResponse: loginReducer.logoutApiResponse,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: (creds) => {
      dispatch(logoutRequest(creds))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Logout)
