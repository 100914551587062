import {
  CLEAR_USER_ARTICLE_DETAILS_RESPONSE,
  CLEAR_USER_ARTICLE_LIST_RESPONSE,
  GET_USER_ARTICLE_DETAILS_REQUEST,
  GET_USER_ARTICLE_DETAILS_RESPONSE,
  GET_USER_ARTICLE_LIST_REQUEST,
  GET_USER_ARTICLE_LIST_RESPONSE,
  UPDATE_POPULAR_FAQ_REQUEST,
} from './actionType'

export const getUserArticleListRequest = (data) => {
  return {
    type: GET_USER_ARTICLE_LIST_REQUEST,
    payload: { data },
  }
}

export const getUserArticleListResponse = (response, offset) => {
  return {
    type: GET_USER_ARTICLE_LIST_RESPONSE,
    payload: { response, offset },
  }
}
export const clearUserArticleListResponse = () => {
  return {
    type: CLEAR_USER_ARTICLE_LIST_RESPONSE,
  }
}
export const updateFaqListRequest = (data) => {
  return {
    type: UPDATE_POPULAR_FAQ_REQUEST,
    payload: { data },
  }
}

export const getUserArticleDetailsRequest = (id) => {
  return {
    type: GET_USER_ARTICLE_DETAILS_REQUEST,
    payload: { id },
  }
}

export const getUserArticleDetailsResponse = (message, responseType, data) => {
  return {
    type: GET_USER_ARTICLE_DETAILS_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const clearUserArticleDetailsResponse = () => {
  return {
    type: CLEAR_USER_ARTICLE_DETAILS_RESPONSE,
  }
}
