export const isSubScribed = () => {
  const Data = localStorage.getItem('verificationData')

  try {
    if (Data && Data.length > 0) {
      return true
    } else return false
  } catch (error) {
    return false
  }
}

export const getsubscriberData = () => {
  const Data = JSON.parse(localStorage.getItem('verificationData'))
  try {
    if (Data != undefined && Data) {
      return Data
    } else return false
  } catch (error) {
    return false
  }
}

export const verificationData = () => {
  const Data = JSON.parse(localStorage.getItem('verificationData'))

  try {
    if (Data != null && Data) {
      return Data
    } else return false
  } catch (error) {
    return false
  }
}
