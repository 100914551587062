import { parseFloatHelper } from 'helpers/mathHelper'

export const isValidEmail = (email) => {
  const regexp = /^([\w\.\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/
  return regexp.test(email) ? true : false
}

export const customEmailvalidator = () => {
  return {
    customEmail: {
      // name the rule
      message: 'The :attribute must be a valid Email.',
      rule: (val, params, validator) => {
        return isValidEmail(val)
      },
    },
  }
}

// allow only character and number
export const customValidatorForOnlyCharacterAndNumber = () => {
  return {
    onlyCharacterAndNumber: {
      // name the rule
      message: 'The :attribute allow only character and number.',
      rule: (val, params, validator) => {
        const regexp = /^[ A-Za-z0-9]*$/
        return regexp.test(val) ? true : false
      },
    },
  }
}
export const greaterThanValidator = () => {
  return {
    greaterThan: {
      // name the rule
      message: 'The :attribute must be greater than :values.',
      rule: (val, params) => {
        return parseFloatHelper(val) > parseFloatHelper(params[0])
          ? true
          : false
      },
      messageReplace: (message, params) =>
        message.replace(':values', params[0]), // optional
      required: true, // optional
    },
  }
}

export const greaterThanEqualToValidator = () => {
  return {
    greaterThanEqualTo: {
      // name the rule
      message: 'The :attribute must be greater than or equal to :values.',
      rule: (val, params) => {
        return parseFloatHelper(val) >= parseFloatHelper(params[0])
          ? true
          : false
      },
      messageReplace: (message, params) =>
        message.replace(':values', params[0]), // optional
      required: true, // optional
    },
  }
}

export const lessThanValidator = () => {
  return {
    lessThan: {
      // name the rule
      message: 'The :attribute must be less than :values.',
      rule: (val, params) => {
        return parseFloatHelper(val) < parseFloatHelper(params[0])
          ? true
          : false
      },
      messageReplace: (message, params) =>
        message.replace(':values', params[0]), // optional
      required: true, // optional
    },
  }
}

export const lessThanEqualToValidator = () => {
  return {
    lessThanEqualTo: {
      // name the rule
      message: 'The :attribute must be less than or equal to :values.',
      rule: (val, params) => {
        return parseFloatHelper(val) <= parseFloatHelper(params[0])
          ? true
          : false
      },
      messageReplace: (message, params) =>
        message.replace(':values', params[0]), // optional
      required: true, // optional
    },
  }
}

//  validation function to check the value of input is valid or not
export const isValidNumericField = (value, precision, scale) => {
  var regex = new RegExp(
    '^(0|-?\\d{0,' + (precision - scale) + '}(\\.\\d{0,' + scale + '})?)$',
    'i',
  )

  return regex.test(value) ? true : false
}

//  validation function to check the value of input is valid or not
export const isValidCharacterField = (value, maxLength) => {
  var regex = new RegExp(`^.{0,${maxLength}}$`)
  return regex.test(value) ? true : false
}

export const customImageValidator = (value) => {
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(value) ? true : false
}

// allow only decimal number with comma seperated
export const customValidatorForOnlyDecimalNumberWithComma = () => {
  return {
    onlyDecimalNumberWithComma: {
      // name the rule
      message: 'The :attribute allow only comma seperated decimal numbers.',
      rule: (val, params, validator) => {
        const regexp = /^[,.0-9]*$/
        return regexp.test(val) ? true : false
      },
    },
  }
}

export const cashAmountValidator = () => {
  return {
    cashAmountValue: {
      message: 'Cash receipt amount exceed.',
      rule: (val, params) => {
        // check params[0](TotalCashAmount) value is greater then params[1](cashPaymentLimit) or not
        // if yes then it will not allow us to proceed as we already reached cash limit
        return parseFloatHelper(params[0]) > parseFloatHelper(params[1])
          ? false
          : true
      },
      required: true, // optional
    },
  }
}

//  validation function to check the value of PanNo is valid or not
export const isValidPanNoField = () => {
  return {
    isValidPanNo: {
      message: 'The :attribute must be valid pan number',
      rule: (val, params, validator) => {
        const regexp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
        return regexp.test(val) ? true : false
      },
    },
  }
}

export const isValidGstNoField = () => {
  return {
    isValidGstNo: {
      message: 'The :attribute must be valid gst number',
      rule: (val, params, validator) => {
        const regexp =
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
        return regexp.test(val) ? true : false
      },
    },
  }
}

export const isValidCharacterFieldX = () => {
  return {
    // using for allow only alphabets
    isValidCharacter: {
      message: 'The :attribute must be String',
      rule: (val, params, validator) => {
        //only alpha allows
        const onlyAlpha = /^[aA-zZ\s]+$/
        return onlyAlpha.test(val) ? true : false
      },
    },
  }
}

export const isValidNumberField = () => {
  return {
    // using for allow only alphabets
    isValidNumber: {
      message: 'Phone number must have 10 digits only',
      rule: (val, params, validator) => {
        const regexp =
          // ^[a-zA-Z0-9]{8,}$ #8 or more characters
          // ^[a-zA-Z0-9]{,16}$ #Less than or equal to 16 characters
          // ^[a-zA-Z0-9]{8}$ #Exactly 8 characters

          /^[0-9]{10}$/
        return regexp.test(val) ? true : false
      },
    },
  }
}

export const isValidCategoryaField = () => {
  return {
    arrayInValueCheck: {
      message: 'Category is already exists',
      rule: (val, params) => {
        return !params.includes(val)
      },
      messageReplace: (message) => message, // optional
      required: true, // optional
    },
  }
}

export const isValidCountryCode = () => {
  return {
    isValidCountryCode: {
      message: 'The :attribute must be valid country code',
      rule: (val, params, validator) => {
        const regexp = /^(\+?\d{1,3}|\d{1,4})$/
        return regexp.test(val) ? true : false
      },
    },
  }
}

export const customFileValidator = (value) => {
  return /\.(zip|pdf|jpg|jpeg|png|txt|doc|docx|csv|xlsx|xls)$/i.test(value)
    ? true
    : false
}

export const isUrl = (value) => {
  return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value) ? true : false
}
