import ShowMoreText from 'react-show-more-text'

export const UserInitialState = () => {
  return {
    email: '',
    password: '',
  }
}

export const createUserInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    email: value.email,
    contactNo: value.phoneNumber,
    roleId: value.roleId,
    fullName: value.fullName,
    countryId: value.countryList,
    userId: value.userId,
  }
}

export const addLawInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    lawId: value.id,
    lawName: value.lawName,
    categoryId: value?.category?.id,
    lawDescription: value.lawDescription,
    subCategoryId: value.subCat?.id,
    revisionDate: '',
    revisionDescription: '',
  }
}

export const initialPaginationState = (currentPage, dataPerPage) => {
  return {
    searchText: '',
    pagination: {
      limit: currentPage,
      page: dataPerPage,
      orderKey: 'createdDate',
      orderBy: 'ASC',
    },
    filter: {},
  }
}

export const getFormattedDescription = (cell) => {
  return (
    <ShowMoreText
      lines={2}
      more={
        <span
          className="show-more-link more"
          style={{ color: 'blue', fontSize: '12px' }}
        >
          Show more
        </span>
      }
      less={
        <span
          className="show-more-link less"
          style={{ color: 'blue', fontSize: '12px' }}
        >
          Show less
        </span>
      }
      className="show-more-content"
      anchorClass="show-more-link"
      expandByClick={true}
      expanded={false}
      keepNewLines={true}
    >
      {cell}
    </ShowMoreText>
  )
}
