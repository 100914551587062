import {
  GET_SUBSCIBER_EXPORT_REQUEST,
  GET_SUBSCIBER_EXPORT_RESPONSE,
  GET_SUBSCRIBER_LIST_REQUEST,
  GET_SUBSCRIBER_LIST_RESPONSE,
} from './actionType'

export const getSubsciberListRequest = (data) => {
  return {
    type: GET_SUBSCRIBER_LIST_REQUEST,
    payload: { data },
  }
}

export const getSubsciberListResponse = (message, responseType, data) => {
  return {
    type: GET_SUBSCRIBER_LIST_RESPONSE,
    payload: { message, responseType, data },
  }
}


export const getSubsciberExportRequest = () => {
  return {
    type: GET_SUBSCIBER_EXPORT_REQUEST,
  }
}

export const getSubsciberExportResponse = (message, responseType, data) => {
  return {
    type: GET_SUBSCIBER_EXPORT_RESPONSE,
    payload: { message, responseType, data },
  }
}

