import { takeEvery, fork, put, all, call } from 'redux-saga/effects'
import {
  GET_TEMPLATE_LIST_REQUEST,
  CREATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_REQUEST,
  GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST,
  GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
  // GET_ROLE_LIST_REQUEST,
} from '../emailTemplate/actionType'

import {
  getTemplateListResponse,
  createTemplateResponse,
  updateTemplateResponse,
  getCustomEmailFieldListResponse,
  getTemplateDetailsListByIdResponse,
  updateEmailTemplateResponse,
  updateEmailTemplateStatusResponse,
} from '../emailTemplate/action'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'
import { invokeApi } from 'helpers/axiosHelper'

function* createTemplate({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/rolessss', data)
    if (response.status) {
      yield put(createTemplateResponse(response))
      // yield put(getTemplateListRequest())
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(createTemplateResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//updateTemplateResponse
function* updateTemplate({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/rolesss', data)
    if (response.status) {
      yield put(updateTemplateResponse(response.message))
      // yield put(getTemplateListRequest())
      ApiResponseMessage(response.message, SUCCESS)
    } else {
      yield put(updateTemplateResponse(response.message, DANGER))
      ApiResponseMessage(response.message, DANGER)
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

//get list
function* getTemplateList() {
  try {
    const response = yield call(invokeApi, 'get', '/emailTemplateTypes')
    if (response.status) {
      yield put(getTemplateListResponse(response.data))
    } else {
      yield put(getTemplateListResponse(response.message, DANGER))
    }
  } catch (error) {
    // yield put(handleApiCallException(error))
  }
}

// //get list
function* getTemplateDetailsByID({ payload: { Data } }) {
  try {
    const response = yield call(
      invokeApi,
      'post',
      '/emailTemplateDetails',
      Data,
    )

    if (response.status) {
      yield put(getTemplateDetailsListByIdResponse(response.data))
    } else {
      yield put(getTemplateDetailsListByIdResponse(response.message, DANGER))
    }
  } catch (error) {
    // yield put(handleApiCallException(error))
  }
}

function* getCustomFieldsTemplateList() {
  try {
    const response = yield call(invokeApi, 'get', '/EmailCustomFields')
    if (response.status) {
      yield put(getCustomEmailFieldListResponse(response.data.list))
    } else {
      yield put(getCustomEmailFieldListResponse(response.message, DANGER))
    }
  } catch (error) {
    // yield put(handleApiCallException(error))
  }
}

function* editTemplate({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'put',
      '/emailTemplate/' + data.templateId,
      data,
    )
    if (response.status) {
      yield put(updateEmailTemplateResponse(response.message, response.status))
    } else {
      yield put(
        updateEmailTemplateResponse(
          response && response.message,
          response.status,
        ),
      )
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    //yield put(handleApiCallException(error));
  }
}

function* emailTemplateDetailStatus({ payload: { data } }) {
  try {
    const response = yield call(
      invokeApi,
      'put',
      '/emailTemplateDetailStatus',
      data,
    )
    if (response.status) {
      yield put(
        updateEmailTemplateStatusResponse(response.message, response.status),
      )
    } else {
      yield put(
        updateEmailTemplateStatusResponse(
          response && response.message,
          response.status,
        ),
      )
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    //yield put(handleApiCallException(error));
  }
}

//watch Function
export function* watchLaw() {
  yield takeEvery(
    UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
    emailTemplateDetailStatus,
  )
  yield takeEvery(UPDATE_EMAIL_TEMPLATE_REQUEST, editTemplate)
  yield takeEvery(CREATE_TEMPLATE_REQUEST, createTemplate)
  yield takeEvery(UPDATE_TEMPLATE_REQUEST, updateTemplate)
  yield takeEvery(GET_TEMPLATE_LIST_REQUEST, getTemplateList)
  yield takeEvery(
    GET_TEMPLATE_DETAILS_LIST_BY_ID_REQUEST,
    getTemplateDetailsByID,
  )
  yield takeEvery(
    GET_CUSTOM_EMAIL_FIELD_LIST_REQUEST,
    getCustomFieldsTemplateList,
  )
}

function* emailtemplateSaga() {
  yield all([fork(watchLaw)])
}
export default emailtemplateSaga
