export const UserInitialState = () => {
  return {
    email: '',
    password: '',
  }
}

export const createUserInitialState = (values) => {
  let value = values === undefined ? '' : values
  return {
    email: value.email,
    contactNo: value.phoneNumber,
    roleId: value.roleId,
    fullName: value.fullName,
    countryId: value ? value.countryList[0] : [],
    userId: value.userId,
    categoryId: value?.categoryList,
    subCategoryId: value?.subCategoryList,
    authenticationMode: value?.authenticationMode
      ? value?.authenticationMode
      : 1,
  }
}
