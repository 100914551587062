import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import { ApiResponseMessage } from 'helpers/tosterHelpers'
import { handleApiCallException } from 'store/shared/action'

import { invokeApi } from 'helpers/axiosHelper'
import { DANGER, SUCCESS } from 'constant/commonConstants'
import {
  createAdminResendQueryResponse,
  getAdminConversationDetailsResponse,
  getQueryListResponse,
} from './action'
import {
  CREATE_ADMIN_RESEND_QUERY_REQUEST,
  GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST,
  GET_QUERY_REQUEST,
} from './actionType'

function* getQueryList({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/console/queryList', data)
    if (response.status) {
      yield put(getQueryListResponse(response.data, data.offset))
    } else {
      yield put(getQueryListResponse([]))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* getConversationDetails({ payload: { id } }) {
  try {
    const response = yield call(
      invokeApi,
      'get',
      `/console/conversationDetails/${id}`,
    )

    if (response.status) {
      yield put(
        getAdminConversationDetailsResponse(
          response.message,
          SUCCESS,
          response.data,
        ),
      )
    } else {
      yield put(getAdminConversationDetailsResponse(response.message, DANGER))
    }
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

function* createResendQuery({ payload: { data } }) {
  try {
    const response = yield call(invokeApi, 'post', '/console/resendRespond', data)
    if (response.status) {
      yield put(createAdminResendQueryResponse(response.message, SUCCESS))
    } else {
      yield put(createAdminResendQueryResponse(response.message, DANGER))
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
  } catch (error) {
    yield put(handleApiCallException(error))
  }
}

export function* watchReport() {
  yield takeEvery(GET_QUERY_REQUEST, getQueryList)
  yield takeEvery(
    GET_ADMIN_CONVERSATION_DETAILS_QUERY_REQUEST,
    getConversationDetails,
  )
  yield takeEvery(CREATE_ADMIN_RESEND_QUERY_REQUEST, createResendQuery)
}
function* ticketSaga() {
  yield all([fork(watchReport)])
}
export default ticketSaga
