import {
  ADD_COUNTRY_REQUEST,
  ADD_COUNTRY_RESPONSE,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_RESPONSE,
  ADD_COUNTRY_STATUS_REQUEST,
  ADD_COUNTRY_STATUS_RESPONSE,
  USER_COUNTRY_LIST_RESPONSE,
  USER_COUNTRY_LIST_REQUEST,
} from './actionType'

export const addCountryRequest = (data) => {
  return {
    type: ADD_COUNTRY_REQUEST,
    payload: { data },
  }
}

//add country response
export const addCountryResponse = (message, responseType) => {
  return {
    type: ADD_COUNTRY_RESPONSE,
    payload: { message, responseType },
  }
}

export const getCountryListRequest = (Data) => {
  return {
    payload: { Data },
    type: GET_COUNTRY_LIST_REQUEST,
  }
}
export const getCountryListResponse = (countryList) => {
  return {
    type: GET_COUNTRY_LIST_RESPONSE,
    payload: { countryList },
  }
}

export const addCountryStatusRequest = (data) => {
  return {
    type: ADD_COUNTRY_STATUS_REQUEST,
    payload: { data },
  }
}

export const addCountryStatusResponse = (message, responseType, data) => {
  return {
    type: ADD_COUNTRY_STATUS_RESPONSE,
    payload: { message, responseType, data },
  }
}

export const userCountryListRequest = (Data) => {
  return {
    payload: { Data },
    type: USER_COUNTRY_LIST_REQUEST,
  }
}

export const userCountryListResponse = (userCountryList) => {
  return {
    type: USER_COUNTRY_LIST_RESPONSE,
    payload: { userCountryList },
  }
}
